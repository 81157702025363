import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { useForm, Controller } from 'react-hook-form';
import { Typeahead } from 'react-bootstrap-typeahead';
import qs from 'query-string';
import scrollIntoView from 'scroll-into-view-if-needed'
import moment from 'moment';

import { useApi } from 'lib/effects';
import { post } from 'lib/api';
import withFormHelpers from 'lib/formHelpers';
import { CancellationToken } from 'lib/cancellationTokens';

import LoadingBar from 'components/LoadingBar';
import Pagination from 'components/Pagination';
import LogsTable from 'components/LogsTable';

export function Logs(props) {

    let anchorRef = useRef();

    let { addToast } = useToasts();

    let params = qs.parse(props.location.search, { arrayFormat: 'bracket-separator' });

    let defaultCriteria = { 
        logTypes: [], 
        users: [],
        filters: [],
        startDate: null,
        endDate: null,
        page: 1,
    };

    let currentCriteria = {
        ...defaultCriteria,
        ...params,
        logTypes: params.logTypes ? params.logTypes.map(t => parseInt(t)) : defaultCriteria.logTypes,
        users: params.users ? params.users.map(t => parseInt(t)) : defaultCriteria.users,
        page: params.page ? parseInt(params.page) : defaultCriteria.page,
    };

    let form = withFormHelpers(useForm({ shouldUnregister: false }));

    let [ typeOptions, setTypeOptions ] = useState(null);
    let [ userOptions, setUserOptions ] = useState(null);
    let [ filterOptions, setFilterOptions ] = useState(null);

    let [ isSearching, setIsSearching ] = useState(false);

    let [ logs, setLogs ] = useState({
        items: [],
        page: 1,
        totalPages: 0,
        totalCount: 0,
    });

    useApi('/api/logs', (response, ex) => {

        if (ex) {
            addToast(ex.message, { appearance: 'error' });
            return;
        }

        setTypeOptions(response.logTypes);
        setUserOptions(response.users);
        setFilterOptions(response.filterOptions);

    }, []);


    useEffect(() => {

        form.reset(currentCriteria);

        setIsSearching(true);

        let cancellationToken = new CancellationToken();

        async function asyncRequest() {

            let response;

            try {
                response = await post('/api/logs/search', currentCriteria, cancellationToken);
            }
            catch (ex) {
    
                if (cancellationToken.isCancelled) {
                    return;
                }
    
                setIsSearching(false);

                addToast(ex.message, { appearance: 'error' });
                return;
    
            }

            setIsSearching(false);

            setLogs(response);

            if (anchorRef.current) {
                scrollIntoView(anchorRef.current, {
                    scrollMode: 'if-needed',
                    block: 'start',
                });
            }
            
        }

        asyncRequest();

        return cancellationToken;

    }, [ props.location ]);

    function generatePath({ logTypes, users, filters, startDate, endDate, page }) {

        let query = {
            logTypes: !logTypes.sequenceEqual(defaultCriteria.logTypes) ? logTypes : null,
            users: !users.sequenceEqual(defaultCriteria.users) ? users : null,
            filters: !filters.sequenceEqual(defaultCriteria.filters) ? filters : null,
            startDate: startDate || null,
            endDate: endDate || null,
            page: page != 1 ? page : null, 
        };

        return '/admin/logs?' + qs.stringify(query, { skipNull: true, arrayFormat: 'bracket-separator', sort: false });

    }

    function handleSubmit(formData) {
        props.history.push(generatePath({ ...formData, page: 1 }));
    }

    function handleReset() {
        props.history.push(generatePath(defaultCriteria));
    }

    if (!typeOptions || !userOptions || !filterOptions) {
        return (
            <LoadingBar />
        );
    }


    return (

        <React.Fragment>

            {isSearching &&
                <LoadingBar />
            }

            <div className="mt-3 container-fluid">

                <h3 className="mb-3"><FontAwesomeIcon icon={[ 'fas', 'search' ]} fixedWidth /> Logs</h3>

                <div className="card mb-3">
                    <div className="card-body">

                        <form onSubmit={form.handleSubmit(handleSubmit)}>

                            <fieldset disabled={isSearching}>

                                <div className="form-group form-row mb-0">
                                    <label className="col-sm-1 col-form-label-sm">Filter By Type</label>
                                    <div className="col-sm-11">

                                        <Controller
                                            name="logTypes"
                                            control={form.control}
                                            render={(renderProps) => (
                                            
                                                <Typeahead
                                                    id="logTypes"
                                                    ref={renderProps.ref}
                                                    size="sm"
                                                    multiple={true}
                                                    clearButton={true}
                                                    options={typeOptions}
                                                    selected={typeOptions.filter(t => renderProps.value.includes(t.id))}
                                                    inputProps={{
                                                        shouldSelectHint: (shouldSelect, e) => {
                                                            return e.code == 'Enter' || shouldSelect;
                                                        },
                                                    }}
                                                    labelKey={c => c.name}
                                                    onChange={(options) => { renderProps.onChange(options.map(o => o.id)); }}
                                                    onBlur={renderProps.onBlur}

                                                />

                                        )}/>

                                    </div>
                                </div>

                                <div className="form-group form-row mb-0">
                                    <label className="col-sm-1 col-form-label-sm">Filter By User</label>
                                    <div className="col-sm-11">

                                        <Controller
                                            name="users"
                                            control={form.control}
                                            render={(renderProps) => (
                                            
                                                <Typeahead
                                                    id="users"
                                                    ref={renderProps.ref}
                                                    size="sm"
                                                    multiple={true}
                                                    clearButton={true}
                                                    options={userOptions}
                                                    selected={userOptions.filter(t => renderProps.value.includes(t.id))}
                                                    inputProps={{
                                                        shouldSelectHint: (shouldSelect, e) => {
                                                            return e.code == 'Enter' || shouldSelect;
                                                        },
                                                    }}
                                                    labelKey={c => c.fullName}
                                                    onChange={(options) => { renderProps.onChange(options.map(o => o.id)); }}
                                                    onBlur={renderProps.onBlur}

                                                />

                                        )}/>

                                    </div>
                                </div>

                                <div className="form-group form-row mb-0">
                                    <label className="col-sm-1 col-form-label-sm">Additional Filters</label>
                                    <div className="col-sm-11">
    
                                        <Controller
                                            name="filters"
                                            control={form.control}
                                            render={(renderProps) => (
                                            
                                                <Typeahead
                                                    id="filters"
                                                    ref={renderProps.ref}
                                                    size="sm"
                                                    multiple={true}
                                                    clearButton={true}
                                                    options={filterOptions}
                                                    selected={renderProps.value}
                                                    inputProps={{
                                                        shouldSelectHint: (shouldSelect, e) => {
                                                            return e.code == 'Enter' || shouldSelect;
                                                        },
                                                    }}
                                                    onChange={(options) => { renderProps.onChange(options); }}
                                                    onBlur={renderProps.onBlur}

                                                />

                                        )}/>

                                    </div>
                                </div>

                                <div className="form-group form-row mb-0">
                                    <label className="col-sm-1 col-form-label-sm">Start Date</label>
                                    <div className="col-sm-11">
                                        <input className="form-control form-control-sm" name="startDate" type="date" ref={form.register} />
                                    </div>
                                </div>

                                <div className="form-group form-row mb-0">
                                    <label className="col-sm-1 col-form-label-sm">End Date</label>
                                    <div className="col-sm-11">
                                        <input className="form-control form-control-sm" name="endDate" type="date" ref={form.register} />
                                    </div>
                                </div>

                                <div className="form-group form-row mb-0">
                                    <div className="col-sm-12">

                                        <button type="submit" className="btn btn-sm btn-primary">
                                            {isSearching &&
                                                <span className="spinner-border spinner-border-sm"></span>
                                            }
                                            {' '}
                                            Search
                                        </button>
                                        {' '}
                                        <button type="button" className="btn btn-sm btn-secondary" onClick={handleReset}>Reset</button>


                                    </div>
                                </div>

                            </fieldset>
                        </form>

                    </div>
                </div>

                <div ref={anchorRef}></div>

                <LogsTable logs={logs.items} />
                
                <Pagination page={logs.page} totalPages={logs.totalPages} pathGenerator={(page) => generatePath({ ...currentCriteria, page })} />

            </div>

        </React.Fragment>

    );

}

Logs.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

export default withRouter(Logs);