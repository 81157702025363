import React, { useState } from 'react'
import Decimal from 'decimal.js';
import NumberInput from 'components/NumberInput';
import { useToasts } from 'react-toast-notifications';

export default function HelperNzPostGst(props) {

    let { addToast } = useToasts();

    let [ gstAmount, setGstAmount ] = useState(0);
    let [ totalAmount, setTotalAmount ] = useState(0);

    function calculate() {

        let domestic = 0;
        let international = 0;

        if (gstAmount && totalAmount) {

            let gstAmountDec = Decimal(gstAmount);
            let totalAmountDec = Decimal(totalAmount);
    
            let taxableAmountPaidExcludingTax = gstAmountDec.div(0.15).toDP(2);
    
            let taxableAmountPaidIncludingTax = taxableAmountPaidExcludingTax.add(gstAmountDec);
    
            let nonTaxableAmountPaid = totalAmountDec.sub(taxableAmountPaidIncludingTax);
    
            domestic = taxableAmountPaidExcludingTax.toNumber();
            international = nonTaxableAmountPaid.toNumber();

        }

        return {
            domestic,
            international,
        };

    }

    function handleResultClicked(event) {
        event.target.select();
        document.execCommand('copy');
        addToast('Copied to clipboard', { appearance: 'info' });
    }

    return (

        <div className="mt-3 container-fluid">
            
            <h3>NZ Post GST Helper</h3>

            <p>Calculates domestic/international components for NZ Post invoices.</p>

            <div className="row">

                <div className="col" style={{ maxWidth: 'var(--breakpoint-sm)' }}>

                    <div className="form-group">
                        <label>GST Amount</label>
                        <NumberInput className="form-control" value={gstAmount} onChange={setGstAmount} />
                    </div>

                    <div className="form-group">
                        <label>Total Amount</label>
                        <NumberInput className="form-control" value={totalAmount} onChange={setTotalAmount} />
                    </div>

                    <hr />

                    <div className="form-group">
                        <label>Domestic (15% GST on Expenses)</label>
                        <input readOnly type="text" className="form-control" value={calculate().domestic} onClick={handleResultClicked} />
                    </div>

                    <div className="form-group">
                        <label>International (No GST)</label>
                        <input readOnly type="text" className="form-control" value={calculate().international} onClick={handleResultClicked} />
                    </div>

                </div>

            </div>


        </div>

    );

}