import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { useForm } from 'react-hook-form';
import qs from 'query-string';
import scrollIntoView from 'scroll-into-view-if-needed'
import moment from 'moment';

import { post } from 'lib/api';
import withFormHelpers from 'lib/formHelpers';
import { CancellationToken } from 'lib/cancellationTokens';

import LoadingBar from 'components/LoadingBar';
import Pagination from 'components/Pagination';


export function BlacklistEntries(props) {

    let anchorRef = useRef();

    let { addToast } = useToasts();

    let params = qs.parse(props.location.search, { arrayFormat: 'bracket-separator' });

    let defaultCriteria = { 
        searchString: null,
        page: 1,
    };

    let currentCriteria = {
        ...defaultCriteria,
        ...params,
        page: params.page ? parseInt(params.page) : defaultCriteria.page,
    };

    let form = withFormHelpers(useForm({ shouldUnregister: false }));

    let [ isSearching, setIsSearching ] = useState(false);

    let [ entries, setEntries ] = useState({
        items: [],
        page: 1,
        totalPages: 0,
        totalCount: 0,
    });

    useEffect(() => {

        form.reset(currentCriteria);

        setIsSearching(true);

        let cancellationToken = new CancellationToken();

        async function asyncRequest() {

            let response;

            try {
                response = await post('/api/blacklist/search', currentCriteria, cancellationToken);
            }
            catch (ex) {
    
                if (cancellationToken.isCancelled) {
                    return;
                }
    
                setIsSearching(false);

                addToast(ex.message, { appearance: 'error' });
                return;
    
            }

            setIsSearching(false);

            setEntries(response);

            if (anchorRef.current) {
                scrollIntoView(anchorRef.current, {
                    scrollMode: 'if-needed',
                    block: 'start',
                });
            }
            
        }

        asyncRequest();

        return cancellationToken;

    }, [ props.location ]);

    function generatePath({ searchString, page }) {

        let query = {
            searchString: searchString || null,
            page: page != 1 ? page : null, 
        };

        return '/admin/blacklist?' + qs.stringify(query, { skipNull: true, arrayFormat: 'bracket-separator', sort: false });

    }

    function handleSubmit(formData) {
        props.history.push(generatePath({ ...formData, page: 1 }));
    }

    function handleReset() {
        props.history.push(generatePath(defaultCriteria));
    }

    return (

        <React.Fragment>

            {isSearching &&
                <LoadingBar />
            }

            <div className="mt-3 container-fluid">

                <div className="float-right">
                    <Link className="btn btn-primary" to="/admin/blacklist/new"><FontAwesomeIcon icon={[ 'fas', 'plus' ]} fixedWidth /> Add Entry</Link>

                </div>

                <h3 className="mb-3"><FontAwesomeIcon icon={[ 'fas', 'skull-crossbones' ]} fixedWidth /> Blacklist</h3>

                <div className="card mb-3">
                    <div className="card-body">

                        <form onSubmit={form.handleSubmit(handleSubmit)}>

                            <fieldset disabled={isSearching}>

                                <div className="form-group form-row mb-0">
                                    <label className="col-sm-1 col-form-label-sm">Search String</label>
                                    <div className="col-sm-11">
                                        <input name="searchString" type="text" ref={form.register} className="form-control form-control-sm mr-sm-2" id="searchString" placeholder="Search string" />
                                    </div>
                                </div>

                                <div className="form-group form-row mb-0">
                                    <div className="col-sm-12">

                                        <button type="submit" className="btn btn-sm btn-primary">
                                            {isSearching &&
                                                <span className="spinner-border spinner-border-sm"></span>
                                            }
                                            {' '}
                                            Search
                                        </button>
                                        {' '}
                                        <button type="button" className="btn btn-sm btn-secondary" onClick={handleReset}>Reset</button>


                                    </div>
                                </div>

                            </fieldset>
                        </form>

                    </div>
                </div>

                <div ref={anchorRef}></div>

                <table className="table">
                    <thead className="thead-dark" style={{ position: 'sticky', top: '0px', zIndex: 1 }}>
                        <tr>
                            <th style={{ width: '1%' }}></th>
                            <th>Description</th>
                            <th style={{ width: '15%' }}>Created On</th>
                        </tr>
                    </thead>

                    <tbody>

                        {entries.items.map((entry) => (
                            <tr key={entry.id}>
                                <td className="text-center"><FontAwesomeIcon icon={[ 'fas', entry.isActive ? 'check' : 'times' ]} /></td>
                                <td><Link className="text-reset" to={`/admin/blacklist/${entry.id}`}>{entry.description}</Link></td>
                                <td>{moment(entry.createdOn).toDate().toLocaleDateString()}</td>
                            </tr>
                        ))}

                        {!entries.totalCount &&
                            <tr>
                                <td colSpan={3}>No results</td>
                            </tr>
                        }
                    </tbody>

                </table>

                <Pagination page={entries.page} totalPages={entries.totalPages} pathGenerator={(page) => generatePath({ ...currentCriteria, page })} />

            </div>

        </React.Fragment>

    );

}

BlacklistEntries.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

export default withRouter(BlacklistEntries);