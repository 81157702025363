import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import { useToasts } from 'react-toast-notifications';
import { Typeahead } from 'react-bootstrap-typeahead';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import cx from 'classnames';
import Decimal from 'decimal.js';

import { useApi } from 'lib/effects';
import { post } from 'lib/api';
import { CancellationToken } from 'lib/cancellationTokens';
import { probabilityScoreStyles, preparednessScoreStyles } from 'lib/utils';

import LoadingBar from 'components/LoadingBar';
import NumberInput from 'components/NumberInput';
import InventoryProductsDetailsModal from 'components/InventoryProductsDetailsModal';
import AnalyseProjectButton from 'components/AnalyseProjectButton';
import NewOrderAddProductsModal from 'components/NewOrderAddProductsModal';
import NewOrderImportModal from 'components/NewOrderImportModal';

export default function NewOrder(props) {

    let { addToast } = useToasts();

    let [ supplierOptions, setSupplierOptions ] = useState(null);
    let [ analyseOptions, setAnalyseOptions ] = useState(null);
    let [ projectOptions, setProjectOptions ] = useState(null);

    let [ sortIndex, setSortIndex ] = useState(0);

    let [ products, setProducts ] = useState([]);
    let [ supplier, setSupplier ] = useState();
    let [ analyse, setAnalyse ] = useState('Last 90 Days');
    let [ project, setProject ] = useState('Next 30 Days');

    let [ isProjectAnalyseWarningDismissed, setIsProjectAnalyseWarningDismissed ] = useState(false);
    let [ isSelectingSupplier, setIsSelectingSupplier ] = useState(false);
    let [ isConfirmingSupplierProductLoad, setIsConfirmingSupplierProductLoad ] = useState(false);
    let [ isLoadingSupplierProducts, setIsLoadingSupplierProducts ] = useState(false);
    let [ isConfirmingCancelOrder, setIsConfirmingCancelOrder ] = useState(false);
    let [ isViewingProductDetails, setIsViewingProductDetails ] = useState(null);
    let [ isUsingAddProductsModal, setIsUsingAddProductsModal ] = useState(false);
    let [ pendingRemoveProductsOperation, setPendingRemoveProductsOperation ] = useState(false);
    let [ shouldHideUnneededProducts, setShouldHideUnneededProducts ] = useState(false);
    let [ shouldHideUnavailableProducts, setShouldHideUnavailableProducts ] = useState(true);
    let [ isRefreshingProducts, setIsRefreshingProducts ] = useState(false);
    let [ isConfirmingQuantityUpdate, setIsConfirmingQuantityUpdate ] = useState(false);
    let [ isConfirmingZeroQuantities, setIsConfirmingZeroQuantities ] = useState(false);
    let [ shouldHidePrices, setShouldHidePrices ] = useState(true);

    let [ pendingExportHgCsvOperation, setPendingExportHgCsvOperation ] = useState(null);
    let [ pendingExportGenericCsvOperation, setPendingExportGenericCsvOperation ] = useState(null);
    let [ pendingExportEliquidTableCsvOperation, setPendingExportEliquidTableCsvOperation ] = useState(null);
    let [ pendingSendToDearOperation, setPendingSendToDearOperation ] = useState(null);
    let [ pendingExportDearPortalOperation, setPendingExportDearPortalOperation ] = useState(null);
    let [ pendingExportDearPurchaseOrderOperation, setPendingExportDearPurchaseOrderOperation ] = useState(null);

    let [ isImporting, setIsImporting ] = useState(false);
    let [ pendingOrderImport, setPendingOrderImport ] = useState(null);

    useApi('/api/ordering', (response, ex) => {

        if (ex) {
            addToast(ex.message, { appearance: 'error' });
            return;
        }

        setSupplierOptions(response.suppliers);
        setAnalyseOptions(response.analyseOptions);
        setProjectOptions(response.projectOptions);

    }, []);

    useEffect(() => {

        if (!isLoadingSupplierProducts) {
            return;
        }

        let cancellationToken = new CancellationToken();

        async function asyncRequest() {

            let accumulatedProducts = [];
            
            let request = {
                suppliers: [ supplier.name ],
                filters: [ 'Inventory Item', 'Not Discontinued', 'Not Deprecated' ],
                brands: [],
                categories: [],
                sorting: 'Smart',
                analyse,
                project,
                searchString: null,
            };

            let page = 0;

            while (true) {

                page++;

                let response;

                try {
                    response = await post('/api/inventory/search', { ...request, page }, cancellationToken);
                }
                catch (ex) {
        
                    if (cancellationToken.isCancelled) {
                        return;
                    }
        
                    setIsLoadingSupplierProducts(false);
    
                    addToast(ex.message, { appearance: 'error' });
                    return;
        
                }
    
                accumulatedProducts = accumulatedProducts.concat(response.items);

                if (page >= response.totalPages) {
                    break;
                }

            }

            setIsLoadingSupplierProducts(false);
            addProducts(accumulatedProducts);

        }

        asyncRequest();

        return cancellationToken;

    }, [ isLoadingSupplierProducts ]);


    useEffect(() => {

        if (!isRefreshingProducts) {
            return;
        }

        let cancellationToken = new CancellationToken();

        async function asyncRequest() {

            let accumulatedProducts = [];
            
            let request = {
                productIds: products.map(p => p.id),
                sorting: 'Id',
                analyse,
                project,
            };

            let page = 0;

            while (true) {

                page++;

                let response;

                try {
                    response = await post('/api/inventory/search', { ...request, page }, cancellationToken);
                }
                catch (ex) {
        
                    if (cancellationToken.isCancelled) {
                        return;
                    }
        
                    setIsRefreshingProducts(false);
    
                    addToast(ex.message, { appearance: 'error' });
                    return;
        
                }

                accumulatedProducts = accumulatedProducts.concat(response.items);

                if (page >= response.totalPages) {
                    break;
                }

            }

            setIsRefreshingProducts(false);
            updateProducts(accumulatedProducts);

        }

        asyncRequest();

        return cancellationToken;

    }, [ isRefreshingProducts ]);


    useEffect(() => {

        if (!pendingExportHgCsvOperation) {
            return;
        }

        let cancellationToken = new CancellationToken();

        async function asyncRequest() {

            let response;

            try {
                response = await post('/api/bulkaction/exporthg', pendingExportHgCsvOperation, cancellationToken);
            }
            catch (ex) {
    
                if (cancellationToken.isCancelled) {
                    return;
                }
    
                setPendingExportHgCsvOperation(false);

                addToast(ex.message, { appearance: 'error' });
                return;
    
            }

            setPendingExportHgCsvOperation(null);
            
            addToast('Order CSV exported.', { appearance: 'success' });

            let printFrame = document.createElement('iframe');
            printFrame.src = response.fileUrl;
            printFrame.style = 'display: none;'
            document.body.appendChild(printFrame);

        }

        asyncRequest();

        return cancellationToken;

    }, [ pendingExportHgCsvOperation ]);

    useEffect(() => {

        if (!pendingExportDearPortalOperation) {
            return;
        }

        let cancellationToken = new CancellationToken();

        async function asyncRequest() {

            let response;

            try {
                response = await post('/api/bulkaction/exportdearportal', pendingExportDearPortalOperation, cancellationToken);
            }
            catch (ex) {
    
                if (cancellationToken.isCancelled) {
                    return;
                }
    
                setPendingExportDearPortalOperation(false);

                addToast(ex.message, { appearance: 'error' });
                return;
    
            }

            setPendingExportDearPortalOperation(null);
            
            addToast('Dear Portal CSV exported.', { appearance: 'success' });

            let printFrame = document.createElement('iframe');
            printFrame.src = response.fileUrl;
            printFrame.style = 'display: none;'
            document.body.appendChild(printFrame);

        }

        asyncRequest();

        return cancellationToken;

    }, [ pendingExportDearPortalOperation ]);


    useEffect(() => {

        if (!pendingExportDearPurchaseOrderOperation) {
            return;
        }

        let cancellationToken = new CancellationToken();

        async function asyncRequest() {

            let response;

            try {
                response = await post('/api/bulkaction/exportdearpo', pendingExportDearPurchaseOrderOperation, cancellationToken);
            }
            catch (ex) {
    
                if (cancellationToken.isCancelled) {
                    return;
                }
    
                setPendingExportDearPurchaseOrderOperation(false);

                addToast(ex.message, { appearance: 'error' });
                return;
    
            }

            setPendingExportDearPurchaseOrderOperation(null);
            
            addToast('Dear Purchase Order CSV exported.', { appearance: 'success' });

            let printFrame = document.createElement('iframe');
            printFrame.src = response.fileUrl;
            printFrame.style = 'display: none;'
            document.body.appendChild(printFrame);

        }

        asyncRequest();

        return cancellationToken;

    }, [ pendingExportDearPurchaseOrderOperation ]);

    useEffect(() => {

        if (!pendingExportGenericCsvOperation) {
            return;
        }

        let cancellationToken = new CancellationToken();

        async function asyncRequest() {

            let response;

            try {
                response = await post('/api/bulkaction/exportgen', pendingExportGenericCsvOperation, cancellationToken);
            }
            catch (ex) {
    
                if (cancellationToken.isCancelled) {
                    return;
                }
    
                setPendingExportGenericCsvOperation(false);

                addToast(ex.message, { appearance: 'error' });
                return;
    
            }

            setPendingExportGenericCsvOperation(null);
            
            addToast('Order CSV exported.', { appearance: 'success' });

            let printFrame = document.createElement('iframe');
            printFrame.src = response.fileUrl;
            printFrame.style = 'display: none;'
            document.body.appendChild(printFrame);

        }

        asyncRequest();

        return cancellationToken;

    }, [ pendingExportGenericCsvOperation ]);


    useEffect(() => {

        if (!pendingExportEliquidTableCsvOperation) {
            return;
        }

        let cancellationToken = new CancellationToken();

        async function asyncRequest() {

            let response;

            try {
                response = await post('/api/bulkaction/exporteliquidtable', pendingExportEliquidTableCsvOperation, cancellationToken);
            }
            catch (ex) {
    
                if (cancellationToken.isCancelled) {
                    return;
                }
    
                setPendingExportEliquidTableCsvOperation(false);

                addToast(ex.message, { appearance: 'error' });
                return;
    
            }

            setPendingExportEliquidTableCsvOperation(null);
            
            addToast('E-liquid table CSV exported.', { appearance: 'success' });

            let printFrame = document.createElement('iframe');
            printFrame.src = response.fileUrl;
            printFrame.style = 'display: none;'
            document.body.appendChild(printFrame);

        }

        asyncRequest();

        return cancellationToken;

    }, [ pendingExportEliquidTableCsvOperation ]);


    useEffect(() => {

        if (!pendingSendToDearOperation) {
            return;
        }

        let cancellationToken = new CancellationToken();

        let response;

        async function asyncRequest() {

            try {
                response = await post('/api/bulkaction/createorder', pendingSendToDearOperation, cancellationToken);
            }
            catch (ex) {
    
                if (cancellationToken.isCancelled) {
                    return;
                }
    
                setPendingSendToDearOperation(false);

                addToast(ex.message, { appearance: 'error' });
                return;
    
            }

            setPendingSendToDearOperation(null);

            for (let fileUrl of response.fileUrls) {
                let printFrame = document.createElement('iframe');
                printFrame.src = fileUrl;
                printFrame.style = 'display: none;'
                document.body.appendChild(printFrame);
            }
            
            addToast('Order sent to DEAR Inventory.', { appearance: 'success' });

        }

        asyncRequest();

        return cancellationToken;

    }, [ pendingSendToDearOperation ]);

    useEffect(() => {

        if (!pendingOrderImport) {
            return;
        }

        let cancellationToken = new CancellationToken();

        async function asyncRequest() {

            let accumulatedProducts = [];
            
            let request = {
                productIds: pendingOrderImport.lines.map(li => li.product.id),
                filters: [ 'Inventory Item' ],
                sorting: 'Id',
            };

            let page = 0;

            while (true) {

                page++;

                let response;

                try {
                    response = await post('/api/inventory/search', { ...request, page }, cancellationToken);
                }
                catch (ex) {
        
                    if (cancellationToken.isCancelled) {
                        return;
                    }
        
                    setPendingOrderImport(false);
    
                    addToast(ex.message, { appearance: 'error' });
                    return;
        
                }
    
                accumulatedProducts = accumulatedProducts.concat(response.items);

                if (page >= response.totalPages) {
                    break;
                }

            }

            let newProducts = pendingOrderImport.lines.map(line => {

                sortIndex++;

                let product = accumulatedProducts.first(p => p.id == line.product.id);

                return {
                    ...product,
                    checked: false,
                    locked: false,
                    unavailable: false,
                    orderQty: line.quantity,
                    unitPrice: line.unitPrice,
                    totalPrice: line.totalPrice,
                    sortIndex,
                };

            });

            setSortIndex(sortIndex);

            setProducts(products.concat(newProducts));

            addToast(`Imported ${newProducts.length} products.`, { appearance: 'success' });
        
            setPendingOrderImport(null);

        }

        asyncRequest();

        return cancellationToken;

    }, [ pendingOrderImport ]);

    function addProducts(newProducts) {

        newProducts = newProducts.filter(n => !products.any(p => p.id == n.id));

        newProducts = newProducts.map(p => {
                
            sortIndex++;

            let orderQty = p.projection.suggestedOrderQty;
            let unitPrice = 0;
            let totalPrice = 0;

            if (!!supplier) {

                let productSupplier = p.productSuppliers.find(ps => ps.supplierId == supplier.id);

                if (productSupplier) {
                    unitPrice = supplier.useLastPrice ? productSupplier.lastPrice : productSupplier.fixedPrice;
                    totalPrice = Decimal(unitPrice).times(orderQty).toDP(2).toNumber();
                }

            }

            return {
                ...p,
                checked: false,
                locked: false,
                unavailable: false,
                orderQty,
                unitPrice,
                totalPrice,
                sortIndex,
            };

        });


        setSortIndex(sortIndex);

        setProducts(products.concat(newProducts));

        addToast(`Added ${newProducts.length} products to order.`, { appearance: 'success' });

    }

    function updateProducts(updatedProducts) {

        for (let product of products) {

            let updatedProduct = updatedProducts.find(p => p.id == product.id);

            if (!updatedProduct) {
                continue; //if for some reason the server didn't send us one of the products we asked for
            }

            Object.assign(product, updatedProduct);

        }

        setProducts([ ...products ]);
        setIsConfirmingQuantityUpdate(true);

        addToast(`Updated ${updatedProducts.length} products.`, { appearance: 'success' });

    }

    function handleSupplierSelected(selectedSupplier) {
        setIsSelectingSupplier(false);

        if (selectedSupplier != supplier) {
            setSupplier(selectedSupplier);
            setIsConfirmingSupplierProductLoad(true);
        }
    }

    function handleLoadSupplierProducts() {
        setIsConfirmingSupplierProductLoad(false);
        setIsLoadingSupplierProducts(true);
    }

    function handleCancelOrder() {
        setIsConfirmingCancelOrder(false);
        setSupplier(null);
        setProducts([]);

        addToast('Order cancelled.', { appearance: 'success' });
    }

    function handleAnalyseOptionChanged(analyse) {
        setAnalyse(analyse);

        if (products.any()) {
            setIsRefreshingProducts(true);
        }
    }

    function handleProjectOptionChanged(project) {
        setProject(project);

        if (products.any()) {
            setIsRefreshingProducts(true);
        }
    }

    function handleRefreshProductsClicked() {
        if (products.any()) {
            setIsRefreshingProducts(true);
        }    
    }

    function handleAllProductsChecked(event) {
        for (let product of products) {
            product.checked = event.target.checked;
        }
        setProducts([ ...products ]);
    }

    function handleProductChecked(product, event) {
        product.checked = event.target.checked;
        setProducts([ ...products ]);
    }

    function handleProductOrderQtyChanged(product, value) {
        product.orderQty = value;

        if (product.unitPrice > 0) {
            product.totalPrice = Decimal(product.unitPrice).times(product.orderQty).toDP(2).toNumber();
        }

        product.unavailable = false;
        setProducts([...products]);
    }

    function handleProductUnitPriceChanged(product, value) {
        product.unitPrice = value;

        if (product.orderQty > 0) {
            product.totalPrice = Decimal(product.unitPrice).times(product.orderQty).toDP(2).toNumber();
        }

        product.unavailable = false;
        setProducts([...products]);
    }

    function handleProductTotalPriceChanged(product, value) {

        product.totalPrice = value;

        if (product.orderQty > 0) {
            product.unitPrice = Decimal(product.totalPrice).div(product.orderQty).toDP(4).toNumber();
        }

        product.unavailable = false;
        setProducts([...products]);
    }

    function handleProductLockToggle(product) {
        product.locked = !product.locked;
        setProducts([...products]);
    }
    
    function handleProductUnavailableToggle(product) {
        if (product.unavailable) {
            product.unavailable = false;
        }
        else {
            product.unavailable = true;
            product.orderQty = 0;
            product.totalPrice = 0;
        }

        setProducts([...products]);
    }

    function handleRemoveProductsClicked() {
        let checkedProducts = products.filter(p => p.checked);

        if (checkedProducts.length > 0) {
            setPendingRemoveProductsOperation(checkedProducts);
        }
    }

    function handleRemoveProductsConfirmed() {
        let removedItems = pendingRemoveProductsOperation;

        setPendingRemoveProductsOperation(null);
        setProducts(products.except(removedItems));
        
        addToast(`Removed ${removedItems.count()} products.`, { appearance: 'success' });
    }

    function handleSortSmartClicked() {

        let temp = products.orderBy(p => [
            p.category.parentId != null ? p.category.parentSortOrder : p.category.sortOrder,
            p.category.parentId != null ? p.category.sortOrder : null,
            p.parentId != null ? p.parentName : p.name,
            p.parentId != null ? p.sortOrder : null,
        ]);

        setProducts(temp);
        addToast('Sorted products using smart order.', { appearance: 'success' });
    }

    function handleSortOriginalClicked() {
        setProducts(products.sort((a, b) => a.sortIndex > b.sortIndex));
        addToast('Sorted products using added order.', { appearance: 'success' });
    }

    function handleAddProductsModalSuccess(newProducts) {
        setIsUsingAddProductsModal(false);
        addProducts(newProducts);
    }

    function handleUpdateOrderQuantitiesConfirmed() {
        for (let product of products.filter(p => !p.unavailable)) {
            product.orderQty = product.projection.suggestedOrderQty;
            product.totalPrice = Decimal(product.unitPrice).times(product.orderQty).toDP(2).toNumber();
        }

        setProducts([ ...products ]);
        setIsConfirmingQuantityUpdate(false);

        addToast('Updated order quantities.', { appearance: 'success' });
    }

    function handleZeroQuantitiesConfirmed() {
        for (let product of products.filter(p => !p.unavailable)) {
            product.orderQty = 0;
            product.totalPrice = 0;
        }

        setProducts([ ...products ]);
        setIsConfirmingZeroQuantities(false);

        addToast('Zeroed order quantities.', { appearance: 'success' });
    }

    function handleExportHgCsv() {
        let orderedProducts = products.filter(p => p.orderQty > 0);

        if (!supplier || !orderedProducts.any()) {
            return;
        }

        let order = {
            supplierId: supplier.id,
            orderLines: orderedProducts.map(p => ({
                productId: p.id,
                quantity: p.orderQty,
            })),
        };

        setPendingExportHgCsvOperation(order);
    }

    function handleExportDearPortal() {
        let orderedProducts = products.filter(p => p.orderQty > 0);

        if (!supplier || !orderedProducts.any()) {
            return;
        }

        let order = {
            supplierId: supplier.id,
            orderLines: orderedProducts.map(p => ({
                productId: p.id,
                quantity: p.orderQty,
            })),
        };

        setPendingExportDearPortalOperation(order);
    }

    
    function handleExportDearPurchaseOrder() {
        let orderedProducts = products.filter(p => p.orderQty > 0);

        if (!supplier || !orderedProducts.any()) {
            return;
        }

        let order = {
            supplierId: supplier.id,
            orderLines: orderedProducts.map(p => ({
                productId: p.id,
                quantity: p.orderQty,
                unitPrice: p.unitPrice,
            })),
        };

        setPendingExportDearPurchaseOrderOperation(order);
    }

    function handleExportGenericCsv() {
        let orderedProducts = products.filter(p => p.orderQty > 0);

        if (!orderedProducts.any()) {
            return;
        }

        let order = {
            orderLines: orderedProducts.map(p => ({
                productId: p.id,
                quantity: p.orderQty,
                unitPrice: p.unitPrice,
            })),
        };

        setPendingExportGenericCsvOperation(order);
    }

    function handleSendToDearInventory() {
        let orderedProducts = products.filter(p => p.orderQty > 0);

        if (!orderedProducts.any()) {
            return;
        }

        let order = {
            supplierId: supplier.id,
            orderLines: orderedProducts.map(p => ({
                productId: p.id,
                quantity: p.orderQty,
                unitPrice: p.unitPrice,
            })),
        };

        setPendingSendToDearOperation(order);
    }

    function handleExportEliquidTableCsv() {
        let orderedProducts = products.filter(p => p.orderQty > 0);

        if (!orderedProducts.any()) {
            return;
        }

        let order = {
            orderLines: orderedProducts.map(p => ({
                productId: p.id,
                quantity: p.orderQty,
            })),
        };

        setPendingExportEliquidTableCsvOperation(order);
    }

    function handleImportSuccess(order) {
        setIsImporting(false);

        setPendingOrderImport(order);
    }

    function hiddenProducts() {
        if (!shouldHideUnneededProducts && !shouldHideUnavailableProducts) {
            return [];
        }

        return products.filter(p => 
            (shouldHideUnneededProducts && p.analysis.materialityScore >= 75 && p.projection.suggestedOrderQty == 0 && p.orderQty == 0)
            || (shouldHideUnavailableProducts && p.unavailable)
        );
    }

    function getStockToolTip(product) {
        return `Available: ${product.available}\r\n`
            + `On Hand: ${product.onHand}\r\n`
            + `Allocated: ${product.allocated}\r\n`
            + `On Order: ${product.onOrder}`;
    }

    function getProjectionToolTip(product) {
        return `Projected Sales: ${product.projection.projectedSales}\r\n`
            + `Quantity Needed: ${product.projection.quantityNeeded}\r\n`
            + `Needed To Meet Reserve Qty: ${product.projection.neededToMeetReserveQty}\r\n`
            + `Suggested Order Qty: ${product.projection.suggestedOrderQty}\r\n`
            + `Probability Score: ${product.projection.probabilityScore}%\r\n`
            + `Preparedness Score: ${product.projection.preparednessScore}%`;
    }

    function getSalesToolTip(product) {
        return `Sold Qty: ${product.analysis.sold}\r\n`
            + `External Sold: ${product.analysis.externalSold}\r\n`
            + `Total Sold: ${product.analysis.totalSold}\r\n`
            + `Materiality Score: ${product.analysis.materialityScore}%\r\n`
            + `Material Avg: ${product.analysis.materialAverageWk}/wk\r\n`;
    }

    if (!supplierOptions || !analyseOptions || !projectOptions) {
        return (
            <LoadingBar />
        );
    }

    return (

        <React.Fragment>

            {isLoadingSupplierProducts 
                || isRefreshingProducts 
                || !!pendingExportHgCsvOperation 
                || !!pendingExportDearPortalOperation
                || !!pendingExportDearPurchaseOrderOperation
                || !!pendingExportGenericCsvOperation
                || !!pendingExportEliquidTableCsvOperation
                || !!pendingSendToDearOperation
                || !!pendingOrderImport &&
                <LoadingBar />
            }

            <div className="mt-3 container-fluid">
             
                {(analyse != 'Last 90 Days' || project != 'Next 30 Days') && !isProjectAnalyseWarningDismissed &&
                    <div className="alert alert-danger small p-2"><FontAwesomeIcon icon={[ 'fas', 'exclamation-triangle' ]} /> Be careful, you've selected a non-typical analyse or project option.<a onClick={() => setIsProjectAnalyseWarningDismissed(true)} className="close" href="javascript: void(0);">&times;</a></div>
                }

                <div className="float-right">
                    <button type="button" className="btn btn-success" onClick={() => setIsUsingAddProductsModal(true)}><FontAwesomeIcon icon={[ 'fas', 'plus' ]} /> Add Products</button>
                    {' '}
                    <AnalyseProjectButton analyse={analyse} project={project} analyseOptions={analyseOptions} projectOptions={projectOptions} onAnalyseOptionClicked={handleAnalyseOptionChanged} onProjectOptionClicked={handleProjectOptionChanged}  />
                    {' '}
                    <DropdownButton as="span" title="Actions">
                        <Dropdown.Item onClick={() => setShouldHideUnavailableProducts(!shouldHideUnavailableProducts)}>{shouldHideUnavailableProducts ? 'Show' : 'Hide'} Unavailable Products</Dropdown.Item>
                        <Dropdown.Item onClick={() => setShouldHideUnneededProducts(!shouldHideUnneededProducts)}>{shouldHideUnneededProducts ? 'Show' : 'Hide'} Unneeded Products</Dropdown.Item>
                        <Dropdown.Item onClick={() => setShouldHidePrices(!shouldHidePrices)}>{shouldHidePrices ? 'Show' : 'Hide'} Prices</Dropdown.Item>
                        <Dropdown.Item disabled={!products.any()} onClick={handleRefreshProductsClicked}>Refresh Products</Dropdown.Item>
                        <Dropdown.Item disabled={!products.any()} onClick={() => setIsConfirmingQuantityUpdate(true)}>Use Suggested Quantities</Dropdown.Item>
                        <Dropdown.Item disabled={!products.any()} onClick={() => setIsConfirmingZeroQuantities(true)}>Zero Quantities</Dropdown.Item>
                        <Dropdown.Item disabled={!products.any(p => p.checked)} onClick={handleRemoveProductsClicked}>Remove Selected</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Header>Sort Products</Dropdown.Header>
                        <Dropdown.Item disabled={!products.any()} onClick={handleSortSmartClicked}>Smart</Dropdown.Item>
                        <Dropdown.Item disabled={!products.any()} onClick={handleSortOriginalClicked}>Added Order</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item disabled={!supplier} onClick={() => setIsImporting(true)}>Import Order</Dropdown.Item>
                        <Dropdown.Divider />
                        
                        <Dropdown.Item disabled={!products.any()} onClick={handleExportGenericCsv}>Export Generic CSV</Dropdown.Item>
                        <Dropdown.Item disabled={!products.any()} onClick={handleExportHgCsv}>Export Generic CSV Supplier Skus</Dropdown.Item>
                        <Dropdown.Item disabled={!products.any()} onClick={handleExportDearPortal}>Export For Dear Web Portal</Dropdown.Item>
                        <Dropdown.Item disabled={!products.any()} onClick={handleExportDearPurchaseOrder}>Export Dear Purchase Order</Dropdown.Item>
                        <Dropdown.Item disabled={!products.any()} onClick={handleExportEliquidTableCsv}>Export E-liquid Table CSV</Dropdown.Item>
                        <Dropdown.Item disabled={supplier == null || !products.any()} onClick={handleSendToDearInventory}>Send to DEAR Inventory</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={() => setIsConfirmingCancelOrder(true)}><FontAwesomeIcon icon={[ 'fas', 'times' ]} fixedWidth /> Cancel Order</Dropdown.Item>
                    </DropdownButton>

                </div>

                {!supplier && !isSelectingSupplier &&
                    <div className="d-flex align-items-center mb-3">
                        <h3 className="mb-0"><FontAwesomeIcon icon={['fas', 'shopping-cart']} /> New Order</h3>
                        <div onClick={() => setIsSelectingSupplier(true)} className="ml-3 btn btn-sm btn-primary"><FontAwesomeIcon icon={['fas', 'user-tie']} /> Select Supplier</div>
                    </div>
                }

                {isSelectingSupplier &&
                
                    <React.Fragment>

                        <div className="mb-1"><FontAwesomeIcon icon={['fas', 'shopping-cart']} /> New Order</div>

                        <div className="d-flex align-items-center mb-3">

                            <Typeahead
                                id="supplier"
                                size="sm"
                                autoFocus={true}
                                style={{width: '300px'}}
                                placeholder="Search for a supplier"
                                options={supplierOptions}
                                labelKey={(o) => o.name}
                                onChange={(o) => handleSupplierSelected(o.first())}
                                inputProps={{
                                    shouldSelectHint: (shouldSelect, e) => {
                                        return e.code == 'Enter' || shouldSelect;
                                    },
                                }}
                            />

                            <button onClick={() => setIsSelectingSupplier(false)} className="btn btn-sm btn-secondary ml-1">Cancel</button>

                        </div>

                    </React.Fragment>

                }

                {supplier && !isSelectingSupplier &&
                    <React.Fragment>

                        <div className="mb-1"><FontAwesomeIcon icon={[ 'fas', 'shopping-cart' ]} /> New Order</div>

                        <div className="d-flex align-items-center mb-1">

                            <h3 className="mb-0">{supplier.name}</h3>

                            <div onClick={() => setIsSelectingSupplier(true)} className="ml-3 btn btn-sm btn-danger"><FontAwesomeIcon icon={[ 'fas', 'times' ]} /> Change Supplier</div>

                        </div>

                        <p className="text-secondary small mb-3">
                            
                            <span>{supplier.currency}</span>

                            <span> | {supplier.isGstPayable ? 'GST Payable' : 'No GST'}</span>

                            <span> | Using {supplier.useLastPrice ? 'Last' : 'Fixed'} Price</span>

                            {supplier.isGstPayable &&
                                <span> (GST {supplier.pricesIncludeGst ? 'Inclusive' : 'Exclusive'})</span>
                            }

                        </p>

                    </React.Fragment>
                }

                <table className="table">
                    <thead className="thead-dark" style={{ position: 'sticky', top: '0px', zIndex: 1 }}>
                        <tr>
                            <th className="text-center" style={{ width: '1%' }}><input type="checkbox" checked={products.any() && products.every(p => p.checked)} onChange={handleAllProductsChecked} /></th>
                            <th>
                                Product
                                {hiddenProducts().any() &&
                                    <span style={{ fontSize: 'inherit' }} className="ml-2 badge badge-warning">{hiddenProducts().count()} hidden</span>
                                }
                            </th>
                            <th style={{ width: '14%' }}>Category / Brand</th>
                            <th style={{ width: '8%' }}>Inventory</th>
                            <th style={{ width: '8%' }}>Sales</th>
                            <th style={{ width: '8%' }}>Needed</th>
                            <th style={{ width: '10%' }}>Order Qty</th>
                            <th style={{ width: '5%' }}></th>
                            <th className={cx({ 'd-none': shouldHidePrices })} style={{ width: '10%' }}>Unit Price</th>
                            <th className={cx({ 'd-none': shouldHidePrices })} style={{ width: '10%' }}>Total Price</th>
                            <th style={{ width: '7%' }}></th>
                        </tr>
                    </thead>

                    <tbody>

                        {products.except(hiddenProducts()).map((product) => (

                            <tr key={product.id} className={cx({ 'bg-light': product.locked })}>

                                <td className="text-center">
                                    <input type="checkbox" checked={product.checked} onChange={handleProductChecked.bind(this, product)} />
                                </td>
                                
                                <td className="align-middle">
                                    <a className="text-reset" href="javascript:void(0);" onClick={() => setIsViewingProductDetails(product)}>{product.name.replaceAll('|', '•')}</a>
                                </td>

                                <td className="align-middle"><small>{product.category.name} / {product.brand.name}</small></td>

                                <td className="align-middle">

                                    <React.Fragment>

                                        <span title={getStockToolTip(product)} className="badge badge-secondary" style={{ fontSize: 'inherit' }}>
                                            {product.onHand}
                                            {product.allocated != 0 &&
                                                <small> {product.allocated > 0 ? '-' : product.allocated < 0 ? '+' : ''}{Math.abs(product.allocated)}</small>
                                            }
                                        </span>

                                        {product.onOrder > 0 &&
                                            <small className="text-secondary"> <FontAwesomeIcon icon={['fas', 'retweet']} />{product.onOrder}</small>
                                        }

                                    </React.Fragment>

                                </td>

                                <td className="align-middle">
                                    <React.Fragment>
                                        <span title={getSalesToolTip(product)} className={cx('badge', product.analysis.totalSold > 0 ? 'badge-primary' : 'badge-secondary')} style={{ fontSize: 'inherit' }}>
                                            {product.analysis.sold}
                                            {product.analysis.externalSold != 0 &&
                                                <small> {product.analysis.externalSold > 0 ? '+' : product.analysis.externalSold < 0 ? '-' : ''}{Math.abs(product.analysis.externalSold)}</small>
                                            }
                                        </span>

                                        {product.analysis.totalSold > 0 &&
                                            <small className="text-secondary"> {product.analysis.materialAverageWk}/wk</small>
                                        }

                                    </React.Fragment>
                                </td>

                                <td className="align-middle">

                                    <React.Fragment>
                                        <span title={getProjectionToolTip(product)} className="badge badge-dark" style={{ fontSize: 'inherit', backgroundColor: preparednessScoreStyles(product.projection.preparednessScore) }}>
                                            {product.projection.quantityNeeded}
                                        </span>

                                        {product.projection.probabilityScore < 75 &&
                                            <small style={probabilityScoreStyles(product.projection.probabilityScore)}> {product.projection.probabilityScore}%</small>
                                        }

                                    </React.Fragment>

                                </td>

                                <td className="align-middle">
                                    <div style={{ minWidth: '55px' }}>
                                        <NumberInput className="form-control form-control-sm" disabled={product.locked} min={0} dp={0} value={product.orderQty} onChange={handleProductOrderQtyChanged.bind(this, product)} />
                                    </div>
                                </td>

                                <td className="align-middle">
                                    {product.orderQty != null && product.orderQty > 0 && product.orderQty > product.projection.quantityNeeded &&
                                        <span className="badge badge-success" style={{fontSize: 'inherit'}}>+{product.orderQty - product.projection.quantityNeeded}</span>
                                    }
                                    {product.orderQty != null && product.orderQty < product.projection.quantityNeeded &&
                                        <span className="badge badge-danger" style={{fontSize: 'inherit'}}>-{product.projection.quantityNeeded - product.orderQty}</span>
                                    }
                                </td>
                                
                                <td className={cx('align-middle', { 'd-none': shouldHidePrices })}>
                                    <div style={{ minWidth: '80px' }}>
                                        <NumberInput className="form-control form-control-sm" disabled={product.locked} min={0} dp={4} value={product.unitPrice} onChange={handleProductUnitPriceChanged.bind(this, product)} />
                                    </div>
                                </td>

                                <td className={cx('align-middle', { 'd-none': shouldHidePrices })}>
                                    <div style={{ minWidth: '80px' }}>
                                        <NumberInput className="form-control form-control-sm" disabled={product.locked} min={0} dp={2} value={product.totalPrice} onChange={handleProductTotalPriceChanged.bind(this, product)} />
                                    </div>
                                </td>

                                <td className="align-middle">
                                    <button type="button" className="btn btn-secondary btn-sm" onClick={handleProductLockToggle.bind(this, product)}><FontAwesomeIcon icon={[ 'fas', product.locked ? 'lock' : 'lock-open' ]} fixedWidth /></button>
                                    {' '}
                                    <button type="button" className="btn btn-info btn-sm" onClick={handleProductUnavailableToggle.bind(this, product)}><FontAwesomeIcon icon={[ product.unavailable ? 'fas' : 'far', 'frown' ]} fixedWidth /></button>
                                </td>
                            </tr>
                        ))}

                        {products.length == 0 &&
                            <tr>
                                <td colSpan={100}>No products added to order yet</td>
                            </tr>
                        }

                    </tbody>

                    {products.any() &&
                        <tfoot>
                            <tr>
                                <td colSpan={100} className="text-right px-5">
                                    {products.sum(p => p.orderQty).toLocaleString()} items — ${products.sum(p => p.totalPrice).toLocaleString()}
                                </td>
                            </tr>
                        </tfoot>
                    }

                </table>

            </div>

            {isConfirmingSupplierProductLoad &&
                <Modal show={true} centered onHide={() =>setIsConfirmingSupplierProductLoad(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Load Products</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Load all products from this supplier?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-secondary" onClick={() => setIsConfirmingSupplierProductLoad(false)}>No</button>
                        <button type="button" className="btn btn-primary" onClick={handleLoadSupplierProducts}>Load Products</button>
                    </Modal.Footer>

                </Modal>
            }

            {isConfirmingCancelOrder &&
                <Modal show={true} centered onHide={() => setIsConfirmingCancelOrder(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Cancel Order</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure you want to cancel the order?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-secondary" onClick={() => setIsConfirmingCancelOrder(false)}>No</button>
                        <button type="button" className="btn btn-danger" onClick={handleCancelOrder}>Cancel Order</button>
                    </Modal.Footer>
                </Modal>
            }

            {pendingRemoveProductsOperation &&
                <Modal show={true} centered onHide={() => setPendingRemoveProductsOperation(null)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Remove Products</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure you want to remove {pendingRemoveProductsOperation.length} products?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-secondary" onClick={() => setPendingRemoveProductsOperation(null)}>No</button>
                        <button type="button" className="btn btn-danger" onClick={handleRemoveProductsConfirmed}>Remove Products</button>
                    </Modal.Footer>
                </Modal>
            }

            {isConfirmingQuantityUpdate &&
                <Modal show={true} centered onHide={() => setIsConfirmingQuantityUpdate(null)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Update Quantities</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Update order with suggested quantities?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-secondary" onClick={() => setIsConfirmingQuantityUpdate(null)}>No</button>
                        <button type="button" className="btn btn-primary" onClick={handleUpdateOrderQuantitiesConfirmed}>Update Quantities</button>
                    </Modal.Footer>
                </Modal>
            }

            {isConfirmingZeroQuantities &&
                <Modal show={true} centered onHide={() => setIsConfirmingZeroQuantities(null)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Zero Quantities</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Set all quantities to zero?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-secondary" onClick={() => setIsConfirmingZeroQuantities(null)}>No</button>
                        <button type="button" className="btn btn-primary" onClick={handleZeroQuantitiesConfirmed}>Zero Quantities</button>
                    </Modal.Footer>
                </Modal>
            }

            {isViewingProductDetails &&
                <InventoryProductsDetailsModal product={isViewingProductDetails} analyse={analyse} project={project} onClose={() => setIsViewingProductDetails(null)} />
            }

            {isUsingAddProductsModal &&
                <NewOrderAddProductsModal analyse={analyse} project={project} onSuccess={handleAddProductsModalSuccess} onCancel={() => setIsUsingAddProductsModal(null)} />
            }

            {isImporting &&
                <NewOrderImportModal supplier={supplier} onSuccess={handleImportSuccess} onCancel={() => setIsImporting(false)} />
            }

        </React.Fragment>

    );

}
