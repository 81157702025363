import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToasts } from 'react-toast-notifications';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import { useApi } from 'lib/effects';

import LoadingBar from 'components/LoadingBar';
import BrandsAddOrUpdateModal from 'components/BrandsAddOrUpdateModal';
import BrandsDeleteModal from 'components/BrandsDeleteModal';

export default function Brands(props) {

    let { addToast } = useToasts();

    let [ brands, setBrands ] = useState(null);

    let [ isAddPending, setIsAddPending ] = useState(false);
    let [ pendingEdit, setPendingEdit ] = useState(null);
    let [ pendingDelete, setPendingDelete ] = useState(null);

    useApi('/api/inventory/brands', (response, ex) => {

        if (ex) {
            addToast(ex.message, { appearance: 'error' });
            return;
        }

        setBrands(response);

    }, []);

    function handleAddSuccess(brand) {
        setBrands(brands.concat([ brand ]));
        setIsAddPending(false);
    }

    function handleEditSuccess(brand) {
        setBrands(brands.filter(u => u != pendingEdit).concat([ brand ]));
        setPendingEdit(null);
    }

    function handleDeleteSuccess() {
        setBrands(brands.filter(u => u != pendingDelete));
        setPendingDelete(null);
    }

    if (!brands) {
        return (
            <LoadingBar />
        );
    }

    return (

        <div className="mt-3 container-fluid">

            <button className="btn btn-success float-right" onClick={() => setIsAddPending(true)}><FontAwesomeIcon icon={[ 'fas', 'plus' ]} /> Add Brand</button>

            <h3><FontAwesomeIcon icon={[ 'fas', 'stamp' ]} /> Brands</h3>

            <p>Configure the brands of products you stock.</p>

            <table className="table">
                <thead className="thead-dark">
                    <tr>
                        <th>Name</th>
                        <th style={{ width: '10%' }}></th>
                    </tr>
                </thead>
                
                <tbody>

                    {brands.orderBy(s => s.name).map((brand) => (
                        <tr key={brand.id}>
                            <td><a className="text-reset" href="javascript: void(0);" onClick={() => setPendingEdit(brand)}>{brand.name}</a></td>
                            <td className="text-right py-0 align-middle">
                                <DropdownButton title="Actions" size="sm">
                                    <Dropdown.Item onClick={() => setPendingEdit(brand)}>Edit Brand</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setPendingDelete(brand)}>Delete Brand</Dropdown.Item>
                                </DropdownButton>
                            </td>
                        </tr>
                    ))}

                </tbody>

            </table>

            {isAddPending &&
                <BrandsAddOrUpdateModal onSuccess={handleAddSuccess} onCancel={() => setIsAddPending(false)} />
            }

            {pendingEdit &&
                <BrandsAddOrUpdateModal brand={pendingEdit} onSuccess={handleEditSuccess} onCancel={() => setPendingEdit(null)} />
            }

            {pendingDelete &&
                <BrandsDeleteModal brand={pendingDelete} onSuccess={handleDeleteSuccess} onCancel={() => setPendingDelete(null)} />
            }


        </div>

    );

}