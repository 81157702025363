import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import Modal from 'react-bootstrap/Modal';

import { tryPut as put } from 'lib/api';
import { CancellationToken } from 'lib/cancellationTokens';

export default function JobsTakeOutOfStanddownModal(props) {

    let { addToast } = useToasts();

    let [ isSubmitting, setIsSubmitting ] = useState(false);

    useEffect(() => {

        if (!isSubmitting) {
            return;
        }

        let cancellationToken = new CancellationToken();

        let promises = props.jobs.map(j => put(`/api/fulfillment/jobs/${j.id}/takeoutofstanddown`));

        Promise.all(promises).then((outcomes) => {

            if (cancellationToken.isCancelled) {
                return;
            }

            let successful = outcomes.filter(([ _, ex ]) => !ex);

            if (successful.any()) {
                addToast(`Took ${successful.count()} jobs out of standdown`, { appearance: 'success' });
            }

            let groupedErrors = outcomes.map(([ _, ex ]) => ex).filter(ex => !!ex).groupBy(e => e.message);

            for (let errorGroup of groupedErrors) {
                addToast(`${errorGroup.key} (${errorGroup.count()})`, { appearance: 'error' });
            }

            setIsSubmitting(false);

            props.onSuccess();

        });

        return cancellationToken;

    }, [ isSubmitting ]);


    function handleSubmit() {
        setIsSubmitting(true);
    }

    function handleCancel() {
        props.onCancel();
    }
    
    return (

        <Modal show={true} centered onHide={handleCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Take Jobs Out Of Standdown</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <p>The following jobs will be taken out of standdown</p>

                <ul>
                    {props.jobs.orderBy(j => j.id).map((job) => (
                        <li key={job.id}>#{job.orderNumber} — {job.shippingName}</li>
                    ))}
                </ul>

            </Modal.Body>

            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={handleCancel}>Cancel</button>

                <button type="button" className="btn btn-primary" onClick={handleSubmit} disabled={isSubmitting}>
                    {isSubmitting &&
                        <span className="spinner-border spinner-border-sm"></span>
                    }
                    {' '}
                    Confirm
                </button>

            </Modal.Footer>
        </Modal>

    );

}

JobsTakeOutOfStanddownModal.propTypes = {
    jobs: PropTypes.array.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};