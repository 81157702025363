import React, { useState, useEffect } from 'react'
import { useToasts } from 'react-toast-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { tryPost as post } from 'lib/api';
import { CancellationToken } from 'lib/cancellationTokens';

export default function Maintenance(props) {

    let { addToast } = useToasts();

    let [ isRebuildingProductIndex, setIsRebuildingProductIndex ] = useState(false);

    useEffect(() => {

        if (!isRebuildingProductIndex) {
            return;
        }

        let cancellationToken = new CancellationToken();

        post("/api/maintenance/rebuildproductindex")
        
            .then(([ _, ex ]) => {

                setIsRebuildingProductIndex(false);

                if (cancellationToken.isCancelled) {
                    return;
                }

                if (ex) {
                    addToast(ex.message, { appearance: 'error' });
                    return;
                }

                addToast('Product index rebuilt', { appearance: 'success' });

            });

        return cancellationToken;

    }, [ isRebuildingProductIndex ]);


    return (

        <div className="mt-3 container-fluid">
            
            <h3><FontAwesomeIcon icon={[ 'fas', 'wrench' ]} /> Maintenance</h3>

            <div className="form-group">
                <button type="button" className="btn btn-primary" onClick={() => setIsRebuildingProductIndex(true)} disabled={isRebuildingProductIndex}>
                    {isRebuildingProductIndex &&
                        <span className="spinner-border spinner-border-sm"></span>
                    }
                    {' '}
                    Rebuild Product Index
                </button>
            </div>

        </div>

    );

}