import React, { useState, useEffect, useContext, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import cx from 'classnames';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import { tryGet as get } from 'lib/api';
import { CancellationToken } from 'lib/cancellationTokens';
import withFormHelpers from 'lib/formHelpers';

import AppContext from 'AppContext';


function JobAddressModalInner({ address, originalAddress, disabled, allowNewAddress, onValidAddress }, ref) {

    let appContext = useContext(AppContext);

    let { addToast } = useToasts();

    let form = withFormHelpers(useForm({ defaultValues: address, mode: 'onBlur' }));
    let currentValues = form.watch();

    let [ isNewAddress, setIsNewAddress ] = useState(false);

    let [ hasDeviated, setHasDeviated ] = useState(originalAddress != null && !address.addressId);

    let [ searchString, setSearchString ] = useState(!originalAddress ? getDefaultSearchString(address) : '');
    let [ resetCount, setResetCount ] = useState(0); //increment to force Typeahead to update search string
    let [ pendingSearch, setPendingSearch ] = useState(null);
    let [ pendingLoad, setPendingLoad ] = useState(null);
    let [ suggestions, setSuggestions ] = useState([]);
    let [ hasSelectedAddress, setHasSelectedAddress ] = useState(originalAddress != null);
    
    useImperativeHandle(ref, () => ({
        submit: () => {
            form.handleSubmit(handleSubmit)();
        }
    }));

    useEffect(() => {

        if (!searchString || searchString.length < 4) {
            return;
        }

        let country = appContext.config.countries.find(c => c.id == currentValues.countryId);

        setPendingSearch({
            searchString,
            isDomestic: country.isDomestic,
            countryAlpha2: country.alpha2,
        });

    }, [ searchString ]);

    useEffect(() => {

        if (!pendingSearch) {
            return;
        }

        let cancellationToken = new CancellationToken();

        get('/api/suggest', pendingSearch)
        
            .then(([ response, ex ]) => {

                if (cancellationToken.isCancelled) {
                    return;
                }

                if (ex) {
                    setPendingSearch(null);

                    addToast(ex.message, { appearance: 'error' });
                    return;
                }

                setSuggestions(response.map(s => ({
                    ...s,
                    isDomestic: pendingSearch.isDomestic,
                })));
                setPendingSearch(null);
                
            });

        return cancellationToken;

    }, [ pendingSearch ]);

    useEffect(() => {

        if (!pendingLoad) {
            return;
        }

        let cancellationToken = new CancellationToken();

        get('/api/suggest/address', pendingLoad)
        
            .then(([ response, ex ]) => {

                if (cancellationToken.isCancelled) {
                    return;
                }

                setPendingLoad(null);

                if (ex) {
                    addToast(ex.message, { appearance: 'error' });
                    return;
                }

                let country = appContext.config.countries.find(c => c.alpha2 == response.country.alpha2);

                form.reset({
                    ...currentValues,
                    address1: response.address1,
                    address2: response.address2,
                    city: response.city,
                    state: response.state,
                    postCode: response.postCode,
                    countryId: country.id,
                    addressId: response.addressId,
                });

                setHasSelectedAddress(true);
                setSuggestions([]);
                setSearchString('');
                setResetCount((i) => i+1);

            });

        return cancellationToken;

    }, [ pendingLoad ]);

    function getDefaultSearchString(addr) {
        return addr.address1 + ' ' + addr.postCode;
    }

    function getOldestAddress() {
        return originalAddress || address;
    }

    function handleResetClick() {

        //something to try to keep your head wrapped around
        //1. if we're viewing an address which we've corrected previously, resetting will take us all the way back to the originally provided customer address
        //2. if we're viewing an address we've not corrected, resetting simply resets the form to where it was when we opened it

        let resetTo = getOldestAddress();

        form.reset(resetTo);

        setHasDeviated(false);

        setHasSelectedAddress(false);

        setSearchString(getDefaultSearchString(resetTo));
        setResetCount((i) => i+1);
    }

    function handleAddressDeviation() {
        setHasDeviated(true);
        form.setValue('addressId', '');
    }

    function handleSubmit(formData) {
        onValidAddress(formData, isNewAddress);
    }

    return (

        <React.Fragment>

            {allowNewAddress &&
                <div className="px-4 py-3 text-light" style={{ backgroundColor: 'var(--tvk-purple-dark-5)' }}>
                    <Form.Check disabled={disabled} id="isNewAddress" type="checkbox" label="This is a completely new address." value={isNewAddress} onChange={(event) => setIsNewAddress(event.target.checked)} custom /> 
                </div>
            }

            <Modal.Body>
                

                {hasDeviated && !currentValues.addressId &&
                    <div className="alert alert-danger small p-2"><FontAwesomeIcon icon={[ 'fas', 'exclamation-triangle' ]} fixedWidth /> This address has been entered manually, or has been modified after selecting a suggested address.</div>
                }

                {(!isNewAddress && currentValues.postCode != getOldestAddress().postCode) &&
                    <div className="alert alert-warning small p-2"><FontAwesomeIcon icon={[ 'fas', 'exclamation-triangle' ]} fixedWidth /> The post code has changed. Is this still a correction, or is it a completely new address?</div>
                }

                <div className="row">

                    <div className="col-6">

                        <form onSubmit={form.handleSubmit(handleSubmit)}>

                            <input name="addressId" type="hidden" ref={form.register} />

                            <fieldset disabled={disabled}>

                                <div className="form-group row mb-0">

                                    <label className="col-2 col-form-label-sm">Name</label>

                                    <div className="col-10">
                                        <input name="name" type="text" ref={form.register({ required: true })} className={cx('form-control form-control-sm', { 'is-invalid': form.hasErrors('name') })} />

                                        {(!isNewAddress && (currentValues.name || null) != getOldestAddress().name) &&
                                            <s className="d-block text-danger small">{getOldestAddress().name || 'Empty'}</s>
                                        }
                                    </div>

                                </div>

                                <div className="form-group row mb-0">

                                    <label className="col-2 col-form-label-sm">Company</label>

                                    <div className="col-10">
                                        <input name="company" type="text" ref={form.register} className={cx('form-control form-control-sm', { 'is-invalid': form.hasErrors('company') })} />

                                        {(!isNewAddress && (currentValues.company || null) != getOldestAddress().company) &&
                                            <s className="d-block text-danger small">{getOldestAddress().company || 'Empty'}</s>
                                        }
                                    </div>

                                </div>

                                <div className="form-group row mb-0">

                                    <label className="col-2 col-form-label-sm">Building</label>

                                    <div className="col-10">
                                        <input name="building" type="text" ref={form.register} className={cx('form-control form-control-sm', { 'is-invalid': form.hasErrors('building') })} />

                                        {(!isNewAddress && (currentValues.building || null) != getOldestAddress().building) &&
                                            <s className="d-block text-danger small">{getOldestAddress().building || 'Empty'}</s>
                                        }
                                    </div>

                                </div>

                                <div className="form-group row mb-0">

                                    <label className="col-2 col-form-label-sm">Address 1</label>

                                    <div className="col-10">
                                        <input name="address1" type="text" ref={form.register({ required: true })} onChange={handleAddressDeviation} className={cx('form-control form-control-sm', { 'is-invalid': form.hasErrors('address1') })} />

                                        {(!isNewAddress && (currentValues.address1 || null) != getOldestAddress().address1) &&
                                            <s className="d-block text-danger small">{getOldestAddress().address1 || 'Empty'}</s>
                                        }
                                    </div>

                                </div>

                                <div className="form-group row mb-0">

                                    <label className="col-2 col-form-label-sm">Address 2</label>

                                    <div className="col-10">
                                        <input name="address2" type="text" ref={form.register} onChange={handleAddressDeviation} className={cx('form-control form-control-sm', { 'is-invalid': form.hasErrors('address2') })} />

                                        {(!isNewAddress && (currentValues.address2 || null) != getOldestAddress().address2) &&
                                            <s className="d-block text-danger small">{getOldestAddress().address2 || 'Empty'}</s>
                                        }
                                    </div>

                                </div>

                                <div className="form-group row mb-0">

                                    <label className="col-2 col-form-label-sm">City</label>

                                    <div className="col-10">
                                        <input name="city" type="text" ref={form.register} onChange={handleAddressDeviation} className={cx('form-control form-control-sm', { 'is-invalid': form.hasErrors('city') })} />

                                        {(!isNewAddress && (currentValues.city || null) != getOldestAddress().city) &&
                                            <s className="d-block text-danger small">{getOldestAddress().city || 'Empty'}</s>
                                        }
                                    </div>

                                </div>

                                <div className="form-group row mb-0">

                                    <label className="col-2 col-form-label-sm">State</label>

                                    <div className="col-10">
                                        <input name="state" type="text" ref={form.register} onChange={handleAddressDeviation} className={cx('form-control form-control-sm', { 'is-invalid': form.hasErrors('state') })} />

                                        {(!isNewAddress && (currentValues.state || null) != getOldestAddress().state) &&
                                            <s className="d-block text-danger small">{getOldestAddress().state || 'Empty'}</s>
                                        }
                                    </div>

                                </div>

                                <div className="form-group row mb-0">

                                    <label className="col-2 col-form-label-sm">Post Code</label>

                                    <div className="col-10">
                                        <input name="postCode" type="text" ref={form.register({ required: true })} onChange={handleAddressDeviation} className={cx('form-control form-control-sm', { 'is-invalid': form.hasErrors('postCode') })} />

                                        {(!isNewAddress && (currentValues.postCode || null) != getOldestAddress().postCode) &&
                                            <s className="d-block text-danger small">{getOldestAddress().postCode || 'Empty'}</s>
                                        }
                                    </div>

                                </div>

                                <div className="form-group row mb-0">

                                    <label className="col-2 col-form-label-sm">Country</label>

                                    <div className="col-10">
                                        <select name="countryId" ref={form.register({ required: true })} className="custom-select custom-select-sm">
                                            {appContext.config.countries.map((countryOption) => (
                                                <option key={countryOption.id} value={countryOption.id}>{countryOption.name}</option>
                                            ))}
                                        </select>

                                        {(!isNewAddress && (currentValues.countryId || null) != getOldestAddress().countryId) &&
                                            <s className="d-block text-danger small">{getOldestAddress().country.name || 'Empty'}</s>
                                        }
                                    </div>

                                </div>                        

                                <div className="form-group row mb-0">

                                    <label className="col-2 col-form-label-sm">Email</label>

                                    <div className="col-10">
                                        <input name="email" type="text" ref={form.register({ required: true })} className={cx('form-control form-control-sm', { 'is-invalid': form.hasErrors('email') })} />

                                        {(!isNewAddress && (currentValues.email || null) != getOldestAddress().email) &&
                                            <s className="d-block text-danger small">{getOldestAddress().email || 'Empty'}</s>
                                        }
                                    </div>

                                </div>

                                <div className="form-group row mb-0">

                                    <label className="col-2 col-form-label-sm">Phone</label>

                                    <div className="col-10">
                                        <input name="phoneNumber" type="text" ref={form.register({ required: true })} className={cx('form-control form-control-sm', { 'is-invalid': form.hasErrors('phoneNumber') })} />

                                        {(!isNewAddress && (currentValues.phoneNumber || null) != getOldestAddress().phoneNumber) &&
                                            <s className="d-block text-danger small">{getOldestAddress().phoneNumber || 'Empty'}</s>
                                        }
                                    </div>

                                </div>

                                <div className="form-group row">

                                    <label className="col-2 col-form-label-sm">Instructions</label>

                                    <div className="col-10">
                                        <input name="instructions" type="text" ref={form.register} className={cx('form-control form-control-sm', { 'is-invalid': form.hasErrors('instructions') })} />

                                        {(!isNewAddress && (currentValues.instructions || null) != getOldestAddress().instructions) &&
                                            <s className="d-block text-danger mt-1 small">{getOldestAddress().instructions || 'Empty'}</s>
                                        }
                                    </div>

                                </div>  

                            </fieldset>

                        </form>

                    </div>

                    <div className="col-6">
                        
                        <div className="form-group">

                            <label>Search for an address</label>

                            <AsyncTypeahead
                                key={resetCount}
                                id="suggestions"
                                filterBy={() => true}
                                defaultInputValue={searchString}
                                defaultOpen={true}
                                isLoading={!!pendingSearch}
                                options={suggestions}
                                labelKey={option => option.fullAddress}
                                inputProps={{
                                    shouldSelectHint: (shouldSelect, e) => {
                                        return e.code == 'Enter' || shouldSelect;
                                    },
                                }}
                                onChange={(options) => setPendingLoad(options.first())}
                                onSearch={setSearchString}
                            />

                        </div>

                        {hasSelectedAddress &&
                        
                            <div className="form-group">
                                <button className="btn btn-danger" onClick={handleResetClick}>Reset</button>
                            </div>

                        }


                    </div>
                </div>

            </Modal.Body>
            
        </React.Fragment>

    );

}

export default React.forwardRef(JobAddressModalInner);

JobAddressModalInner.propTypes = {
    address: PropTypes.object.isRequired,
    originalAddress: PropTypes.object,
    allowNewAddress: PropTypes.bool,
    disabled: PropTypes.bool,
    onValidAddress: PropTypes.func.isRequired,
};