import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToasts } from 'react-toast-notifications';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import { useApi } from 'lib/effects';
import { tryPost as post } from 'lib/api';
import { CancellationToken } from 'lib/cancellationTokens';

import LoadingBar from 'components/LoadingBar';
import CategoriesAddOrUpdateModal from 'components/CategoriesAddOrUpdateModal';
import CategoriesDeleteModal from 'components/CategoriesDeleteModal';

export default function Categories(props) {

    let { addToast } = useToasts();

    let [ categories, setCategories ] = useState(null);

    let [ isAddPending, setIsAddPending ] = useState(false);
    let [ pendingEdit, setPendingEdit ] = useState(null);
    let [ pendingDelete, setPendingDelete ] = useState(null);
    let [ isReordering, setIsReordering ] = useState(false);
    let [ isSavingOrder, setIsSavingOrder ] = useState(false);

    useApi('/api/inventory/categories', (response, ex) => {

        if (ex) {
            addToast(ex.message, { appearance: 'error' });
            return;
        }

        setCategories(response);

    }, []);

    useEffect(() => {

        if (!isSavingOrder) {
            return;
        }

        let cancellationToken = new CancellationToken();

        let request = categories.concat(categories.flatMap(c => c.children)).map(c => ({ id: c.id, sortOrder: c.sortOrder }));
        
        post('/api/inventory/categories/reorder', request)
        
            .then(([ _, ex ]) => {

                if (cancellationToken.isCancelled) {
                    return;
                }

                setIsSavingOrder(false);

                if (ex) {
                    addToast(ex.message, { appearance: 'error' });
                    return;
                }

                setIsReordering(false);
                
                addToast(`Sorting saved`, { appearance: 'success' });

            });

        return cancellationToken;

    }, [ isSavingOrder ]);
    
    function getChildCategories(id) {
        return categories.filter(c => c.parentId == id);
    }

    function handleAddSuccess(category) {
        setCategories(categories.concat([ category ]));
        setIsAddPending(false);
    }

    function handleEditSuccess(category) {
        setCategories(categories.filter(u => u != pendingEdit).concat([ category ]));
        setPendingEdit(null);
    }

    function handleDeleteSuccess() {
        setCategories(categories.filter(u => u != pendingDelete));
        setPendingDelete(null);
    }

    function handleCategoryReorder(category, change) {
        categories = moveCategory(categories, category, change);
        setCategories(categories);
    }

    function handleChildReorder(category, parent, change) {
        category.children = moveCategory(parent.children, category, change);
        setCategories([ ...categories ]);
    }

    function moveCategory(arr, category, change) {

        let temp = arr.orderBy(c => c.sortOrder);

        let currIdx = temp.indexOf(category);

        let newIdx = currIdx + change;

        if (newIdx < 0 || newIdx > temp.length -1) return;
        
        temp.splice(currIdx, 1);
        temp.splice(newIdx, 0, category);

        temp.forEach((item, i) => {
            item.sortOrder = i + 1;
        });

        return temp;

    }


    function handleSaveOrder() {
        setIsSavingOrder(true);
    }

    if (!categories) {
        return (
            <LoadingBar />
        );
    }

    return (

        <div className="mt-3 container-fluid">

            <div className="float-right">
                {!isReordering &&
                    <button className="btn btn-primary" onClick={() => setIsReordering(true)}><FontAwesomeIcon icon={['fas', 'list-ol']} /> Change Sorting</button>
                }

                {isReordering &&

                    <button type="button" className="btn btn-danger" onClick={handleSaveOrder} disabled={isSavingOrder}>
                        {isSavingOrder &&
                            <span className="spinner-border spinner-border-sm"></span>
                        }
                        {!isSavingOrder &&
                            <FontAwesomeIcon icon={['fas', 'check']} />
                        }
                        {' '}
                        Save Sorting
                    </button>

                }

                {' '}

                <button className="btn btn-success" onClick={() => setIsAddPending(true)}><FontAwesomeIcon icon={['fas', 'plus']} /> Add Category</button>

            </div>


            <h3><FontAwesomeIcon icon={[ 'fas', 'sitemap' ]} /> Categories</h3>

            <p>Configure your product categories.</p>

            <table className="table">
                <thead className="thead-dark">
                    <tr>
                        {isReordering &&
                            <th style={{ width: '1%' }}></th>
                        }
                        
                        <th>Name</th>
                        <th style={{ width: '10%' }}></th>
                    </tr>
                </thead>
                
                <tbody>

                    {categories.filter(c => c.isParent).orderBy(c => c.sortOrder).map((category) => (
                        <React.Fragment key={category.id}>

                            <tr>

                                {isReordering &&
                                    <td className="align-middle text-center py-0">
                                        <div className="btn-group">
                                            <button className="btn btn-sm btn-primary" disabled={categories.orderBy(c => c.sortOrder).first() == category} onClick={handleCategoryReorder.bind(null, category, -1)}><FontAwesomeIcon icon={['fas', 'chevron-up']} /></button>
                                            <button className="btn btn-sm btn-primary" disabled={categories.orderBy(c => c.sortOrder).last() == category} onClick={handleCategoryReorder.bind(null, category, 1)}><FontAwesomeIcon icon={['fas', 'chevron-down']} /></button>
                                        </div>
                                    </td>
                                }

                                <td><a className="text-reset" href="javascript: void(0);" onClick={() => setPendingEdit(category)}>{category.name}</a></td>
                                
                                <td className="text-right py-0 align-middle">
                                    <DropdownButton title="Actions" size="sm">
                                        <Dropdown.Item onClick={() => setPendingEdit(category)}>Edit Category</Dropdown.Item>
                                        <Dropdown.Item onClick={() => setPendingDelete(category)}>Delete Category</Dropdown.Item>
                                    </DropdownButton>
                                </td>

                            </tr>

                            {getChildCategories(category.id).orderBy(c => c.sortOrder).map((child) => (

                                <tr key={child.id}>

                                    {isReordering &&
                                        <td className="align-middle text-center py-0">
                                            <div className="btn-group">
                                                <button className="btn btn-sm btn-primary" disabled={getChildCategories(category.id).orderBy(c => c.sortOrder).first() == child} onClick={handleChildReorder.bind(null, child, category, -1)}><FontAwesomeIcon icon={['fas', 'chevron-up']} /></button>
                                                <button className="btn btn-sm btn-primary" disabled={getChildCategories(category.id).orderBy(c => c.sortOrder).last() == child} onClick={handleChildReorder.bind(null, child, category, 1)}><FontAwesomeIcon icon={['fas', 'chevron-down']} /></button>
                                            </div>
                                        </td>
                                    }

                                    <td><a className="text-reset" href="javascript: void(0);" onClick={() => setPendingEdit(child)}>{child.parentName}: {child.name}</a></td>

                                    <td className="text-right py-0 align-middle">
                                        <DropdownButton title="Actions" size="sm">
                                            <Dropdown.Item onClick={() => setPendingEdit(child)}>Edit Category</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setPendingDelete(child)}>Delete Category</Dropdown.Item>
                                        </DropdownButton>
                                    </td>
                                </tr>

                            ))}

                        </React.Fragment>
                    ))}

                </tbody>

            </table>

            {isAddPending &&
                <CategoriesAddOrUpdateModal onSuccess={handleAddSuccess} onCancel={() => setIsAddPending(false)} />
            }

            {pendingEdit &&
                <CategoriesAddOrUpdateModal category={pendingEdit} onSuccess={handleEditSuccess} onCancel={() => setPendingEdit(null)} />
            }

            {pendingDelete &&
                <CategoriesDeleteModal category={pendingDelete} onSuccess={handleDeleteSuccess} onCancel={() => setPendingDelete(null)} />
            }


        </div>

    );

}