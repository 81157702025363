import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import Modal from 'react-bootstrap/Modal';
import { useForm, Controller } from 'react-hook-form';
import { Typeahead } from 'react-bootstrap-typeahead';

import { useApi } from 'lib/effects';
import { post } from 'lib/api';
import withFormHelpers from 'lib/formHelpers';
import { CancellationToken } from 'lib/cancellationTokens';

import Pagination from 'components/Pagination';

export default function NewOrderAddProductsModal(props) {

    let { addToast } = useToasts();

    let defaultValues = {
        categories: [], 
        brands: [], 
        suppliers: [],
        filters: [ 'Inventory Item', 'Not Discontinued', 'Not Deprecated' ], 
        sorting: 'Smart', 
        searchString: null,
    };

    let form = withFormHelpers(useForm({ defaultValues, shouldUnregister: false }));

    let [ page, setPage ] = useState(1);
    let pageSize = 10;

    let [ filterOptions, setFilterOptions ] = useState(null);
    let [ sortingOptions, setSortingOptions ] = useState(null);
    let [ categoryOptions, setCategoryOptions ] = useState(null);
    let [ brandOptions, setBrandOptions ] = useState(null);
    let [ supplierOptions, setSupplierOptions ] = useState(null);

    let [ products, setProducts ] = useState([]);

    let [ pendingSearch, setPendingSearch ] = useState(null);

    useApi('/api/inventory', (response, ex) => {

        if (ex) {
            props.onCancel();
            
            addToast(ex.message, { appearance: 'error' });
            return;
        }

        setFilterOptions(response.filterOptions);
        setSortingOptions(response.sortingOptions);
        setCategoryOptions(response.categories);
        setBrandOptions(response.brands);
        setSupplierOptions(response.suppliers);

    }, []);

    useEffect(() => {

        if (!pendingSearch) {
            return;
        }

        let cancellationToken = new CancellationToken();

        async function asyncRequest() {

            let accumulatedProducts = [];
        
            let page = 0;

            while (true) {

                page++;

                let response;

                try {
                    response = await post('/api/inventory/search', { ...pendingSearch, page }, cancellationToken);
                }
                catch (ex) {
        
                    if (cancellationToken.isCancelled) {
                        return;
                    }
        
                    setPendingSearch(null);
    
                    addToast(ex.message, { appearance: 'error' });
                    return;
        
                }
    
                accumulatedProducts = accumulatedProducts.concat(response.items);

                if (page >= response.totalPages) {
                    break;
                }

            }

            setProducts(accumulatedProducts);
            setPendingSearch(null);

        }

        asyncRequest();

        return cancellationToken;

    }, [ pendingSearch ]);

    function handleSubmit(formData) {
        setPendingSearch({
            ...formData,
            analyse: props.analyse,
            project: props.project,
        });
    }

    function handleReset() {
        form.reset(defaultValues);
        setProducts([]);
        setPage(1);
    }

    function handleAllProductsChecked(event) {
        for (let product of products) {
            product.checked = event.target.checked;
        }
        setProducts([ ...products ]);
    }

    function handleProductChecked(product, event) {
        product.checked = event.target.checked;
        setProducts([ ...products ]);
    }

    function handleSuccess() {
        props.onSuccess(products.filter(p => !!p.checked));
    }

    function currentPage() {
        return products.takePage(page, pageSize);
    }

    if (!categoryOptions || !brandOptions || !supplierOptions) {
        return null;
    }

    return (

        <Modal show={true} centered size="lg" onHide={props.onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Add Products</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <form onSubmit={form.handleSubmit(handleSubmit)}>

                    <fieldset disabled={!!pendingSearch}>

                        <div className="form-group form-row mb-0">
                            <label className="col-sm-2 col-form-label-sm">Filter By Category</label>
                            <div className="col-sm-10">

                                <Controller
                                    name="categories"
                                    control={form.control}
                                    render={(renderProps) => (
                                    
                                        <Typeahead
                                            id="categories"
                                            ref={renderProps.ref}
                                            size="sm"
                                            multiple={true}
                                            clearButton={true}
                                            options={categoryOptions}
                                            selected={categoryOptions.filter(o => renderProps.value.includes(o.name))}
                                            inputProps={{
                                                shouldSelectHint: (shouldSelect, e) => {
                                                    return e.code == 'Enter' || shouldSelect;
                                                },
                                            }}
                                            labelKey={c => [ c.parentName, c.name ].filter(c => !!c).join(': ')}
                                            onChange={(options) => { renderProps.onChange(options.map(o => o.name)); }}
                                            onBlur={renderProps.onBlur}

                                        />



                                )}/>

                            </div>
                        </div>

                        <div className="form-group form-row mb-0">
                            <label className="col-sm-2 col-form-label-sm">Filter By Brand</label>
                            <div className="col-sm-10">

                                <Controller
                                    name="brands"
                                    control={form.control}
                                    render={(renderProps) => (
                                    
                                        <Typeahead
                                            id="brands"
                                            ref={renderProps.ref}
                                            size="sm"
                                            multiple={true}
                                            clearButton={true}
                                            options={brandOptions}
                                            selected={brandOptions.filter(o => renderProps.value.includes(o.name))}
                                            inputProps={{
                                                shouldSelectHint: (shouldSelect, e) => {
                                                    return e.code == 'Enter' || shouldSelect;
                                                },
                                            }}
                                            labelKey={c => c.name}
                                            onChange={(options) => { renderProps.onChange(options.map(o => o.name)); }}
                                            onBlur={renderProps.onBlur}

                                        />

                                )}/>

                            </div>
                        </div>

                        <div className="form-group form-row mb-0">
                            <label className="col-sm-2 col-form-label-sm">Filter By Supplier</label>
                            <div className="col-sm-10">

                                <Controller
                                    name="suppliers"
                                    control={form.control}
                                    render={(renderProps) => (
                                    
                                        <Typeahead
                                            id="suppliers"
                                            ref={renderProps.ref}
                                            size="sm"
                                            multiple={true}
                                            clearButton={true}
                                            options={supplierOptions}
                                            selected={supplierOptions.filter(o => renderProps.value.includes(o.name))}
                                            inputProps={{
                                                shouldSelectHint: (shouldSelect, e) => {
                                                    return e.code == 'Enter' || shouldSelect;
                                                },
                                            }}
                                            labelKey={c => c.name}
                                            onChange={(options) => { renderProps.onChange(options.map(o => o.name)); }}
                                            onBlur={renderProps.onBlur}

                                        />

                                )}/>

                            </div>
                        </div>


                        <div className="form-group form-row mb-0">
                            <label className="col-sm-2 col-form-label-sm">Additional Filters</label>
                            <div className="col-sm-10">

                                <Controller
                                    name="filters"
                                    control={form.control}
                                    render={(renderProps) => (
                                    
                                        <Typeahead
                                            id="filters"
                                            ref={renderProps.ref}
                                            size="sm"
                                            multiple={true}
                                            clearButton={true}
                                            options={filterOptions}
                                            selected={renderProps.value}
                                            inputProps={{
                                                shouldSelectHint: (shouldSelect, e) => {
                                                    return e.code == 'Enter' || shouldSelect;
                                                },
                                            }}
                                            onChange={(options) => { renderProps.onChange(options); }}
                                            onBlur={renderProps.onBlur}
                                        />

                                )}/>

                            </div>
                        </div>

                        <div className="form-group form-row mb-0">
                            <label className="col-sm-2 col-form-label-sm">Sort By</label>
                            <div className="col-sm-10">
                                <select name="sorting" ref={form.register} className="custom-select custom-select-sm">
                                    {sortingOptions.map((sortOption) => (

                                        <option key={sortOption} value={sortOption}>{sortOption}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="form-group form-row mb-0">
                            <label className="col-sm-2 col-form-label-sm">Search String</label>
                            <div className="col-sm-10">
                                <input name="searchString" type="text" ref={form.register} className="form-control form-control-sm mr-sm-2" id="searchString" placeholder="Search string" />
                            </div>
                        </div>

                        <div className="form-group form-row mb-0">
                            <div className="col-sm-12">
                                <button type="submit" className="btn btn-sm btn-primary">
                                    {!!pendingSearch &&
                                        <span className="spinner-border spinner-border-sm"></span>
                                    }
                                    {' '}
                                    Search
                                </button>
                                {' '}
                                <button type="button" className="btn btn-sm btn-secondary" onClick={handleReset}>Reset</button>
                            </div>
                        </div>

                    </fieldset>
                </form>

                <hr />

                <table className="table small">

                    <thead className="thead-dark">
                        <tr>

                            <th className="text-center" style={{ width: '1%' }}>
                                <input type="checkbox" checked={products.any() && products.every(p => p.checked)} onChange={handleAllProductsChecked} />
                            </th>

                            <th>
                                Product
                                {products.except(currentPage().items).any(p => !!p.checked) &&
                                    <span style={{ fontSize: 'inherit' }} className="badge badge-warning ml-2">{products.count(p => !!p.checked)} selected</span>
                                }
                            </th>

                        </tr>
                    </thead>

                    <tbody>

                        {currentPage().items.map((product) => (

                            <tr key={product.id}>
                                <td className="text-center" >
                                    <input type="checkbox" checked={!!product.checked} onChange={handleProductChecked.bind(this, product)} />
                                </td>
                                <td>{product.name.replaceAll('|', '•')}</td>
                            </tr>

                        ))}

                        {!currentPage().totalCount &&
                            <tr>
                                <td colSpan={2}>No results</td>
                            </tr>
                        }

                    </tbody>

                </table>

                <Pagination page={currentPage().page} totalPages={currentPage().totalPages} onPageClicked={setPage} />

            </Modal.Body>

            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={props.onCancel}>Cancel</button>
                <button type="button" className="btn btn-primary" disabled={!products.any()} onClick={handleSuccess}>Add Products</button>
            </Modal.Footer>

        </Modal>

    );

}

NewOrderAddProductsModal.propTypes = {
    analyse: PropTypes.string.isRequired,
    project: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};