import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Decimal from 'decimal.js';

export default function NumberInput({ value, onChange, min, max, dp, ...otherProps }) {

    let [ internalValue, setInternalValue ] = useState(value);

    useEffect(() => {
        setInternalValue(value);
    }, [ value ]);

    function handleChange(event) {
        setInternalValue(event.target.value);
    }
    
    function handleBlur(event) {

        let sanitized = event.target.value.replaceAll(/[^0-9.]/g, '');

        let dec;

        try {
            dec = Decimal(sanitized);
        }
        catch (ex) {
        }

        if (dec == null)
        {
            dec = Decimal(0);
        }
        else if (!!min && dec.lt(min))
        {
            dec = Decimal(min);
        }
        else if (!!max && dec.gt(max))
        {
            dec = Decimal(max);
        }
        else if (!!dp && dec.dp() > dp)
        {
            dec = dec.toDP(dp);
        }

        internalValue = dec.toNumber(dp);

        setInternalValue(internalValue);

        if (internalValue != value) {
            onChange(internalValue);
        }
    }

    return (
        <input {...otherProps} type="text" value={internalValue} onChange={handleChange} onBlur={handleBlur} />
    );

 }

NumberInput.propTypes = {
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
    dp: PropTypes.number,
};