import React, { useContext } from 'react'
import { Link } from 'react-router-dom';

import AppContext from 'AppContext';

export default function Home(props) {

    let appContext = useContext(AppContext);

    function handleLoginClicked() {
        appContext.login();
    }

    return (

        <div className="mt-3 container-fluid">
            
            {appContext.me &&

                <React.Fragment>

                    <p>Hello {appContext.me.firstName}, welcome to VapeServ.</p>

                    <p><Link to="/me/profile">Update your profile</Link></p>

                    <p><Link to="/me/password">Change your password</Link></p>

                </React.Fragment>

            }

            {!appContext.me &&

                <React.Fragment>

                    <p>Please login.</p>

                    <a className="btn btn-primary" onClick={handleLoginClicked}>Login</a>

                </React.Fragment>

            }

        </div>

    );

}