import Decimal from 'decimal.js';

Array.prototype.first = function(predicate) {

    let arr;
    
    if (predicate) {
        arr = this.filter(predicate);
    }
    else {
        arr = this;
    }

    return arr[0];

}

Array.prototype.last = function(predicate) {

    let arr;
    
    if (predicate) {
        arr = this.filter(predicate);
    }
    else {
        arr = this;
    }

    return arr[arr.length - 1];

}

Array.prototype.any = function(predicate) {

    let arr;
    
    if (predicate) {
        arr = this.filter(predicate);
    }
    else {
        arr = this;
    }

    return arr.length > 0;

}

Array.prototype.sequenceEqual = function(secondArr) {

    if (this.length != secondArr.length) {
        return false;
    }

    for (let idx in this) {
        if (this[idx] != secondArr[idx]) {
            return false;
        }
    }

    return true;

}

    

Array.prototype.orderBy = function(selector, direction) {

    function innerCompare(a, b) {

        if (typeof a === 'string' && typeof b === 'string') {
            return direction == 'desc'
                ? b.localeCompare(a, 'en', {'sensitivity': 'base'})
                : a.localeCompare(b, 'en', {'sensitivity': 'base'});
        }
        
        return direction == 'desc'
            ? a < b
            : a > b;
    }

    return this.slice().sort((a, b) => {

        let resultA = selector(a);
        let resultB = selector(b);

        if (Array.isArray(resultA)) {

            for (let i in resultA) {

                if (resultA[i] === resultB[i]) continue;

                return innerCompare(resultA[i], resultB[i]);
            }
            
            return false;

        }
        else {
            return innerCompare(resultA, resultB);
        }

    });

}


Array.prototype.orderByDescending = function(selector) {
    return this.orderBy(selector, 'desc');
}

Array.prototype.min = function(selector) {
    return this.map(selector).orderBy(x => x).first();
}

Array.prototype.max = function(selector) {
    return this.map(selector).orderBy(x => x).last();
}

Array.prototype.skip = function(num) {
    return this.slice(num);
}

Array.prototype.take = function(num) {
    return this.slice(0, num);
}

Array.prototype.count = function(predicate) {

    let arr;
    
    if (predicate) {
        arr = this.filter(predicate);
    }
    else {
        arr = this;
    }

    return arr.length;

}

Array.prototype.except = function(arr) {
    return this.filter(i => !arr.includes(i));
}

Array.prototype.distinct = function() {

    let arr = [];

    for (let item of this) {
        if (!arr.includes(item)) {
            arr.push(item);
        }
    }

    return arr;

}

Array.prototype.sum = function(selector) {

    let arr;
    
    if (selector) {
        arr = this.map(selector);
    }
    else {
        arr = this;
    }

    let result = arr.reduce((accumulator, currentValue) => accumulator.plus(currentValue), Decimal(0));

    return result.toNumber();
}

class GroupedArray extends Array {

    key = null;
    
    constructor(key) {
        super();
        this.key = key;
    }

}

Array.prototype.groupBy = function(selector) {

    function innerMatch(a, b) {

        if (Array.isArray(a)) {

            for (let i in a) {
                if (a[i] !== b[i]) {
                    return false;
                }
            }
            
            return true;

        }
        else {
            return a == b;
        }

    }

    let accum = [];

    for (let item of this) {

        let key = selector(item);

        let group = accum.find(i => innerMatch(i.key, key));
    
        if (!group) {
            group = new GroupedArray(key);
            accum.push(group);
        }

        group.push(item);

    }

    return accum;

}

export default class PagedList {

    items = [];
    page = 0;
    pageSize = 0;
    totalPages = 0;
    totalCount = 0;

    constructor(page, pageSize, totalPages, totalCount, items) {
        this.page = page;
        this.pageSize = pageSize;
        this.totalPages = totalPages;
        this.totalCount = totalCount;
        this.items = items;
    }

    get hasPreviousPage() {
        return this.page > 1;
    }

    get hasNextPage() {
        return this.page < this.totalPages;
    }

};

Array.prototype.takePage = function(page, pageSize) {

    let pagedList = new PagedList();

    let slicedItems = this.skip((page - 1) * pageSize).take(pageSize);

    pagedList.page = page;
    pagedList.pageSize = pageSize;
    pagedList.totalPages = Math.ceil(this.count() / pageSize); //concerned about javascript's god awful rounding here
    pagedList.totalCount = this.count();
    pagedList.items = slicedItems;

    return pagedList;

}