import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import Modal from 'react-bootstrap/Modal';

import { tryPut as put } from 'lib/api';
import { CancellationToken } from 'lib/cancellationTokens';

import JobAddressModalInner from 'components/JobAddressModalInner';

export default function JobBillingAddressModal({ job, allowNewAddress = false, onSuccess, onCancel }) {

    let modalInnerRef = useRef();

    let { addToast } = useToasts();

    let [ pendingSave, setPendingSave ] = useState(null);

    useEffect(() => {

        if (!pendingSave) {
            return;
        }

        let cancellationToken = new CancellationToken();

        put(`/api/fulfillment/jobs/${job.id}/billingaddress`, pendingSave)
        
            .then(([ _, ex ]) => {

                if (cancellationToken.isCancelled) {
                    return;
                }

                setPendingSave(null);

                if (ex) {
                    addToast(ex.message, { appearance: 'error' });
                    return;
                }

                addToast('Billing address updated.', { appearance: 'success' });

                onSuccess();

            });

        return cancellationToken;

    }, [ pendingSave ]);

    function handleSubmit() {
        modalInnerRef.current.submit();
    }

    function handleValidAddress(address, isNewAddress) {
        setPendingSave({
            ...address,
            isNewAddress,
        });
    }

    return (

        <Modal show={true} centered size="xl" onHide={onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Change Billing Address</Modal.Title>
            </Modal.Header>

            <JobAddressModalInner disabled={!!pendingSave} ref={modalInnerRef} address={job.billingAddress} originalAddress={job.originalBillingAddress} allowNewAddress={allowNewAddress} onValidAddress={handleValidAddress} />

            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={onCancel}>Cancel</button>

                <button type="button" className="btn btn-primary" onClick={handleSubmit} disabled={!!pendingSave}>
                    {!!pendingSave &&
                        <span className="spinner-border spinner-border-sm"></span>
                    }
                    {' '}
                    Change Billing Address
                </button>

            </Modal.Footer>
        </Modal>

    );

}

JobBillingAddressModal.propTypes = {
    job: PropTypes.object.isRequired,
    allowNewAddress: PropTypes.bool,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};