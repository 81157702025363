import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import scrollIntoView from 'scroll-into-view-if-needed'
import moment from 'moment';
import cx from 'classnames';

import { tryPost as post } from 'lib/api';
import { CancellationToken } from 'lib/cancellationTokens';

import LoadingBar from 'components/LoadingBar';
import Pagination from 'components/Pagination';


export function Journals() {

    let anchorRef = useRef();

    let { addToast } = useToasts();

    let [ journalEntries, setJournalEntries ] = useState(null);

    let [ isLoading, setIsLoading ] = useState(true);
    let [ page, setPage ] = useState(1);
    let [ view, setView ] = useState('Show All');
    let [ startDate, setStartDate ] = useState(moment().format('YYYY-MM-DD'));
    let [ endDate, setEndDate ] = useState(moment().format('YYYY-MM-DD'));
    let [ sortBy, setSortBy ] = useState('Newest First');
    let [ productIds, setProductIds ] = useState([]);

    let [ productSearchResult, setProductSearchResult ] = useState([]);
    let [ productSearchString, setProductSearchString ] = useState(null);

    useEffect(() => {

        if (view != 'Live View') {
            return;
        }

        let interval = setInterval(() => {
            setIsLoading(true);
        }, 5000);

        return () => {
            clearInterval(interval);
        };

    });

    useEffect(() => {

        if (!isLoading) {
            return;
        }

        let cancellationToken = new CancellationToken();

        let request = {
            newestFirst: sortBy == 'Newest First' || view == 'Live View',
            filters: ['Last Hour', 'Last Day', 'Last 7 Days', 'Last 30 Days'].includes(view) ? [view] : [],
            startDate: view == 'Date Range' ? startDate : null,
            endDate: view == 'Date Range' ? endDate : null,
            productIds: productIds,
            page: page,
        };

        post('/api/inventory/journalentries', request)

            .then(([response, ex]) => {

                setIsLoading(false);

                if (cancellationToken.isCancelled) {
                    return;
                }

                if (ex) {
                    addToast(ex.message, { appearance: 'error' });
                    return;
                }

                if (!!journalEntries && view == 'Live View' && response.totalCount > 0) {

                    let lastDateInThisLot = response.items.last().journalEffectiveDate;

                    let removedJournalEntries = journalEntries.items
                        .filter(je => je.journalEffectiveDate > lastDateInThisLot)
                        .filter(i => !response.items.any(j => j.id == i.id));

                    for (let removedEntry of removedJournalEntries) {
                        removedEntry.removedAt = removedEntry.removedAt ?? moment();
                    }

                    let newJournalEntries = response.items.filter(i => !journalEntries.items.any(j => j.id == i.id));

                    let updatedJournalEntries = response.items.except(newJournalEntries).map(updated => {
                        let original = journalEntries.items.find(j => j.id == updated.id);
                        return { ...original, ...updated };
                    });

                    response.items = updatedJournalEntries
                        .concat(newJournalEntries)
                        .concat(removedJournalEntries)
                        .orderByDescending(i => [ i.journalEffectiveDate, i.journalId, i.id ]);

                }

                setJournalEntries(response);
                setIsLoading(false);

                if (view != 'Live View') {
                    if (anchorRef.current) {
                        scrollIntoView(anchorRef.current, {
                            scrollMode: 'if-needed',
                            block: 'start',
                        });
                    }
                }

            });

        return cancellationToken;

    }, [ isLoading ]);

    useEffect(() => {

        if (!productSearchString) {
            return;
        }

        let cancellationToken = new CancellationToken();

        post('/api/inventory/search', { searchString: productSearchString, filters: [ 'Inventory Item'] })
        
            .then(([ response, ex ]) => {

                setProductSearchString(null);

                if (cancellationToken.isCancelled) {
                    return;
                }

                if (ex) {
                    addToast(ex.message, { appearance: 'error' });
                    return;
                }

                setProductSearchResult(response.items);
                
            });

        return cancellationToken;

    }, [ productSearchString ]);

    function handleProductSearch(searchString) {
        setProductSearchResult(null);
        setProductSearchString(searchString);
    }

    function handleCriteriaChanged() {
        setJournalEntries(null);
        setPage(1);
        setIsLoading(true);
    }

    function handlePageChanged(page) {
        setPage(page);
        setIsLoading(true);
    }

    function buildRowStyles(entry) {

        if (!!entry.removedAt) {

            let minutes = moment().diff(entry.removedAt, 'minutes');

            if (minutes > 60) {
                return null;
            }

            let tint = rgbTint(200, minutes, 60);
            return { backgroundColor: `rgb(255,${tint},${tint})` };

        }
        else {

            let minutes = moment().diff(moment(entry.journalEffectiveDate), 'minutes');

            if (minutes > 60) {
                return null;
            }

            let tint = rgbTint(200, minutes, 60);
            return { backgroundColor: `rgb(${tint},255,${tint})` };

        }


    }

    function buildRowClassName(entry) {
        if (!!entry.removedAt) {
            return moment().diff(entry.removedAt, 'minutes') < 1 ? 'tvk-removed-item' : null;
        }
        else {
            return moment().diff(moment(entry.journalEffectiveDate), 'minutes') < 1 ? 'tvk-new-item' : null;
        }

    }

    function rgbTint(minimumTint, num, maxNum) {
        let remaining = 255 - minimumTint;
        num = Math.max(num, 0);
        let x = minimumTint + (num * (remaining / maxNum));
        return x;
    }

    return (

        <React.Fragment>

            {(isLoading) &&
                <LoadingBar />
            }

            <div className="mt-3 container-fluid">

                <div className="d-flex align-items-center mb-3 flex-wrap">

                    <h3 className="mb-0"><FontAwesomeIcon icon={['fas', 'retweet']} fixedWidth /> Journals</h3>

                    <div className="ml-4">
                        <select className="custom-select" value={view} onChange={(e) => handleCriteriaChanged(setView(e.target.value))}>
                            <option value="Live View">Live View</option>
                            <option value="Show All">Show All</option>
                            <option value="Last Hour">Last Hour</option>
                            <option value="Last Day">Last Day</option>
                            <option value="Last 7 Days">Last 7 Days</option>
                            <option value="Last 30 Days">Last 30 Days</option>
                            <option value="Date Range">Date Range</option>
                        </select>
                    </div>

                    {view == 'Date Range' &&

                        <React.Fragment>

                            <div className="ml-4">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">From</span>
                                    </div>
                                    <input type="date" className="form-control" value={startDate} onChange={(e) => handleCriteriaChanged(setStartDate(e.target.value))} />
                                </div>
                            </div>

                            <div className="ml-4">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">To</span>
                                    </div>
                                    <input type="date" className="form-control" value={endDate} onChange={(e) => handleCriteriaChanged(setEndDate(e.target.value))} />
                                </div>
                            </div>

                        </React.Fragment>

                    }

                    {view != 'Live View' &&

                        <div className="ml-4">
                            <select className="custom-select" value={sortBy} onChange={(e) => handleCriteriaChanged(setSortBy(e.target.value))}>
                                <option value="Newest First">Newest First</option>
                                <option value="Oldest First">Oldest First</option>
                            </select>
                        </div>

                    }

                    <div className="ml-4" style={{ width: '500px' }}>

                        <div className="input-group">

                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <FontAwesomeIcon icon={["fas", "box-open"]} />
                                </span>
                            </div>

                            <AsyncTypeahead
                                id="products"
                                multiple={true}
                                clearButton={true}
                                placeholder="Filter by products"
                                isLoading={productSearchResult == null}
                                options={productSearchResult || []}
                                labelKey={option => option.name}
                                inputProps={{
                                    shouldSelectHint: (shouldSelect, e) => {
                                        return e.code == 'Enter' || shouldSelect;
                                    },
                                }}
                                onSearch={handleProductSearch}
                                onChange={(options) => handleCriteriaChanged(setProductIds(options.map(p => p.id)))}
                            />

                        </div>

                    </div>

                </div>

                <div ref={anchorRef}></div>

                <table className="table table-sm tvk-journals-table">

                    <thead className="thead-dark" style={{ position: 'sticky', top: '0px', zIndex: 1 }}>
                        <tr>
                            <th>Date</th>
                            <th>Type</th>
                            <th>Job</th>
                            <th>Product</th>
                            <th className="text-center">Sold</th>
                            <th className="text-center">Ext. Sold</th>
                            <th className="text-center">Shipped</th>
                            <th className="text-center">On Order</th>
                            <th className="text-center">On Hand</th>
                            <th className="text-center">Allocated</th>
                            <th className="text-center">Available</th>
                        </tr>
                    </thead>

                    <tbody>

                        <React.Fragment>

                            {!journalEntries &&
                                <tr colSpan="999"><td>No journal entries.</td></tr>
                            }

                            {!!journalEntries && journalEntries.items.map((entry) => (

                                <React.Fragment key={entry.id}>

                                    <tr className={buildRowClassName(entry)} style={buildRowStyles(entry)}>
                                        <td>{moment(entry.journalEffectiveDate).toDate().toLocaleString()}</td>

                                        <td>{entry.journalTypeName}</td>

                                        <td>
                                            {!!entry.journalJobId &&
                                                <Link to={`/fulfillment/${entry.journalJobId}`}>#{entry.journalJobOrderNumber}</Link>
                                            }
                                            {!entry.journalJobId &&
                                                <span>n/a</span>
                                            }
                                        </td>

                                        <td>{entry.productName}</td>

                                        {(entry.journalTypeName != 'Status Update') &&

                                            <React.Fragment>

                                                <td className="text-center">{entry.sold || ''}</td>

                                                <td className="text-center">{entry.externalSold || ''}</td>

                                                <td className="text-center">{entry.shipped || ''}</td>

                                                <td>
                                                    <NumbersWidget main={entry.onOrder} local={entry.localOnOrder} dear={entry.dearOnOrder} running={entry.runningOnOrder} />
                                                </td>

                                                <td>
                                                    <NumbersWidget main={entry.onHand} local={entry.localOnHand} dear={entry.dearOnHand} running={entry.runningOnHand} />
                                                </td>

                                                <td>
                                                    <NumbersWidget main={entry.allocated} local={entry.localAllocated} dear={entry.dearAllocated} running={entry.runningAllocated} />
                                                </td>

                                                <td>
                                                    <NumbersWidget main={entry.available} local={entry.localAvailable} dear={entry.dearAvailable} running={entry.runningAvailable} />
                                                </td>

                                            </React.Fragment>

                                        }

                                        {(entry.journalTypeName == 'Status Update') &&
                                            <td colspan={7}>
                                                {entry.published == 1 &&
                                                    <span className="mr-2">✔️ Published</span>
                                                }
                                                {entry.published == -1 &&
                                                    <span className="mr-2">❌ Not published</span>
                                                }
                                                {entry.purchasable == 1 &&
                                                    <span className="mr-2">✔️ Purchasable</span>
                                                }
                                                {entry.purchasable == -1 &&
                                                    <span className="mr-2">❌ Not purchasable</span>
                                                }
                                            </td>
                                        }


                                    </tr>

                                </React.Fragment>

                            ))}

                        </React.Fragment>

                    </tbody>

                </table>

                {(!!journalEntries && view != 'Live View') &&
                    <Pagination page={journalEntries.page} totalPages={journalEntries.totalPages} onPageClicked={handlePageChanged} />
                }

            </div>

        </React.Fragment>

    );

}

export default withRouter(Journals);

function NumbersWidget({ main, local, dear, running }) {

    return (
        <React.Fragment>

            {main != 0 &&

                <div className="d-flex align-items-center">

                    <div className={cx('d-flex align-items-center', main > 0 ? 'text-success' : 'text-danger', running != null ? 'justify-content-end' : 'justify-content-center')} style={{ flex: '1' }}>

                        <span className="font-weight-bold" style={{ fontSize: '65%' }}>{local == main ? "L" : "D"} </span>

                        <span className="ml-1">
                            <FontAwesomeIcon icon={['fas', main > 0 ? 'caret-up' : 'caret-down']} /> {Math.abs(main)}
                        </span>

                    </div>

                    {running != null &&
                        <div className="small text-muted ml-2" style={{ flex: '1' }}>{running}</div>
                    }

                </div>

            }

            {main == 0 && (local != 0 || dear != 0) &&

                <div className="d-flex">

                    <div className="text-tvk-orange d-flex align-items-center justify-content-end" style={{ flex: '1' }}>

                        <span className="font-weight-bold" style={{ fontSize: '65%' }}>L </span>

                        <span className="ml-1">
                            <FontAwesomeIcon icon={['fas', local > 0 ? 'caret-up' : 'caret-down']} /> {Math.abs(local)}
                        </span>

                    </div>

                    <div className="text-tvk-orange d-flex align-items-center ml-2" style={{ flex: '1' }}>

                        <span className="font-weight-bold" style={{ fontSize: '65%' }}>D </span>

                        <span className="ml-1">
                            <FontAwesomeIcon icon={['fas', dear > 0 ? 'caret-up' : 'caret-down']} /> {Math.abs(dear)}
                        </span>

                    </div>

                </div>

            }

        </React.Fragment>

    );

}