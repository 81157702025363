import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToasts } from 'react-toast-notifications';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import { useApi } from 'lib/effects';

import LoadingBar from 'components/LoadingBar';
import OrganisationRolesAddModal from 'components/OrganisationRolesAddModal';
import OrganisationRolesEditModal from 'components/OrganisationRolesEditModal';
import OrganisationRolesDeleteModal from 'components/OrganisationRolesDeleteModal';

export default function OrganisationRoles(props) {

    let { addToast } = useToasts();

    let [ roles, setRoles ] = useState(null);

    let [ isAddPending, setIsAddPending ] = useState(false);
    let [ pendingEdit, setPendingEdit ] = useState(null);
    let [ pendingDelete, setPendingDelete ] = useState(null);

    useApi('/api/organisation/roles', (response, ex) => {

        if (ex) {

            addToast(ex.message, { appearance: 'error' });
            return;

        }

        setRoles(response);

    }, []);


    function handleAddSuccess(role) {
        setRoles(roles.concat([ role ]));
        setIsAddPending(false);
    }

    function handleEditSuccess(role) {
        setRoles(roles.filter(u => u != pendingEdit).concat([ role ]));
        setPendingEdit(null);
    }

    function handleDeleteSuccess() {
        setRoles(roles.filter(u => u != pendingDelete));
        setPendingDelete(null);
    }

    if (!roles) {
        return (
            <LoadingBar />
        );
    }

    return (

        <div className="mt-3 container-fluid">

            <button className="btn btn-success float-right" onClick={() => setIsAddPending(true)}><FontAwesomeIcon icon={[ 'fas', 'plus' ]} /> Add Role</button>

            <h3><FontAwesomeIcon icon={[ 'fas', 'users' ]} /> Roles</h3>

            <p>Roles are used to group permissions.</p>

            <table className="table">
                <thead className="thead-dark">
                    <tr>
                        <th style={{ width: '15%' }}>Name</th>
                        <th style={{ width: '35%' }}>Description</th>
                        <th>Permissions</th>
                        <th style={{ width: '10%' }}></th>
                    </tr>
                </thead>
                
                <tbody>

                    {roles.sort((a, b) => a.name > b.name).map((role) => (
                        <tr key={role.id}>
                            <td>{role.name}</td>
                            <td>{role.description}</td>
                            <td>{role.permissions.join(', ')}</td>
                            <td className="text-right">
                                <DropdownButton title="Actions">
                                    <Dropdown.Item onClick={() => setPendingEdit(role)}>Edit Role</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setPendingDelete(role)}>Delete Role</Dropdown.Item>
                                </DropdownButton>
                            </td>
                        </tr>
                    ))}

                </tbody>

            </table>

            {isAddPending &&
                <OrganisationRolesAddModal onSuccess={handleAddSuccess} onCancel={() => setIsAddPending(false)} />
            }

            {pendingEdit &&
                <OrganisationRolesEditModal role={pendingEdit} onSuccess={handleEditSuccess} onCancel={() => setPendingEdit(null)} />
            }

            {pendingDelete &&
                <OrganisationRolesDeleteModal role={pendingDelete} onSuccess={handleDeleteSuccess} onCancel={() => setPendingDelete(null)} />
            }

        </div>

    );

}