import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToasts } from 'react-toast-notifications';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import { useApi } from 'lib/effects';

import LoadingBar from 'components/LoadingBar';
import SuppliersAddOrUpdateModal from 'components/SuppliersAddOrUpdateModal';
import SuppliersDeleteModal from 'components/SuppliersDeleteModal';

export default function Suppliers(props) {

    let { addToast } = useToasts();

    let [ suppliers, setSuppliers ] = useState(null);

    let [ isAddPending, setIsAddPending ] = useState(false);
    let [ pendingEdit, setPendingEdit ] = useState(null);
    let [ pendingDelete, setPendingDelete ] = useState(null);

    useApi('/api/inventory/suppliers', (response, ex) => {

        if (ex) {
            addToast(ex.message, { appearance: 'error' });
            return;
        }

        setSuppliers(response);

    }, []);


    function handleAddSuccess(supplier) {
        setSuppliers(suppliers.concat([ supplier ]));
        setIsAddPending(false);
    }

    function handleEditSuccess(supplier) {
        setSuppliers(suppliers.filter(u => u != pendingEdit).concat([ supplier ]));
        setPendingEdit(null);
    }

    function handleDeleteSuccess() {
        setSuppliers(suppliers.filter(u => u != pendingDelete));
        setPendingDelete(null);
    }


    if (!suppliers) {
        return (
            <LoadingBar />
        );
    }

    return (

        <div className="mt-3 container-fluid">

            <button className="btn btn-success float-right" onClick={() => setIsAddPending(true)}><FontAwesomeIcon icon={[ 'fas', 'plus' ]} /> Add Supplier</button>

            <h3><FontAwesomeIcon icon={[ 'fas', 'building' ]} /> Suppliers</h3>

            <p>Configure the suppliers you purchase products from.</p>

            <table className="table">
                <thead className="thead-dark">
                    <tr>
                        <th>Name</th>
                        <th style={{ width: '10%' }}>Currency</th>
                        <th style={{ width: '10%' }}>GST Payable</th>
                        <th style={{ width: '10%' }}>GST Inclusive</th>
                        <th style={{ width: '10%' }}>Use Last Price</th>
                        <th style={{ width: '10%' }}></th>
                    </tr>
                </thead>
                
                <tbody>

                    {suppliers.orderBy(s => s.name).map((supplier) => (
                        <tr key={supplier.id}>
                            <td><a className="text-reset" href="javascript: void(0);" onClick={() => setPendingEdit(supplier)}>{supplier.name}</a></td>
                            <td>{supplier.currency}</td>
                            <td><FontAwesomeIcon icon={[ 'fas', supplier.isGstPayable ? 'check' : 'times' ]} /></td>
                            <td><FontAwesomeIcon icon={[ 'fas', supplier.pricesIncludeGst ? 'check' : 'times' ]} /></td>
                            <td><FontAwesomeIcon icon={[ 'fas', supplier.useLastPrice ? 'check' : 'times' ]} /></td>
                            <td className="text-right py-0 align-middle">
                                <DropdownButton title="Actions" size="sm">
                                    <Dropdown.Item onClick={() => setPendingEdit(supplier)}>Edit Supplier</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setPendingDelete(supplier)}>Delete Supplier</Dropdown.Item>
                                </DropdownButton>
                            </td>
                        </tr>
                    ))}

                </tbody>

            </table>

            {isAddPending &&
                <SuppliersAddOrUpdateModal onSuccess={handleAddSuccess} onCancel={() => setIsAddPending(false)} />
            }

            {pendingEdit &&
                <SuppliersAddOrUpdateModal supplier={pendingEdit} onSuccess={handleEditSuccess} onCancel={() => setPendingEdit(null)} />
            }

            {pendingDelete &&
                <SuppliersDeleteModal supplier={pendingDelete} onSuccess={handleDeleteSuccess} onCancel={() => setPendingDelete(null)} />
            }


        </div>

    );

}