import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToasts } from 'react-toast-notifications';
import { useForm, Controller } from 'react-hook-form';
import { Typeahead } from 'react-bootstrap-typeahead';
import cx from 'classnames';
import Modal from 'react-bootstrap/Modal';

import { useApi } from 'lib/effects';
import { put, ApiValidationError } from 'lib/api';
import withFormHelpers from 'lib/formHelpers';
import { CancellationToken } from 'lib/cancellationTokens';


export default function OrganisationRolesEditModal(props) {

    let { addToast } = useToasts();

    let [ permissions, setPermissions ] = useState(null);

    let [ formData, setFormData ] = useState(null);
    let form = withFormHelpers(useForm({ defaultValues: props.role, mode: 'onBlur' }));

    useApi('/api/organisation/permissions', (response, ex) => {

        if (ex) {
            props.onCancel();

            addToast(ex.message, { appearance: 'error' });
            return;
        }

        setPermissions(response);

    }, []);

    useEffect(() => {

        if (!formData) {
            return;
        }

        let cancellationToken = new CancellationToken();

        async function asyncRequest() {

            let response;

            try {
                response = await put(`/api/organisation/roles/${props.role.id}`, formData, cancellationToken);
            }
            catch (ex) {
    
                if (cancellationToken.isCancelled) {
                    return;
                }

                setFormData(null);
                
                if (ex instanceof ApiValidationError) {
                    form.setApiValidationErrors(ex.validationErrors);
                    return;
                }

                addToast(ex.message, { appearance: 'error' });
                return;
    
            }

            setFormData(null);

            props.onSuccess(response);

            addToast('Role has been updated.', { appearance: 'success' });
            
        }

        asyncRequest();

        return cancellationToken;

    }, [ formData ]);

    function handleSubmit(formData) {
        setFormData(formData);
    }

    function handleCancel() {
        props.onCancel();
    }

    if (!permissions) {
        return null;
    }

    return (

        <Modal show={true} centered size="lg" onHide={handleCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Edit {props.role.name}</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <form onSubmit={form.handleSubmit(handleSubmit)}>

                    <fieldset disabled={!!formData}>

                        <div className="form-group">

                            <label>Name</label>

                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <FontAwesomeIcon icon={[ 'fas', 'user' ]} />
                                    </span>
                                </div>

                                <input name="name" type="text" ref={form.register({ required: true })} className={cx('form-control', form.validationClassName('name'))} />

                                {form.hasErrors('name') &&
                                    <div className="invalid-feedback">{form.errorMessage('name', 'Please enter a valid name.')}</div>
                                }

                            </div>

                        </div>

                        <div className="form-group">

                            <label>Description</label>

                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <FontAwesomeIcon icon={[ 'fas', 'user' ]} />
                                    </span>
                                </div>

                                <input name="description" type="text" ref={form.register({ required: true })} className={cx('form-control', form.validationClassName('description'))} />

                                {form.hasErrors('description') &&
                                    <div className="invalid-feedback">{form.errorMessage('description', 'Please enter a valid description.')}</div>
                                }

                            </div>

                        </div>

                        <div className="form-group">

                            <label>Permissions</label>

                            <div className="input-group">

                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <FontAwesomeIcon icon={[ 'fas', 'tag' ]} />
                                    </span>
                                </div>

                                <Controller
                                    control={form.control}
                                    name="permissions"
                                    rules={{ required: true }}
                                    render={(renderProps) => (
                                    
                                        <Typeahead
                                            id="permissions"
                                            ref={renderProps.ref}
                                            multiple={true}
                                            defaultSelected={renderProps.value}
                                            inputProps={{
                                                shouldSelectHint: (shouldSelect, e) => {
                                                    return e.code == 'Enter' || shouldSelect;
                                                },
                                            }}
                                            isValid={form.isValid('permissions')}
                                            isInvalid={form.isInvalid('permissions')}
                                            onChange={renderProps.onChange}
                                            onBlur={renderProps.onBlur}
                                            options={permissions}
                                        />

                                )}/>

                                {form.hasErrors('permissions') &&
                                    <div className="invalid-feedback d-block">{form.errorMessage('lastName', 'Please select one or more permissions.')}</div>
                                }

                            </div>

                        </div>

                    </fieldset>

                </form>

            </Modal.Body>

            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={handleCancel}>Cancel</button>

                <button type="button" className="btn btn-primary" onClick={form.handleSubmit(handleSubmit)} disabled={!!formData}>
                    {formData &&
                        <span className="spinner-border spinner-border-sm"></span>
                    }
                    {' '}
                    Update Role
                </button>

            </Modal.Footer>
        </Modal>

    );

}

OrganisationRolesEditModal.propTypes = {
    role: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};