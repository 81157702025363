import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ConfirmDeleteButton({ onClick, className, ...otherProps }) {

    let [ isDeleting, setIsDeleting ] = useState(false);

    function handleClick() {

        if (isDeleting) {

            onClick();

            setIsDeleting(false);

        }
        else {

            setIsDeleting(true);

        }

    }

    function handleBlur() {
        setIsDeleting(false);
    }

    return (
        <button {...otherProps} type="button" className={cx('btn btn-sm btn-danger', className)} onClick={handleClick} onBlur={handleBlur}>
            <FontAwesomeIcon icon={['fas', isDeleting ? 'times-circle' : 'times']} fixedWidth /> 
        </button>
    );

 }

ConfirmDeleteButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};