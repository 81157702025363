
export function preparednessScoreStyles(preparednessScore) {

    if (preparednessScore >= 100) {
        return "rgb(0,180,0)";
    }

    let minTint = 160;
    let maxTint = 220;
    let diff = maxTint - minTint;
    let howRed = (100 - preparednessScore) / 100;
    let finalTint = minTint + (diff * howRed);

    return `rgb(${finalTint},0,0)`;
}


export function probabilityScoreStyles(probabilityScore) {

    let lowest = 25;
    let ideal = 75;

    let redPct = probabilityScore < lowest ? 1 : probabilityScore < ideal ? (lowest / probabilityScore) : 0;

    let maxTint = 220;

    return {
        color: `rgb(${maxTint / 1 * redPct},0,0)`,
    }
}


export function retailGpStyles(retailGp) {

    let lowest = 15;
    let ideal = 40;
    let highest = 50;

    let greenPct = retailGp > highest ? 1 : retailGp >= ideal ? (retailGp / highest) : 0;
    let redPct = retailGp < lowest ? 1 : retailGp < ideal ? (lowest / retailGp) : 0;

    let maxTint = 200;

    return {
        color: `rgb(${maxTint / 1 * redPct},${maxTint / 1 * greenPct},0)`,
    }
}

