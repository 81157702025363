import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import { useForm, Controller } from 'react-hook-form';
import cx from 'classnames';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useApi } from 'lib/effects';
import { tryPut as put, tryPost as post, ApiValidationError } from 'lib/api';
import withFormHelpers from 'lib/formHelpers';
import { CancellationToken } from 'lib/cancellationTokens';

export default function AttributesCreateOrUpdateModal(props) {

    let { addToast } = useToasts();

    let [ formData, setFormData ] = useState(null);

    let form = withFormHelpers(useForm({ defaultValues: props.attribute, mode: 'onBlur' }));

    useEffect(() => {

        if (!formData) return;

        let cancellationToken = new CancellationToken();

        (!!props.attribute
            ? post(`/api/inventory/attributes/${props.attribute.id}`, formData)
            : put('/api/inventory/attributes', formData))
    
            .then(([ response, ex ]) => {

                if (cancellationToken.isCancelled) {
                    return;
                }

                if (ex) {
    
                    setFormData(null);
                    
                    if (ex instanceof ApiValidationError) {
                        form.setApiValidationErrors(ex.validationErrors);
                        return;
                    }
    
                    addToast(ex.message, { appearance: 'error' });
                    return;
        
                }

                setFormData(null);

                props.onSuccess(response);
    
                let toastMsg = !!props.attribute ? 'Attribute updated' : 'Attribute added';

                addToast(toastMsg, { appearance: 'success' });

            });
            
        return cancellationToken;

    }, [ formData ]);

    function handleSubmit(formData) {
        setFormData(formData);
    }

    return (

        <Modal show={true} centered onHide={props.onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {!props.attribute &&
                        <React.Fragment>Add Attribute</React.Fragment>
                    }
                    {!!props.attribute &&
                        <React.Fragment>Edit {props.attribute.name}</React.Fragment>
                    }
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <form onSubmit={form.handleSubmit(handleSubmit)}>

                    <fieldset disabled={!!formData}>

                        <div className="form-group">

                            <label>Name</label>

                            <input name="name" type="text" ref={form.register({ required: true })} className={cx('form-control', form.validationClassName('name'))} />

                            {form.hasErrors('name') &&
                                <div className="invalid-feedback">{form.errorMessage('name', 'Please enter a valid name.')}</div>
                            }

                        </div>

                        <div className="form-group">

                            <label>Value Regex</label>

                            <input name="valueRegex" type="text" ref={form.register} className={cx('form-control', form.validationClassName('valueRegex'))} />

                            {form.hasErrors('valueRegex') &&
                                <div className="invalid-feedback">{form.errorMessage('valueRegex', 'Please enter a valid value regex.')}</div>
                            }

                        </div>

                        <div className="form-group">

                            <label>Hint</label>

                            <input name="hint" type="text" ref={form.register} className={cx('form-control', form.validationClassName('hint'))} />

                            {form.hasErrors('hint') &&
                                <div className="invalid-feedback">{form.errorMessage('hint', 'Please enter a valid hint.')}</div>
                            }

                        </div>

                        <div className="form-group">
                            <Form.Check name="allowMultiple" id="allowMultiple" type="checkbox" ref={form.register} label="Allow multiple" custom />

                            <small class="form-text text-muted">Allow multiple values separated by semicolons (eg.. Tobacco;Minty).</small>
                        </div>

                        <div className="form-group">
                            <Form.Check name="canBeSetOnVariant" id="canBeSetOnVariant" type="checkbox" ref={form.register} label="Can set on variant" custom />

                            <small class="form-text text-muted">For attributes which differ by variant (eg.. Size and Strength)</small>
                        </div>

                    </fieldset>

                </form>

            </Modal.Body>

            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={props.onCancel}>Cancel</button>

                <button type="button" className="btn btn-primary" onClick={form.handleSubmit(handleSubmit)} disabled={!!formData}>
                    {formData &&
                        <span className="spinner-border spinner-border-sm"></span>
                    }
                    {' '}
                    <React.Fragment>
                        {!props.attribute &&
                            <React.Fragment>Add Attribute</React.Fragment>
                        }
                        {!!props.attribute &&
                            <React.Fragment>Update Attribute</React.Fragment>
                        }
                    </React.Fragment>
                </button>

            </Modal.Footer>
        </Modal>

    );

}

AttributesCreateOrUpdateModal.propTypes = {
    attribute: PropTypes.object,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};