import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import cx from 'classnames';

export default function Pagination(props) {

    let { page, totalPages, showPages = 10, pathGenerator } = props;

    let hasPreviousPage = page > 1
    let previousPage = page - 1;
    let hasNextPage = page < totalPages;
    let nextPage = page + 1;
    let isFirstPage = page == 1;
    let isLastPage = page >= totalPages;
    
    let first = 1;
    let last = totalPages || 1;

    if (totalPages > showPages) {

        first = Math.max(Math.floor(page - (showPages / 2)), 1);

        if ((first + showPages) > totalPages)
        {
            first = totalPages - showPages + 1
        }

        last = first + showPages - 1;

    }

    let skipFirstPage = first > 1;
    let skipLastPage = last < totalPages;
    let pages = [];

    for (let i = first; i <= last; i++) { 
        pages.push(i);
    }

    function handlePageClicked(page) {
        if (props.onPageClicked) {
            props.onPageClicked(page);
        }
    }

    return (
        <ul className="pagination mt-3">

            <li className={cx('page-item', { 'disabled': !hasPreviousPage })}>
                {hasPreviousPage &&
                    <React.Fragment>
                        {!!pathGenerator &&
                            <Link className="page-link" to={pathGenerator(previousPage)} onClick={handlePageClicked.bind(this, previousPage)}>&lt;&lt; Previous</Link>
                        }
                        {!pathGenerator &&
                            <a href="javascript:void(0);" className="page-link" onClick={handlePageClicked.bind(this, previousPage)}>&lt;&lt; Previous</a>
                        }
                    </React.Fragment>
                    
                }
                {!hasPreviousPage &&
                    <span className="page-link">&lt;&lt; Previous</span>
                }
            </li>

            {skipFirstPage &&
                <li className="page-item disabled"><span className="page-link">…</span></li>
            }

            {pages.map(p => 

                <li key={p} className={cx('page-item', { 'active': p == page })}>
                    <React.Fragment>
                        {!!pathGenerator &&
                            <Link to={pathGenerator(p)} className="page-link" onClick={handlePageClicked.bind(this, p)}>{p}</Link>
                        }
                        {!pathGenerator &&
                            <a href="javascript:void(0);" className="page-link" onClick={handlePageClicked.bind(this, p)}>{p}</a>
                        }
                    </React.Fragment>
                    
                </li>

            )}

            {skipLastPage &&
                <li className="page-item disabled"><span className="page-link">…</span></li>
            }

            <li className={cx('page-item', { 'disabled': !hasNextPage })}>
                {hasNextPage &&
                    <React.Fragment>
                    {!!pathGenerator &&
                        <Link className="page-link" to={pathGenerator(nextPage)} onClick={handlePageClicked.bind(this, nextPage)}>Next &gt;&gt;</Link>
                    }
                    {!pathGenerator &&
                        <a href="javascript:void(0);" className="page-link" onClick={handlePageClicked.bind(this, nextPage)}>Next &gt;&gt;</a>
                    }
                </React.Fragment>
                    
                }
                {!hasNextPage &&
                    <span className="page-link">Next &gt;&gt;</span>
                }
            </li>


        </ul>
    );

}

Pagination.propTypes = {
    totalPages: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    showPages: PropTypes.number,
    pathGenerator: PropTypes.func,
    onPageClicked: PropTypes.func,
};