export default function withFormHelpers(form) {

    function isValid(field) {

        if (!form.formState.isSubmitted && !form.formState.touched[field])
            return false;

        return !form.errors[field];

    }

    function isInvalid(field) {

        if (!form.formState.isSubmitted || !form.formState.touched[field])
            return false;

        return !!form.errors[field];

    }

    function validationClassName(field) {

        if (!form.formState.isSubmitted && !form.formState.touched[field])
            return null;

        return form.errors[field] ? 'is-invalid' : 'is-valid';

    }

    function isDirty(field) {
        return !!form.formState.dirtyFields[field];
    }

    function hasErrors(field) {
        return !!form.errors[field];
    }

    function errorMessage(field, defaultErrorMessage) {
        return form.errors[field]?.message || defaultErrorMessage;
    }

    function setApiValidationErrors(validationErrors) {

        for (let [ field, errors ] of Object.entries(validationErrors)) {
            this.setError(field, { 
                type: "apiError",
                message: errors.join(' '),
                shouldFocus: true,
            });
        }
        
    }


    let formWrapper = Object.create(form);
    formWrapper.isValid = isValid.bind(formWrapper);
    formWrapper.isInvalid = isInvalid.bind(formWrapper);
    formWrapper.validationClassName = validationClassName.bind(formWrapper);
    formWrapper.hasErrors = hasErrors.bind(formWrapper);
    formWrapper.errorMessage = errorMessage.bind(formWrapper);
    formWrapper.setApiValidationErrors = setApiValidationErrors.bind(formWrapper);
    formWrapper.isDirty = isDirty.bind(formWrapper);
    return formWrapper;
}