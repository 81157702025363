import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import cx from 'classnames';

import withFormHelpers from 'lib/formHelpers';

import AppContext from 'AppContext';

export default function BlacklistEntryRuleModal({ rule, onSuccess, onCancel }) {

    let appContext = useContext(AppContext);

    let { addToast } = useToasts();
    
    let [ section, setSection ] = useState(whichSectionToShow());

    let form = withFormHelpers(useForm({ defaultValues: rule, mode: 'onBlur' }));

    function whichSectionToShow() {

        if (!!rule) {

            if (rule.creditCardMask || rule.creditCardExpiry) {
                return 'creditCard';
            }
            else if (rule.ipAddressMask) {
                return 'ipAddress';
            }
            else if (rule.customerNumber) {
                return 'customerNumber';
            }

        }

        return 'shippingAddress';

    }

    function handleSubmit(formData) {
        onSuccess({
            ...formData,
            shippingCountryId: formData.shippingCountryId || null,
            shippingCountry: appContext.config.countries.find(c => c.id == formData.shippingCountryId),
        });
    }

    function handleCancel() {
        onCancel();
    }

    return (

        <Modal show={true} centered size="xl" onHide={handleCancel}>
            <Modal.Header closeButton>
                <Modal.Title>{rule ? 'Edit' : 'Add'} Rule</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <form onSubmit={form.handleSubmit(handleSubmit)}>

                    <div className="form-group">
                        <Form.Check name="onNew" ref={form.register} id="onNew" type="checkbox" label="On New" custom />
                    </div>

                    <div className="form-group">
                        <Form.Check name="onAddressValid" ref={form.register} id="onAddressValid" type="checkbox" label="On Address Valid" custom />
                    </div>

                    <ul className="nav nav-pills">
                        <li className="nav-item">
                            <a href="javascript:void(0);" className={cx('nav-link', { 'active': section == 'shippingAddress'})} onClick={() => setSection('shippingAddress')}>Shipping Address</a>
                        </li>
                        <li className="nav-item">
                            <a href="javascript:void(0);" className={cx('nav-link', { 'active': section == 'creditCard'})} onClick={() => setSection('creditCard')}>Credit Card</a>
                        </li>
                        <li className="nav-item">
                            <a href="javascript:void(0);" className={cx('nav-link', { 'active': section == 'ipAddress'})} onClick={() => setSection('ipAddress')}>IP Address</a>
                        </li>
                        <li className="nav-item">
                            <a href="javascript:void(0);" className={cx('nav-link', { 'active': section == 'customerNumber'})} onClick={() => setSection('customerNumber')}>Customer Number</a>
                        </li>
                    </ul>

                    <div className="m-3" style={{ minHeight: '250px' }}>

                        {section == 'shippingAddress' &&

                            <div className="row">

                                <div className="col">
                                    <div className="form-group-sm form-row">
                                        <label className="col-3 col-form-label-sm">Name</label>
                                        <div className="col-9">
                                            <input name="shippingName" type="text" className="form-control form-control-sm" ref={form.register} />
                                        </div>
                                    </div>

                                    <div className="form-group-sm form-row">
                                        <label className="col-3 col-form-label-sm">Company</label>
                                        <div className="col-9">
                                            <input name="shippingCompany" type="text" className="form-control form-control-sm" ref={form.register} />
                                        </div>
                                    </div>

                                    <div className="form-group-sm form-row">
                                        <label className="col-3 col-form-label-sm">Building</label>
                                        <div className="col-9">
                                            <input name="shippingBuilding" type="text" className="form-control form-control-sm" ref={form.register} />
                                        </div>
                                    </div>

                                    <div className="form-group-sm form-row">
                                        <label className="col-3 col-form-label-sm">Address 1</label>
                                        <div className="col-9">
                                            <input name="shippingAddress1" type="text" className="form-control form-control-sm" ref={form.register} />
                                        </div>
                                    </div>

                                    <div className="form-group-sm form-row">
                                        <label className="col-3 col-form-label-sm">Address 2</label>
                                        <div className="col-9">
                                            <input name="shippingAddress2" type="text" className="form-control form-control-sm" ref={form.register} />
                                        </div>
                                    </div>

                                    <div className="form-group-sm form-row">
                                        <label className="col-3 col-form-label-sm">City</label>
                                        <div className="col-9">
                                            <input name="shippingCity" type="text" className="form-control form-control-sm" ref={form.register} />
                                        </div>
                                    </div>

                                </div>

                                <div className="col">

                                    <div className="form-group-sm form-row">
                                        <label className="col-3 col-form-label-sm">State</label>
                                        <div className="col-9">
                                            <input name="shippingState" type="text" className="form-control form-control-sm" ref={form.register} />
                                        </div>
                                    </div>

                                    <div className="form-group-sm form-row">
                                        <label className="col-3 col-form-label-sm">Country</label>
                                        <div className="col-9">
                                            <select name="shippingCountryId" ref={form.register} className="custom-select custom-select-sm">
                                                <option value=""></option>
                                                {appContext.config.countries.map((country) => (
                                                    <option key={country.id} value={country.id}>{country.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group-sm form-row">
                                        <label className="col-3 col-form-label-sm">Post Code</label>
                                        <div className="col-9">
                                            <input name="shippingPostCode" type="text" className="form-control form-control-sm" ref={form.register} />
                                        </div>
                                    </div>

                                    <div className="form-group-sm form-row">
                                        <label className="col-3 col-form-label-sm">Phone Number</label>
                                        <div className="col-9">
                                            <input name="shippingPhoneNumber" type="text" className="form-control form-control-sm" ref={form.register} />
                                        </div>
                                    </div>

                                    <div className="form-group-sm form-row">
                                        <label className="col-3 col-form-label-sm">Email</label>
                                        <div className="col-9">
                                            <input name="shippingEmail" type="text" className="form-control form-control-sm" ref={form.register} />
                                        </div>
                                    </div>
                                </div>




                            </div>

                        }

                        {section == 'creditCard' &&

                            <React.Fragment>
                                <div className="form-group form-row">
                                    <label className="col-2 col-form-label">Credit Card Mask</label>
                                    <div className="col-10">
                                        <input name="creditCardMask" type="text" className="form-control" ref={form.register} />
                                    </div>
                                </div>

                                <div className="form-group form-row">
                                    <label className="col-2 col-form-label">Credit Card Expiry</label>
                                    <div className="col-10">
                                        <input name="creditCardExpiry" type="text" className="form-control" ref={form.register} />
                                    </div>
                                </div>
                            </React.Fragment>
                        }

                        {section == 'ipAddress' &&
                            <React.Fragment>
                                <div className="form-group form-row">
                                    <label className="col-2 col-form-label">IP Address Mask</label>
                                    <div className="col-10">
                                        <input name="ipAddressMask" type="text" className="form-control" ref={form.register} />
                                    </div>
                                </div>
                            </React.Fragment>
                        }

                        {section == 'customerNumber' &&
                            <React.Fragment>
                                <div className="form-group form-row">
                                    <label className="col-2 col-form-label">Customer Number</label>
                                    <div className="col-10">
                                        <input name="customerNumber" type="text" className="form-control" ref={form.register} />
                                    </div>
                                </div>
                            </React.Fragment>

                        }
                    </div>



                </form>

            </Modal.Body>

            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={handleCancel}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={form.handleSubmit(handleSubmit)}>{rule ? 'Edit' : 'Add'} Rule</button>
            </Modal.Footer>
        </Modal>

    );

}

BlacklistEntryRuleModal.propTypes = {
    rule: PropTypes.object,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};