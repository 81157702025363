import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToasts } from 'react-toast-notifications';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import { useApi } from 'lib/effects';

import LoadingBar from 'components/LoadingBar';
import OrganisationUsersAddModal from 'components/OrganisationUsersAddModal';
import OrganisationUsersEditModal from 'components/OrganisationUsersEditModal';
import OrganisationUsersDeleteModal from 'components/OrganisationUsersDeleteModal';
import OrganisationUsersChangePasswordModal from 'components/OrganisationUsersChangePasswordModal';

export default function OrganisationUsers(props) {

    let { addToast } = useToasts();

    let [ users, setUsers ] = useState(null);

    let [ isAddPending, setIsAddPending ] = useState(false);
    let [ pendingEdit, setPendingEdit ] = useState(null);
    let [ pendingDelete, setPendingDelete ] = useState(null);
    let [ pendingPasswordChange, setPendingPasswordChange ] = useState(null);

    useApi('/api/organisation/users', (response, ex) => {

        if (ex) {

            addToast(ex.message, { appearance: 'error' });
            return;

        }

        setUsers(response);

    }, []);


    function handleAddSuccess(user) {
        setUsers(users.concat([ user ]));
        setIsAddPending(false);
    }

    function handleEditSuccess(user) {
        setUsers(users.filter(u => u != pendingEdit).concat([ user ]));
        setPendingEdit(null);
    }

    function handleDeleteSuccess() {
        setUsers(users.filter(u => u != pendingDelete));
        setPendingDelete(null);
    }

    function handlePasswordChangeSuccess() {
        setPendingPasswordChange(null);
    }

    if (!users) {
        return (
            <LoadingBar />
        );
    }

    return (

        <div className="mt-3 container-fluid">

            <button className="btn btn-success float-right" onClick={() => setIsAddPending(true)}><FontAwesomeIcon icon={[ 'fas', 'plus' ]} /> Add User</button>

            <h3><FontAwesomeIcon icon={[ 'fas', 'user' ]} /> Users</h3>

            <p>Users with access to the system.</p>

            <table className="table">
                <thead className="thead-dark">
                    <tr>
                        <th style={{ width: '5%' }}>Active</th>
                        <th style={{ width: '15%' }}>Email Address</th>
                        <th style={{ width: '15%' }}>Name</th>
                        
                        <th>Roles</th>
                        <th style={{ width: '10%' }}></th>
                    </tr>
                </thead>
                
                <tbody>

                    {users.sort((a, b) => a.fullName > b.fullName).map((user) => (
                        <tr key={user.id}>
                            <td><FontAwesomeIcon icon={[ 'fas', user.isActive ? 'check' : 'times' ]} /></td>
                            <td>{user.email}</td>
                            <td>{user.fullName}</td>
                            <td>{user.roles.join(', ')}</td>
                            <td className="text-right">
                                <DropdownButton title="Actions">
                                    <Dropdown.Item onClick={() => setPendingEdit(user)}>Edit User</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setPendingPasswordChange(user)}>Change User Password</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setPendingDelete(user)}>Delete User</Dropdown.Item>
                                </DropdownButton>
                            </td>
                        </tr>
                    ))}

                </tbody>

            </table>

            {isAddPending &&
                <OrganisationUsersAddModal onSuccess={handleAddSuccess} onCancel={() => setIsAddPending(false)} />
            }

            {pendingEdit &&
                <OrganisationUsersEditModal user={pendingEdit} onSuccess={handleEditSuccess} onCancel={() => setPendingEdit(null)} />
            }

            {pendingDelete &&
                <OrganisationUsersDeleteModal user={pendingDelete} onSuccess={handleDeleteSuccess} onCancel={() => setPendingDelete(null)} />
            }

            {pendingPasswordChange &&
                <OrganisationUsersChangePasswordModal user={pendingPasswordChange} onSuccess={handlePasswordChangeSuccess} onCancel={() => setPendingPasswordChange(null)} />
            }

        </div>

    );

}