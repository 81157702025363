import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import Modal from 'react-bootstrap/Modal';

import { tryPut as put } from 'lib/api';
import { CancellationToken } from 'lib/cancellationTokens';

export default function JobsUnconsolidateModal({ parentJob, childJobs, onSuccess, onCancel }) {

    let { addToast } = useToasts();

    let [ isSubmitting, setIsSubmitting ] = useState(false);

    useEffect(() => {

        if (!isSubmitting) {
            return;
        }

        let cancellationToken = new CancellationToken();

        let request = childJobs.map(j => j.id);

        put(`/api/fulfillment/jobs/${parentJob.id}/unconsolidate`, request)
        
            .then(([ _, ex ]) => {

                if (cancellationToken.isCancelled) {
                    return;
                }

                setIsSubmitting(false);

                if (ex) {
                    addToast(ex.message, { appearance: 'error' });
                    return;
                }

                addToast('Job has been unconsolidated.', { appearance: 'success' });
                
                onSuccess();

            });

        return cancellationToken;

    }, [ isSubmitting ]);


    function handleSubmit() {
        setIsSubmitting(true);
    }
    
    return (

        <Modal show={true} centered onHide={onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Unconsolidate Job #{parentJob.orderNumber}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>Separate the following jobs from their parent?</p>

                <ul>
                    {childJobs.map((job) => (
                        <li key={job.id}>
                            #{job.orderNumber} — {job.shippingName}
                        </li>
                    ))}
                </ul>

            </Modal.Body>

            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={onCancel}>Cancel</button>

                <button type="button" className="btn btn-primary" onClick={handleSubmit} disabled={isSubmitting}>
                    {isSubmitting &&
                        <span className="spinner-border spinner-border-sm"></span>
                    }
                    {' '}
                    Confirm
                </button>

            </Modal.Footer>
        </Modal>

    );

}

JobsUnconsolidateModal.propTypes = {
    parentJob: PropTypes.object.isRequired,
    childJobs: PropTypes.array.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};