import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import { useForm } from 'react-hook-form';
import cx from 'classnames';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import { post, ApiValidationError } from 'lib/api';
import withFormHelpers from 'lib/formHelpers';
import { CancellationToken } from 'lib/cancellationTokens';

import AppContext from 'AppContext';

export default function CardBinsEditModal(props) {

    let appContext = useContext(AppContext);

    let { addToast } = useToasts();

    let [ formData, setFormData ] = useState(null);
    let form = withFormHelpers(useForm({ defaultValues: props.cardBin, mode: 'onBlur' }));

    useEffect(() => {

        if (!formData) {
            return;
        }

        let cancellationToken = new CancellationToken();

        async function asyncRequest() {

            let request = {
                ...props.cardBin,
                ...formData,
            };

            let response;

            try {
                response = await post(`/api/cardbins/items/${props.cardBin.id}`, request, cancellationToken);
            }
            catch (ex) {
    
                if (cancellationToken.isCancelled) {
                    return;
                }

                setFormData(null);
                
                if (ex instanceof ApiValidationError) {
                    form.setApiValidationErrors(ex.validationErrors);
                    return;
                }

                addToast(ex.message, { appearance: 'error' });
                return;
    
            }

            setFormData(null);

            props.onSuccess(response);

            addToast('Card bin has been updated.', { appearance: 'success' });
            
        }

        asyncRequest();

        return cancellationToken;

    }, [ formData ]);

    function handleSubmit(formData) {
        setFormData(formData);
    }

    function handleCancel() {
        props.onCancel();
    }

    return (

        <Modal show={true} centered size="lg" onHide={handleCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Card Bin #{props.cardBin.prefix}</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <form onSubmit={form.handleSubmit(handleSubmit)}>

                    <fieldset disabled={!!formData}>


                        <div className="form-group">
                            <label>Country</label>

                            <select name="countryId" ref={form.register({ required: true })} className={cx('custom-select custom-select-sm', { 'is-invalid': form.hasErrors('countryId') })}>
                                {appContext.config.countries.map((country) => (
                                    <option key={country.id} value={country.id}>{country.name}</option>
                                ))}
                            </select>

                            {form.hasErrors('countryId') &&
                                <div className="invalid-feedback">{form.errorMessage('countryId', 'Please select a country.')}</div>
                            }
                        </div>

                        <div className="form-group">
                            <label>Description</label>
                            <input name="description" type="text" ref={form.register({ required: true })} className={cx('form-control', { 'is-invalid': form.hasErrors('description') })} />

                            {form.hasErrors('description') &&
                                <div className="invalid-feedback">{form.errorMessage('description', 'Please enter a valid description.')}</div>
                            }
                        </div>

                        <div className="form-group">
                            <label>Notes</label>
                            <textarea name="notes" ref={form.register} className="form-control" />
                        </div>

                        <div className="form-group">
                            <Form.Check name="isOverridden" ref={form.register} id="isOverridden" type="checkbox" label="Is Overridden" custom />
                            <small className="form-text text-muted">This will prevent future automatic updates to country/description fields.</small>
                        </div>

                    </fieldset>

                </form>

            </Modal.Body>

            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={handleCancel}>Cancel</button>

                <button type="button" className="btn btn-primary" onClick={form.handleSubmit(handleSubmit)} disabled={!!formData}>
                    {formData &&
                        <span className="spinner-border spinner-border-sm"></span>
                    }
                    {' '}
                    Update Card Bin
                </button>

            </Modal.Footer>
        </Modal>

    );

}

CardBinsEditModal.propTypes = {
    cardBin: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};