import React, { useContext, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { validate as emailValidator } from 'email-validator';
import cx from 'classnames';

import { put, ApiValidationError } from 'lib/api';
import withFormHelpers from 'lib/formHelpers';
import { CancellationToken } from 'lib/cancellationTokens';

import AppContext from 'AppContext';

export default function SettingsProfile(props) {

    let { addToast } = useToasts();

    let appContext = useContext(AppContext);

    let [ formData, setFormData ] = useState(null);

    let form = withFormHelpers(useForm({
        mode: 'onBlur',
        defaultValues: { 
            firstName: appContext.me.firstName,
            lastName: appContext.me.lastName,
            email: appContext.me.email,
        },
    }));

    useEffect(() => {

        if (!formData) {
            return;
        }

        let cancellationToken = new CancellationToken();

        async function asyncRequest() {

            let response;

            try {
                response = await put('/api/me/profile', formData, cancellationToken);
            }
            catch (ex) {
    
                if (cancellationToken.isCancelled) {
                    return;
                }

                setFormData(null);
                
                if (ex instanceof ApiValidationError) {
                    form.setApiValidationErrors(ex.validationErrors);
                    return;
                }

                addToast(ex.message, { appearance: 'error' });
                return;
    
            }

            setFormData(null);

            appContext.updateMe({
                ...appContext.me,
                ...formData,
            });

            addToast('Your profile has been updated.', { appearance: 'success' });
            
        }

        asyncRequest();

        return cancellationToken;

    }, [ formData ]);

    function handleFormSubmit(formData) {
        setFormData(formData);
    }

    return (

        <div className="mt-3 col-3">

            <h3><FontAwesomeIcon icon={[ 'fas', 'address-card' ]} /> Update Profile</h3>

            <p>Please make the required changes to your profile below.</p>

            <form onSubmit={form.handleSubmit(handleFormSubmit)}>

                <fieldset disabled={!!formData}>

                    <div className="form-group">

                        <label>Email Address</label>

                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <FontAwesomeIcon icon={[ 'fas', 'envelope' ]} />
                                </span>
                            </div>

                            <input name="email" type="text" autoFocus={true} ref={form.register({ required: true, validate: emailValidator })} className={cx( 'form-control', (form.formState.isSubmitted || form.formState.touched.email) && (form.errors.email ? 'is-invalid' : 'is-valid') )} />

                            {form.errors.email &&
                                <div className="invalid-feedback">{ form.errors.email.message || 'Please enter a valid email address.' }</div>
                            }

                        </div>

                    </div>

                    <div className="form-group">

                        <label>First Name</label>

                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <FontAwesomeIcon icon={[ 'fas', 'user' ]} />
                                </span>
                            </div>

                            <input name="firstName" type="text" ref={form.register({ required: true })} className={cx( 'form-control', (form.formState.isSubmitted || form.formState.touched.firstName) && (form.errors.firstName ? 'is-invalid' : 'is-valid') )} />

                            {form.errors.firstName &&
                                <div className="invalid-feedback">{ form.errors.firstName.message || 'Please enter a valid first name.' }</div>
                            }

                        </div>

                    </div>

                    <div className="form-group">

                        <label>Last Name</label>

                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <FontAwesomeIcon icon={[ 'fas', 'user' ]} />
                                </span>
                            </div>

                            <input name="lastName" type="text" ref={form.register({ required: true })} className={cx( 'form-control', (form.formState.isSubmitted || form.formState.touched.lastName) && (form.errors.lastName ? 'is-invalid' : 'is-valid') )} />

                            {form.errors.lastName &&
                                <div className="invalid-feedback">{ form.errors.lastName.message || 'Please enter a valid last name.' }</div>
                            }

                        </div>

                    </div>

                    <div className="form-group mt-4">
                        <button type="submit" className="btn btn-primary">
                            {formData &&
                                <span className="spinner-border spinner-border-sm"></span>
                            }
                            {' '}
                            Update Profile
                        </button>
                    </div>

                </fieldset>

            </form>

        </div>

    );

}
