import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import qs from 'query-string';
import moment from 'moment';

import { tryPut as put, tryPost as post, tryGet as get, ApiNotFoundError } from 'lib/api';
import { CancellationToken } from 'lib/cancellationTokens';
import { playSuccess, playComplete, playError } from 'lib/sounds';

import JobStatus from 'components/JobStatus';

export default function JobPrintShippingLabelsModal({ onClose }) {

    let jobNumberInputRef = useRef();
    let serviceCodeInputRef = useRef();
    let ageNumberInputRef = useRef();
    let saturdayDeliveryNumberInputRef = useRef();

    let { addToast } = useToasts();

    let [ job, setJob ] = useState(null);
    let [ pendingLoad, setPendingLoad ] = useState(false);
    let [ pendingPrint, setPendingPrint ] = useState(false);
    let [ hasPrinted, setHasPrinted ] = useState(false);
    let [ isPrintingPrescription, setIsPrintingPrescription ] = useState(false);

    useEffect(() => {
        jobNumberInputRef.current.focus();
    }, []);

    useEffect(() => {

        if (!pendingLoad) {
            return;
        }

        let cancellationToken = new CancellationToken();

        get(`/api/fulfillment/jobs/${pendingLoad}`)
        
            .then(([ response, ex ]) => {

                if (cancellationToken.isCancelled) {
                    return;
                }

                setPendingLoad(null);

                if (ex) {

                    if (ex instanceof ApiNotFoundError) {
                        addToast('Job not found', { appearance: 'error' });
                    }
                    else {
                        addToast(ex.message, { appearance: 'error' });
                    }

                    playError();

                    return;
                }
                
                job = response;

                setJob(job);

                if (!job.isReadyForLabelPrinting) {
                    playError();
                    return;
                }

                if (!!job.adminNotes) {
                    playError();
                    return;
                }

                if (!job.shippingMethod.isServiceCodeRequired
                    && !job.shippingMethod.isAgeTicketRequired
                    && (!job.isSaturdayDelivery || !job.shippingMethod.isSaturdayDeliveryTicketRequired)) {

                    setPendingPrint({});
                    return;
                }

                playSuccess();

                if (job.shippingMethod.isServiceCodeRequired) {
                    serviceCodeInputRef.current.focus();
                }
                else if (job.shippingMethod.isAgeTicketRequired) {
                    ageNumberInputRef.current.focus();
                }
                else if (job.isSaturdayDelivery && job.shippingMethod.isSaturdayDeliveryTicketRequired) {
                    saturdayDeliveryNumberInputRef.current.focus();
                }
                
            });

        return cancellationToken;

    }, [ pendingLoad ]);

    useEffect(() => {

        if (!pendingPrint) {
            return;
        }

        let cancellationToken = new CancellationToken();

        if (!job.isLabelPrinted) {


            put(`/api/fulfillment/jobs/${job.id}/printshippinglabel`, pendingPrint)
            
                .then(([ response, ex ]) => {

                    if (cancellationToken.isCancelled) {
                        return;
                    }

                    setPendingPrint(null);

                    if (ex) {
                        addToast(ex.message, { appearance: 'error' });

                        playError();

                        return;
                    }
                    
                    job = response.job;

                    setHasPrinted(true);
                    setJob(job);

                    let printerAppUrl = qs.stringifyUrl({ 
                        url: 'fulfillv2://print', 
                        query: { type: job.shippingMethod.printingAppLabelName, url: response.fileUrl },
                    })
        
                    let printFrame = document.createElement('iframe');
                    printFrame.src = printerAppUrl;
                    printFrame.style = 'display: none;'
                    document.body.appendChild(printFrame);

                    playComplete();

                    if (job.hasPrescriptionFile) {
                        setTimeout(() => {
                            setIsPrintingPrescription(true);
                        }, 5000);
                    }

                    jobNumberInputRef.current.focus();

                });

        }
        else {

            put(`/api/fulfillment/jobs/${job.id}/reprintshippinglabel`)
            
                .then(([ response, ex ]) => {

                    if (cancellationToken.isCancelled) {
                        return;
                    }

                    setPendingPrint(null);

                    if (ex) {
                        addToast(ex.message, { appearance: 'error' });

                        playError();

                        return;
                    }
                    
                    setHasPrinted(true);

                    let printerAppUrl = qs.stringifyUrl({ 
                        url: 'fulfillv2://print', 
                        query: { type: job.shippingMethod.printingAppLabelName, url: response.fileUrl },
                    })
        
                    let printFrame = document.createElement('iframe');
                    printFrame.src = printerAppUrl;
                    printFrame.style = 'display: none;'
                    document.body.appendChild(printFrame);

                    playComplete();

                    jobNumberInputRef.current.focus();

                });

        }

        return cancellationToken;

    }, [ pendingPrint ]);

    useEffect(() => {

        if (!isPrintingPrescription) {
            return;
        }

        let printerAppUrl = qs.stringifyUrl({ 
            url: 'fulfillv2://print', 
            query: { type: 'Prescription', url: job.prescriptionFile.url },
        })

        let printFrame = document.createElement('iframe');
        printFrame.src = printerAppUrl;
        printFrame.style = 'display: none;'
        document.body.appendChild(printFrame);

        setIsPrintingPrescription(false);

    }, [ isPrintingPrescription ]);

    function handleJobNumberKeyDown(event) {
        if (event.code != 'Enter') {
            return;
        }

        //reset
        setJob(null);
        setHasPrinted(false);

        let jobId = event.target.value;

        jobNumberInputRef.current.value = '';

        setPendingLoad(jobId);
    }

    function handleServiceCodeKeyDown(event) {
        if (event.code != 'Enter') {
            return;
        }

        event.target.value = {
            //cp bags
            '9415599004758': 'CPOLTPDL',
            '9415599009005': 'CPOLTPA5',
            '9415599009012': 'CPOLTPA4',
            '9415599009029': 'CPOLTPFS',
            '9415599009036': 'CPOLTPLF',
            //jiffy
            '9318348861254': 'CPOLTPDL',
            '9318348861285': 'CPOLTPA5',
            '9318348861223': 'CPOLTPA4',
            '9318348861346': 'CPOLTPFS',
            //testing
            'dle': 'CPOLTPDL',
            'a5': 'CPOLTPA5',
            'a4': 'CPOLTPA4',
            'fs': 'CPOLTPFS',
            'lf': 'CPOLTPLF',
            'xl': 'CPOLTPXL'
        }[event.target.value] || event.target.value;

        if (!new RegExp(job.shippingMethod.serviceCodeRegex).test(event.target.value)) {
            playError();
            addToast('Invalid service code.', { appearance: 'error' });
            return;
        }

        if (job.shippingMethod.isAgeTicketRequired || (job.isSaturdayDelivery && job.shippingMethod.isSaturdayDeliveryTicketRequired)) {

            playSuccess();

            if (job.shippingMethod.isAgeTicketRequired) {
                ageNumberInputRef.current.focus();
            }
            else {
                saturdayDeliveryNumberInputRef.current.focus();
            }
            
            return;

        }

        proceedWithPrinting();
    }

    function handleAgeTicketKeyDown(event) {
        if (event.code != 'Enter') {
            return;
        }

        event.target.value = {
            'test': 'YGXXXXXXXXXXX',
        }[event.target.value] || event.target.value;

        if (!new RegExp(job.shippingMethod.ageNumberRegex).test(event.target.value)) {
            playError();
            addToast('Invalid age number.', { appearance: 'error' });
            return;
        }

        if (job.isSaturdayDelivery && job.shippingMethod.isSaturdayDeliveryTicketRequired) {
            playSuccess();
            saturdayDeliveryNumberInputRef.current.focus();
            return;
        }

        proceedWithPrinting();
    }

    function handleSaturdayDeliveryTicketKeyDown(event) {
        if (event.code != 'Enter') {
            return;
        }

        event.target.value = {
            'test': 'SRXXXXXXXXXXX',
        }[event.target.value] || event.target.value;

        if (!new RegExp(job.shippingMethod.saturdayDeliveryNumberRegex).test(event.target.value)) {
            playError();
            addToast('Invalid saturday number.', { appearance: 'error' });
            return;
        }

        proceedWithPrinting();

    }

    function proceedWithPrinting() {

        let serviceCode = serviceCodeInputRef.current.value;

		let ageNumber;
        let saturdayDeliveryNumber;

		if (job.shippingMethod.isAgeTicketRequired) {
			ageNumber = ageNumberInputRef.current.value;
		}
		
        if (job.isSaturdayDelivery && job.shippingMethod.isSaturdayDeliveryTicketRequired) {
            saturdayDeliveryNumber = saturdayDeliveryNumberInputRef.current.value;
        }

        setPendingPrint({
            serviceCode,
            ageNumber,
            saturdayDeliveryNumber,
        });

    }

    function handleAdminNotesDismiss() {

        setJob({
            ...job,
            adminNotesDismissed: true,
        });

        if (!job.shippingMethod.isServiceCodeRequired
            && !job.shippingMethod.isAgeTicketRequired
            && (!job.isSaturdayDelivery || !job.shippingMethod.isSaturdayDeliveryTicketRequired)) {

            setPendingPrint({});
            return;
        }

        //because react batches state updates inside event handlers, the inputs aren't rendered immediately
        setTimeout(() => {

            if (job.shippingMethod.isServiceCodeRequired) {
                serviceCodeInputRef.current.focus();
            }
            else if (job.shippingMethod.isAgeTicketRequired) {
                ageNumberInputRef.current.focus();
            }
            else if (job.isSaturdayDelivery && job.shippingMethod.isSaturdayDeliveryTicketRequired) {
                saturdayDeliveryNumberInputRef.current.focus();
            }

        }, 0);

    }

    return (

        <Modal show={true} size="lg" centered onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Print Shipping Labels</Modal.Title>
            </Modal.Header>

            <Modal.Body className="p-0">

                <div className="p-3 text-light" style={{ backgroundColor: 'var(--tvk-purple-dark-5)' }}>

                    <div className="form-group">
                        <label>Scan Packing Slip</label>
                        <input ref={jobNumberInputRef} disabled={!!pendingLoad || !!pendingPrint} placeholder="Packing slip barcode goes here." type="text" className="form-control" intialValue={''} onKeyDown={handleJobNumberKeyDown} />
                    </div>

                </div>

                <div className="p-3 d-flex align-items-center" style={{ minHeight: '300px' }}>

                    {!job &&

                        <React.Fragment>

                            {!pendingLoad &&
                                <div className="col text-center">
                                    <FontAwesomeIcon style={{ fontSize: '5rem' }} icon={['fas', 'qrcode']} fixedWidth />
                                    <div className="mt-3">Scan a packing slip to continue</div>
                                </div>
                            }

                            {!!pendingLoad &&
                                <div className="col text-center">
                                    <span className="spinner-border" style={{ width: '5rem', height: '5rem', color: 'var(--tvk-purple-light-30)' }}></span>
                                </div>
                            }

                        </React.Fragment>

                    }

                    {!!job &&

                        <React.Fragment>

                            <div className="col">

                                <h5>Job #{job.orderNumber}</h5>

                                <JobStatus job={job} />

                                <dl className="row mt-3">

                                    <dt className="col-4">Shipping</dt>
                                    <dd className="col-8">{job.shippingMethod.name}</dd>

                                    <dt className="col-4">Address</dt>
                                    <dd className="col-8">
                                        <div style={{ whiteSpace: 'pre-line' }}>
                                            {job.shippingAddress.addressFormatted}
                                        </div>
                                    </dd>

                                    <dt className="col-4">Total</dt>
                                    <dd className="col-8">{job.combinedShippableItemCount.toLocaleString()} items — ${job.combinedTotal.toLocaleString()}</dd>

                                    {!!job.trackingNumber &&
                                        <React.Fragment>
                                            <dt className="col-4">Tracking #</dt>
                                            <dd className="col-8">{job.trackingNumber}</dd>
                                        </React.Fragment>
                                    }

                                    {!!job.serviceCode &&
                                        <React.Fragment>
                                            <dt className="col-4">Service</dt>
                                            <dd className="col-8">{job.saturdayDeliveryNumber}</dd>
                                        </React.Fragment>
                                    }

                                    {!!job.saturdayDeliveryNumber &&
                                        <React.Fragment>
                                            <dt className="col-4">Sat #</dt>
                                            <dd className="col-8">{job.saturdayDeliveryNumber}</dd>
                                        </React.Fragment>
                                    }

                                    {!!job.ageNumber &&
                                        <React.Fragment>
                                            <dt className="col-4">Age #</dt>
                                            <dd className="col-8">{job.ageNumber}</dd>
                                        </React.Fragment>
                                    }

                                </dl>

                            </div>

                            {!!pendingPrint &&
                                <div className="col text-center">
                                    <span className="spinner-border" style={{ width: '5rem', height: '5rem', color: 'var(--tvk-purple-light-30)' }}></span>
                                </div>
                            }

                            {hasPrinted && !pendingPrint &&
                                <div className="col text-center">
                                    <FontAwesomeIcon className="text-success" style={{ fontSize: '5rem' }} icon={['fas', 'check-circle']} fixedWidth />
                                    
                                    <h5 className="mt-3">Success!</h5>
                                    
                                    <p>A label is now being printed.</p>

                                    <button type="button" className="btn btn-primary" onClick={() => setPendingPrint({})}>Reprint</button>

                                    {job.hasPrescriptionFile &&
                                    
                                        <div className="mt-3 alert alert-warning small p-2">

                                            {isPrintingPrescription &&
                                                <React.Fragment>
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                    {' '}
                                                    A prescription is being printed.
                                                </React.Fragment>
                                            }

                                            {!isPrintingPrescription &&
                                                <React.Fragment>
                                                    Remember to include customer prescription. <a disabled={isPrintingPrescription} className="alert-link" href="javascript: void(0);" onClick={() => setIsPrintingPrescription(true)}>Reprint</a>
                                                </React.Fragment>
                                            }

                                        </div>
                                    }


                                </div>
                            }

                            {!pendingPrint && !hasPrinted &&
                            
                                <React.Fragment>

                                    {!job.isReadyForLabelPrinting &&

                                        <React.Fragment>

                                            {job.isOnHold &&
                                                <div className="col text-center">
                                                    <FontAwesomeIcon className="text-warning" style={{ fontSize: '5rem' }} icon={['fas', 'hand-paper']} fixedWidth />

                                                    <h5 className="mt-3">On Hold</h5>

                                                    <p>{job.holdReason || 'This job is on hold.'}</p>
                                                </div>
                                            }

                                            {job.isLabelPrinted &&
                                                <div className="col text-center">
                                                    <FontAwesomeIcon className="text-danger" style={{ fontSize: '5rem' }} icon={['fas', 'exclamation-circle']} fixedWidth />

                                                    <h5 className="mt-3">Already Printed</h5>

                                                    <p>A label was printed {moment(job.labelPrinted).fromNow()}.</p>

                                                    <button type="button" className="btn btn-primary" onClick={() => setPendingPrint({})}>Reprint</button>

                                                    {job.hasPrescriptionFile &&

                                                        <div className="mt-3 alert alert-warning small p-2">

                                                            {isPrintingPrescription &&
                                                                <React.Fragment>
                                                                    <span className="spinner-border spinner-border-sm"></span>
                                                                    {' '}
                                                                    A prescription is being printed.
                                                                </React.Fragment>
                                                            }

                                                            {!isPrintingPrescription &&
                                                                <React.Fragment>
                                                                    Remember to include customer prescription. <a disabled={isPrintingPrescription} className="alert-link" href="javascript: void(0);" onClick={() => setIsPrintingPrescription(true)}>Reprint</a>
                                                                </React.Fragment>
                                                            }

                                                        </div>
                                                    }

                                                </div>
                                            }

                                            {!job.isOnHold && !job.isLabelPrinted &&
                                                <div className="col text-center">
                                                    <FontAwesomeIcon className="text-danger" style={{ fontSize: '5rem' }} icon={['fas', 'exclamation-circle']} fixedWidth />

                                                    <h5 className="mt-3">Invalid State</h5>

                                                    <p>This job is not in a valid state for label printing.</p>
                                                </div>
                                            }

                                        </React.Fragment>

                                    }

                                    {job.isReadyForLabelPrinting &&
                                    
                                        <React.Fragment>

                                            {job.adminNotes && !job.adminNotesDismissed &&

                                                <div className="col text-center">
                                                    <FontAwesomeIcon className="text-warning" style={{ fontSize: '5rem' }} icon={['fas', 'user-tie']} fixedWidth />

                                                    <h5 className="mt-3">Admin Notes</h5>

                                                    <p>{job.adminNotes}</p>

                                                    <button type="button" className="btn btn-primary" onClick={handleAdminNotesDismiss}>Dismiss</button>
                                                </div>
                                            }

                                            {(!job.adminNotes || job.adminNotesDismissed) &&

                                                <div className="col">

                                                    {job.shippingMethod.isServiceCodeRequired &&
                                                        <div className="form-group">
                                                            <label>Service Code</label>
                                                            <input ref={serviceCodeInputRef} type="text" className="form-control" intialValue={''} onKeyDown={handleServiceCodeKeyDown} />
                                                        </div>
                                                    }

                                                    {job.shippingMethod.isAgeTicketRequired &&
                                                        <div className="form-group">
                                                            <label>Age #</label>
                                                            <input ref={ageNumberInputRef} type="text" className="form-control" intialValue={''} onKeyDown={handleAgeTicketKeyDown} />
                                                        </div>
                                                    }

                                                    {job.isSaturdayDelivery && job.shippingMethod.isSaturdayDeliveryTicketRequired &&
                                                        <div className="form-group">
                                                            <label>Saturday #</label>
                                                            <input ref={saturdayDeliveryNumberInputRef} type="text" className="form-control" intialValue={''} onKeyDown={handleSaturdayDeliveryTicketKeyDown} />
                                                        </div>
                                                    }

                                                </div>

                                            }

                                        </React.Fragment>

                                    }

                                </React.Fragment>
                            
                            }

                        </React.Fragment>

                    }

                </div>

            </Modal.Body>

        </Modal>

    );

}

JobPrintShippingLabelsModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};