import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import Modal from 'react-bootstrap/Modal';

import { tryDel as del } from 'lib/api';
import { CancellationToken } from 'lib/cancellationTokens';

export default function AttributesDeleteModal(props) {

    let { addToast } = useToasts();

    let [ isSubmitting, setIsSubmitting ] = useState(false);

    useEffect(() => {

        if (!isSubmitting) return;

        let cancellationToken = new CancellationToken();

        del(`/api/inventory/attributes/${props.attribute.id}`)
    
            .then(([ _, ex ]) => {

                if (cancellationToken.isCancelled) {
                    return;
                }

                if (ex) {
                    addToast(ex.message, { appearance: 'error' });
                    setIsSubmitting(false);
                    return;
                }

                setIsSubmitting(false);
            
                addToast('Attribute has been deleted.', { appearance: 'success' });
    
                props.onSuccess();

            });
            
        return cancellationToken;

    }, [ isSubmitting ]);

    return (

        <Modal show={true} centered onHide={props.onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Delete {props.attribute.name}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>Are you sure you want to delete {props.attribute.name}?</p>
            </Modal.Body>

            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={props.onCancel}>Cancel</button>

                <button type="button" className="btn btn-danger" onClick={() => setIsSubmitting(true)} disabled={isSubmitting}>
                    {isSubmitting &&
                        <span className="spinner-border spinner-border-sm"></span>
                    }
                    {' '}
                    Delete Attribute
                </button>

            </Modal.Footer>
        </Modal>
    );

}

AttributesDeleteModal.propTypes = {
    attribute: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};