import successSoundMp3 from 'sounds/success.mp3';
import completeSoundMp3 from 'sounds/complete.mp3';
import errorSoundMp3 from 'sounds/error.mp3';

var successSound = new Audio(successSoundMp3);
var completeSound = new Audio(completeSoundMp3);
var errorSound = new Audio(errorSoundMp3);

export function playSuccess() {
    successSound.play();
}

export function playComplete() {
    completeSound.play();
}

export function playError() {
    errorSound.play();
}
