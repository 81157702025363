import React, { useContext, useRef } from 'react'
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import qs from 'query-string';

import AppContext from 'AppContext';

function Header(props) {

    let appContext = useContext(AppContext);

    let jobSearchInputRef = useRef();
    
    function handleJobSearchFormSubmit(e) {
        
        let str = jobSearchInputRef.current.value;

        let emailMatch = str.match(/^\s*(\S+@\S+\.\S+)\s*$/);

        if (emailMatch) {
            str = "email:" + emailMatch[1].trim();
        }
        else {

            let orderNumberMatch = str.match(/^\s*(\w+)\s*$/);

            if (orderNumberMatch) {
                str = "ord:" + orderNumberMatch[1].trim();
            }

        }


        
        var newUrl = qs.stringifyUrl({ url: '/fulfillment', query: { searchString: str, filters: [ 'Include Deleted' ] } }, { arrayFormat: 'bracket-separator' });

        props.history.push(newUrl);

        e.preventDefault();
    }

    return (

        <Navbar className="navbar navbar-expand-lg navbar-dark">

            <Navbar.Brand as={Link} to="/">
                <img src="/images/logo.png" />
            </Navbar.Brand>

            {appContext.me &&
            
                <React.Fragment>

                <Nav className="mr-auto">

                    {appContext.me.permissions.includes('Administration') &&
                    
                        <NavDropdown title="Admin">
                            <NavDropdown.Item as={Link} to="/admin/users"><FontAwesomeIcon icon={[ 'fas', 'user' ]} fixedWidth /> Users</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/roles"><FontAwesomeIcon icon={[ 'fas', 'users' ]} fixedWidth /> Roles</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/blacklist"><FontAwesomeIcon icon={[ 'fas', 'skull-crossbones' ]} fixedWidth /> Blacklist</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/cardbins"><FontAwesomeIcon icon={[ 'fab', 'cc-visa' ]} fixedWidth /> Card Bins</NavDropdown.Item>
                        
                            {/* {appContext.me.permissions.includes('Power User') &&
                                <NavDropdown.Item as={Link} to="/admin/maintenance"><FontAwesomeIcon icon={[ 'fas', 'wrench' ]} fixedWidth /> Maintenance</NavDropdown.Item>
                            } */}
                        
                        </NavDropdown>

                    }

                    {appContext.me.permissions.includes('Logging') &&
                    
                        <NavDropdown title="Logs">
                            <NavDropdown.Item as={Link} to="/admin/logs"><FontAwesomeIcon icon={[ 'fas', 'search' ]} fixedWidth /> All Logs</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item as={Link} to={qs.stringifyUrl({ url: '/admin/logs', query: { users: [ appContext.me.id ] } }, { arrayFormat: 'bracket-separator' })}><FontAwesomeIcon icon={[ 'fas', 'user-tag' ]} fixedWidth /> My Actions</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={qs.stringifyUrl({ url: '/admin/logs', query: { filters: ['Errors & Warnings'] } }, { arrayFormat: 'bracket-separator' })}><FontAwesomeIcon icon={[ 'fas', 'exclamation-triangle' ]} fixedWidth /> Errors &amp; Warnings</NavDropdown.Item>
                        </NavDropdown>

                    }

                    {appContext.me.permissions.includes('Book Keeping') &&
                        
                        <NavDropdown title="Books">
                            <NavDropdown.Item as={Link} to="/helpers/nzpostgst">NZ Post GST Helper</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/helpers/xerocsv">Xero Bank Statement CSV Generator</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/helpers/vendproducts">Vend Product File Updater</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/helpers/vrareport">VRA Annual Sales Report Generator</NavDropdown.Item>
                            {/* <NavDropdown.Item as={Link} to="/helpers/vendreconcile">Vend Reconciler</NavDropdown.Item> */}
                        </NavDropdown>

                    }

                    {appContext.me.permissions.includes('Inventory Management') &&
                    
                        <NavDropdown title="Products">
                            <NavDropdown.Item as={Link} to='/inventory/products'><FontAwesomeIcon icon={[ 'fas', 'box-open' ]} fixedWidth /> Products</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={qs.stringifyUrl({ url: '/inventory/products', query: { filters: [ 'Inventory', 'Unit' ] } }, { arrayFormat: 'bracket-separator' })}><FontAwesomeIcon icon={[ 'fas', 'boxes' ]} fixedWidth /> Inventory Items</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item as={Link} to={qs.stringifyUrl({ url: '/inventory/products', query: { view: 'Forecasting', filters: [ 'Inventory', 'Unit' ] } }, { arrayFormat: 'bracket-separator' })}><FontAwesomeIcon icon={[ 'fas', 'boxes' ]} fixedWidth /> Forecasting</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={qs.stringifyUrl({ url: '/inventory/products', query: { view: 'Forecasting', filters: [ 'Inventory', 'Unit' ], sorting: 'Material Average' }}, { arrayFormat: 'bracket-separator' })}><FontAwesomeIcon icon={[ 'fas', 'award' ]} fixedWidth /> Fast Movers</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={qs.stringifyUrl({ url: '/inventory/products', query: { view: 'Forecasting', filters: [ 'Inventory', 'Unit' ], sorting: 'Urgency' }}, { arrayFormat: 'bracket-separator' })}><FontAwesomeIcon icon={[ 'fas', 'bullhorn' ]} fixedWidth /> Low Stock</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={qs.stringifyUrl({ url: '/inventory/products', query: { view: 'Forecasting', filters: [ 'Inventory', 'Unit' ], sorting: 'Over Stocked' }}, { arrayFormat: 'bracket-separator' })}><FontAwesomeIcon icon={[ 'far', 'grimace' ]} fixedWidth /> Over Stock</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item as={Link} to="/inventory/journals"><FontAwesomeIcon icon={[ 'fas', 'retweet' ]} fixedWidth /> Journals</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/inventory/attributes"><FontAwesomeIcon icon={[ 'fas', 'asterisk' ]} fixedWidth /> Attributes</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/inventory/categories"><FontAwesomeIcon icon={[ 'fas', 'sitemap' ]} fixedWidth /> Categories</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/inventory/brands"><FontAwesomeIcon icon={[ 'fas', 'stamp' ]} fixedWidth /> Brands</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/inventory/suppliers"><FontAwesomeIcon icon={[ 'fas', 'building' ]} fixedWidth /> Suppliers</NavDropdown.Item>
                        </NavDropdown>

                    }

                    {appContext.me.permissions.includes('Ordering') &&
                    
                        <NavDropdown title="Ordering">
                            <NavDropdown.Item as={Link} to="/ordering/new"><FontAwesomeIcon icon={[ 'fas', 'shopping-cart' ]} fixedWidth /> New Order</NavDropdown.Item>
                        </NavDropdown>

                    }

                    {appContext.me.permissions.includes('Fulfillment') &&
                    
                        <NavDropdown title="Fulfillment">
                            <NavDropdown.Item as={Link} to="/fulfillment"><FontAwesomeIcon icon={[ 'fas', 'hard-hat' ]} fixedWidth /> All Jobs</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item as={Link} to={qs.stringifyUrl({ url: '/fulfillment', query: { filters: ['Awaiting Dispatch', 'Not Snoozed'] } }, { arrayFormat: 'bracket-separator' })}><FontAwesomeIcon icon={[ 'fas', 'clock' ]} fixedWidth /> Awaiting Dispatch</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={qs.stringifyUrl({ url: '/fulfillment', query: { filters: ['Dispatched', 'Untracked'] } }, { arrayFormat: 'bracket-separator' })}><FontAwesomeIcon icon={[ 'fas', 'truck' ]} fixedWidth /> Tracking Required</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={qs.stringifyUrl({ url: '/fulfillment', query: { filters: ['Invalid Address', 'Not Snoozed'] } }, { arrayFormat: 'bracket-separator' })}><FontAwesomeIcon icon={[ 'fas', 'address-card' ]} fixedWidth /> Invalid Addresses</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={qs.stringifyUrl({ url: '/fulfillment', query: { filters: ['Issues', 'Not Snoozed'] } }, { arrayFormat: 'bracket-separator' })}><FontAwesomeIcon icon={[ 'fas', 'exclamation' ]} fixedWidth /> Unresolved Issues</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={qs.stringifyUrl({ url: '/fulfillment', query: { filters: ['Pending Payments', 'Not Snoozed'] } }, { arrayFormat: 'bracket-separator' })}><FontAwesomeIcon icon={[ 'fas', 'dollar-sign' ]} fixedWidth /> Pending Payment</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={qs.stringifyUrl({ url: '/fulfillment', query: { filters: ['Unpaid Shipping', 'Not Snoozed'] } }, { arrayFormat: 'bracket-separator' })}><FontAwesomeIcon icon={[ 'far', 'frown' ]} fixedWidth /> Unpaid Shipping</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={qs.stringifyUrl({ url: '/fulfillment', query: { filters: ['On Hold', 'Not Snoozed'] } }, { arrayFormat: 'bracket-separator' })}><FontAwesomeIcon icon={[ 'fas', 'stop-circle' ]} fixedWidth /> On Hold</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={qs.stringifyUrl({ url: '/fulfillment', query: { filters: ['Snoozed'] } }, { arrayFormat: 'bracket-separator' })}><FontAwesomeIcon icon={[ 'fas', 'moon' ]} fixedWidth /> Snoozed</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={qs.stringifyUrl({ url: '/fulfillment', query: { shippingMethods: ['5'] } }, { arrayFormat: 'bracket-separator' })}><FontAwesomeIcon icon={[ 'fas', 'store' ]} fixedWidth /> Click & Collect</NavDropdown.Item>
                        </NavDropdown>

                    }

                    {appContext.me.permissions.includes('Fulfillment') &&
                        
                        <NavDropdown title="VapeHQ">
                            <NavDropdown.Item as={Link} to="/vapehq/dummyshipments">Dummy Shipments</NavDropdown.Item>
                        </NavDropdown>

                    }

                </Nav>

                <Nav className="mr-2">
                    <form className="form-inline" onSubmit={handleJobSearchFormSubmit}>
                        <input ref={jobSearchInputRef} className="form-control form-control-sm mr-2" style={{ width: '200px' }} type="search" placeholder="Order number or email" />
                        <button className="btn btn-sm btn-tvk-purple-light-30" type="submit"><FontAwesomeIcon icon={[ 'fas', 'search' ]} fixedWidth /></button>
                    </form>
                </Nav>

                <Nav>

                    <NavDropdown alignRight title={
                        <React.Fragment>
                            <FontAwesomeIcon icon={[ 'fas', 'user-circle' ]} fixedWidth /> {appContext.me.firstName}
                        </React.Fragment>
                    }>
                        <NavDropdown.Item as={Link} to="/me/profile"><FontAwesomeIcon icon={[ 'fas', 'address-card' ]} fixedWidth /> Update Profile</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/me/password"><FontAwesomeIcon icon={[ 'fas', 'key' ]} fixedWidth /> Change Password</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item onClick={() => appContext.logout()}><FontAwesomeIcon icon={[ 'fas', 'sign-out-alt' ]} fixedWidth /> Logout</NavDropdown.Item>

                    </NavDropdown>

                </Nav>

                </React.Fragment>

            }

        </Navbar>

    );

}

Header.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

export default withRouter(Header);