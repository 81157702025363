import React, { useContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToasts } from 'react-toast-notifications';
import { useForm, Controller } from 'react-hook-form';
import { Typeahead } from 'react-bootstrap-typeahead';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import qs from 'query-string';
import scrollIntoView from 'scroll-into-view-if-needed'
import moment from 'moment';
import cx from 'classnames';

import { useApi } from 'lib/effects';
import { post } from 'lib/api';
import withFormHelpers from 'lib/formHelpers';
import { CancellationToken } from 'lib/cancellationTokens';
import { probabilityScoreStyles, retailGpStyles, preparednessScoreStyles } from 'lib/utils';

import AppContext from 'AppContext';
import LoadingBar from 'components/LoadingBar';
import Pagination from 'components/Pagination';
import InventoryProductsSetListedModal from 'components/InventoryProductsSetListedModal';
import InventoryProductsPrintShelfLabelsModal from 'components/InventoryProductsPrintShelfLabelsModal';
import InventoryProductsSetStockLocatorModal from 'components/InventoryProductsSetStockLocatorModal';
import InventoryProductsSetDiscontinuedModal from 'components/InventoryProductsSetDiscontinuedModal';
import InventoryProductsSetDeprecatedModal from 'components/InventoryProductsSetDeprecatedModal';
import InventoryProductsDetailsModal from 'components/InventoryProductsDetailsModal';
import AnalyseProjectButton from 'components/AnalyseProjectButton';
import InventoryProductsImportModal from 'components/InventoryProductsImportModal';
import InventoryProductsMetaImportModal from 'components/InventoryProductsMetaImportModal';
import InventoryProductSuppliersImportModal from 'components/InventoryProductSuppliersImportModal';
import InventoryProductsSetPriceModal from 'components/InventoryProductsSetPriceModal';
import ProductsGroupModal from 'components/ProductsGroupModal';
import ProductsUngroupModal from 'components/ProductsUngroupModal';

export function InventoryProducts(props) {

    let appContext = useContext(AppContext);

    let anchorRef = useRef();

    let { addToast } = useToasts();

    let params = qs.parse(props.location.search, { arrayFormat: 'bracket-separator' });

    let defaultCriteria = { 
        view: 'Default',
        categories: [], 
        brands: [], 
        suppliers: [],
        filters: [], 
        sorting: 'Smart', 
        analyse: 'Last 90 Days',
        project: 'Next 30 Days',
        searchString: null, 
        page: 1,
    };

    let currentCriteria = {
        ...defaultCriteria,
        ...params,
        page: params.page ? parseInt(params.page) : defaultCriteria.page,
    };

    let form = withFormHelpers(useForm({ shouldUnregister: false }));

    let [ filterOptions, setFilterOptions ] = useState(null);
    let [ sortingOptions, setSortingOptions ] = useState(null);
    let [ categoryOptions, setCategoryOptions ] = useState(null);
    let [ brandOptions, setBrandOptions ] = useState(null);
    let [ supplierOptions, setSupplierOptions ] = useState(null);
    let [ notListedCount, setNotListedCount ] = useState(0);
    let [ notBarcodedCount, setNotBarcodedCount ] = useState(0);
    let [ noStockLocatorCount, setNoStockLocatorCount ] = useState(0);
    let [ analyseOptions, setAnalyseOptions ] = useState(null);
    let [ projectOptions, setProjectOptions ] = useState(null);
    let [ lastDearSync, setLastDearSync ] = useState(null);

    let [ isExporting, setIsExporting ] = useState(false);
    let [ isExportingProductSuppliers, setIsExportingProductSuppliers ] = useState(false);
    let [ isImporting, setIsImporting ] = useState(false);
    let [ isImportingProductMeta, setIsImportingProductMeta ] = useState(false);
    let [ isImportingProductSuppliers, setIsImportingProductSuppliers ] = useState(false);

    let [ isSearching, setIsSearching ] = useState(false);
    let [ products, setProducts ] = useState({
        items: [],
        page: 1,
        totalPages: 0,
        totalCount: 0,
    });


    let [ pendingSetListedOperation, setPendingSetListedOperation ] = useState(null);
    let [ pendingPrintShelfLabelsOperation, setPendingPrintShelfLabelsOperation ] = useState(null);
    let [ pendingSetStockLocatorOperation, setPendingSetStockLocatorOperation ] = useState(null);
    let [ pendingSetDiscontinuedOperation, setPendingSetDiscontinuedOperation ] = useState(null);
    let [ pendingSetDeprecatedOperation, setPendingSetDeprecatedOperation ] = useState(null);
    let [ pendingSetPriceOperation, setPendingSetPriceOperation ] = useState(null);
    let [ pendingGroupOperation, setPendingGroupOperation ] = useState(null);
    let [ pendingUngroupOperation, setPendingUngroupOperation ] = useState(null);

    let [ viewingProductDetails, setViewingProductDetails ] = useState(null);

    useApi('/api/inventory', (response, ex) => {

        if (ex) {

            addToast(ex.message, { appearance: 'error' });
            return;

        }

        setFilterOptions(response.filterOptions);
        setSortingOptions(response.sortingOptions);
        setCategoryOptions(response.categories);
        setBrandOptions(response.brands);
        setSupplierOptions(response.suppliers);
        setNotListedCount(response.notListedCount);
        setNotBarcodedCount(response.notBarcodedCount);
        setNoStockLocatorCount(response.noStockLocatorCount);
        setAnalyseOptions(response.analyseOptions);
        setProjectOptions(response.projectOptions);
        setLastDearSync(response.lastDearSync);

    }, []);


    useEffect(() => {

        form.reset({
            categories: currentCriteria.categories,
            brands: currentCriteria.brands,
            suppliers: currentCriteria.suppliers,
            filters: currentCriteria.filters,
            sorting: currentCriteria.sorting,
            searchString: currentCriteria.searchString,
        });

        setIsSearching(true);

        let cancellationToken = new CancellationToken();

        async function asyncRequest() {

            let response;

            try {
                response = await post('/api/inventory/search', currentCriteria, cancellationToken);
            }
            catch (ex) {
    
                if (cancellationToken.isCancelled) {
                    return;
                }
    
                setIsSearching(false);

                addToast(ex.message, { appearance: 'error' });
                return;
    
            }

            setIsSearching(false);

            setProducts(response);

            if (anchorRef.current) {
                scrollIntoView(anchorRef.current, {
                    scrollMode: 'if-needed',
                    block: 'start',
                });
            }
            
        }

        asyncRequest();

        return cancellationToken;

    }, [ props.location ]);

    useEffect(() => {

        if (!isExporting) return;

        let cancellationToken = new CancellationToken();

        async function asyncRequest() {

            let response;

            try {
                response = await post('/api/inventory/export', currentCriteria, cancellationToken);
            }
            catch (ex) {
    
                if (cancellationToken.isCancelled) {
                    return;
                }
    
                setIsExporting(false);

                addToast(ex.message, { appearance: 'error' });
                return;
    
            }

            setIsExporting(false);

            addToast('Downloading CSV', { appearance: 'success' });

            let printFrame = document.createElement('iframe');
            printFrame.src = response.fileUrl;
            printFrame.style = 'display: none;'
            document.body.appendChild(printFrame);

            
        }

        asyncRequest();

        return cancellationToken;

    }, [ isExporting ]);

    useEffect(() => {

        if (!isExportingProductSuppliers) return;

        let cancellationToken = new CancellationToken();

        async function asyncRequest() {

            let response;

            try {
                response = await post('/api/inventory/exportsuppliers', currentCriteria, cancellationToken);
            }
            catch (ex) {
    
                if (cancellationToken.isCancelled) {
                    return;
                }
    
                setIsExportingProductSuppliers(false);

                addToast(ex.message, { appearance: 'error' });
                return;
    
            }

            setIsExportingProductSuppliers(false);

            addToast('Downloading CSV', { appearance: 'success' });

            let printFrame = document.createElement('iframe');
            printFrame.src = response.fileUrl;
            printFrame.style = 'display: none;'
            document.body.appendChild(printFrame);

            
        }

        asyncRequest();

        return cancellationToken;

    }, [ isExportingProductSuppliers ]);

    function generatePath({ view, searchString, filters, sorting, categories, brands, suppliers, analyse, project, page }) {

        let query = {
            view: view != 'Default' ? view : null,
            categories: !categories.sequenceEqual(defaultCriteria.categories) ? categories : null,
            brands: !brands.sequenceEqual(defaultCriteria.brands) ? brands : null,
            suppliers: !suppliers.sequenceEqual(defaultCriteria.suppliers) ? suppliers : null,
            filters: !filters.sequenceEqual(defaultCriteria.filters) ? filters : null,
            sorting: sorting != defaultCriteria.sorting ? sorting : null,
            analyse: analyse != defaultCriteria.analyse ? analyse : null,
            project: project != defaultCriteria.project ? project : null,
            searchString: searchString || null,
            page: page != 1 ? page : null, 
        };

        return '/inventory/products?' + qs.stringify(query, { skipNull: true, arrayFormat: 'bracket-separator', sort: false });

    }

    function getAllCheckedProducts() {
        return products.items.concat(products.items.flatMap(p => p.children)).filter(p => p.checked);
    }

    function handleSubmit(formData) {
        props.history.push(generatePath({ ...currentCriteria, ...formData, page: 1 }));
    }

    function handleReset() {
        props.history.push(generatePath({
            ...currentCriteria,
            view: 'Default',
            categories: defaultCriteria.categories,
            brands: defaultCriteria.brands,
            suppliers: defaultCriteria.suppliers,
            filters: defaultCriteria.filters,
            sorting: defaultCriteria.sorting,
            searchString: defaultCriteria.searchString,
            page: 1,
        }));
    }

    function handleNotListedCountClicked() {
        let newCriteria = {
            ...currentCriteria,
            view: 'Default',
            categories: defaultCriteria.categories,
            brands: defaultCriteria.brands,
            suppliers: defaultCriteria.suppliers,
            searchString: defaultCriteria.searchString,
            filters: [ 'In Stock', 'Unlisted', 'Include Deprecated' ],
        };

        props.history.push(generatePath(newCriteria));
    }

    function handleNotBarcodedCountClicked() {
        let newCriteria = {
            ...currentCriteria,
            view: 'Default',
            categories: defaultCriteria.categories,
            brands: defaultCriteria.brands,
            suppliers: defaultCriteria.suppliers,
            searchString: defaultCriteria.searchString,
            filters: [ 'In Stock', 'No Barcode', 'Not Discontinued' ],
        };

        props.history.push(generatePath(newCriteria));
    }

    function handleNoStockLocatorCountClicked() {

        let newCriteria = {
            ...currentCriteria,
            view: 'Default',
            categories: defaultCriteria.categories,
            brands: defaultCriteria.brands,
            suppliers: defaultCriteria.suppliers,
            searchString: defaultCriteria.searchString,
            filters: [ 'In Stock', 'No Stock Locator', 'Not Discontinued' ],
        };

        props.history.push(generatePath(newCriteria));
    }

    function handleAllProductsChecked(event) {
        for (let product of products.items) {
            product.checked = event.target.checked;
        }
        setProducts({ ...products });
    }

    function handleProductChecked(product, event) {
        product.checked = event.target.checked;
        setProducts({ ...products });
    }

    function handleSetListedClicked() {
        setPendingSetListedOperation(getAllCheckedProducts());
    }

    function handleSetListedSuccess() {
        setPendingSetListedOperation(null);

        props.history.push(generatePath(currentCriteria));
    }

    function handlePrintShelfLabelsClicked() {
        setPendingPrintShelfLabelsOperation(getAllCheckedProducts());
    }

    function handlePrintShelfLabelsSuccess() {
        setPendingPrintShelfLabelsOperation(null);

        props.history.push(generatePath(currentCriteria));
    }

    function handleSetStockLocatorClicked() {
        setPendingSetStockLocatorOperation(getAllCheckedProducts());
    }

    function handleSetStockLocatorSuccess() {
        setPendingSetStockLocatorOperation(null);

        props.history.push(generatePath(currentCriteria));
    }

    function handleSetDiscontinuedClicked() {
        setPendingSetDiscontinuedOperation(getAllCheckedProducts());
    }
    
    function handleSetDiscontinuedSuccess() {
        setPendingSetDiscontinuedOperation(null);

        props.history.push(generatePath(currentCriteria));
    }

    function handleSetDeprecatedClicked() {
        setPendingSetDeprecatedOperation(getAllCheckedProducts());
    }
    
    function handleSetDeprecatedSuccess() {
        setPendingSetDeprecatedOperation(null);

        props.history.push(generatePath(currentCriteria));
    }

    function handleSetPriceClicked() {
        setPendingSetPriceOperation(getAllCheckedProducts());
    }
    
    function handleSetPriceSuccess() {
        setPendingSetPriceOperation(null);

        props.history.push(generatePath(currentCriteria));
    }

    function handleViewProductClicked(product) {
        setViewingProductDetails(product);
    }

    function handleAnalyseOptionClicked(analyse) {
        props.history.push(generatePath({ ...currentCriteria, analyse }));
    }

    function handleProjectOptionClicked(project) {
        props.history.push(generatePath({ ...currentCriteria, project }));
    }

    function handleExportClicked() {
        setIsExporting(true);
    }

    function handleExportProductSuppliersClicked() {
        setIsExportingProductSuppliers(true);
    }

    function handleChildrenToggle(product) {
        product.childrenExpanded = !product.childrenExpanded;
        setProducts({ ...products });
    }

    function handleChangeViewClicked(view) {
        props.history.push(generatePath({ ...currentCriteria, view }));
    }

    function handleSkuClicked(product) {
        navigator.clipboard.writeText(product.sku);
        addToast('Sku copied to clipboard', { appearance: 'info' });
    }

    function handleChildProductChecked(product, event) {
        product.checked = event.target.checked;
        setProducts({ ...products });
    }

    function handleGroupClicked() {
        let selectedProducts = getAllCheckedProducts();

        if (selectedProducts.count() < 2) {
            return;
        }

        setPendingGroupOperation(selectedProducts);
    }

    function handleGroupSuccess() {
        setPendingGroupOperation(null);
        props.history.push(props.location);
    }

    function handleUngroupClicked() {
        let selectedProducts = getAllCheckedProducts();

        if (selectedProducts.count() < 2) {
            return;
        }

        setPendingUngroupOperation(selectedProducts);
    }


    function handleUngroupSuccess() {
        setPendingUngroupOperation(null);
        props.history.push(props.location);
    }

    function getStockToolTip(product) {
        return `Available: ${product.available}\r\n`
            + `On Hand: ${product.onHand}\r\n`
            + `Allocated: ${product.allocated}\r\n`
            + `On Order: ${product.onOrder}`;
    }

    function getProjectionToolTip(product) {
        return `Projected Sales: ${product.projection.projectedSales}\r\n`
            + `Quantity Needed: ${product.projection.quantityNeeded}\r\n`
            + `Needed To Meet Reserve Qty: ${product.projection.neededToMeetReserveQty}\r\n`
            + `Suggested Order Qty: ${product.projection.suggestedOrderQty}\r\n`
            + `Probability Score: ${product.projection.probabilityScore}%\r\n`
            + `Preparedness Score: ${product.projection.preparednessScore}%`;
    }

    function getSalesToolTip(product) {
        return `Sold Qty: ${product.analysis.sold}\r\n`
            + `External Sold: ${product.analysis.externalSold}\r\n`
            + `Total Sold: ${product.analysis.totalSold}\r\n`
            + `Materiality Score: ${product.analysis.materialityScore}%\r\n`
            + `Material Avg: ${product.analysis.materialAverageWk}/wk\r\n`;
    }

    if (!filterOptions || !sortingOptions || !categoryOptions || !brandOptions || !supplierOptions || !analyseOptions || !projectOptions) {
        return (
            <LoadingBar />
        );
    }


    return (

        <React.Fragment>

            {(isSearching || isExporting) &&
                <LoadingBar />
            }

            <div className="mt-3 container-fluid">

                <div className="float-right">

                    {!!lastDearSync &&
                        <small className="text-muted mr-3"><FontAwesomeIcon icon={[ 'fas', 'sync' ]} /> {moment(lastDearSync).fromNow()}</small>
                    }
                    <DropdownButton as="span" title={
                        <span><FontAwesomeIcon icon={['fas', 'eye']} /> View: {currentCriteria.view}</span>
                    }>
                        <Dropdown.Item onClick={() => handleChangeViewClicked('Default')}>Default</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleChangeViewClicked('Forecasting')}>Forecasting</Dropdown.Item>
                    </DropdownButton>
                    {' '}
                    <AnalyseProjectButton analyse={currentCriteria.analyse} project={currentCriteria.project} analyseOptions={analyseOptions} projectOptions={projectOptions} onAnalyseOptionClicked={handleAnalyseOptionClicked} onProjectOptionClicked={handleProjectOptionClicked} />
                    {' '}
                        
                    <DropdownButton as="span" title="Actions">
                        <Dropdown.Item as={Link} to="/inventory/products/new"><FontAwesomeIcon icon={[ 'fas', 'plus' ]} fixedWidth /> Add New Product</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Header>Bulk Actions</Dropdown.Header>
                        <Dropdown.Item onClick={handlePrintShelfLabelsClicked}>Print Shelf Labels</Dropdown.Item>
                        <Dropdown.Item onClick={handleSetStockLocatorClicked}>Set Stock Locators</Dropdown.Item>
                        <Dropdown.Item onClick={handleSetDiscontinuedClicked}>Set As Discontinued</Dropdown.Item>
                        <Dropdown.Item onClick={handleSetDeprecatedClicked}>Set As Deprecated</Dropdown.Item>
                        <Dropdown.Item onClick={handleSetPriceClicked}>Set Price</Dropdown.Item>
                        <Dropdown.Item onClick={handleSetListedClicked}>Set As Listed</Dropdown.Item>

                        {appContext.me.permissions.includes('Power User') &&
                            <React.Fragment>
                                <Dropdown.Item disabled={getAllCheckedProducts().count() < 2} onClick={handleGroupClicked}><FontAwesomeIcon icon={[ 'fas', 'plus' ]} fixedWidth /> Group Products</Dropdown.Item>
                                {/* <Dropdown.Item disabled={getAllCheckedProducts().count() < 2} onClick={handleUngroupClicked}><FontAwesomeIcon icon={[ 'fas', 'minus' ]} fixedWidth /> Ungroup Products</Dropdown.Item> */}
                            </React.Fragment>
                        }

                        <Dropdown.Divider />
                        <Dropdown.Header>Import &amp; Export</Dropdown.Header>
                        <Dropdown.Item onClick={() => setIsImporting(true)}>Import Products</Dropdown.Item>
                        <Dropdown.Item onClick={() => setIsImportingProductMeta(true)}>Import Product Meta</Dropdown.Item>
                        <Dropdown.Item onClick={() => setIsImportingProductSuppliers(true)}>Import Product Suppliers</Dropdown.Item>
                        <Dropdown.Item onClick={handleExportClicked}>Export Products</Dropdown.Item>
                        <Dropdown.Item onClick={handleExportProductSuppliersClicked}>Export Product Suppliers</Dropdown.Item>
                    </DropdownButton>
                    
                </div>

                <h3 className="mb-3"><FontAwesomeIcon icon={[ 'fas', 'box-open' ]} /> Products</h3>

                {notListedCount > 0 &&
                    <div className="alert alert-warning small p-2">There are {notListedCount} products which have been received but not published. <a className="alert-link" href="javascript: void(0);" onClick={handleNotListedCountClicked}>View Products →</a><a onClick={() => setNotListedCount(0)} className="close" href="javascript: void(0);">&times;</a></div>
                }

                {notBarcodedCount > 0 &&
                    <div className="alert alert-warning small p-2">There are {notBarcodedCount} products which have been received but do not have barcodes. <a className="alert-link" href="javascript: void(0);" onClick={handleNotBarcodedCountClicked}>View Products →</a><a onClick={() => setNotBarcodedCount(0)} className="close" href="javascript: void(0);">&times;</a></div>
                }

                {noStockLocatorCount > 0 &&
                    <div className="alert alert-warning small p-2">There are {noStockLocatorCount} products which have been received but have no stock locator set. <a className="alert-link" href="javascript: void(0);" onClick={handleNoStockLocatorCountClicked}>View Products →</a><a onClick={() => setNoStockLocatorCount(0)} className="close" href="javascript: void(0);">&times;</a></div>
                }

                <div className="card mb-3">
                    <div className="card-body">

                        <form onSubmit={form.handleSubmit(handleSubmit)}>

                            <fieldset disabled={isSearching}>

                                <div className="form-group form-row mb-0">
                                    <label className="col-sm-1 col-form-label-sm">Filter By Category</label>
                                    <div className="col-sm-11">

                                        <Controller
                                            name="categories"
                                            control={form.control}
                                            render={(renderProps) => (
                                            
                                                <Typeahead
                                                    id="categories"
                                                    ref={renderProps.ref}
                                                    size="sm"
                                                    multiple={true}
                                                    clearButton={true}
                                                    options={categoryOptions}
                                                    selected={categoryOptions.filter(o => renderProps.value.includes(o.name))}
                                                    inputProps={{
                                                        shouldSelectHint: (shouldSelect, e) => {
                                                            return e.code == 'Enter' || shouldSelect;
                                                        },
                                                    }}
                                                    labelKey={c => [ c.parentName, c.name ].filter(c => !!c).join(': ')}
                                                    onChange={(options) => { renderProps.onChange(options.map(o => o.name)); }}
                                                    onBlur={renderProps.onBlur}

                                                />

                                        )}/>

                                    </div>
                                </div>

                                <div className="form-group form-row mb-0">
                                    <label className="col-sm-1 col-form-label-sm">Filter By Brand</label>
                                    <div className="col-sm-11">

                                        <Controller
                                            name="brands"
                                            control={form.control}
                                            render={(renderProps) => (
                                            
                                                <Typeahead
                                                    id="brands"
                                                    ref={renderProps.ref}
                                                    size="sm"
                                                    multiple={true}
                                                    clearButton={true}
                                                    options={brandOptions}
                                                    selected={brandOptions.filter(o => renderProps.value.includes(o.name))}
                                                    inputProps={{
                                                        shouldSelectHint: (shouldSelect, e) => {
                                                            return e.code == 'Enter' || shouldSelect;
                                                        },
                                                    }}
                                                    labelKey={c => c.name}
                                                    onChange={(options) => { renderProps.onChange(options.map(o => o.name)); }}
                                                    onBlur={renderProps.onBlur}

                                                />

                                        )}/>

                                    </div>
                                </div>

                                <div className="form-group form-row mb-0">
                                    <label className="col-sm-1 col-form-label-sm">Filter By Supplier</label>
                                    <div className="col-sm-11">

                                        <Controller
                                            name="suppliers"
                                            control={form.control}
                                            render={(renderProps) => (
                                            
                                                <Typeahead
                                                    id="suppliers"
                                                    ref={renderProps.ref}
                                                    size="sm"
                                                    multiple={true}
                                                    clearButton={true}
                                                    options={supplierOptions}
                                                    selected={supplierOptions.filter(o => renderProps.value.includes(o.name))}
                                                    inputProps={{
                                                        shouldSelectHint: (shouldSelect, e) => {
                                                            return e.code == 'Enter' || shouldSelect;
                                                        },
                                                    }}
                                                    labelKey={c => c.name}
                                                    onChange={(options) => { renderProps.onChange(options.map(o => o.name)); }}
                                                    onBlur={renderProps.onBlur}

                                                />

                                        )}/>

                                    </div>
                                </div>


                                <div className="form-group form-row mb-0">
                                    <label className="col-sm-1 col-form-label-sm">Additional Filters</label>
                                    <div className="col-sm-11">
    
                                        <Controller
                                            name="filters"
                                            control={form.control}
                                            render={(renderProps) => (
                                            
                                                <Typeahead
                                                    id="filters"
                                                    ref={renderProps.ref}
                                                    size="sm"
                                                    multiple={true}
                                                    clearButton={true}
                                                    options={filterOptions}
                                                    selected={renderProps.value}
                                                    inputProps={{
                                                        shouldSelectHint: (shouldSelect, e) => {
                                                            return e.code == 'Enter' || shouldSelect;
                                                        },
                                                    }}
                                                    onChange={(options) => { renderProps.onChange(options); }}
                                                    onBlur={renderProps.onBlur}

                                                />

                                        )}/>

                                    </div>
                                </div>

                                <div className="form-group form-row mb-0">
                                    <label className="col-sm-1 col-form-label-sm">Sort By</label>
                                    <div className="col-sm-11">
                                        <select name="sorting" ref={form.register} className="custom-select custom-select-sm">
                                            {sortingOptions.map((sortOption) => (

                                                <option key={sortOption} value={sortOption}>{sortOption}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group form-row mb-0">
                                    <label className="col-sm-1 col-form-label-sm">Search String</label>
                                    <div className="col-sm-11">
                                        <input name="searchString" type="text" ref={form.register} className="form-control form-control-sm mr-sm-2" id="searchString" placeholder="Search string" />
                                    </div>
                                </div>

                                <div className="form-group form-row mb-0">
                                    <div className="col-sm-12">

                                        <button type="submit" className="btn btn-sm btn-primary">
                                            {isSearching &&
                                                <span className="spinner-border spinner-border-sm"></span>
                                            }
                                            {' '}
                                            Search
                                        </button>
                                        {' '}
                                        <button type="button" className="btn btn-sm btn-secondary" onClick={handleReset}>Reset</button>


                                    </div>
                                </div>

                            </fieldset>
                        </form>

                    </div>
                </div>

                <div ref={anchorRef}></div>

                <table className="table">
                    <thead className="thead-dark" style={{ position: 'sticky', top: '0px', zIndex: 1 }}>
                        <tr>
                            <th style={{ width: '1%' }}></th>
                            <th className="text-center" style={{ width: '1%' }}><input type="checkbox" checked={products.items.any() && products.items.every(p => p.checked)} onChange={handleAllProductsChecked} /></th>
                            
                            <th>Product</th>
                            <th style={{ width: '14%' }}>Category / Brand</th>

                            {currentCriteria.view == 'Default' &&
                                <React.Fragment>
                                    <th style={{ width: '8%' }}>Avg. Cost</th>
                                    <th style={{ width: '8%' }}>Retail Price</th>
                                    <th style={{ width: '8%' }}>Inventory</th>
                                </React.Fragment>
                            }

                            {currentCriteria.view == 'Forecasting' &&
                                <React.Fragment>
                                    <th style={{ width: '8%' }}>Sales</th>
                                    <th style={{ width: '8%' }}>Inventory</th>
                                    <th style={{ width: '8%' }}>Needed</th>
                                </React.Fragment>
                            }

                            <th style={{ width: '5%' }}></th>

                        </tr>
                    </thead>

                    <tbody>

                        {products.items.map((product) => (

                            <React.Fragment key={product.id}>

                                <tr>

                                    <td className="text-center">
                                        {product.children.any() &&
                                            <button className="btn btn-sm p-0" onClick={handleChildrenToggle.bind(null, product)}><FontAwesomeIcon icon={[ 'fas', !product.childrenExpanded ? 'chevron-down' : 'chevron-up' ]} /></button>
                                        }
                                        {!product.children.any() &&
                                            <span>•</span>
                                        }
                                    </td>

                                    <td className="text-center"><input type="checkbox" checked={!!product.checked} onChange={handleProductChecked.bind(null, product)} /></td>

                                    <td>

                                        {!!product.stockLocator &&
                                            <span className="badge badge-secondary mr-2" style={{ fontSize: 'inherit' }}>{product.stockLocator}</span>
                                        }

                                        {product.isGiftCardItem &&
                                            <React.Fragment><FontAwesomeIcon icon={['fas', 'gift']} fixedWidth />{' '}</React.Fragment>
                                        }

                                        {product.isShippingItem &&
                                            <React.Fragment><FontAwesomeIcon icon={['fas', 'truck']} fixedWidth />{' '}</React.Fragment>
                                        }

                                        {currentCriteria.view == 'Default' &&
                                            <a className="text-reset" href={`/inventory/products/${product.id}`}>{product.name.replaceAll('|', '•')}</a>
                                        }

                                        {currentCriteria.view == 'Forecasting' &&
                                            <a className="text-reset" href="javascript:void(0);" onClick={handleViewProductClicked.bind(null, product)}>{product.name.replaceAll('|', '•')}</a>
                                        }

                                        <small className="ml-2 text-secondary" onClick={handleSkuClicked.bind(null, product)}> {product.sku}</small>

                                        {product.isUnmanagedListing && 
                                            <span className="ml-2 badge badge-secondary">Unmanaged Listing</span>
                                        }

                                        {(product.isTopLevel && !product.isListed && !product.isUnmanagedListing) &&
                                            <span className="ml-2 badge badge-tvk-orange">Unlisted</span>
                                        }

                                        {product.isDeprecated &&
                                            <span className="ml-2 badge badge-danger">Deprecated</span>
                                        }

                                        {!product.isDeprecated && product.isDiscontinued &&
                                            <span className="ml-2 badge badge-warning">Discontinued</span>
                                        }

                                    </td>

                                    <td><small>{product.category.name} / {product.brand.name}</small></td>

                                    {currentCriteria.view == 'Default' &&

                                        <React.Fragment>

                                            <td>

                                                {product.averageCost != 0 &&
                                                    <span>${product.averageCost.toLocaleString()}</span>
                                                }

                                                {product.averageCost == 0 &&
                                                    <span className="text-secondary">—</span>
                                                }

                                            </td>

                                            <td>
                                                
                                                {product.priceRetail == 0 &&
                                                    <span className="text-secondary">—</span>
                                                }

                                                {product.priceRetail != 0 &&
                                                    <span>${product.priceRetail.toLocaleString()}</span>
                                                }

                                                {product.priceRetailGp > 0 && product.priceRetailGp < 100 &&
                                                    <small style={retailGpStyles(product.priceRetailGp)}> {product.priceRetailGp}%</small>
                                                }
                                                
                                            </td>

                                            <td>

                                                {(product.isInventoryGroup || product.isInventoryItem) &&

                                                    <React.Fragment>

                                                        <span title={getStockToolTip(product)} className={cx('badge badge-dark')} style={{ backgroundColor: preparednessScoreStyles(product.projection.preparednessScore), fontSize: 'inherit' }}>
                                                            {product.onHand}
                                                            {product.allocated != 0 &&
                                                                <small> {product.allocated > 0 ? '-' : product.allocated < 0 ? '+' : ''}{Math.abs(product.allocated)}</small>
                                                            }
                                                        </span>

                                                        {product.onOrder > 0 &&
                                                            <small className="text-secondary"> <FontAwesomeIcon icon={['fas', 'retweet']} />{product.onOrder}</small>
                                                        }

                                                    </React.Fragment>

                                                }

                                                {!(product.isInventoryGroup || product.isInventoryItem) &&
                                                    <span className="text-secondary">—</span>
                                                }

                                            </td>

                                            <td className="p-0 align-middle">
                                                <DropdownButton size="sm" title="Actions">
                                                    <Dropdown.Item disabled={!product.wpUrl} target="_blank" href={product.wpUrl}><FontAwesomeIcon icon={[ 'fas', 'shopping-cart' ]} fixedWidth /> View Product In WooCommerce</Dropdown.Item>
                                                </DropdownButton>
                                            </td>

                                        </React.Fragment>
                                    }


                                    {currentCriteria.view == 'Forecasting' &&

                                        <React.Fragment>

                                            <td>
                                                {(product.isInventoryGroup || product.isInventoryItem) &&

                                                    <React.Fragment>
                                                        <span title={getSalesToolTip(product)} className={cx('badge', product.analysis.totalSold > 0 ? 'badge-primary' : 'badge-secondary')} style={{ fontSize: 'inherit' }}>
                                                            {product.analysis.sold}
                                                            {product.analysis.externalSold != 0 &&
                                                                <small> {product.analysis.externalSold > 0 ? '+' : product.analysis.externalSold < 0 ? '-' : ''}{Math.abs(product.analysis.externalSold)}</small>
                                                            }
                                                        </span>

                                                        {product.analysis.materialAverageWk > 0 &&
                                                            <small className="text-secondary"> {product.analysis.materialAverageWk}/wk</small>
                                                        }

                                                    </React.Fragment>

                                                }

                                                {!(product.isInventoryGroup || product.isInventoryItem) &&
                                                    <span className="text-secondary">—</span>
                                                }
                                            </td>

                                            <td>

                                                {(product.isInventoryGroup || product.isInventoryItem) &&

                                                    <React.Fragment>

                                                        <span title={getStockToolTip(product)} className="badge badge-secondary" style={{ fontSize: 'inherit' }}>
                                                            {product.onHand}
                                                            {product.allocated != 0 &&
                                                                <small> {product.allocated > 0 ? '-' : product.allocated < 0 ? '+' : ''}{Math.abs(product.allocated)}</small>
                                                            }
                                                        </span>

                                                        {product.onOrder > 0 &&
                                                            <small className="text-secondary"> <FontAwesomeIcon icon={['fas', 'retweet']} />{product.onOrder}</small>
                                                        }

                                                    </React.Fragment>

                                                }

                                                {!(product.isInventoryGroup || product.isInventoryItem) &&
                                                    <span className="text-secondary">—</span>
                                                }

                                            </td>

                                            <td>
                                                {(product.isInventoryGroup || product.isInventoryItem) &&

                                                    <React.Fragment>
                                                        <span title={getProjectionToolTip(product)} className="badge badge-dark" style={{ fontSize: 'inherit', backgroundColor: preparednessScoreStyles(product.projection.preparednessScore) }}>
                                                            {product.projection.quantityNeeded}
                                                        </span>

                                                        {product.projection.probabilityScore < 75 &&
                                                            <small style={probabilityScoreStyles(product.projection.probabilityScore)}> {product.projection.probabilityScore}%</small>
                                                        }
                                                        
                                                    </React.Fragment>

                                                }

                                                {!(product.isInventoryGroup || product.isInventoryItem) &&
                                                    <span className="text-secondary">—</span>
                                                }

                                            </td>

                                        </React.Fragment>
                                        
                                    }

                                    
                                </tr>

                                {!!product.childrenExpanded &&

                                    <tr>
                                        <td className="p-0 border-top-0"></td>
                                        <td className="p-0 border-top-0" colSpan={100}>

                                            <table className="table table-sm" style={{ backgroundColor: 'var(--light)' }}>
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th style={{ width: '2%' }}></th>
                                                        <th>Variant</th>

                                                        {currentCriteria.view == 'Default' &&
                                                            <React.Fragment>
                                                                <th style={{ width: '8%' }}>Avg. Cost</th>
                                                                <th style={{ width: '8%' }}>Retail Price</th>
                                                                <th style={{ width: '8%' }}>Inventory</th>
                                                            </React.Fragment>
                                                        }

                                                        {currentCriteria.view == 'Forecasting' &&
                                                            <React.Fragment>
                                                                <th style={{ width: '8%' }}>Sales</th>
                                                                <th style={{ width: '8%' }}>Inventory</th>
                                                                <th style={{ width: '8%' }}>Needed</th>
                                                            </React.Fragment>
                                                        }

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {product.children.map((child, i) => (
                                                        <tr key={i}>

                                                            <td className="text-center"><input type="checkbox" checked={!!child.checked} onChange={handleChildProductChecked.bind(null, child)} /></td>

                                                            <td>

                                                                {!!child.stockLocator &&
                                                                    <span className="badge badge-secondary mr-2" style={{ fontSize: 'inherit' }}>{child.stockLocator}</span>
                                                                }

                                                                {currentCriteria.view == 'Default' &&
                                                                    <a className="text-reset" href={`/inventory/products/${product.id}`}>{child.name.replaceAll('|', '•')}</a>
                                                                }

                                                                {currentCriteria.view == 'Forecasting' &&
                                                                    <a className="text-reset" href="javascript:void(0);" onClick={handleViewProductClicked.bind(null, child)}>{child.name.replaceAll('|', '•')}</a>
                                                                }

                                                                <small className="ml-2 text-secondary" onClick={handleSkuClicked.bind(null, child)}> {child.sku}</small>

                                                                {child.isDeprecated &&
                                                                    <span className="ml-2 badge badge-danger">Deprecated</span>
                                                                }

                                                                {!child.isDeprecated && child.isDiscontinued &&
                                                                    <span className="ml-2 badge badge-warning">Discontinued</span>
                                                                }

                                                            </td>


                                                            {currentCriteria.view == 'Default' &&
                                                                <React.Fragment>

                                                                    <td>

                                                                        {child.averageCost != 0 &&
                                                                            <span>${child.averageCost.toLocaleString()}</span>
                                                                        }

                                                                        {child.averageCost == 0 &&
                                                                            <span className="text-secondary">—</span>
                                                                        }

                                                                    </td>

                                                                    <td>

                                                                        {child.priceRetail == 0 &&
                                                                            <span className="text-secondary">—</span>
                                                                        }

                                                                        {child.priceRetail != 0 &&
                                                                            <span>${child.priceRetail.toLocaleString()}</span>
                                                                        }

                                                                        {child.priceRetailGp > 0 && child.priceRetailGp < 100 &&
                                                                            <small style={retailGpStyles(child.priceRetailGp)}> {child.priceRetailGp}%</small>
                                                                        }

                                                                    </td>

                                                                    <td>

                                                                        {child.isInventoryItem &&

                                                                            <React.Fragment>

                                                                                <span title={getStockToolTip(child)} className={cx('badge badge-dark')} style={{ backgroundColor: preparednessScoreStyles(child.projection.preparednessScore), fontSize: 'inherit' }}>
                                                                                    {child.onHand}
                                                                                    {child.allocated != 0 &&
                                                                                        <small> {child.allocated > 0 ? '-' : child.allocated < 0 ? '+' : ''}{Math.abs(child.allocated)}</small>
                                                                                    }
                                                                                </span>

                                                                                {child.onOrder > 0 &&
                                                                                    <small className="text-secondary"> <FontAwesomeIcon icon={['fas', 'retweet']} />{child.onOrder}</small>
                                                                                }

                                                                            </React.Fragment>

                                                                        }

                                                                        {!child.isInventoryItem &&
                                                                            <span className="text-secondary">—</span>
                                                                        }

                                                                    </td>

                                                                </React.Fragment>
                                                            }

                                                            {currentCriteria.view == 'Forecasting' &&

                                                                <React.Fragment>

                                                                    <td>

                                                                        {child.isInventoryItem &&

                                                                            <React.Fragment>
                                                                                <span title={getSalesToolTip(child)} className={cx('badge', child.analysis.totalSold > 0 ? 'badge-primary' : 'badge-secondary')} style={{ fontSize: 'inherit' }}>
                                                                                    {child.analysis.sold}
                                                                                    {child.analysis.externalSold != 0 &&
                                                                                        <small> {child.analysis.externalSold > 0 ? '+' : child.analysis.externalSold < 0 ? '-' : ''}{Math.abs(child.analysis.externalSold)}</small>
                                                                                    }
                                                                                </span>

                                                                                {child.analysis.totalSold > 0 &&
                                                                                    <small className="text-secondary"> {child.analysis.materialAverageWk}/wk</small>
                                                                                }

                                                                            </React.Fragment>

                                                                        }

                                                                        {!child.isInventoryItem &&
                                                                            <span className="text-secondary">—</span>
                                                                        }

                                                                    </td>

                                                                    <td>

                                                                        {child.isInventoryItem &&

                                                                            <React.Fragment>

                                                                                <span title={getStockToolTip(child)} className="badge badge-secondary" style={{ fontSize: 'inherit' }}>
                                                                                    {child.onHand}
                                                                                    {child.allocated != 0 &&
                                                                                        <small> {child.allocated > 0 ? '-' : child.allocated < 0 ? '+' : ''}{Math.abs(child.allocated)}</small>
                                                                                    }
                                                                                </span>

                                                                                {child.onOrder > 0 &&
                                                                                    <small className="text-secondary"> <FontAwesomeIcon icon={['fas', 'retweet']} />{child.onOrder}</small>
                                                                                }

                                                                            </React.Fragment>

                                                                        }

                                                                        {!child.isInventoryItem &&
                                                                            <span className="text-secondary">—</span>
                                                                        }

                                                                    </td>

                                                                    <td>
                                                                        {child.isInventoryItem &&

                                                                            <React.Fragment>
                                                                                <span title={getProjectionToolTip(child)} className="badge badge-dark" style={{ fontSize: 'inherit', backgroundColor: preparednessScoreStyles(child.projection.preparednessScore) }}>
                                                                                    {child.projection.quantityNeeded}
                                                                                </span>

                                                                                {child.projection.probabilityScore < 75 &&
                                                                                    <small style={probabilityScoreStyles(child.projection.probabilityScore)}> {child.projection.probabilityScore}%</small>
                                                                                }

                                                                            </React.Fragment>

                                                                        }

                                                                        {!child.isInventoryItem &&
                                                                            <span className="text-secondary">—</span>
                                                                        }

                                                                    </td>

                                                                </React.Fragment>
                                                            }

                                                        </tr>

                                                    ))}

                                                </tbody>

                                            </table>

                                        </td>
                                    </tr>
                                }

                            </React.Fragment>

                        ))}

                        {!products.totalCount &&
                            <tr>
                                <td colSpan={100}>No results</td>
                            </tr>
                        }
                    </tbody>

                    {products.totalCount > 0 &&
                        <tfoot>
                            <tr>
                                <td colSpan={100} className="text-right px-5">
                                    {products.totalCount.toLocaleString()} products
                                </td>
                            </tr>
                        </tfoot>
                    }

                </table>

                <Pagination page={products.page} totalPages={products.totalPages} pathGenerator={(page) => generatePath({ ...currentCriteria, page })} />

            </div>

            {pendingSetListedOperation &&
                <InventoryProductsSetListedModal products={pendingSetListedOperation} onSuccess={handleSetListedSuccess} onCancel={() => setPendingSetListedOperation(null)} />
            }

            {pendingPrintShelfLabelsOperation &&
                <InventoryProductsPrintShelfLabelsModal products={pendingPrintShelfLabelsOperation} onSuccess={handlePrintShelfLabelsSuccess} onCancel={() => setPendingPrintShelfLabelsOperation(null)} />
            }

            {pendingSetStockLocatorOperation &&
                <InventoryProductsSetStockLocatorModal products={pendingSetStockLocatorOperation} onSuccess={handleSetStockLocatorSuccess} onCancel={() => setPendingSetStockLocatorOperation(null)} />
            }

            {pendingSetDiscontinuedOperation &&
                <InventoryProductsSetDiscontinuedModal products={pendingSetDiscontinuedOperation} onSuccess={handleSetDiscontinuedSuccess} onCancel={() => setPendingSetDiscontinuedOperation(null)} />
            }

            {pendingSetDeprecatedOperation &&
                <InventoryProductsSetDeprecatedModal products={pendingSetDeprecatedOperation} onSuccess={handleSetDeprecatedSuccess} onCancel={() => setPendingSetDeprecatedOperation(null)} />
            }

            {viewingProductDetails &&
                <InventoryProductsDetailsModal product={viewingProductDetails} analyse={currentCriteria.analyse} project={currentCriteria.project} onClose={() => setViewingProductDetails(null)} />
            }

            {isImporting &&
                <InventoryProductsImportModal onSuccess={() => setIsImporting(false)} onCancel={() => setIsImporting(false)} />
            }

            {isImportingProductMeta &&
                <InventoryProductsMetaImportModal onSuccess={() => setIsImportingProductMeta(false)} onCancel={() => setIsImportingProductMeta(false)} />
            }

            {isImportingProductSuppliers &&
                <InventoryProductSuppliersImportModal onSuccess={() => setIsImportingProductSuppliers(false)} onCancel={() => setIsImportingProductSuppliers(false)} />
            }

            {pendingSetPriceOperation &&
                <InventoryProductsSetPriceModal products={pendingSetPriceOperation} onSuccess={handleSetPriceSuccess} onCancel={() => setPendingSetPriceOperation(null)} />
            }

            {pendingGroupOperation &&
                <ProductsGroupModal products={pendingGroupOperation} onSuccess={handleGroupSuccess} onCancel={() => setPendingGroupOperation(null)} />
            }

            {/* {pendingUngroupOperation &&
                <ProductsUngroupModal parentProduct={pendingUngroupOperation} childProducts={pendingUngroupOperation.children} onSuccess={handleUngroupSuccess} onCancel={() => setPendingUngroupOperation(null)} />
            } */}


        </React.Fragment>

    );

}

InventoryProducts.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

export default withRouter(InventoryProducts);