import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import Modal from 'react-bootstrap/Modal';

import { tryPut as put } from 'lib/api';
import { CancellationToken } from 'lib/cancellationTokens';
import { playSuccess, playComplete, playError } from 'lib/sounds';

export default function JobAddTrackingInfoModal({ job, onSuccess, onCancel }) {

    let trackingNumberInputRef = useRef();
    let ageNumberInputRef = useRef();
    let saturdayDeliveryNumberInputRef = useRef();

    let { addToast } = useToasts();

    let [ pendingOperation, setPendingOperation ] = useState(null);

    useEffect(() => {
        trackingNumberInputRef.current.focus();
    }, []);


    useEffect(() => {

        if (!pendingOperation) {
            return;
        }

        let cancellationToken = new CancellationToken();

        put(`/api/fulfillment/jobs/${job.id}/addtracking`, pendingOperation)
            
        .then(([ _, ex ]) => {

            if (cancellationToken.isCancelled) {
                return;
            }

            setPendingOperation(null);

            if (ex) {
                addToast(ex.message, { appearance: 'error' });

                playError();

                return;
            }
            
            onSuccess();

            addToast('Tracking info added', { appearance: 'success' });

            playComplete();

        });

        return cancellationToken;

    }, [ pendingOperation ]);


    function handleTrackingNumberKeyDown(event) {
        if (event.code != 'Enter') {
            return;
        }

        event.target.value = {
            'test': 'XXXXXXXXXXXXXXXXXXXXXXXX',
        }[event.target.value] || event.target.value;

        if (!new RegExp(job.shippingMethod.trackingNumberRegex).test(event.target.value)) {
            playError();
            addToast('Invalid tracking number.', { appearance: 'error' });
            return;
        }

        if (job.shippingMethod.isAgeTicketRequired || (job.isSaturdayDelivery && job.shippingMethod.isSaturdayDeliveryTicketRequired)) {

            playSuccess();

            if (job.shippingMethod.isAgeTicketRequired) {
                ageNumberInputRef.current.focus();
            }
            else {
                saturdayDeliveryNumberInputRef.current.focus();
            }
            
            return;

        }

        handleSubmit();
    }

    function handleAgeTicketKeyDown(event) {
        if (event.code != 'Enter') {
            return;
        }

        event.target.value = {
            'test': 'YGXXXXXXXXXXX',
        }[event.target.value] || event.target.value;

        if (!new RegExp(job.shippingMethod.ageNumberRegex).test(event.target.value)) {
            playError();
            addToast('Invalid age number.', { appearance: 'error' });
            return;
        }

        if (job.isSaturdayDelivery && job.shippingMethod.isSaturdayDeliveryTicketRequired) {
            playSuccess();
            saturdayDeliveryNumberInputRef.current.focus();
            return;
        }

        handleSubmit();
    }

    function handleSaturdayDeliveryTicketKeyDown(event) {
        if (event.code != 'Enter') {
            return;
        }

        event.target.value = {
            'test': 'SRXXXXXXXXXXX',
        }[event.target.value] || event.target.value;

        if (!new RegExp(job.shippingMethod.saturdayDeliveryNumberRegex).test(event.target.value)) {
            playError();
            addToast('Invalid saturday number.', { appearance: 'error' });
            return;
        }

        handleSubmit();

    }

    function handleSubmit() {

        let trackingNumber = trackingNumberInputRef.current.value;
        let ageNumber = ageNumberInputRef.current.value;

        let saturdayDeliveryNumber;

        if (job.isSaturdayDelivery && job.shippingMethod.isSaturdayDeliveryTicketRequired) {
            saturdayDeliveryNumber = saturdayDeliveryNumberInputRef.current.value;
        }

        setPendingOperation({
            trackingNumber,
            ageNumber,
            saturdayDeliveryNumber,
        });

    }

    return (

        <Modal show={true} size="lg" centered onHide={onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Add Tracking Info</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <div className="form-group">
                    <label>Tracking #</label>
                    <input ref={trackingNumberInputRef} type="text" className="form-control" intialValue={''} onKeyDown={handleTrackingNumberKeyDown} />
                </div>

                {job.shippingMethod.isAgeTicketRequired &&
                    <div className="form-group">
                        <label>Age #</label>
                        <input ref={ageNumberInputRef} type="text" className="form-control" intialValue={''} onKeyDown={handleAgeTicketKeyDown} />
                    </div>
                }

                {job.isSaturdayDelivery && job.shippingMethod.isSaturdayDeliveryTicketRequired &&
                    <div className="form-group">
                        <label>Saturday #</label>
                        <input ref={saturdayDeliveryNumberInputRef} type="text" className="form-control" intialValue={''} onKeyDown={handleSaturdayDeliveryTicketKeyDown} />
                    </div>
                }

            </Modal.Body>

            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={onCancel}>Cancel</button>

                <button type="button" className="btn btn-primary" onClick={handleSubmit} disabled={!!pendingOperation}>
                    {!!pendingOperation &&
                        <span className="spinner-border spinner-border-sm"></span>
                    }
                    {' '}
                    Add Tracking Info
                </button>

            </Modal.Footer>

        </Modal>

    );

}

JobAddTrackingInfoModal.propTypes = {
    job: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};