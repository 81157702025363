import './App.scss';

import { v4 as uuid } from 'uuid';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';

import { stringifyUrl } from 'query-string';

import { get, ApiError, ApiAuthorizationError, setHeaderMiddleware, setErrorMiddleware } from 'lib/api';
import 'lib/arrays';

import AppContext from 'AppContext';
import Header from 'components/Header';
import Home from 'components/Home';
import MePassword from 'components/MePassword';
import MeProfile from 'components/MeProfile';
import OrganisationUsers from 'components/OrganisationUsers';
import OrganisationRoles from 'components/OrganisationRoles';
import InventoryProducts from 'components/InventoryProducts';
import Product from 'components/Product';
import NewOrder from 'components/NewOrder';
import Logs from 'components/Logs';
import BlacklistEntries from 'components/BlacklistEntries';
import BlacklistEntry from 'components/BlacklistEntry';
import CardBins from 'components/CardBins';
import Jobs from 'components/Jobs';
import Job from 'components/Job';
import Journals from 'components/Journals';
import HelperXeroCsv from 'components/HelperXeroCsv';
import HelperNzPostGst from 'components/HelperNzPostGst';
import HelperVendProducts from 'components/HelperVendProducts';
import HelperVendReconcile from 'components/HelperVendReconcile';
import HelperVraReport from 'components/HelperVraReport';
import HelperDummyShipments from 'components/HelperDummyShipments';
import Maintenance from 'components/Maintenance';
import Suppliers from 'components/Suppliers';
import Categories from 'components/Categories';
import Brands from 'components/Brands';
import Attributes from 'components/Attributes';

library.add(fas, far, fab);


export default class App extends React.Component {

    state = {
        me: null,
        config: null,
    }

    constructor(props) {
        super(props);
        setHeaderMiddleware(this.apiHeaderHandler)
        setErrorMiddleware(this.apiErrorHandler);
    }

    apiHeaderHandler = (headers) => {

        let access_token = localStorage.getItem('access_token');

        if (access_token) {
            headers.append('Authorization', 'Bearer ' + access_token)
        }

        return headers;

    };

    apiErrorHandler = (ex) => {

        if (ex instanceof ApiAuthorizationError) {
        
            setTimeout(() => {
                localStorage.removeItem('access_token');
                this.login();
            }, 5000);

            return new Error(`You've either been logged out, or don't have the authority to that. Please try logging in again.`);

        }

        if (!(ex instanceof ApiError)) {
            console.log('Unexpected API error', ex, ex.stack);
            return new Error('Sorry! Computer says no! Please try whatever it was you were doing again shortly.');
        }

        return ex;

    };


    componentDidMount = async () => {

        let response;

        try {
            response = await get('/api/me');
        }
        catch (ex) {
            alert('Fatal error, could not start application.');
            return;
        }

        this.setState({
            ...this.state,
            ...response,
        });

    }

    login = () => {

        let nonce = uuid();
        
        localStorage.setItem('nonce', nonce);

        let url = '/connect/authorize';

        let query = {
            response_type: 'id_token token',
            client_id: 'vapeserv-web',
            scope: 'vapeserv-api openid',
            redirect_uri: window.location.origin,
            state: window.location.pathname + window.location.search,
            nonce,
        }

        window.location = stringifyUrl({ url, query });

    }

    logout = async () => {

        var idToken = localStorage.getItem('id_token');

        localStorage.removeItem('id_token');
        localStorage.removeItem('access_token');

        let url = '/connect/endsession';

        let query = {
            id_token_hint: idToken,
            post_logout_redirect_uri: window.location.origin,
        }

        window.location = stringifyUrl({ url, query });

    }

    isLoggedIn = () => !!this.me;

    updateMe = (me) => {
        this.setState({
            ...this.state,
            me,
        });
    }

    render = () => {

        if (!this.state.config)
            return null;

        let context = {
            ...this.state,
            login: this.login,
            logout: this.logout,
            isLoggedIn: this.isLoggedIn,
            updateMe: this.updateMe,
        };

        return (

            <div className="app">

                <ToastProvider placement="bottom-right" autoDismiss={true}>

                    <AppContext.Provider value={context}>

                        <Router>

                            <Header />

                            <Switch>

                                <Route exact strict path="/vapehq/dummyshipments">
                                    <HelperDummyShipments />
                                </Route>

                                <Route exact strict path="/helpers/vrareport">
                                    <HelperVraReport />
                                </Route>

                                <Route exact strict path="/helpers/nzpostgst">
                                    <HelperNzPostGst />
                                </Route>

                                <Route exact strict path="/helpers/xerocsv">
                                    <HelperXeroCsv />
                                </Route>

                                <Route exact strict path="/helpers/vendproducts">
                                    <HelperVendProducts />
                                </Route>

                                <Route exact strict path="/helpers/vendreconcile">
                                    <HelperVendReconcile />
                                </Route>

                                <Route exact strict path="/admin/maintenance">
                                    <Maintenance />
                                </Route>

                                <Route exact strict path="/admin/users">
                                    <OrganisationUsers />
                                </Route>

                                <Route exact strict path="/admin/roles">
                                    <OrganisationRoles />
                                </Route>

                                <Route exact strict path="/admin/logs">
                                    <Logs />
                                </Route>

                                <Route exact strict path="/fulfillment/:id">
                                    <Job />
                                </Route>

                                <Route exact strict path="/fulfillment">
                                    <Jobs />
                                </Route>

                                <Route exact strict path={[
                                    '/admin/blacklist/new',
                                    '/admin/blacklist/:id(\\d+)',
                                ]}>
                                    <BlacklistEntry />
                                </Route>

                                <Route exact strict path="/admin/cardbins">
                                    <CardBins />
                                </Route>

                                <Route exact strict path="/admin/blacklist">
                                    <BlacklistEntries />
                                </Route>

                                <Route exact strict path={[
                                    '/inventory/products/new',
                                    '/inventory/products/:id(\\d+)',
                                ]}>
                                    <Product />
                                </Route>

                                <Route exact strict path="/inventory/products">
                                    <InventoryProducts />
                                </Route>

                                <Route exact strict path="/inventory/suppliers">
                                    <Suppliers />
                                </Route>

                                <Route exact strict path="/inventory/categories">
                                    <Categories />
                                </Route>

                                <Route exact strict path="/inventory/brands">
                                    <Brands />
                                </Route>

                                <Route exact strict path="/inventory/attributes">
                                    <Attributes />
                                </Route>

                                <Route exact strict path="/inventory/journals">
                                    <Journals />
                                </Route>

                                <Route exact strict path="/me/password">
                                    <MePassword />
                                </Route>

                                <Route exact strict path="/me/profile">
                                    <MeProfile />
                                </Route>

                                <Route exact strict path="/ordering/new">
                                    <NewOrder />
                                </Route>

                                <Route exact strict path="/">
                                    <Home />
                                </Route>

                                <Route>
                                    <p>not found</p>
                                </Route>

                            </Switch>

                        </Router>

                    </AppContext.Provider>

                </ToastProvider>

            </div>

        )

    }

}
