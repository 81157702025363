import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToasts } from 'react-toast-notifications';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import { useApi } from 'lib/effects';

import LoadingBar from 'components/LoadingBar';
import AttributesAddOrUpdateModal from 'components/AttributesAddOrUpdateModal';
import AttributesDeleteModal from 'components/AttributesDeleteModal';

export default function Attributes(props) {

    let { addToast } = useToasts();

    let [ attributes, setAttributes ] = useState(null);

    let [ isAddPending, setIsAddPending ] = useState(false);
    let [ pendingEdit, setPendingEdit ] = useState(null);
    let [ pendingDelete, setPendingDelete ] = useState(null);

    useApi('/api/inventory/attributes', (response, ex) => {

        if (ex) {
            addToast(ex.message, { appearance: 'error' });
            return;
        }

        setAttributes(response);

    }, []);
    
    function handleAddSuccess(attribute) {
        setAttributes(attributes.concat([ attribute ]));
        setIsAddPending(false);
    }

    function handleEditSuccess(attribute) {
        setAttributes(attributes.filter(u => u != pendingEdit).concat([ attribute ]));
        setPendingEdit(null);
    }

    function handleDeleteSuccess() {
        setAttributes(attributes.filter(u => u != pendingDelete));
        setPendingDelete(null);
    }


    if (!attributes) {
        return (
            <LoadingBar />
        );
    }

    return (

        <div className="mt-3 container-fluid">

            <div className="float-right">
                <button className="btn btn-success" onClick={() => setIsAddPending(true)}><FontAwesomeIcon icon={['fas', 'plus']} /> Add Attribute</button>
            </div>


            <h3><FontAwesomeIcon icon={[ 'fas', 'asterisk' ]} /> Attributes</h3>

            <p>Configure your product attributes.</p>

            <table className="table">
                <thead className="thead-dark">
                    <tr>
                        <th>Name</th>
                        <th>Hint</th>
                        <th style={{ width: '10%' }}></th>
                    </tr>
                </thead>
                
                <tbody>

                    {attributes.orderBy(c => c.name).map((attribute) => (
                        <React.Fragment>

                            <tr key={attribute.id}>

                                <td><a className="text-reset" href="javascript: void(0);" onClick={() => setPendingEdit(attribute)}>{attribute.name}</a></td>
                                
                                <td>{attribute.hint}</td>

                                <td className="text-right py-0 align-middle">
                                    <DropdownButton title="Actions" size="sm">
                                        <Dropdown.Item onClick={() => setPendingEdit(attribute)}>Edit Attribute</Dropdown.Item>
                                        <Dropdown.Item onClick={() => setPendingDelete(attribute)}>Delete Attribute</Dropdown.Item>
                                    </DropdownButton>
                                </td>

                            </tr>

                        </React.Fragment>
                    ))}

                </tbody>

            </table>

            {isAddPending &&
                <AttributesAddOrUpdateModal onSuccess={handleAddSuccess} onCancel={() => setIsAddPending(false)} />
            }

            {pendingEdit &&
                <AttributesAddOrUpdateModal attribute={pendingEdit} onSuccess={handleEditSuccess} onCancel={() => setPendingEdit(null)} />
            }

            {pendingDelete &&
                <AttributesDeleteModal attribute={pendingDelete} onSuccess={handleDeleteSuccess} onCancel={() => setPendingDelete(null)} />
            }


        </div>

    );

}