import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import Form from 'react-bootstrap/Form';

import { tryPost as post } from 'lib/api';
import { CancellationToken } from 'lib/cancellationTokens';

export default function InventoryProductSuppliersImportModal(props) {

    let { addToast } = useToasts();

    let [ file, setFile ] = useState(null);
    let [ isSubmitting, setIsSubmitting ] = useState(false);

    useEffect(() => {

        if (!isSubmitting) {
            return;
        }

        let cancellationToken = new CancellationToken();

        let request = {
            fileBase64: file.asBase64,
        };
        
        post('/api/inventory/importproductsuppliers', request)
        
            .then(([ response, ex ]) => {

                if (cancellationToken.isCancelled) {
                    return;
                }

                setIsSubmitting(false);

                if (ex) {
                    addToast(ex.message, { appearance: 'error' });
                    return;
                }

                addToast(`Import success`, { appearance: 'success' });

                props.onSuccess();

            });

        return cancellationToken;

    }, [ isSubmitting ]);


    function handleSubmit() {
        setIsSubmitting(true);
    }

    function handleCancel() {
        props.onCancel();
    }


    function handleFileSelected(event) {

        let file = event.target.files[0];

        setFile(file);

        let reader = new FileReader();

        reader.onload = (ev) => {
            file.asBase64 = ev.target.result.match('(?:data:text\/csv;base64,)(.+)')[1];
        };

        reader.readAsDataURL(file);

    }
    
    return (

        <Modal show={true} centered size="lg" onHide={handleCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Import Product Suppliers</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <div className="form-group">
                    <label>File</label>
                    <Form.File onChange={handleFileSelected} label={file?.name ?? 'Upload CSV'} custom />
                </div>

            </Modal.Body>

            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={handleCancel}>Cancel</button>

                <button type="button" className="btn btn-primary" onClick={handleSubmit} disabled={isSubmitting}>
                    {isSubmitting &&
                        <span className="spinner-border spinner-border-sm"></span>
                    }
                    {' '}
                    Confirm
                </button>

            </Modal.Footer>
        </Modal>

    );

}

InventoryProductSuppliersImportModal.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};