import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import cx from 'classnames';

export default function LogsTable(props) {

    let { logs, stickyHeader = true, className = null, headerClassName = 'thead-dark' } = props;

    return (

        <table className={cx('table', className)}>
            <thead className={headerClassName} style={stickyHeader ? { position: 'sticky', top: '0px', zIndex: 1 } : null}>
                <tr>
                    <th style={{ width: '1%' }}></th>
                    <th style={{ width: '15%' }}>Type</th>
                    <th>Details</th>
                    <th style={{ width: '5%' }}></th>
                    <th style={{ width: '8%' }}>User</th>
                    <th style={{ width: '15%' }}>Logged</th>
                </tr>
            </thead>

            <tbody>

                {logs.map((log) => (
                    <tr key={log.id}>
                        <td>
                            {log.isInfo &&
                                <FontAwesomeIcon className="text-info" icon={['fas', 'info-circle']} />
                            }
                            {log.isWarning &&
                                <FontAwesomeIcon className="text-warning" icon={['fas', 'exclamation-triangle']} />
                            }
                            {log.isError &&
                                <FontAwesomeIcon className="text-danger" icon={['fas', 'exclamation-triangle']} />
                            }
                            {log.isDebug &&
                                <FontAwesomeIcon className="text-secondary" icon={['fas', 'bug']} />
                            }

                        </td>
                        <td>{log.name}</td>
                        <td><div dangerouslySetInnerHTML={{ __html: log.body }}></div></td>
                        <td>

                            <div className="inline-badges text-nowrap">

                                {log.subjectUser &&
                                    <span className="badge badge-secondary" title={`Action performed by ${log.subjectUser.fullName}`}>{log.subjectUser.fullName}</span>
                                }

                                {log.subjectJob &&
                                    <Link as="span" to={`/fulfillment/${log.subjectJob.id}`} className="badge badge-secondary" title={`Action performed on job #${log.subjectJob.orderNumber}`}>#{log.subjectJob.orderNumber}</Link>
                                }

                                {log.subjectProduct &&
                                    <Link as="span" to={`/inventory/products/${log.subjectProduct.id}`} className="badge badge-secondary" title={`Action performed on product ${log.subjectProduct.name}`}>{log.subjectProduct.sku}</Link>
                                }

                            </div>

                        </td>
                        <td>{log.user?.fullName || 'System'}</td>
                        <td>{moment(log.loggedOn).toDate().toLocaleString()}</td>
                    </tr>
                ))}

                {!logs.any() &&
                    <tr>
                        <td colSpan={100}>No logs</td>
                    </tr>
                }
            </tbody>

        </table>

    );

}

LogsTable.propTypes = {
    logs: PropTypes.array.isRequired,
    stickyHeader: PropTypes.bool,
    className: PropTypes.string,
    headerClassName: PropTypes.string,
};