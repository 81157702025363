import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';

import { tryPut as put } from 'lib/api';
import { CancellationToken } from 'lib/cancellationTokens';

export default function JobPaymentModalInner({ job, onSuccess, onCancel }) {

    let { addToast } = useToasts();

    let [ date, setDate ] = useState(moment().format('YYYY-MM-DD'));

    let [ isSaving, setIsSaving ] = useState(false);

    useEffect(() => {

        if (!isSaving) {
            return;
        }

        let cancellationToken = new CancellationToken();

        put(`/api/fulfillment/jobs/${job.id}/verifypayment`, date)
        
            .then(([ _, ex ]) => {

                if (cancellationToken.isCancelled) {
                    return;
                }

                setIsSaving(false);

                if (ex) {
                    addToast(ex.message, { appearance: 'error' });
                    return;
                }

                addToast('Payment verified.', { appearance: 'success' });

                onSuccess();

            });

        return cancellationToken;

    }, [ isSaving ]);

    function handleDateChange(event) {
        setDate(event.target.value);
    }

    return (

        <React.Fragment>

            <Modal.Body>

                <div className="col-8 mx-auto">

                    <fieldset disabled={isSaving}>

                        <div className="form-group form-row mb-0">
                            <label className="col-5 col-form-label">Method</label>
                            <div className="col-7">
                                <div className="form-control-plaintext">{job.paymentMethod.name}</div>
                            </div>
                        </div>

                        <div className="form-group form-row mb-0">
                            <label className="col-5 col-form-label">Reference</label>
                            <div className="col-7">
                                <div className="form-control-plaintext">{job.paymentReference}</div>
                            </div>
                        </div>

                        <div className="form-group form-row mb-0">
                            <label className="col-5 col-form-label">Name</label>
                            <div className="col-7">
                                <div className="form-control-plaintext">{job.billingName}</div>
                            </div>
                        </div>

                        <div className="form-group form-row mb-0">
                            <label className="col-5 col-form-label">Amount</label>
                            <div className="col-7">
                                <div className="form-control-plaintext">${job.paymentAmount}</div>
                            </div>
                        </div>

                        <div className="form-group form-row mb-0">
                            <label className="col-5 col-form-label">Order Date</label>
                            <div className="col-7">
                                <div className="form-control-plaintext">{moment(job.orderDate).toDate().toLocaleString()}</div>
                            </div>
                        </div>

                        <div className="form-group form-row">
                            <label className="col-5 col-form-label">Payment Date</label>
                            <div className="col-7">
                                <input type="date" className="form-control" value={date} onChange={handleDateChange} />
                            </div>
                        </div>



                    </fieldset>



                </div>

            </Modal.Body>

            <Modal.Footer>
                <button className="btn btn-secondary" disabled={isSaving} onClick={onCancel}>Back</button>
                {' '}
                <button type="button" className="btn btn-primary float-right" onClick={() => setIsSaving(true)} disabled={isSaving}>
                    {isSaving &&
                        <span className="spinner-border spinner-border-sm"></span>
                    }
                    {' '}
                    Verify Payment
                </button>
            </Modal.Footer>

        </React.Fragment>

    );

}

JobPaymentModalInner.propTypes = {
    job: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};