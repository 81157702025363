import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import cx from 'classnames';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import { get, post, put, del } from 'lib/api';
import withFormHelpers from 'lib/formHelpers';
import { CancellationToken } from 'lib/cancellationTokens';

import LoadingBar from 'components/LoadingBar';
import BlacklistEntryRuleModal from 'components/BlacklistEntryRuleModal';

function BlacklistEntry(props) {

    let { addToast } = useToasts();

    let form = withFormHelpers(useForm({ mode: 'onBlur' }));
    
    let { id } = props.match.params;

    let [ entry, setEntry ] = useState(null);

    let [ isSaving, setIsSaving ] = useState(false);
    let [ isConfirmingDelete, setIsConfirmingDelete ] = useState(false);
    let [ isDeleting, setIsDeleting ] = useState(false);

    let [ isAddRulePending, setIsAddRulePending ] = useState(false);
    let [ pendingEditRule, setPendingEditRule ] = useState(null);
    let [ pendingDeleteRule, setPendingDeleteRule ] = useState(null);

    useEffect(() => {

        if (!id) {

            entry = {
                id: null,
                description: null,
                notes: null,
                isActive: true,
                rules: [],
            };

            setEntry(entry);

            form.reset(entry);

            return;

        }

        let cancellationToken = new CancellationToken();

        async function asyncRequest() {

            let response;

            try {
                response = await get(`/api/blacklist/entries/${id}`, cancellationToken);
            }
            catch (ex) {
    
                if (cancellationToken.isCancelled) {
                    return;
                }
    
                addToast(ex.message, { appearance: 'error' });
                return;
    
            }

            setEntry(response);

            form.reset(response);
            
        }

        asyncRequest();

        return cancellationToken;

    }, []);

    useEffect(() => {

        if (!isSaving) {
            return;
        }

        let cancellationToken = new CancellationToken();

        async function asyncRequest() {

            let request;

            if (entry.id) {
                request = post(`/api/blacklist/entries/${id}`, entry, cancellationToken);
            }
            else {
                request = put('/api/blacklist/entries', entry, cancellationToken);
            }

            let response;

            try {
                response = await request;
            }
            catch (ex) {
    
                if (cancellationToken.isCancelled) {
                    return;
                }

                setIsSaving(false);
    
                addToast(ex.message, { appearance: 'error' });
                return;
    
            }

            setIsSaving(false);

            setEntry(response);

            form.reset(response);

            props.history.push(`/admin/blacklist/${response.id}`);

            addToast('Blacklist entry saved', { appearance: 'success' });
            
        }

        asyncRequest();

        return cancellationToken;

    }, [ isSaving ]);

    useEffect(() => {

        if (!isDeleting) {
            return;
        }

        let cancellationToken = new CancellationToken();

        async function asyncRequest() {

            try {
                await del(`/api/blacklist/entries/${id}`, cancellationToken);
            }
            catch (ex) {
    
                if (cancellationToken.isCancelled) {
                    return;
                }

                setIsDeleting(false);
    
                addToast(ex.message, { appearance: 'error' });
                return;
    
            }

            props.history.push('/admin/blacklist');

            addToast('Blacklist entry deleted', { appearance: 'success' });
            
        }

        asyncRequest();

        return cancellationToken;

    }, [ isDeleting ]);


    function handleAddRuleSuccess(rule) {
        entry.rules = entry.rules.concat([ rule ]);
        setEntry({ ...entry });
        setIsAddRulePending(false);
    }

    function handleEditRuleSuccess(rule) {
        Object.assign(pendingEditRule, rule);
        setEntry({ ...entry });
        setPendingEditRule(null);
    }

    function handleDeleteRuleConfirm() {
        entry.rules = entry.rules.except([ pendingDeleteRule ]);
        setEntry({ ...entry });
        setPendingDeleteRule();
    }

    function handleSubmit(formData) {
        setEntry({ 
            ...entry,
            ...formData,
        });

        setIsSaving(true);
    }

    if (!entry) {
        return (
            <LoadingBar />
        );
    }

    return (

        <React.Fragment>

            {(isSaving || isDeleting) &&
                <LoadingBar />
            }

            <div className="mt-3 container-fluid">

                <div className="float-right">
                    {entry.id &&
                        <React.Fragment>
                            <button type="button" className="btn btn-danger" onClick={() => setIsConfirmingDelete(true)}>
                                <FontAwesomeIcon icon={['fas', 'times']} fixedWidth />
                                {' '}
                                Delete
                            </button>
                            {' '}
                        </React.Fragment>
                    }
                    <button type="button" className="btn btn-success" onClick={form.handleSubmit(handleSubmit)} disabled={isSaving}>
                        {isSaving &&
                            <span className="spinner-border spinner-border-sm"></span>
                        }
                        {!isSaving &&
                            <FontAwesomeIcon icon={[ 'fas', 'check' ]} fixedWidth />
                        }
                        {' '}
                        Save Entry
                    </button>
                </div>

                <div className="mb-1"><Link className="text-reset text-decoration-none" to="/admin/blacklist"><FontAwesomeIcon icon={['fas', 'skull-crossbones']} fixedWidth /> Blacklist</Link></div>

                {entry.id &&
                    <h3>Entry #{entry.id}</h3>
                }

                {!entry.id &&
                    <h3>New Entry</h3>
                }

                <hr />

                <div className="row">

                    <div className="col-3">


                        <div className="card card-custom mb-3">
                            <h5 className="card-header">Details</h5>
                            <div className="card-body">


                                <form onSubmit={form.handleSubmit(handleSubmit)}>

                                    <fieldset disabled={isSaving}>

                                        <div className="form-group">

                                            <label>Description</label>

                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <FontAwesomeIcon icon={['fas', 'pencil-alt']} />
                                                    </span>
                                                </div>

                                                <input name="description" type="text" ref={form.register({ required: true })} className={cx('form-control', { 'is-invalid': form.hasErrors('description') })} />

                                                {form.hasErrors('description') &&
                                                    <div className="invalid-feedback">{form.errorMessage('description', 'Please enter a valid description.')}</div>
                                                }

                                            </div>

                                        </div>

                                        <div className="form-group">

                                            <label>Notes</label>

                                            <div className="input-group">

                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <FontAwesomeIcon icon={['fas', 'align-justify']} />
                                                    </span>
                                                </div>

                                                <textarea name="notes" ref={form.register} className="form-control" rows={5} />

                                            </div>

                                        </div>

                                        <div className="form-group">
                                            <Form.Check name="isActive" ref={form.register} id="isActive" type="checkbox" label="Is Active" custom />
                                        </div>

                                    </fieldset>

                                </form>


                            </div>
                        </div>

                    </div>

                    <div className="col-9">

                        <div className="card card-custom mb-3">
                            <div className="card-header py-0 d-flex align-items-center justify-content-between">
                                <h5 style={{ margin: '0.75rem 0' }}>Rules</h5>
                                <button type="button" className="btn btn-sm btn-primary" disabled={isSaving} onClick={() => setIsAddRulePending(true)}><FontAwesomeIcon icon={['fas', 'plus']} fixedWidth /> Add Rule</button>
                            </div>
                            <div className="card-body p-0">


                                <table className="table mb-0">
                                    <thead className="thead-custom">
                                        <tr>
                                            <th style={{ width: '15%' }}>Triggers</th>
                                            <th>Match</th>
                                            <th style={{ width: '15%' }}></th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        {entry.rules.map((rule) => (
                                            <tr key={rule.id}>
                                                <td>{[ rule.onNew ? 'On New' : null, rule.onAddressValid ? 'On Address Valid' : null].filter(t => !!t).join(', ')}</td>
                                                <td>

                                                    <dl className="row mb-0">

                                                        {rule.shippingName &&
                                                            <React.Fragment>
                                                                <dt className="col-3 font-weight-normal">Shipping Name</dt>
                                                                <dd className="col-9">{rule.shippingName}</dd>
                                                            </React.Fragment>
                                                        }

                                                        {rule.shippingCompany &&
                                                            <React.Fragment>
                                                                <dt className="col-3 font-weight-normal">Shipping Company</dt>
                                                                <dd className="col-9">{rule.shippingCompany}</dd>
                                                            </React.Fragment>
                                                        }

                                                        {rule.shippingBuilding &&
                                                            <React.Fragment>
                                                                <dt className="col-3 font-weight-normal">Shipping Building</dt>
                                                                <dd className="col-9">{rule.shippingBuilding}</dd>
                                                            </React.Fragment>
                                                        }

                                                        {rule.shippingAddress1 &&
                                                            <React.Fragment>
                                                                <dt className="col-3 font-weight-normal">Shipping Address 1</dt>
                                                                <dd className="col-9">{rule.shippingAddress1}</dd>
                                                            </React.Fragment>
                                                        }

                                                        {rule.shippingAddress2 &&
                                                            <React.Fragment>
                                                                <dt className="col-3 font-weight-normal">Shipping Address 2</dt>
                                                                <dd className="col-9">{rule.shippingAddress2}</dd>
                                                            </React.Fragment>
                                                        }

                                                        {rule.shippingCity &&
                                                            <React.Fragment>
                                                                <dt className="col-3 font-weight-normal">Shipping City</dt>
                                                                <dd className="col-9">{rule.shippingCity}</dd>
                                                            </React.Fragment>
                                                        }

                                                        {rule.shippingState &&
                                                            <React.Fragment>
                                                                <dt className="col-3 font-weight-normal">Shipping State</dt>
                                                                <dd className="col-9">{rule.shippingState}</dd>
                                                            </React.Fragment>
                                                        }

                                                        {rule.shippingPostCode &&
                                                            <React.Fragment>
                                                                <dt className="col-3 font-weight-normal">Shipping Post Code</dt>
                                                                <dd className="col-9">{rule.shippingPostCode}</dd>
                                                            </React.Fragment>
                                                        }

                                                        {rule.shippingCountry &&
                                                            <React.Fragment>
                                                                <dt className="col-3 font-weight-normal">Shipping Country</dt>
                                                                <dd className="col-9">{rule.shippingCountry.name}</dd>
                                                            </React.Fragment>
                                                        }

                                                        {rule.shippingPhoneNumber &&
                                                            <React.Fragment>
                                                                <dt className="col-3 font-weight-normal">Shipping Phone Number</dt>
                                                                <dd className="col-9">{rule.shippingPhoneNumber}</dd>
                                                            </React.Fragment>
                                                        }

                                                        {rule.shippingEmail &&
                                                            <React.Fragment>
                                                                <dt className="col-3 font-weight-normal">Shipping Email</dt>
                                                                <dd className="col-9">{rule.shippingEmail}</dd>
                                                            </React.Fragment>
                                                        }

                                                        {rule.creditCardMask &&
                                                            <React.Fragment>
                                                                <dt className="col-3 font-weight-normal">Credit Card Mask</dt>
                                                                <dd className="col-9">{rule.creditCardMask}</dd>
                                                            </React.Fragment>
                                                        }

                                                        {rule.creditCardExpiry &&
                                                            <React.Fragment>
                                                                <dt className="col-3 font-weight-normal">Credit Card Expiry</dt>
                                                                <dd className="col-9">{rule.creditCardExpiry}</dd>
                                                            </React.Fragment>
                                                        }

                                                        {rule.ipAddressMask &&
                                                            <React.Fragment>
                                                                <dt className="col-3 font-weight-normal">IP Address Mask</dt>
                                                                <dd className="col-9">{rule.ipAddressMask}</dd>
                                                            </React.Fragment>
                                                        }

                                                        {rule.customerNumber &&
                                                            <React.Fragment>
                                                                <dt className="col-3 font-weight-normal">Customer Number</dt>
                                                                <dd className="col-9">{rule.customerNumber}</dd>
                                                            </React.Fragment>
                                                        }

                                                    </dl>



                                                </td>

                                                <td className="text-right">
                                                    <button type="button" className="btn btn-sm btn-primary" disabled={isSaving} onClick={() => setPendingEditRule(rule)}>Edit</button>
                                                    {' '}
                                                    <button type="button" className="btn btn-sm btn-danger" disabled={isSaving} onClick={() => setPendingDeleteRule(rule)}>Delete</button>
                                                </td>
                                            </tr>
                                        ))}

                                        {!entry.rules.any() &&
                                            <tr>
                                                <td colSpan={3}>No rules</td>
                                            </tr>
                                        }
                                    </tbody>

                                </table>

                            </div>
                        </div>

                    </div>

                </div>

                {isAddRulePending &&
                    <BlacklistEntryRuleModal onSuccess={handleAddRuleSuccess} onCancel={() => setIsAddRulePending(false)} />
                }

                {!!pendingEditRule &&
                    <BlacklistEntryRuleModal rule={pendingEditRule} onSuccess={handleEditRuleSuccess} onCancel={() => setPendingEditRule(null)} />
                }

                {!!pendingDeleteRule &&
                    <Modal show={true} centered onHide={() =>setPendingDeleteRule(null)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Delete Rule</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure you want to delete this rule?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-secondary" onClick={() => setPendingDeleteRule(null)}>No</button>
                            <button type="button" className="btn btn-danger" onClick={handleDeleteRuleConfirm}>Delete Rule</button>
                        </Modal.Footer>

                    </Modal>
                }

                {isConfirmingDelete &&
                    <Modal show={true} centered onHide={() =>setIsConfirmingDelete(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Delete Entry</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure you want to delete this entry?</p>

                            <div className="alert alert-danger small p-2"><FontAwesomeIcon icon={[ 'fas', 'exclamation-triangle' ]} fixedWidth /> In most cases, marking the entry as inactive is preferred.</div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-secondary" onClick={() => setIsConfirmingDelete(false)}>No</button>
                            {' '}
                            <button type="button" className="btn btn-danger" onClick={() => setIsDeleting(true)} disabled={isDeleting}>
                                {isDeleting &&
                                    <span className="spinner-border spinner-border-sm"></span>
                                }
                                {' '}
                                Delete Entry
                            </button>
                        </Modal.Footer>

                    </Modal>
                }

            </div>


        </React.Fragment>
        
    );

}

BlacklistEntry.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

export default withRouter(BlacklistEntry);