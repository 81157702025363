import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import { useForm } from 'react-hook-form';
import cx from 'classnames';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import { tryPut as put, tryPost as post, ApiValidationError } from 'lib/api';
import withFormHelpers from 'lib/formHelpers';
import { CancellationToken } from 'lib/cancellationTokens';

export default function SuppliersCreateOrUpdateModal(props) {

    let { addToast } = useToasts();

    let [ formData, setFormData ] = useState(null);

    let form = withFormHelpers(useForm({ defaultValues: props.supplier, mode: 'onBlur' }));


    useEffect(() => {

        if (!formData) return;

        let cancellationToken = new CancellationToken();

        (!!props.supplier
            ? post(`/api/inventory/suppliers/${props.supplier.id}`, formData)
            : put('/api/inventory/suppliers', formData))
    
            .then(([ response, ex ]) => {

                if (cancellationToken.isCancelled) {
                    return;
                }

                if (ex) {
    
                    setFormData(null);
                    
                    if (ex instanceof ApiValidationError) {
                        form.setApiValidationErrors(ex.validationErrors);
                        return;
                    }
    
                    addToast(ex.message, { appearance: 'error' });
                    return;
        
                }

                setFormData(null);

                props.onSuccess(response);
    
                let toastMsg = !!props.supplier ? 'Supplier updated' : 'Supplier added';

                addToast(toastMsg, { appearance: 'success' });

            });
            
        return cancellationToken;

    }, [ formData ]);



    function handleSubmit(formData) {
        setFormData(formData);
    }


    return (

        <Modal show={true} centered onHide={props.onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {!props.supplier &&
                        <React.Fragment>Add Supplier</React.Fragment>
                    }
                    {!!props.supplier &&
                        <React.Fragment>Edit {props.supplier.name}</React.Fragment>
                    }
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <form onSubmit={form.handleSubmit(handleSubmit)}>

                    <fieldset disabled={!!formData}>

                        <div className="form-group">

                            <label>Name</label>

                            <input name="name" type="text" ref={form.register({ required: true })} className={cx('form-control', form.validationClassName('name'))} />

                            {form.hasErrors('name') &&
                                <div className="invalid-feedback">{form.errorMessage('name', 'Please enter a valid name.')}</div>
                            }

                        </div>

                        <div className="form-group">

                            <label>Currency</label>

                            <input name="currency" type="text" ref={form.register({ required: true })} className={cx('form-control', form.validationClassName('currency'))} />

                            {form.hasErrors('currency') &&
                                <div className="invalid-feedback">{form.errorMessage('name', 'Please enter a valid currency.')}</div>
                            }

                            <small class="form-text text-muted">The currency you pay this supplier in (three digit code, eg.. NZD, AUD, USD).</small>

                        </div>

                        <div className="form-group">
                            <Form.Check name="isGstPayable" id="isGstPayable" type="checkbox" ref={form.register} label="GST Payable" custom />

                            <small class="form-text text-muted">For the vast majority NZ companies this will be true.</small>
                        </div>

                        

                        <div className="form-group">
                            <Form.Check name="pricesIncludeGst" id="pricesIncludeGst" type="checkbox" ref={form.register} label="Prices Include GST" custom />

                            <small class="form-text text-muted">Any prices you load will be treated as GST inclusive (not recommended).</small>
                        </div>

                        <div className="form-group">
                            <Form.Check name="useLastPrice" id="useLastPrice" type="checkbox" ref={form.register} label="Use Last Price" custom />

                            <small class="form-text text-muted">When ordering, use the last price paid (rather than a fixed price).</small>
                        </div>

                    </fieldset>

                </form>

            </Modal.Body>

            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={props.onCancel}>Cancel</button>

                <button type="button" className="btn btn-primary" onClick={form.handleSubmit(handleSubmit)} disabled={!!formData}>
                    {formData &&
                        <span className="spinner-border spinner-border-sm"></span>
                    }
                    {' '}
                    <React.Fragment>
                        {!props.supplier &&
                            <React.Fragment>Add Supplier</React.Fragment>
                        }
                        {!!props.supplier &&
                            <React.Fragment>Update Supplier</React.Fragment>
                        }
                    </React.Fragment>
                </button>

            </Modal.Footer>
        </Modal>

    );

}

SuppliersCreateOrUpdateModal.propTypes = {
    supplier: PropTypes.object,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};