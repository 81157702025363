import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import cx from 'classnames';

import { put, ApiValidationError } from 'lib/api';
import withFormHelpers from 'lib/formHelpers';
import { CancellationToken } from 'lib/cancellationTokens';

export default function SettingsPassword(props) {

    let { addToast } = useToasts();

    let [ formData, setFormData ] = useState(null);

    let form = withFormHelpers(useForm({ mode: 'onBlur' }));

    useEffect(() => {

        if (!formData) {
            return;
        }

        let cancellationToken = new CancellationToken();

        async function asyncRequest() {

            try {
                await put('/api/me/password', formData, cancellationToken);
            }
            catch (ex) {
    
                if (cancellationToken.isCancelled) {
                    return;
                }
                
                setFormData(null);

                if (ex instanceof ApiValidationError) {
                    form.setApiValidationErrors(ex.validationErrors);
                    return;
                }

                addToast(ex.message, { appearance: 'error' });
                return;
    
            }

            setFormData(null);

            form.reset();

            addToast('Your password has been changed.', { appearance: 'success' });
            
        }

        asyncRequest();

        return cancellationToken;

    }, [ formData ]);

    function handleFormSubmit(formData) {
        setFormData(formData);
    }

    return (

        <div className="mt-3 col-3">

            <h3><FontAwesomeIcon icon={[ 'fas', 'key' ]} fixedWidth /> Change Password</h3>

            <p>Please enter your new password twice to confirm.</p>

            <form onSubmit={form.handleSubmit(handleFormSubmit)}>

                <fieldset disabled={!!formData}>

                    <div className="form-group">

                        <label>New Password</label>

                        <div className="input-group">

                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <FontAwesomeIcon icon={[ 'fas', 'key' ]} />
                                </span>
                            </div>

                            <input name="newPassword" type="password" autoFocus={true} ref={form.register({ required: true })} onBlur={() => { if (form.getValues('newPasswordConfirm')) form.trigger('newPasswordConfirm'); }} className={cx('form-control', form.validationClassName('newPassword'))} />

                            {form.hasErrors('newPassword') &&
                                <div className="invalid-feedback">{form.errorMessage('newPassword', 'Please enter a valid password.')}</div>
                            }

                        </div>

                    </div>

                    <div className="form-group">

                        <label>Confirm Password</label>

                        <div className="input-group">

                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <FontAwesomeIcon icon={[ 'fas', 'check' ]} />
                                </span>
                            </div>

                            <input name="newPasswordConfirm" type="password" ref={form.register({ required: true, validate: (value) => !form.getValues('newPassword') || value == form.getValues('newPassword') })} className={cx('form-control', form.validationClassName('newPasswordConfirm'))} />

                            {form.hasErrors('newPasswordConfirm') &&
                                <div className="invalid-feedback">{form.errorMessage('newPasswordConfirm', 'Type the same password twice to confirm.')}</div>
                            }

                        </div>

                    </div>

                    <div className="form-group">
                        <Form.Check name="endSessions" id="end_sessions" type="checkbox" ref={form.register} label="Logout all other computers and devices" custom />
                    </div>

                    <hr />
                    
                    <div className="form-group">

                        <label>Current Password</label>

                        <div className="input-group">

                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <FontAwesomeIcon icon={[ 'fas', 'lock' ]} />
                                </span>
                            </div>

                            <input name="password" type="password" ref={form.register({ required: true })} className={cx( 'form-control', { 'is-invalid': form.isInvalid('password')})} />

                            {form.hasErrors('password') &&
                                <div className="invalid-feedback">{form.errorMessage('password', 'Please enter your current password.')}</div>
                            }

                        </div>

                        <small className="form-text text-muted">Just to be sure it's you, please enter your current password.</small>

                    </div>
                    
                    <div className="form-group mt-4">
                        <button type="submit" className="btn btn-primary">
                            {formData &&
                                <span className="spinner-border spinner-border-sm"></span>
                            }
                            {' '}
                            Change Password
                        </button>
                    </div>

                </fieldset>

            </form>

        </div>

    );

}
