import { useEffect } from 'react';

import { tryGet as get } from 'lib/api'
import { CancellationToken } from 'lib/cancellationTokens';

export function useApi(url, query, callback, inputs) {
    
    if (callback === undefined && inputs === undefined) {
        inputs = query;
        callback = null;
        query = null;
    }

    else if (inputs === undefined) {
        inputs = callback;
        callback = query;
        query = null;
    }

    useEffect(() => {

        let cancellationToken = new CancellationToken();

        get(url, query)
        
            .then(([ response, ex ]) => {

                if (cancellationToken.isCancelled) {
                    return;
                }

                callback(response, ex);

            });

        return cancellationToken;

    }, inputs);

}