import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToasts } from 'react-toast-notifications';
import cx from 'classnames';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import qs from 'query-string';

import { useApi } from 'lib/effects';
import { tryGet as get, tryPost as post, tryPut as put, tryDel as del } from 'lib/api';
import { CancellationToken } from 'lib/cancellationTokens';

import LoadingBar from 'components/LoadingBar';
import JobStatus from 'components/JobStatus';
import LogsTable from 'components/LogsTable';
import JobChildModal from 'components/JobChildModal';
import JobsUnconsolidateModal from 'components/JobsUnconsolidateModal';
import JobsSnoozeModal from 'components/JobsSnoozeModal';
import JobsUnsnoozeModal from 'components/JobsUnsnoozeModal';
import JobsHoldModal from 'components/JobsHoldModal';
import JobsUnholdModal from 'components/JobsUnholdModal';
import JobsResolveModal from 'components/JobsResolveModal';
import JobsTakeOutOfStanddownModal from 'components/JobsTakeOutOfStanddownModal';
import JobShippingAddressModal from 'components/JobShippingAddressModal';
import JobBillingAddressModal from 'components/JobBillingAddressModal';
import JobPaymentModal from 'components/JobPaymentModal';
import ForgiveUnpaidShippingModal from 'components/ForgiveUnpaidShippingModal';
import JobAddTrackingInfoModal from 'components/JobAddTrackingInfoModal';
import JobDeleteModal from 'components/JobDeleteModal';
import JobUnverifyPaymentModal from 'components/JobUnverifyPaymentModal';
import JobUndeleteModal from 'components/JobUndeleteModal';
import JobCompleteModal from 'components/JobCompleteModal';
import JobUncompleteModal from 'components/JobUncompleteModal';
import JobsPickupModal from 'components/JobsPickupModal';
import JobsDeliverModal from 'components/JobsDeliverModal';
import JobsCollectModal from 'components/JobsCollectModal';
import JobRefetchItemsModal from 'components/JobRefetchItemsModal';

function Job(props) {

    let { addToast } = useToasts();

    let adminNotesInputRef = useRef(null);
    let packingSlipNotesInputRef = useRef(null);
    let shippingMethodInputRef = useRef(null);
    let authorityToLeaveInputRef = useRef(null);
    let ruralDeliveryInputRef = useRef(null);
    let saturdayDeliveryInputRef = useRef(null);

    let [ shippingMethodOptions, setShippingMethodOptions ] = useState(null);

    let [ isLoading, setIsLoading ] = useState(true);

    let [ job, setJob ] = useState(null);
    let [ logs, setLogs ] = useState([]);

    let [ childJobOpened, setChildJobOpened ] = useState(null);

    let [ isUnconsolidatePending, setIsUnconsolidatePending ] = useState(false);
    let [ isSnoozePending, setIsSnoozePending ] = useState(false);
    let [ isUnsnoozePending, setIsUnsnoozePending ] = useState(false);
    let [ isHoldPending, setIsHoldPending ] = useState(false);
    let [ isUnholdPending, setIsUnholdPending ] = useState(false);
    let [ isResolvePending, setIsResolvePending ] = useState(false);
    let [ isTakeOutOfStanddownPending, setIsTakeOutOfStanddownPending ] = useState(false);
    let [ isDispatchPending, setIsDispatchPending ] = useState(false);
    let [ isUndispatchPending, setIsUndispatchPending ] = useState(false);
    let [ isReprintPending, setIsReprintPending ] = useState(false);

    let [ isPickupPending, setIsPickupPending ] = useState(false);
    let [ isDeliverPending, setIsDeliverPending ] = useState(false);
    let [ isCollectPending, setIsCollectPending ] = useState(false);

    let [ isAdminNotesDirty, setIsAdminNotesDirty ] = useState(false);
    let [ isPackingSlipNotesDirty, setIsPackingSlipNotesDirty ] = useState(false);
    let [ isSavingAdminNotes, setIsSavingAdminNotes ] = useState(false);
    let [ isSavingPackingSlipNotes, setIsSavingPackingSlipNotes ] = useState(false);
    let [ isChangingShippingMethod, setIsChangingShippingMethod ] = useState(false);
    let [ isSavingShippingMethod, setIsSavingShippingMethod ] = useState(false);
    let [ isChangingShippingAddress, setIsChangingShippingAddress ] = useState(false);
    let [ isChangingBillingAddress, setIsChangingBillingAddress ] = useState(false);
    let [ isConfirmingForgive, setIsConfirmingForgive ] = useState(false);
    let [ isAddingTrackingInfo, setIsAddingTrackingInfo ] = useState(false);
    let [ isConfirmingUndelete, setIsConfirmingUndelete ] = useState(false);
    let [ isConfirmingComplete, setIsConfirmingComplete ] = useState(false);
    let [ isConfirmingUncomplete, setIsConfirmingUncomplete ] = useState(false);

    let [ pendingVerifyPaymentOperation, setPendingVerifyPaymentOperation ] = useState(null);
    let [ pendingUnverifyPaymentOperation, setPendingUnverifyPaymentOperation ] = useState(null);
    let [ pendingDelete, setPendingDelete ] = useState(null);
    let [ pendingSeparate, setPendingSeparate ] = useState(null);

    let [ file, setFile ] = useState(null);
    let [ isSubmitting, setIsSubmitting ] = useState(false);
    let [ isRemovingPrescription, setIsRemovingPrescription ] = useState(false);

    let [ isConfirmingRefetchItems, setIsConfirmingRefetchItems ] = useState(false);

    useApi('/api/fulfillment', (response, ex) => {

        if (ex) {
            addToast(ex.message, { appearance: 'error' });
            return;
        }

        setShippingMethodOptions(response.shippingMethodOptions);

    }, []);

    useEffect(() => {

        if (!isLoading) {
            return;
        }

        let cancellationToken = new CancellationToken();

        let pendingJob;
        let pendingLogs = [];

        function getJob(id) {

            get(`/api/fulfillment/jobs/${id}`)
        
                .then(([ response, ex ]) => {

                    if (cancellationToken.isCancelled) {
                        return;
                    }

                    if (ex) {
                        addToast(ex.message, { appearance: 'error' });
                        return;
                    }

                    pendingJob = response;

                    if (pendingJob.isChild) {
                        props.history.push(`/fulfillment/${pendingJob.parentId}`);
                        getJob(pendingJob.parentId);
                        return;
                    }
                    
                    getLogs(id, 1);

                });

        }

        function searchJobByOrderNumber(orderNumber) {

            post('/api/fulfillment/search', { parentOnly: false, orderNumber })
        
                .then(([ response, ex ]) => {

                    if (cancellationToken.isCancelled) {
                        return;
                    }

                    if (ex) {
                        addToast(ex.message, { appearance: 'error' });
                        return;
                    }

                    if (response.items.count() != 1) {
                        addToast('Could not find job using order number', { appearance: 'error' });
                        return;
                    }

                    pendingJob = response.items[0];

                    if (pendingJob.isChild) {
                        getJob(pendingJob.parentId);
                        return;
                    }
                    
                    getLogs(pendingJob.id, 1);

                });

        }

        function getLogs(id, page) {

            post('/api/logs/search', { jobId: id, page: page++ })
        
                .then(([ response, ex ]) => {

                    if (cancellationToken.isCancelled) {
                        return;
                    }

                    if (ex) {
                        addToast(ex.message, { appearance: 'error' });
                        return;
                    }

                    pendingLogs = pendingLogs.concat(response.items);

                    if (page > response.totalPages) {
                        complete();
                        return;
                    }

                    getLogs(id, page);

                });
    
            }

        function complete() {

            if (props.match.params.id != pendingJob.id) {
                props.history.push(`/fulfillment/${pendingJob.id}`);
            }

            setIsLoading(false);
            setJob(pendingJob);
            setLogs(pendingLogs);
        }

        let orderNumberMatch = props.match.params.id.match(/^order(\w+)$/);

        if (orderNumberMatch) {
            searchJobByOrderNumber(orderNumberMatch[1]);
        }
        else {
            getJob(props.match.params.id);
        }

        return cancellationToken;

    }, [ isLoading ]);

    useEffect(() => {

        if (!isSavingAdminNotes) {
            return;
        }

        let cancellationToken = new CancellationToken();

        let adminNotes = adminNotesInputRef.current.value || null;

        put(`/api/fulfillment/jobs/${job.id}/adminnotes`, adminNotes)
        
            .then(([ _, ex ]) => {

                if (cancellationToken.isCancelled) {
                    return;
                }

                setIsSavingAdminNotes(false);

                if (ex) {
                    addToast(ex.message, { appearance: 'error' });
                    return;
                }

                setIsAdminNotesDirty(false);
                setJob({
                    ...job,
                    adminNotes,
                }); //update job to prevent flash of old content issue
                setIsLoading(true);
                
                addToast('Admin notes saved.', { appearance: 'success' });

            });

        return cancellationToken;

    }, [ isSavingAdminNotes ]);

    useEffect(() => {

        if (!isSavingPackingSlipNotes) {
            return;
        }

        let cancellationToken = new CancellationToken();

        let packingSlipNotes = packingSlipNotesInputRef.current.value || null;

        put(`/api/fulfillment/jobs/${job.id}/packingslipnotes`, packingSlipNotes)
        
            .then(([ _, ex ]) => {

                if (cancellationToken.isCancelled) {
                    return;
                }

                setIsSavingPackingSlipNotes(false);

                if (ex) {
                    addToast(ex.message, { appearance: 'error' });
                    return;
                }

                setIsPackingSlipNotesDirty(false);
                setJob({
                    ...job,
                    packingSlipNotes,
                }); //update job to prevent flash of old content issue
                setIsLoading(true);
                
                addToast('Packing slip notes saved.', { appearance: 'success' });

            });

        return cancellationToken;

    }, [ isSavingPackingSlipNotes ]);

    useEffect(() => {

        if (!isSavingShippingMethod) {
            return;
        }

        let cancellationToken = new CancellationToken();

        let newShippingMethod = shippingMethodOptions.find(o => o.id == shippingMethodInputRef.current.value);

        let request = {
            shippingMethodId: newShippingMethod.id,
            shippingMethodName: newShippingMethod.name,
            isAuthorityToLeave: authorityToLeaveInputRef.current.checked,
            isRuralDelivery: ruralDeliveryInputRef.current.checked,
            isSaturdayDelivery: saturdayDeliveryInputRef.current.checked,
        };

        put(`/api/fulfillment/jobs/${job.id}/shippingmethod`, request)
        
            .then(([ _, ex ]) => {

                if (cancellationToken.isCancelled) {
                    return;
                }

                setIsSavingShippingMethod(false);

                if (ex) {
                    addToast(ex.message, { appearance: 'error' });
                    return;
                }

                setIsChangingShippingMethod(false);
                setJob({
                    ...job,
                    ...request,
                }); //update job to prevent flash of old content issue
                setIsLoading(true);
                
                addToast('Shipping method changed.', { appearance: 'success' });

            });

        return cancellationToken;

    }, [ isSavingShippingMethod ]);

    useEffect(() => {

        if (!isDispatchPending) {
            return;
        }

        let cancellationToken = new CancellationToken();

        put(`/api/fulfillment/jobs/${job.id}/dispatch`, [ job.id ])
        
            .then(([ response, ex ]) => {

                if (cancellationToken.isCancelled) {
                    return;
                }

                setIsDispatchPending(false);

                if (ex) {
                    addToast(ex.message, { appearance: 'error' });
                    return;
                }

                let printerAppUrl = qs.stringifyUrl({ 
                    url: 'fulfillv2://print', 
                    query: { type: 'Packing Slip', url: response.fileUrl },
                })
    
                let printFrame = document.createElement('iframe');
                printFrame.src = printerAppUrl;
                printFrame.style = 'display: none;'
                document.body.appendChild(printFrame);

                addToast(`Job dispatched.`, { appearance: 'success' });

                setIsLoading(true);

            });

        return cancellationToken;

    }, [ isDispatchPending ]);

    useEffect(() => {

        if (!isReprintPending) {
            return;
        }

        let cancellationToken = new CancellationToken();

        put('/api/fulfillment/reprint', [ job.id ])
        
            .then(([ response, ex ]) => {

                if (cancellationToken.isCancelled) {
                    return;
                }

                setIsReprintPending(false);

                if (ex) {
                    addToast(ex.message, { appearance: 'error' });
                    return;
                }

                let printerAppUrl = qs.stringifyUrl({ 
                    url: 'fulfillv2://print', 
                    query: { type: 'Packing Slip', url: response.fileUrl },
                })
    
                let printFrame = document.createElement('iframe');
                printFrame.src = printerAppUrl;
                printFrame.style = 'display: none;'
                document.body.appendChild(printFrame);

                addToast(`Packing slip reprinted.`, { appearance: 'success' });

            });

        return cancellationToken;

    }, [ isReprintPending ]);

    useEffect(() => {

        if (!isUndispatchPending) {
            return;
        }

        let cancellationToken = new CancellationToken();

        put(`/api/fulfillment/jobs/${job.id}/undispatch`)
        
            .then(([ _, ex ]) => {

                if (cancellationToken.isCancelled) {
                    return;
                }

                setIsUndispatchPending(false);

                if (ex) {
                    addToast(ex.message, { appearance: 'error' });
                    return;
                }

                setIsLoading(true);
                
                addToast('Job undispatched.', { appearance: 'success' });

            });

        return cancellationToken;

    }, [ isUndispatchPending ]);

    function handleUnconsolidateSuccess() {
        setIsUnconsolidatePending(false);
        setIsLoading(true);
    }

    function handleSnoozeSuccess() {
        setIsSnoozePending(false);
        setIsLoading(true);
    }

    function handleUnsnoozeSuccess() {
        setIsUnsnoozePending(false);
        setIsLoading(true);
    }

    function handleHoldSuccess() {
        setIsHoldPending(false);
        setIsLoading(true);
    }

    function handleUnholdSuccess() {
        setIsUnholdPending(false);
        setIsLoading(true);
    }

    function handleResolveSuccess() {
        setIsResolvePending(false);
        setIsLoading(true);
    }

    function handleTakeOutOfStanddownSuccess() {
        setIsTakeOutOfStanddownPending(false);
        setIsLoading(true);
    }

    function handleBillingAddressChangeSuccess() {
        setIsChangingBillingAddress(false);
        setIsLoading(true);
    }

    function handleShippingAddressChangeSuccess() {
        setIsChangingShippingAddress(false);
        setIsLoading(true);
    }

    function handleVerifyPaymentSuccess() {
        setPendingVerifyPaymentOperation(null);
        setIsLoading(true);
    }

    function handleUnverifyPaymentSuccess() {
        setPendingUnverifyPaymentOperation(null);
        setIsLoading(true);
    }

    function handleForgiveSuccess() {
        setIsConfirmingForgive(false);
        setIsLoading(true);
    }

    function handleDeleteSuccess() {
        setPendingDelete(null);
        setIsLoading(true);
    }

    function handleAddTrackingInfoSuccess() {
        setIsAddingTrackingInfo(false);
        setIsLoading(true);
    }

    function handleSeparateSuccess() {
        setPendingSeparate(null);
        setIsLoading(true);
    }

    function handleUndeleteSuccess() {
        setIsConfirmingUndelete(false);
        setIsLoading(true);
    }

    function handleCompleteSuccess() {
        setIsConfirmingComplete(false);
        setIsLoading(true);
    }

    function handleUncompleteSuccess() {
        setIsConfirmingUncomplete(false);
        setIsLoading(true);
    }

    function handlePickupSuccess() {
        setIsPickupPending(false);
        setIsLoading(true);
    }

    function handleDeliverSuccess() {
        setIsDeliverPending(false);
        setIsLoading(true);
    }

    function handleCollectSuccess() {
        setIsCollectPending(false);
        setIsLoading(true);
    }

    function handleRefetchItemsSuccess() {
        setIsConfirmingRefetchItems(false);
        setIsLoading(true);
    }

    function handleFileSelected(event) {

        let file = event.target.files[0];

        setFile(file);

        let reader = new FileReader();

        reader.onload = (ev) => {

            file.asBase64 = ev.target.result.match('(?:data:(?:.*);base64,)(.+)')[1];

            setIsSubmitting(true);
        };

        reader.readAsDataURL(file);

    }

    useEffect(() => {

        if (!isSubmitting) {
            return;
        }

        let cancellationToken = new CancellationToken();

        let request = {
            name: file.name,
            payloadBase64: file.asBase64,
        };
        
        post(`/api/fulfillment/jobs/${job.id}/prescription`, request)
        
            .then(([ _, ex ]) => {

                if (cancellationToken.isCancelled) {
                    return;
                }

                setIsSubmitting(false);

                if (ex) {
                    addToast(ex.message, { appearance: 'error' });
                    return;
                }

                setIsLoading(true);

                addToast('Prescription added', { appearance: 'success' });


            });

        return cancellationToken;

    }, [ isSubmitting ]);

    useEffect(() => {

        if (!isRemovingPrescription) {
            return;
        }

        let cancellationToken = new CancellationToken();

        del(`/api/fulfillment/jobs/${job.id}/prescription`)
        
            .then(([ _, ex ]) => {

                if (cancellationToken.isCancelled) {
                    return;
                }

                setIsRemovingPrescription(false);

                if (ex) {
                    addToast(ex.message, { appearance: 'error' });
                    return;
                }

                setIsLoading(true);

                addToast('Prescription removed', { appearance: 'success' });

            });

        return cancellationToken;

    }, [ isRemovingPrescription ]);

    if (!job) {
        return (
            <LoadingBar />
        );
    }

    return (

        <React.Fragment>

            {(isLoading || isDispatchPending || isUndispatchPending || isReprintPending) &&
                <LoadingBar />
            }

            <div className="mt-3 container-fluid">

                <div className="float-right">
                    
                    <Button variant="success" disabled={job.isDispatched} onClick={() => setIsDispatchPending(true)}><FontAwesomeIcon icon={[ 'fas', 'arrow-alt-circle-right' ]} fixedWidth /> Dispatch</Button>
                    {' '}
                    <DropdownButton as="span" title="Actions">
                        <Dropdown.Item disabled={!job.isBlockedByUnresolvedIssues} onClick={() => setIsResolvePending(true)}><FontAwesomeIcon icon={['fas', 'check']} fixedWidth /> Resolve Issues</Dropdown.Item>
                        <Dropdown.Item disabled={job.isPaid} onClick={() => setPendingVerifyPaymentOperation(job)}><FontAwesomeIcon icon={['fas', 'dollar-sign']} fixedWidth /> Verify Payment</Dropdown.Item>
                        <Dropdown.Item disabled={job.isDispatched || !job.isPaid} onClick={() => setPendingUnverifyPaymentOperation(job)}><FontAwesomeIcon icon={['fas', 'hand-holding-usd']} fixedWidth /> Unverify Payment</Dropdown.Item>
                        <Dropdown.Item disabled={!job.isBlockedByUnpaidShipping} onClick={() => setIsConfirmingForgive(true)}><FontAwesomeIcon icon={['fas', 'pray']} fixedWidth /> Forgive Unpaid Shipping</Dropdown.Item>
                        <Dropdown.Item disabled={!job.isInStanddown} onClick={() => setIsTakeOutOfStanddownPending(true)}><FontAwesomeIcon icon={['fas', 'hourglass-end']} fixedWidth /> Take Out Of Standdown</Dropdown.Item>
                        <Dropdown.Item disabled={job.isDispatched || job.isSnoozed} onClick={() => setIsSnoozePending(true)}><FontAwesomeIcon icon={['fas', 'moon']} fixedWidth /> Snooze Job</Dropdown.Item>
                        <Dropdown.Item disabled={!job.isSnoozed} onClick={() => setIsUnsnoozePending(true)}><FontAwesomeIcon icon={['fas', 'sun']} fixedWidth /> Unsnooze Job</Dropdown.Item>
                        <Dropdown.Item disabled={job.isTracked || job.isOnHold} onClick={() => setIsHoldPending(true)}><FontAwesomeIcon icon={['fas', 'hand-paper']} fixedWidth /> Put Job On Hold</Dropdown.Item>
                        <Dropdown.Item disabled={!job.isOnHold} onClick={() => setIsUnholdPending(true)}><FontAwesomeIcon icon={['far', 'hand-paper']} fixedWidth /> Take Job Off Hold</Dropdown.Item>
                        <Dropdown.Item disabled={!job.isDispatched} onClick={() => setIsReprintPending(true)}><FontAwesomeIcon icon={[ 'fas', 'print' ]} fixedWidth /> Reprint Packing Slip</Dropdown.Item>
                        <Dropdown.Item disabled={!job.children.any()} onClick={() => setIsUnconsolidatePending(true)}><FontAwesomeIcon icon={['fas', 'minus']} fixedWidth /> Unconsolidate Job</Dropdown.Item>
                        <Dropdown.Item disabled={!job.isDispatched} onClick={() => setIsUndispatchPending(true)}><FontAwesomeIcon icon={[ 'far', 'arrow-alt-circle-left' ]} fixedWidth /> Undispatch Job</Dropdown.Item>
                        <Dropdown.Item disabled={!job.isDispatched || job.isTracked} onClick={() => setIsAddingTrackingInfo(true)}><FontAwesomeIcon icon={[ 'fas', 'tape' ]} fixedWidth /> Add Tracking Info</Dropdown.Item>
                        <Dropdown.Item disabled={job.isDeleted || job.isDispatched} onClick={() => setPendingDelete(job)}><FontAwesomeIcon icon={[ 'fas', 'trash-alt' ]} fixedWidth /> Delete Job</Dropdown.Item>
                        <Dropdown.Item disabled={!job.isDeleted} onClick={() => setIsConfirmingUndelete(true)}><FontAwesomeIcon icon={[ 'fas', 'trash-restore-alt' ]} fixedWidth /> Undelete Job</Dropdown.Item>
                        <Dropdown.Item disabled={!job.isNonShippable || job.isCompleted} onClick={() => setIsConfirmingComplete(true)}><FontAwesomeIcon icon={[ 'fas', 'check-square' ]} fixedWidth /> Complete Job</Dropdown.Item>
                        <Dropdown.Item disabled={!job.isCompleted} onClick={() => setIsConfirmingUncomplete(true)}><FontAwesomeIcon icon={[ 'fas', 'window-close' ]} fixedWidth /> Uncomplete Job</Dropdown.Item>
                        
                        <Dropdown.Item disabled={job.isNonShippable || !job.isTracked || job.isPickedUp} onClick={() => setIsPickupPending(true)}><FontAwesomeIcon icon={['fas', 'truck']} fixedWidth /> Mark As Picked Up</Dropdown.Item>
                        <Dropdown.Item disabled={job.isNonShippable || !job.isPickedUp || job.isDelivered} onClick={() => setIsDeliverPending(true)}><FontAwesomeIcon icon={['fas', 'store']} fixedWidth /> Mark As Delivered</Dropdown.Item>
                        <Dropdown.Item disabled={job.isNonShippable || !job.shippingMethod.isClickAndCollect || !job.isDelivered || job.isCollected} onClick={() => setIsCollectPending(true)}><FontAwesomeIcon icon={['fas', 'handshake']} fixedWidth /> Mark As Collected</Dropdown.Item>

                        <Dropdown.Divider />
                        <Dropdown.Item onClick={() => setIsConfirmingRefetchItems(true)}><FontAwesomeIcon icon={[ 'fas', 'sync' ]} fixedWidth /> Refetch Order Items</Dropdown.Item>

                        <Dropdown.Item target="_blank" href={job.orderUrl}><FontAwesomeIcon icon={[ 'fas', 'link' ]} fixedWidth /> View Order at {job.storeName}</Dropdown.Item>

                        {!!job.customerUrl &&
                            <Dropdown.Item target="_blank" href={job.customerUrl}><FontAwesomeIcon icon={[ 'fas', 'link' ]} fixedWidth /> View Customer at {job.storeName}</Dropdown.Item>
                        }
                        
                        {!!job.dearSaleUrl &&
                            <Dropdown.Item target="_blank" href={job.dearSaleUrl}><FontAwesomeIcon icon={[ 'fas', 'boxes' ]} fixedWidth /> View Sale In DEAR Inventory</Dropdown.Item>
                        }
                    </DropdownButton>
                 
                </div>

                <div className="mb-1"><Link className="text-reset text-decoration-none" to="/fulfillment"><FontAwesomeIcon icon={['fas', 'hard-hat']} fixedWidth /> Jobs</Link></div>

                <div className="d-flex align-items-center">

                    <h3 className="mb-0">Job #{job.orderNumber}</h3>
                        
                    <JobStatus job={job} className="ml-2" />

                </div>

                <hr />

                <div className="row">

                    <div className="col-3">

                        <div className="card card-custom mb-3">
                            <div className="card-header py-0 d-flex align-items-center justify-content-between">
                                <h5 style={{ margin: '0.75rem 0' }}>Admin Notes</h5>

                                {isAdminNotesDirty &&
                                    <div>
                                        <button type="button" className="btn btn-sm btn-danger" disabled={isSavingAdminNotes} onClick={() => setIsAdminNotesDirty(false)}>Cancel</button>
                                        {' '}
                                        <button type="button" className="btn btn-sm btn-success" disabled={isSavingAdminNotes} onClick={() => setIsSavingAdminNotes(true)}>
                                        {isSavingAdminNotes &&
                                            <span className="spinner-border spinner-border-sm"></span>
                                        }
                                        {' '}
                                        Save
                                        </button>
                                    </div>
                                }

                            </div>
                            <div className="card-body p-0">
                                {!isAdminNotesDirty &&
                                    <div className={cx('form-control-plaintext p-3', { 'text-muted': !job.adminNotes })} style={{ cursor: 'pointer' }} onClick={() => setIsAdminNotesDirty(true)}>{job.adminNotes || 'Admin notes can be entered here.'}</div>
                                }
                                {isAdminNotesDirty &&
                                    <textarea ref={adminNotesInputRef} autoFocus={true} defaultValue={job.adminNotes} disabled={isSavingAdminNotes} className="form-control border-0 p-3" />
                                }
                            </div>
                        </div>

                        {job.isDispatched &&

                            <div className="card card-custom mb-3">
                                <h5 className="card-header">Status</h5>
                                <div className="card-body">


                                    {!!job.trackingNumber &&
                                        <React.Fragment>

                                            <dl className="row mb-0">

                                                {!!job.starShipItOrderNumber &&
                                                    <React.Fragment>
                                                    <dt className="col-5">StarShipIt #</dt>
                                                    <dd className="col-7">{job.starShipItOrderNumber}</dd>
                                                    </React.Fragment>
                                                }

                                                {!!job.trackingNumber &&
                                                    <React.Fragment>
                                                        <dt className="col-5">Tracking #</dt>
                                                        <dd className="col-7"><a target="_blank" href={job.trackingUrl}>{job.trackingNumber}</a></dd>
                                                    </React.Fragment>
                                                }

                                                {job.ruralDeliveryNumber &&
                                                    <React.Fragment>
                                                        <dt className="col-5">RD #</dt>
                                                        <dd className="col-7">
                                                            {job.ruralDeliveryNumber}
                                                        </dd>
                                                    </React.Fragment>
                                                }

                                                {job.saturdayDeliveryNumber &&
                                                    <React.Fragment>
                                                        <dt className="col-5">Sat #</dt>
                                                        <dd className="col-7">
                                                            {job.saturdayDeliveryNumber}
                                                        </dd>
                                                    </React.Fragment>
                                                }

                                                {job.ageNumber &&
                                                    <React.Fragment>
                                                        <dt className="col-5">Age #</dt>
                                                        <dd className="col-7">
                                                            {job.ageNumber}
                                                        </dd>
                                                    </React.Fragment>
                                                }

                                            </dl>

                                            <hr />

                                        </React.Fragment>
                                    }

                                    <dl className="row mb-0 small">

                                        <dt className="col-5">Dispatched</dt>
                                        <dd className="col-7">{moment(job.dispatched).toDate().toLocaleString()}</dd>

                                        {job.isTracked &&
                                            <React.Fragment>
                                                <dt className="col-5">Packed</dt>
                                                <dd className="col-7">{moment(job.tracked).toDate().toLocaleString()}</dd>
                                            </React.Fragment>
                                        }

                                        {job.isPickedUp &&
                                            <React.Fragment>
                                                <dt className="col-5">Picked Up</dt>
                                                <dd className="col-7">{moment(job.pickedUp).toDate().toLocaleString()}</dd>
                                            </React.Fragment>
                                        }

                                        {job.isDelivered &&
                                            <React.Fragment>
                                                <dt className="col-5">Delivered</dt>
                                                <dd className="col-7">{moment(job.delivered).toDate().toLocaleString()}</dd>
                                            </React.Fragment>
                                        }

                                        {job.isCollected &&
                                            <React.Fragment>
                                                <dt className="col-5">Collected</dt>
                                                <dd className="col-7">{moment(job.collected).toDate().toLocaleString()}</dd>
                                            </React.Fragment>
                                        }

                                    </dl>

                                </div>
                            </div>

                        }

                        <div className="card card-custom mb-3">
                            <div className="card-header py-0 d-flex align-items-center justify-content-between">
                                <h5 style={{ margin: '0.75rem 0' }}>Shipping Details</h5>
                                {!job.isDispatched &&
                                    <button type="button" className="btn btn-sm btn-primary" onClick={() => setIsChangingShippingAddress(true)}>Change</button>
                                }
                            </div>
                            <div className="card-body">

                                {job.isDispatched && !job.hasShippingAddressChangedSinceDispatch &&
                                    <div className="alert alert-warning small p-2">Job dispatched, shipping details locked. <a className="alert-link" href="javascript: void(0);" onClick={() => setIsChangingShippingAddress(job.shippingAddress)}>Change for next time</a></div>
                                }

                                {job.isDispatched && job.hasShippingAddressChangedSinceDispatch &&
                                    <div className="alert alert-warning small p-2">Shipping details have been changed for next time. <a className="alert-link" href="javascript: void(0);" onClick={() => setIsChangingShippingAddress(job.shippingAddress)}>Review/Edit</a></div>
                                }
                                
                                <dl className="row mb-0">

                                    <dt className="col-4">Address</dt>
                                    <dd className="col-8">
                                        <span style={{ whiteSpace: 'pre-line' }}>
                                            {(job.shippedAddress || job.shippingAddress).addressFormatted}
                                        </span>
                                    </dd>

                                    <dt className="col-4">Email</dt>
                                    <dd className="col-8">{(job.shippedAddress || job.shippingAddress).email}</dd>

                                    <dt className="col-4">Phone</dt>
                                    <dd className="col-8">{(job.shippedAddress || job.shippingAddress).phoneNumber}</dd>

                                    <dt className="col-4">Instructions</dt>
                                    <dd className="col-8">{(job.shippedAddress || job.shippingAddress).instructions || 'n/a'}</dd>

                                </dl>

                            </div>
                        </div>

                        <div className="card card-custom mb-3">
                            <div className="card-header py-0 d-flex align-items-center justify-content-between">
                                <h5 style={{ margin: '0.75rem 0' }}>Shipping Method</h5>

                                {!job.isDispatched &&
                                
                                    <React.Fragment>

                                        {!isChangingShippingMethod &&
                                            <button type="button" className="btn btn-sm btn-primary" disabled={isSavingShippingMethod} onClick={() => setIsChangingShippingMethod(true)}>Change</button>
                                        }
                                        {isChangingShippingMethod &&
                                            <div>
                                                <button type="button" className="btn btn-sm btn-danger" disabled={isSavingShippingMethod} onClick={() => setIsChangingShippingMethod(false)}>Cancel</button>
                                                {' '}
                                                <button type="button" className="btn btn-sm btn-success" disabled={isSavingShippingMethod} onClick={() => setIsSavingShippingMethod(true)}>
                                                    {isSavingShippingMethod &&
                                                        <span className="spinner-border spinner-border-sm"></span>
                                                    }
                                                    {' '}
                                                    Save
                                                </button>
                                            </div>
                                        }

                                    </React.Fragment>

                                }

                            </div>
                            <div className="card-body">

                                {!isChangingShippingMethod &&

                                    <dl className="row mb-0">

                                        <dt className="col-5">Shipping Method</dt>
                                        <dd className="col-7">{job.shippingMethod.name}</dd>

                                        <dt className="col-5">Authority To Leave</dt>
                                        <dd className="col-7">
                                            <FontAwesomeIcon icon={['fas', job.isAuthorityToLeave ? 'check' : 'times']} fixedWidth />
                                        </dd>

                                        <dt className="col-5">Rural Delivery</dt>
                                        <dd className="col-7">
                                            <FontAwesomeIcon icon={['fas', job.isRuralDelivery ? 'check' : 'times']} fixedWidth />
                                        </dd>

                                        <dt className="col-5">Saturday Delivery</dt>
                                        <dd className="col-7">
                                            <FontAwesomeIcon icon={['fas', job.isSaturdayDelivery ? 'check' : 'times']} fixedWidth />
                                        </dd>

                                    </dl>

                                }

                                {isChangingShippingMethod &&

                                    <React.Fragment>

                                        <div class="form-group">
                                            <label>Shipping Method</label>
                                            <select ref={shippingMethodInputRef} className="form-control custom-select" defaultValue={job.shippingMethod.id} disabled={isSavingShippingMethod}>
                                                {shippingMethodOptions.map(m => (
                                                    <option value={m.id}>{m.name}</option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <Form.Check ref={authorityToLeaveInputRef} id="isAuthorityToLeave" type="checkbox" label="Authority To Leave" defaultChecked={job.isAuthorityToLeave} disabled={isSavingShippingMethod} custom />
                                        </div>

                                        <div className="form-group">
                                            <Form.Check ref={ruralDeliveryInputRef} id="isRuralDelivery" type="checkbox" label="Rural Delivery" defaultChecked={job.isRuralDelivery} disabled={isSavingShippingMethod} custom />
                                        </div>

                                        <div className="form-group">
                                            <Form.Check ref={saturdayDeliveryInputRef} id="isSaturdayDelivery" type="checkbox" label="Saturday Delivery" defaultChecked={job.isSaturdayDelivery} disabled={isSavingShippingMethod} custom />
                                        </div>

                                    </React.Fragment>

                                }

                            </div>
                        </div>

                        <div className="card card-custom mb-3">
                            <div className="card-header py-0 d-flex align-items-center justify-content-between">
                                <h5 style={{ margin: '0.75rem 0' }}>Packing Slip Notes</h5>

                                {!job.isDispatched && isPackingSlipNotesDirty &&
                                    <div>
                                        <button type="button" className="btn btn-sm btn-danger" disabled={isSavingPackingSlipNotes} onClick={() => setIsPackingSlipNotesDirty(false)}>Cancel</button>
                                        {' '}
                                        <button type="button" className="btn btn-sm btn-success" disabled={isSavingPackingSlipNotes} onClick={() => setIsSavingPackingSlipNotes(true)}>
                                        {isSavingPackingSlipNotes &&
                                            <span className="spinner-border spinner-border-sm"></span>
                                        }
                                        {' '}
                                        Save
                                        </button>
                                    </div>
                                }

                            </div>
                            <div className="card-body p-0">
                                {(job.isDispatched || !isPackingSlipNotesDirty) &&
                                    <div className={cx('form-control-plaintext p-3', { 'text-muted': !job.packingSlipNotes })} style={{ cursor: 'pointer' }} onClick={() => setIsPackingSlipNotesDirty(true)}>{job.packingSlipNotes || 'Notes to appear on the packing slip.'}</div>
                                }
                                {!job.isDispatched && isPackingSlipNotesDirty &&
                                    <textarea ref={packingSlipNotesInputRef} autoFocus={true} defaultValue={job.packingSlipNotes} disabled={isSavingPackingSlipNotes} className="form-control border-0 p-3" />
                                }
                            </div>
                        </div>

                        <div className="card card-custom mb-3">
                            <h5 className="card-header">Prescription</h5>
                            <div className="card-body">
                                {!job.prescriptionFile &&

                                    <div>

                                        <div className="form-group mb-0">
                                            <Form.File disabled={isSubmitting} onChange={handleFileSelected} label={file?.name ?? 'Upload prescription'} custom />
                                        </div>

                                    </div>
                                }

                                {!!job.prescriptionFile &&
                                    <div>

                                        <p><a href={job.prescriptionFile.url}>{job.prescriptionFile.name}</a></p>

                                        <div className="form-group mb-0">
                                            <button type="button" className="btn btn-sm btn-danger" onClick={() => setIsRemovingPrescription(true)} disabled={isRemovingPrescription}>
                                                {isRemovingPrescription &&
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                }
                                                {' '}
                                                Remove Prescription
                                            </button>
                                        </div>

                                    </div>

                                }
                            </div>
                        </div>

                        <div className="card card-custom mb-3">
                            <div className="card-header py-0 d-flex align-items-center justify-content-between">
                                <h5 style={{ margin: '0.75rem 0' }}>Billing Details</h5>
                                {!job.isBilled &&
                                    <button type="button" className="btn btn-sm btn-primary" onClick={() => setIsChangingBillingAddress(true)}>Change</button>
                                }
                            </div>
                            <div className="card-body">

                                {job.isDispatched && !job.hasBillingAddressChangedSinceDispatch &&
                                    <div className="alert alert-warning small p-2">Job dispatched, billing details locked. <a className="alert-link" href="javascript: void(0);" onClick={() => setIsChangingBillingAddress(job.billingAddress)}>Change for next time</a></div>
                                }

                                {job.isDispatched && job.hasBillingAddressChangedSinceDispatch &&
                                    <div className="alert alert-warning small p-2">Billing details have been changed for next time. <a className="alert-link" href="javascript: void(0);" onClick={() => setIsChangingBillingAddress(job.billingAddress)}>Review/Edit</a></div>
                                }

                                <dl className="row mb-0">

                                    <dt className="col-4">Address</dt>
                                    <dd className="col-8">
                                        <span style={{ whiteSpace: 'pre-line' }}>
                                            {(job.billedAddress || job.billingAddress).addressFormatted}
                                        </span>
                                    </dd>

                                    <dt className="col-4">Email</dt>
                                    <dd className="col-8">{(job.billedAddress || job.billingAddress).email}</dd>

                                    <dt className="col-4">Phone</dt>
                                    <dd className="col-8">{(job.billedAddress || job.billingAddress).phoneNumber}</dd>

                                </dl>

                            </div>
                        </div>

                    </div>

                    <div className="col-9">

                        {!job.children.any() &&
                        
                            <div className="card card-custom mb-3">
                                <h5 className="card-header">Order Details</h5>
                                <div className="card-body">

                                    <div className="row">

                                        <div className="col">

                                            <dl className="row mb-0">

                                                <dt className="col-4">Order #</dt>
                                                <dd className="col-8">{job.orderNumber}</dd>

                                                <dt className="col-4">Order Date</dt>
                                                <dd className="col-8">{moment(job.orderDate).toDate().toLocaleString()}</dd>

                                                <dt className="col-4">IP Address</dt>
                                                <dd className="col-8"><a target="_blank" href={`https://www.infobyip.com/ip-${job.ipAddress}.html`}>{job.ipAddress}</a></dd>

                                                <dt className="col-4">Comments</dt>
                                                <dd className="col-8">
                                                    {job.orderComments || 'n/a'}
                                                </dd>

                                            </dl>

                                        </div>

                                        <div className="col">

                                            <dl className="row mb-0">

                                                <dt className="col-4">Total</dt>
                                                <dd className="col-8">${job.total}</dd>

                                                <dt className="col-4">Payment Method</dt>
                                                <dd className="col-8">

                                                    {job.paymentMethod.name}
                                                    {!job.isPaid &&
                                                        <div className="ml-2 d-inline inline-badges">
                                                            <span className="badge badge-tvk-orange" title="Awaiting payment.">$ Pending</span>
                                                        </div>
                                                    }

                                                </dd>

                                                <dt className="col-4">Payment Ref.</dt>
                                                <dd className="col-8">{job.paymentReference || 'n/a'}</dd>

                                            </dl>

                                        </div>


                                    </div>

                                </div>
                            </div>

                        }

                        {job.children.any() &&
                            <div className="card card-custom mb-3">
                                <h5 className="card-header">Order Details</h5>
                                <div className="card-body p-0">


                                    <table className="table mb-0">
                                        <thead className="thead thead-custom">
                                            <tr>
                                                <th style={{ width: '10%' }}>Order #</th>
                                                <th>Comments</th>
                                                <th>IP Address</th>
                                                <th>Payment Method</th>
                                                <th>Payment Ref.</th>
                                                <th>Total</th>
                                                <th style={{ width: '15%' }}>Order Date</th>
                                                <th style={{ width: '1%' }}></th>
                                            </tr>
                                        </thead>

                                        <tbody>

                                            {[ job ].concat(job.children).map((child) => (

                                                <tr>
                                                    <td>
                                                        <a className="text-reset" href="javascript:void(0);" onClick={() => setChildJobOpened(child)}>{child.orderNumber}</a>
                                                    </td>
                                                    <td><div title={child.orderComments} style={{ maxWidth: '150px' }} className="text-truncate">{child.orderComments || 'n/a'}</div></td>
                                                    <td><a target="_blank" href={`https://www.infobyip.com/ip-${child.ipAddress}.html`}>{child.ipAddress}</a></td>
                                                    <td>
                                                        {child.paymentMethod.name}
                                                        {!child.isPaid &&
                                                            <div className="ml-2 d-inline inline-badges">
                                                                <span className="badge badge-tvk-orange" title="Awaiting payment.">$ Pending</span>
                                                            </div>
                                                        }
                                                    </td>
                                                    <td>{child.paymentReference || 'n/a'}</td>
                                                    <td>${child.total}</td>
                                                    <td>{moment(child.orderDate).toDate().toLocaleString()}</td>
                                                    <td className="py-0 align-middle">

                                                        <DropdownButton size="sm" as="span" title="Actions">
                                                            <Dropdown.Item disabled={child.isPaid} onClick={() => setPendingVerifyPaymentOperation(child)}><FontAwesomeIcon icon={['fas', 'dollar-sign']} fixedWidth /> Verify Payment</Dropdown.Item>
                                                            <Dropdown.Item disabled={child.isDispatched || !child.isPaid} onClick={() => setPendingUnverifyPaymentOperation(child)}><FontAwesomeIcon icon={['fas', 'hand-holding-usd']} fixedWidth /> Unverify Payment</Dropdown.Item>
                                                            <Dropdown.Item disabled={!child.isChild || job.isDispatched} onClick={() => setPendingSeparate(child)}><FontAwesomeIcon icon={[ 'fas', 'minus' ]} fixedWidth /> Unconsolidate Job</Dropdown.Item>
                                                            <Dropdown.Item disabled={!child.isChild || job.isDispatched} onClick={() => setPendingDelete(child)}><FontAwesomeIcon icon={[ 'fas', 'trash-alt' ]} fixedWidth /> Delete Job</Dropdown.Item>
                                                            <Dropdown.Divider />
                                                            <Dropdown.Item target="_blank" href={child.orderUrl}><FontAwesomeIcon icon={[ 'fas', 'link' ]} fixedWidth /> View Order At {child.storeName}</Dropdown.Item>
                                                            <Dropdown.Item target="_blank" href={child.customerUrl}><FontAwesomeIcon icon={[ 'fas', 'link' ]} fixedWidth /> View Customer At {child.storeName}</Dropdown.Item>  

                                                            {!!child.dearSaleUrl &&
                                                                <Dropdown.Item target="_blank" href={child.dearSaleUrl}><FontAwesomeIcon icon={['fas', 'boxes']} fixedWidth /> View Sale In DEAR Inventory</Dropdown.Item>
                                                            }
                                                               
                                                        </DropdownButton>

                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody>

                                    </table>

                                </div>
                            </div>
                        }

                        
                        <div className="card card-custom mb-3">
                            <h5 className="card-header">Order Items</h5>
                            <div className="card-body p-0">


                                <table className="table mb-0">
                                    <thead className="thead thead-custom">
                                        <tr>
                                            <th style={{ width: '3%' }} className="text-center">Qty</th>
                                            <th>Product</th>
                                            <th style={{ width: '10%' }} className="text-center">Unit Price</th>
                                            <th style={{ width: '10%' }} className="text-center">Total Price</th>
                                            <th style={{ width: '15%' }} className="text-center">Stock Locator</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {job.combinedItems.map((jobItem, i) => (
                                            <tr key={i}>
                                                <td className="text-center">{jobItem.quantity}</td>
                                                <td>
                                                    <React.Fragment>
                                                        {jobItem.isGiftCardItem &&
                                                            <React.Fragment><FontAwesomeIcon icon={['fas', 'gift']} fixedWidth />{' '}</React.Fragment>
                                                        }
                                                        {jobItem.isShippingItem &&
                                                            <React.Fragment><FontAwesomeIcon icon={['fas', 'truck']} fixedWidth />{' '}</React.Fragment>
                                                        }
                                                        {jobItem.name?.replaceAll('|', '•')}
                                                        {jobItem.notes &&
                                                            <div className="font-italic ml-3">{jobItem.notes}</div>
                                                        }
                                                    </React.Fragment>
                                                </td>
                                                <td className="text-center">${jobItem.unitPrice}</td>
                                                <td className="text-center">${jobItem.totalPrice}</td>
                                                <td className="text-center">{jobItem.stockLocator}</td>
                                            </tr>
                                        ))}
                                    </tbody>

                                    <tfoot>
                                        <tr>
                                            <td colSpan={100} className="text-right px-5">
                                                {job.combinedShippableItemCount.toLocaleString()} items — ${job.combinedTotal.toLocaleString()}
                                            </td>
                                        </tr>
                                    </tfoot>

                                </table>

                            </div>
                        </div>

                        <div className="card card-custom mb-3">
                            <h5 className="card-header">Logs</h5>
                            <div className="card-body p-0">
                                <LogsTable className={'mb-0'} headerClassName={'thead thead-custom'} stickyHeader={false} logs={logs} />
                            </div>
                        </div>

                    </div>
        
                </div>

            </div>

            {childJobOpened &&
                <JobChildModal job={childJobOpened} onClose={() => setChildJobOpened(null)} />
            }

            {isUnconsolidatePending &&
                <JobsUnconsolidateModal parentJob={job} childJobs={job.children} onSuccess={handleUnconsolidateSuccess} onCancel={() => setIsUnconsolidatePending(false)} />
            }

            {isSnoozePending &&
                <JobsSnoozeModal jobs={[ job ]} onSuccess={handleSnoozeSuccess} onCancel={() => setIsSnoozePending(false)} />
            }

            {isUnsnoozePending &&
                <JobsUnsnoozeModal jobs={[ job ]} onSuccess={handleUnsnoozeSuccess} onCancel={() => setIsUnsnoozePending(false)} />
            }

            {isHoldPending &&
                <JobsHoldModal jobs={[ job ]} onSuccess={handleHoldSuccess} onCancel={() => setIsHoldPending(false)} />
            }

            {isUnholdPending &&
                <JobsUnholdModal jobs={[ job ]} onSuccess={handleUnholdSuccess} onCancel={() => setIsUnholdPending(false)} />
            }

            {isResolvePending &&
                <JobsResolveModal jobs={[ job ]} onSuccess={handleResolveSuccess} onCancel={() => setIsResolvePending(false)} />
            }

            {isTakeOutOfStanddownPending &&
                <JobsTakeOutOfStanddownModal jobs={[ job ]} onSuccess={handleTakeOutOfStanddownSuccess} onCancel={() => setIsTakeOutOfStanddownPending(false)} />
            }

            {isChangingBillingAddress &&
                <JobBillingAddressModal allowNewAddress={!job.isDispatched} job={job} onSuccess={handleBillingAddressChangeSuccess} onCancel={() => setIsChangingBillingAddress(false)} />
            }

            {isChangingShippingAddress &&
                <JobShippingAddressModal allowNewAddress={!job.isDispatched} job={job} onSuccess={handleShippingAddressChangeSuccess} onCancel={() => setIsChangingShippingAddress(false)} />
            }

            {!!pendingVerifyPaymentOperation &&
                <JobPaymentModal job={pendingVerifyPaymentOperation} onSuccess={handleVerifyPaymentSuccess} onClose={() => setPendingVerifyPaymentOperation(null)} />
            }

            {!!pendingUnverifyPaymentOperation &&
                <JobUnverifyPaymentModal job={pendingUnverifyPaymentOperation} onSuccess={handleUnverifyPaymentSuccess} onCancel={() => setPendingUnverifyPaymentOperation(null)} />
            }

            {isConfirmingForgive &&
                <ForgiveUnpaidShippingModal job={job} onSuccess={handleForgiveSuccess} onCancel={() => setIsConfirmingForgive(false)} />
            }

            {isAddingTrackingInfo &&
                <JobAddTrackingInfoModal job={job} onSuccess={handleAddTrackingInfoSuccess} onCancel={() => setIsAddingTrackingInfo(false)} />
            }

            {!!pendingDelete &&
                <JobDeleteModal job={pendingDelete} onSuccess={handleDeleteSuccess} onCancel={() => setPendingDelete(null)} />
            }

            {!!pendingSeparate &&
                <JobsUnconsolidateModal parentJob={job} childJobs={[ pendingSeparate ]} onSuccess={handleSeparateSuccess} onCancel={() => setPendingSeparate(null)} />
            }

            {isConfirmingUndelete &&
                <JobUndeleteModal job={job} onSuccess={handleUndeleteSuccess} onCancel={() => setIsConfirmingUndelete(false)} />
            }

            {isConfirmingComplete &&
                <JobCompleteModal job={job} onSuccess={handleCompleteSuccess} onCancel={() => setIsConfirmingComplete(false)} />
            }

            {isConfirmingUncomplete &&
                <JobUncompleteModal job={job} onSuccess={handleUncompleteSuccess} onCancel={() => setIsConfirmingUncomplete(false)} />
            }

            {isPickupPending &&
                <JobsPickupModal jobs={[ job ]} onSuccess={handlePickupSuccess} onCancel={() => setIsPickupPending(false)} />
            }

            {isDeliverPending &&
                <JobsDeliverModal jobs={[ job ]} onSuccess={handleDeliverSuccess} onCancel={() => setIsDeliverPending(false)} />
            }

            {isCollectPending &&
                <JobsCollectModal jobs={[ job ]} onSuccess={handleCollectSuccess} onCancel={() => setIsCollectPending(false)} />
            }

            {isConfirmingRefetchItems &&
                <JobRefetchItemsModal job={job} onSuccess={handleRefetchItemsSuccess} onCancel={() => setIsConfirmingRefetchItems(false)} />
            }

        </React.Fragment>
        
    );

}

Job.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

export default withRouter(Job);