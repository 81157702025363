import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { tryPost as post } from 'lib/api';
import { CancellationToken } from 'lib/cancellationTokens';

import JobPaymentModalInner from 'components/JobPaymentModalInner';

export default function JobBulkPaymentModal({ onClose }) {

    let { addToast } = useToasts();

    let [ jobs, setJobs ] = useState(null);
    let [ selectedJob, setSelectedJob ] = useState(null);

    useEffect(() => {

        let cancellationToken = new CancellationToken();

        let pendingJobs = [];

        getJobs(1);

        function getJobs(page) {

            post('/api/fulfillment/search', { parentOnly: false, filters: [ 'Unpaid' ], page: page++ })
        
                .then(([ response, ex ]) => {

                    if (cancellationToken.isCancelled) {
                        return;
                    }

                    if (ex) {
                        addToast(ex.message, { appearance: 'error' });
                        onClose();
                        return;
                    }

                    pendingJobs = pendingJobs.concat(response.items);

                    if (page > response.totalPages) {
                        complete();
                        return;
                    }

                    getJobs(page);

                });
    
            }

        function complete() {

            if (!pendingJobs.any()) {
                onClose();
            }

            setJobs(pendingJobs);

        }

        return cancellationToken;

    }, []);

    function handleJobSelected(job) {
        setSelectedJob(job);
    }

    function handlePaymentAdded() {

        jobs = jobs.except([ selectedJob ]);

        if (!jobs.any()) {
            onClose();
        }

        setJobs(jobs);
        setSelectedJob(null);
    }

    function handlePaymentCancel() {
        setSelectedJob(null);
    }

    if (!jobs) {
        return null;
    }
    
    return (

        <Modal show={true} centered size="xl" onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Verify Payments</Modal.Title>
            </Modal.Header>

            {!!selectedJob &&
                <JobPaymentModalInner job={selectedJob} onSuccess={handlePaymentAdded} onCancel={handlePaymentCancel} />
            }

            {!selectedJob &&
            
                <Modal.Body>
                
                    <table className="table mb-0">
                        <thead className="thead-dark">
                            <tr>
                                <th style={{ width: '15%' }}>Method</th>
                                <th>Name</th>
                                <th>Reference</th>
                                
                                <th style={{ width: '15%' }}>Amount</th>
                                <th style={{ width: '15%' }}>Order Date</th>
                                <th style={{ width: '1%' }}></th>
                            </tr>
                        </thead>

                        <tbody>

                            {jobs.map((job) => (
                                <tr key={job.id}>
                                    <td>{job.paymentMethod.name}</td>
                                    <td>{job.billingName}</td>
                                    <td>{job.paymentReference}</td>

                                    <td>${job.paymentAmount}</td>
                                    <td>
                                        <span title={moment(job.orderDate).toDate().toLocaleString()}>
                                            {moment(job.orderDate).toDate().toLocaleDateString()}
                                        </span>
                                    </td>
                                    <td className="py-0 align-middle">
                                        <button className="btn btn-sm btn-primary" onClick={handleJobSelected.bind(null, job)}><FontAwesomeIcon icon={[ 'fas', 'check' ]} fixedWidth /></button>
                                    </td>
                                </tr>
                            ))}

                        </tbody>

                    </table>
                    
                </Modal.Body>

            }


        </Modal>



    );

}

JobBulkPaymentModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};