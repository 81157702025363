import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { useForm } from 'react-hook-form';
import qs from 'query-string';
import scrollIntoView from 'scroll-into-view-if-needed'
import moment from 'moment';

import { post } from 'lib/api';
import withFormHelpers from 'lib/formHelpers';
import { CancellationToken } from 'lib/cancellationTokens';

import LoadingBar from 'components/LoadingBar';
import Pagination from 'components/Pagination';
import CardBinsEditModal from 'components/CardBinsEditModal';

export function CardBins(props) {

    let anchorRef = useRef();

    let { addToast } = useToasts();

    let params = qs.parse(props.location.search, { arrayFormat: 'bracket-separator' });

    let defaultCriteria = { 
        searchString: null,
        page: 1,
    };

    let currentCriteria = {
        ...defaultCriteria,
        ...params,
        page: params.page ? parseInt(params.page) : defaultCriteria.page,
    };

    let form = withFormHelpers(useForm({ shouldUnregister: false }));

    let [ isSearching, setIsSearching ] = useState(false);

    let [ cardBins, setCardBins ] = useState({
        items: [],
        page: 1,
        totalPages: 0,
        totalCount: 0,
    });

    let [ pendingEdit, setPendingEdit ] = useState(null);


    useEffect(() => {

        form.reset(currentCriteria);

        setIsSearching(true);

        let cancellationToken = new CancellationToken();

        async function asyncRequest() {

            let response;

            try {
                response = await post('/api/cardbins/search', currentCriteria, cancellationToken);
            }
            catch (ex) {
    
                if (cancellationToken.isCancelled) {
                    return;
                }
    
                setIsSearching(false);

                addToast(ex.message, { appearance: 'error' });
                return;
    
            }

            setIsSearching(false);

            setCardBins(response);

            if (anchorRef.current) {
                scrollIntoView(anchorRef.current, {
                    scrollMode: 'if-needed',
                    block: 'start',
                });
            }
            
        }

        asyncRequest();

        return cancellationToken;

    }, [ props.location ]);

    function generatePath({ searchString, page }) {

        let query = {
            searchString: searchString || null,
            page: page != 1 ? page : null, 
        };

        return '/admin/cardbins?' + qs.stringify(query, { skipNull: true, arrayFormat: 'bracket-separator', sort: false });

    }

    function handleSubmit(formData) {
        props.history.push(generatePath({ ...formData, page: 1 }));
    }

    function handleReset() {
        props.history.push(generatePath(defaultCriteria));
    }

    function handleEditSuccess(cardBin) {
        props.history.push(generatePath(currentCriteria));
        setPendingEdit(null);
    }

    return (

        <React.Fragment>

            {isSearching &&
                <LoadingBar />
            }

            <div className="mt-3 container-fluid">

                <h3 className="mb-3"><FontAwesomeIcon icon={[ 'fab', 'cc-visa' ]} fixedWidth /> Card Bins</h3>

                <div className="card mb-3">
                    <div className="card-body">

                        <form onSubmit={form.handleSubmit(handleSubmit)}>

                            <fieldset disabled={isSearching}>

                                <div className="form-group form-row mb-0">
                                    <label className="col-sm-1 col-form-label-sm">Search String</label>
                                    <div className="col-sm-11">
                                        <input name="searchString" type="text" ref={form.register} className="form-control form-control-sm mr-sm-2" id="searchString" placeholder="Search string" />
                                    </div>
                                </div>

                                <div className="form-group form-row mb-0">
                                    <div className="col-sm-12">

                                        <button type="submit" className="btn btn-sm btn-primary">
                                            {isSearching &&
                                                <span className="spinner-border spinner-border-sm"></span>
                                            }
                                            {' '}
                                            Search
                                        </button>
                                        {' '}
                                        <button type="button" className="btn btn-sm btn-secondary" onClick={handleReset}>Reset</button>


                                    </div>
                                </div>

                            </fieldset>
                        </form>

                    </div>
                </div>

                <div ref={anchorRef}></div>

                <table className="table">
                    <thead className="thead-dark" style={{ position: 'sticky', top: '0px', zIndex: 1 }}>
                        <tr>
                            <th style={{ width: '5%' }}>Prefix</th>
                            <th style={{ width: '10%' }}>Country</th>
                            <th>Description</th>
                            <th style={{ width: '15%' }}>Updated On</th>
                        </tr>
                    </thead>

                    <tbody>

                        {cardBins.items.map((cardBin) => (
                            <tr key={cardBin.id}>
                                <td>{cardBin.prefix}</td>
                                <td>{cardBin.country?.name || 'Unknown'}</td>
                                <td><a href="javascript: void(0);" className="text-reset" onClick={() => setPendingEdit(cardBin)}>{cardBin.description}</a></td>
                                <td>{moment(cardBin.updatedOn).toDate().toLocaleDateString()}</td>
                            </tr>
                        ))}

                        {!cardBins.totalCount &&
                            <tr>
                                <td colSpan={2}>No results</td>
                            </tr>
                        }
                    </tbody>

                </table>

                <Pagination page={cardBins.page} totalPages={cardBins.totalPages} pathGenerator={(page) => generatePath({ ...currentCriteria, page })} />

            </div>

            {pendingEdit &&
                <CardBinsEditModal cardBin={pendingEdit} onSuccess={handleEditSuccess} onCancel={() => setPendingEdit(null)} />
            }

        </React.Fragment>

    );

}

CardBins.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

export default withRouter(CardBins);