import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToasts } from 'react-toast-notifications';
import { useForm, Controller } from 'react-hook-form';
import { Typeahead } from 'react-bootstrap-typeahead';
import cx from 'classnames';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { validate as emailValidator } from 'email-validator';

import { useApi } from 'lib/effects';
import { post, ApiValidationError } from 'lib/api';
import withFormHelpers from 'lib/formHelpers';
import { CancellationToken } from 'lib/cancellationTokens';

export default function OrganisationUsersAddModal(props) {

    let { addToast } = useToasts();

    let defaultValues = {
        roles: [],
        isActive: true,
    };

    let [ roles, setRoles ] = useState(null);

    let [ formData, setFormData ] = useState(null);
    let form = withFormHelpers(useForm({ defaultValues, mode: 'onBlur' }));

    useApi('/api/organisation/roles', (response, ex) => {

        if (ex) {
            props.onCancel();

            addToast(ex.message, { appearance: 'error' });
            return;
        }

        setRoles(response.map(r => r.name));

    }, []);

    useEffect(() => {

        if (!formData) {
            return;
        }

        let cancellationToken = new CancellationToken();

        async function asyncRequest() {

            let response;

            try {
                response = await post('/api/organisation/users', formData, cancellationToken);
            }
            catch (ex) {
    
                if (cancellationToken.isCancelled) {
                    return;
                }

                setFormData(null);
                
                if (ex instanceof ApiValidationError) {
                    form.setApiValidationErrors(ex.validationErrors);
                    return;
                }

                addToast(ex.message, { appearance: 'error' });
                return;
    
            }

            setFormData(null);
            
            addToast('User has been added.', { appearance: 'success' });

            props.onSuccess(response);

        }

        asyncRequest();

        return cancellationToken;

    }, [ formData ]);

    function handleSubmit(formData) {
        setFormData(formData);
    }

    function handleCancel() {
        props.onCancel();
    }

    if (!roles) {
        return null;
    }

    return (

        <Modal show={true} centered size="lg" onHide={handleCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Add User</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <form onSubmit={form.handleSubmit(handleSubmit)}>

                    <fieldset disabled={!!formData}>

                        <div className="form-group">

                            <label>Email Address</label>

                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <FontAwesomeIcon icon={[ 'fas', 'envelope' ]} />
                                    </span>
                                </div>

                                <input name="email" type="text" autoFocus={true} ref={form.register({ required: true, validate: emailValidator })} className={cx('form-control', form.validationClassName('email'))} />

                                {form.hasErrors('email') &&
                                    <div className="invalid-feedback">{form.errorMessage('email', 'Please enter a valid email address.')}</div>
                                }

                            </div>

                        </div>

                        <div className="form-group">

                            <label>First Name</label>

                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <FontAwesomeIcon icon={[ 'fas', 'user' ]} />
                                    </span>
                                </div>

                                <input name="firstName" type="text" ref={form.register({ required: true })} className={cx('form-control', form.validationClassName('firstName'))} />

                                {form.hasErrors('firstName') &&
                                    <div className="invalid-feedback">{form.errorMessage('firstName', 'Please enter a valid first name.')}</div>
                                }

                            </div>

                        </div>

                        <div className="form-group">

                            <label>Last Name</label>

                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <FontAwesomeIcon icon={[ 'fas', 'user' ]} />
                                    </span>
                                </div>

                                <input name="lastName" type="text" ref={form.register({ required: true })} className={cx('form-control', form.validationClassName('lastName'))} />

                                {form.hasErrors('lastName') &&
                                    <div className="invalid-feedback">{form.errorMessage('lastName', 'Please enter a valid last name.')}</div>
                                }

                            </div>

                        </div>

                        <div className="form-group">

                            <label>Roles</label>

                            <div className="input-group">

                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <FontAwesomeIcon icon={[ 'fas', 'tag' ]} />
                                    </span>
                                </div>

                                <Controller
                                    control={form.control}
                                    name="roles"
                                    rules={{ required: true }}
                                    render={(renderProps) => (
                                    
                                        <Typeahead
                                            id="roles"
                                            ref={renderProps.ref}
                                            multiple={true}
                                            defaultSelected={renderProps.value}
                                            inputProps={{
                                                shouldSelectHint: (shouldSelect, e) => {
                                                    return e.code == 'Enter' || shouldSelect;
                                                },
                                            }}
                                            isValid={form.isValid('roles')}
                                            isInvalid={form.isInvalid('roles')}
                                            onChange={renderProps.onChange}
                                            onBlur={renderProps.onBlur}
                                            options={roles}
                                        />

                                )}/>

                                {form.hasErrors('roles') &&
                                    <div className="invalid-feedback d-block">{form.errorMessage('lastName', 'Please select one or more roles.')}</div>
                                }

                            </div>

                        </div>

                        <div className="form-group">

                            <label>Password</label>

                            <div className="input-group">

                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <FontAwesomeIcon icon={[ 'fas', 'key' ]} />
                                    </span>
                                </div>

                                <input name="password" type="password" autoFocus={true} ref={form.register({ required: true })} onBlur={() => { if (form.getValues('passwordConfirm')) form.trigger('passwordConfirm'); }} className={cx('form-control', form.validationClassName('password'))} />

                                {form.hasErrors('password') &&
                                    <div className="invalid-feedback">{form.errorMessage('password', 'Please enter a valid password.')}</div>
                                }

                            </div>

                        </div>

                        <div className="form-group">

                            <label>Confirm Password</label>

                            <div className="input-group">

                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <FontAwesomeIcon icon={[ 'fas', 'check' ]} />
                                    </span>
                                </div>

                                <input name="passwordConfirm" type="password" ref={form.register({ required: true, validate: (value) => !form.getValues('passwordConfirm') || value == form.getValues('passwordConfirm') })} className={cx('form-control', form.validationClassName('passwordConfirm'))} />

                                {form.hasErrors('passwordConfirm') &&
                                    <div className="invalid-feedback">{form.errorMessage('passwordConfirm', 'Type the same password twice to confirm.')}</div>
                                }

                            </div>

                        </div>

                        <div className="form-group">
                            <Form.Check name="isActive" id="isActive" type="checkbox" ref={form.register} label="Is Active" custom />
                        </div>


                    </fieldset>

                </form>

            </Modal.Body>

            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={handleCancel}>Cancel</button>

                <button type="button" className="btn btn-primary" onClick={form.handleSubmit(handleSubmit)} disabled={!!formData}>
                    {formData &&
                        <span className="spinner-border spinner-border-sm"></span>
                    }
                    {' '}
                    Add User
                </button>

            </Modal.Footer>
        </Modal>

    );

}

OrganisationUsersAddModal.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};