import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import { useForm, Controller } from 'react-hook-form';
import cx from 'classnames';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useApi } from 'lib/effects';
import { tryPut as put, tryPost as post, ApiValidationError } from 'lib/api';
import withFormHelpers from 'lib/formHelpers';
import { CancellationToken } from 'lib/cancellationTokens';

export default function CategoriesCreateOrUpdateModal(props) {

    let { addToast } = useToasts();

    let [ formData, setFormData ] = useState(null);

    let defaultValues = props.category ?? {
        attributes: [],
    };

    let form = withFormHelpers(useForm({ defaultValues, mode: 'onBlur' }));

    let [ attributes, setAttributes ] = useState(null);
    let [ parentOptions, setParentOptions ] = useState(null);

    useApi('/api/inventory', (response, ex) => {

        if (ex) {

            props.onCancel();

            addToast(ex.message, { appearance: 'error' });
            return;
        }

        setAttributes(response.attributes);

        let parentOptions = response.categories.filter(c => c.isParent);

        setParentOptions(parentOptions);

    }, []);

    useEffect(() => {

        if (!formData) return;

        let cancellationToken = new CancellationToken();

        (!!props.category
            ? post(`/api/inventory/categories/${props.category.id}`, formData)
            : put('/api/inventory/categories', formData))
    
            .then(([ response, ex ]) => {

                if (cancellationToken.isCancelled) {
                    return;
                }

                if (ex) {
    
                    setFormData(null);
                    
                    if (ex instanceof ApiValidationError) {
                        form.setApiValidationErrors(ex.validationErrors);
                        return;
                    }
    
                    addToast(ex.message, { appearance: 'error' });
                    return;
        
                }

                setFormData(null);

                props.onSuccess(response);
    
                let toastMsg = !!props.category ? 'Category updated' : 'Category added';

                addToast(toastMsg, { appearance: 'success' });

            });
            
        return cancellationToken;

    }, [ formData ]);

    function handleSubmit(formData) {
        setFormData(formData);
    }

    if (!attributes || !parentOptions) {
        return null;
    }

    return (

        <Modal show={true} centered onHide={props.onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {!props.category &&
                        <React.Fragment>Add Category</React.Fragment>
                    }
                    {!!props.category &&
                        <React.Fragment>Edit {props.category.name}</React.Fragment>
                    }
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <form onSubmit={form.handleSubmit(handleSubmit)}>

                    <fieldset disabled={!!formData}>


                        <div className="form-group">

                            <label>Name</label>

                            <input name="name" type="text" ref={form.register({ required: true })} className={cx('form-control', form.validationClassName('name'))} />

                            {form.hasErrors('name') &&
                                <div className="invalid-feedback">{form.errorMessage('name', 'Please enter a valid name.')}</div>
                            }

                        </div>

                        <div className="form-group">

                            <label>Parent</label>

                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <FontAwesomeIcon icon={[ 'fas', 'sitemap' ]} />
                                    </span>
                                </div>

                                <Controller
                                    name="parentId"
                                    control={form.control}
                                    render={(renderProps) => (
                                    
                                        <Typeahead
                                            id="parentId"
                                            ref={renderProps.ref}
                                            clearButton={true}
                                            options={parentOptions}
                                            selected={parentOptions.filter(o => renderProps.value == o.id)}
                                            inputProps={{
                                                shouldSelectHint: (shouldSelect, e) => {
                                                    return e.code == 'Enter' || shouldSelect;
                                                },
                                            }}
                                            labelKey={c => c.name}
                                            onChange={(options) => { renderProps.onChange(options.first()?.id ?? null); }}
                                            onBlur={renderProps.onBlur}

                                        />

                                )}/>

                            </div>

                        </div>

                        <div className="form-group">

                            <label>Attributes</label>

                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <FontAwesomeIcon icon={[ 'fas', 'asterisk' ]} />
                                    </span>
                                </div>

                                <Controller
                                    name="attributes"
                                    control={form.control}
                                    render={(renderProps) => (
                                    
                                        <Typeahead
                                            id="attributes"
                                            ref={renderProps.ref}
                                            options={attributes}
                                            multiple={true}
                                            clearButton={true}
                                            selected={attributes.filter(o => renderProps.value.includes(o))}
                                            inputProps={{
                                                shouldSelectHint: (shouldSelect, e) => {
                                                    return e.code == 'Enter' || shouldSelect;
                                                },
                                            }}
                                            onChange={(options) => { renderProps.onChange(options); }}
                                            onBlur={renderProps.onBlur}

                                        />

                                )}/>


                            </div>

                            <small class="form-text text-muted">Required attributes for any product in this category.</small>

                        </div>

                        {!form.watch('parentId') &&

                            <div className="form-group">
                                <Form.Check name="isContainerOnly" id="isContainerOnly" type="checkbox" ref={form.register} label="Cannot directly contain products" custom />

                                <small class="form-text text-muted">Force products to be placed into a sub-category.</small>
                            </div>

                        }

                    </fieldset>

                </form>

            </Modal.Body>

            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={props.onCancel}>Cancel</button>

                <button type="button" className="btn btn-primary" onClick={form.handleSubmit(handleSubmit)} disabled={!!formData}>
                    {formData &&
                        <span className="spinner-border spinner-border-sm"></span>
                    }
                    {' '}
                    <React.Fragment>
                        {!props.category &&
                            <React.Fragment>Add Category</React.Fragment>
                        }
                        {!!props.category &&
                            <React.Fragment>Update Category</React.Fragment>
                        }
                    </React.Fragment>
                </button>

            </Modal.Footer>
        </Modal>

    );

}

CategoriesCreateOrUpdateModal.propTypes = {
    category: PropTypes.object,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};