import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import Modal from 'react-bootstrap/Modal';
import { Chart } from "react-google-charts";
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useApi } from 'lib/effects';

export default function JobChildModal({ job, onClose }) {

    return (

        <Modal show={true} centered size="xl" onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Job #{job.orderNumber}</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <div className="card card-custom mb-3">
                    <h5 className="card-header">Order Details</h5>
                    <div className="card-body">

                        <div className="row">

                            <div className="col">

                                <dl className="row mb-0">

                                    <dt className="col-4">Order #</dt>
                                    <dd className="col-8">{job.orderNumber}</dd>

                                    <dt className="col-4">Order Date</dt>
                                    <dd className="col-8">{moment(job.orderDate).toDate().toLocaleString()}</dd>

                                    <dt className="col-4">IP Address</dt>
                                    <dd className="col-8"><a target="_blank" href={`https://www.infobyip.com/ip-${job.ipAddress}.html`}>{job.ipAddress}</a></dd>

                                    <dt className="col-4">Comments</dt>
                                    <dd className="col-8">
                                        {job.orderComments || 'n/a'}
                                    </dd>

                                </dl>

                            </div>

                            <div className="col">

                                <dl className="row mb-0">

                                    <dt className="col-4">Total</dt>
                                    <dd className="col-8">${job.total}</dd>

                                    <dt className="col-4">Payment Method</dt>
                                    <dd className="col-8">

                                        {job.paymentMethod.name}
                                        {!job.isPaid &&
                                            <div className="ml-2 d-inline inline-badges">
                                                <span className="badge badge-tvk-orange" title="Awaiting payment.">$ Pending</span>
                                            </div>
                                        }

                                    </dd>

                                    <dt className="col-4">Payment Ref.</dt>
                                    <dd className="col-8">{job.paymentReference || 'n/a'}</dd>

                                </dl>

                            </div>


                        </div>

                    </div>
                </div>

                <div className="card card-custom mb-3">
                    <h5 className="card-header border-bottom-0">Order Items</h5>
                    <div className="card-body p-0">


                        <table className="table mb-0">
                            <thead className="thead thead-custom">
                                <tr>
                                    <th style={{ width: '3%' }} className="text-center">Qty</th>
                                    <th>Product</th>
                                    <th style={{ width: '10%' }} className="text-center">Unit Price</th>
                                    <th style={{ width: '10%' }} className="text-center">Total Price</th>
                                    <th style={{ width: '15%' }} className="text-center">Stock Locator</th>
                                </tr>
                            </thead>
                            <tbody>
                                {job.items.map((jobItem, i) => (
                                    <tr key={i}>
                                        <td className="text-center">{jobItem.quantity}</td>
                                        <td>
                                            <React.Fragment>
                                                {jobItem.isGiftCardItem &&
                                                    <React.Fragment><FontAwesomeIcon icon={['fas', 'gift']} fixedWidth />{' '}</React.Fragment>
                                                }
                                                {jobItem.isShippingItem &&
                                                    <React.Fragment><FontAwesomeIcon icon={['fas', 'truck']} fixedWidth />{' '}</React.Fragment>
                                                }
                                                {jobItem.name?.replaceAll('|', '•')}
                                                {jobItem.notes &&
                                                    <div className="font-italic ml-3">{jobItem.notes}</div>
                                                }
                                            </React.Fragment>
                                        </td>
                                        <td className="text-center">${jobItem.unitPrice}</td>
                                        <td className="text-center">${jobItem.totalPrice}</td>
                                        <td className="text-center">{jobItem.stockLocator}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>
                </div>

            </Modal.Body>
        </Modal>

    );

}

JobChildModal.propTypes = {
    job: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
};