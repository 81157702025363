import React, { useState, useEffect } from 'react'
import { useToasts } from 'react-toast-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import moment from 'moment';

import { tryPut as put } from 'lib/api';
import { CancellationToken } from 'lib/cancellationTokens';

export default function HelperXeroCsv(props) {

    let { addToast } = useToasts();

    let [ type, setType ] = useState("pxpaytrans");
    let [ file, setFile ] = useState(null);
    let [ startDate, setStartDate ] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    let [ endDate, setEndDate ] = useState(moment().endOf('month').format('YYYY-MM-DD'));
    let [ isSubmitting, setIsSubmitting ] = useState(false);

    useEffect(() => {

        if (!isSubmitting) {
            return;
        }

        let cancellationToken = new CancellationToken();

        let url = `/api/books/xerocsv/${type}`;

        let request = {
            startDate,
            endDate,
            fileBase64: file.asBase64,
        };
        
        put(url, request)
        
            .then(([ response, ex ]) => {

                if (cancellationToken.isCancelled) {
                    return;
                }

                setIsSubmitting(false);

                if (ex) {
                    addToast(ex.message, { appearance: 'error' });
                    return;
                }

                addToast('Downloading CSV', { appearance: 'success' });

                let printFrame = document.createElement('iframe');
                printFrame.src = response.fileUrl;
                printFrame.style = 'display: none;'
                document.body.appendChild(printFrame);

            });

        return cancellationToken;

    }, [ isSubmitting ]);

    function handleFileSelected(event) {

        let file = event.target.files[0];

        setFile(file);

        let reader = new FileReader();

        reader.onload = (ev) => {
            file.asBase64 = ev.target.result.match('(?:data:text\/csv;base64,)(.+)')[1];
        };

        reader.readAsDataURL(file);

    }

    function handleStartDateChange(event) {
        setStartDate(event.target.value);
    }

    function handleEndDateChange(event) {
        setEndDate(event.target.value);
    }

    function handleSubmit() {
        setIsSubmitting(true);
    }

    return (

        <div className="mt-3 container-fluid">
            
            <h3>Xero Bank Statement CSV Generator</h3>

            <p>Converts reports provided by payment gateways into Xero bank statement CSVs.</p>

            <div className="row">

                <div className="col-3">

                    <div className="form-group">
                        <label>Start Date</label>
                        <input type="date" className="form-control" value={startDate} onChange={handleStartDateChange} />
                    </div>

                    <div className="form-group">
                        <label>End Date</label>
                        <input type="date" className="form-control" value={endDate} onChange={handleEndDateChange} />
                    </div>

                    <div className="form-group">
                        <label>File</label>
                        <Form.File onChange={handleFileSelected} label={file?.name ?? 'Upload report CSV'} custom />
                    </div>

                    <div className="form-group">

                        <label>Type</label>
                        <Form.Check type="radio" label="PxPay Transactions" id="pxpaytrans" value="pxpaytrans" checked={type == "pxpaytrans"} onChange={e => setType(e.target.value)} custom />
                        <Form.Check type="radio" label="PxPay Settlement" id="pxpaysettle" value="pxpaysettle" checked={type == "pxpaysettle"} onChange={e => setType(e.target.value)} custom />
                        <Form.Check type="radio" label="Laybuy Transactions" id="laybuytrans" value="laybuytrans" checked={type == "laybuytrans"} onChange={e => setType(e.target.value)} custom />
                        <Form.Check type="radio" label="Laybuy Transactions (Grouped)" id="laybuytransgrouped" value="laybuytransgrouped" checked={type == "laybuytransgrouped"} onChange={e => setType(e.target.value)} custom />
                        <Form.Check type="radio" label="Laybuy Settlement" id="laybuysettle" value="laybuysettle" checked={type == "laybuysettle"} onChange={e => setType(e.target.value)} custom />
                        <Form.Check type="radio" label="Verifone Transactions" id="veritrans" value="veritrans" checked={type == "veritrans"} onChange={e => setType(e.target.value)} custom />
                        <Form.Check type="radio" label="Verifone Settlement" id="verisettle" value="verisettle" checked={type == "verisettle"} onChange={e => setType(e.target.value)} custom />
                    </div>

                    <div className="form-group">
                        <button type="button" className="btn btn-primary" onClick={handleSubmit} disabled={isSubmitting}>
                            {isSubmitting &&
                                <span className="spinner-border spinner-border-sm"></span>
                            }
                            {' '}
                            Generate Statement
                        </button>
                    </div>

                </div>


                <div className="col-3">

                    <div className="alert alert-primary small">

                        <p><FontAwesomeIcon icon={[ 'fas', 'info-circle' ]} fixedWidth /> Please ensure applicable criteria are used when exporting the following reports from their respective payment gateways:</p>

                        <h6>PxPay Transactions</h6>

                        <ul>
                            <li>Type: <i>Full Transactions</i></li>
                            <li><i>Authorized</i> transactions only</li>
                            <li><i>End Date</i> should be set to day after the target end date</li>
                        </ul>

                        <h6>PxPay Settlement</h6>
                        
                        <ul>
                            <li>Type: <i>Standard (Settlement Date)</i></li>
                            <li><i>Start Date</i> and <i>End Date</i> should be set to 10 days before and after the target range</li>
                        </ul>

                        <h6>Laybuy Transactions</h6>

                        <ul>
                            <li>Type: <i>Transaction</i></li>
                        </ul>

                        <h6>Laybuy Settlement</h6>
                        
                        <ul>
                            <li>Type: <i>Settlement</i></li>
                            <li><i>Start Date</i> and <i>End Date</i> should be set to 10 days before and after the target range</li>
                        </ul>


                        <h6>Verifone Transactions</h6>

                        <ul>
                            <li>Type: <i>Transactions</i></li>
                            <li>Filter by <i>Created Date</i></li>
                            <li>Export CSV as <i>Basic report</i></li>
                        </ul>

                        <h6>Verifone Settlement</h6>
                        
                        <ul>
                            <li>Type: <i>Transactions</i></li>
                            <li>Filter by <i>Settled Date</i></li>
                            <li>Export CSV as <i>Full report</i></li>
                        </ul>


                    </div>

                </div>

            </div>

        </div>

    );

}