import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import Modal from 'react-bootstrap/Modal';

import { tryPut as put, tryPost as post } from 'lib/api';
import { CancellationToken } from 'lib/cancellationTokens';

import AppContext from 'AppContext';
import JobAddressModalInner from 'components/JobAddressModalInner';

export default function JobBulkShippingAddressModal({ onClose }) {

    let appContext = useContext(AppContext);

    let modalInnerRef = useRef();

    let { addToast } = useToasts();

    let [ recentlyActiveUsers, setRecentlyActiveUsers ] = useState(null);
    let [ jobs, setJobs ] = useState(null);
    let [ pendingSave, setPendingSave ] = useState(null);

    useEffect(() => {

        let cancellationToken = new CancellationToken();

        let pendingLogs = [];
        let pendingJobs = [];

        post('/api/logs/search', { filters: [ 'User Action', 'Last Minute' ], logTypes: [ 1 ], excludeUsers: [ appContext.me.id ] })
        
            .then(([ response, ex ]) => {

                if (cancellationToken.isCancelled) {
                    return;
                }

                if (ex) {
                    addToast(ex.message, { appearance: 'error' });
                    onClose();
                    return;
                }

                pendingLogs = response.items;

                getJobs(1);

            });

        function getJobs(page) {

            post('/api/fulfillment/search', { filters: [ 'Invalid Address' ], page: page++ })
        
                .then(([ response, ex ]) => {

                    if (cancellationToken.isCancelled) {
                        return;
                    }

                    if (ex) {
                        addToast(ex.message, { appearance: 'error' });
                        onClose();
                        return;
                    }

                    pendingJobs = pendingJobs.concat(response.items);

                    if (page > response.totalPages) {
                        complete();
                        return;
                    }

                    getJobs(page);

                });
    
            }

        function complete() {

            if (!pendingJobs.any()) {
                onClose();
            }

            let users = pendingLogs.filter(l => !!l.user).groupBy(l => l.user.id).map(l => l.first().user);
            
            setRecentlyActiveUsers(users);

            setJobs(pendingJobs);

        }

        return cancellationToken;

    }, []);

    useEffect(() => {

        if (!pendingSave) {
            return;
        }

        let cancellationToken = new CancellationToken();

        let job = jobs.first();

        put(`/api/fulfillment/jobs/${job.id}/shippingaddress`, pendingSave)
        
            .then(([ _, ex ]) => {

                if (cancellationToken.isCancelled) {
                    return;
                }

                if (ex) {

                    setPendingSave(null);

                    addToast(ex.message, { appearance: 'error' });
                    return;
                }

                addToast('Shipping address updated.', { appearance: 'success' });

                jobs = jobs.skip(1);

                if (!jobs.any()) {
                    onClose();
                    return;
                }

                setJobs(jobs);

                setPendingSave(null);

            });

        return cancellationToken;

    }, [ pendingSave ]);

    function handleSkip() {
        jobs = jobs.skip(1);

        if (!jobs.any()) {
            onClose();
            return;
        }

        setJobs(jobs);
    }

    function handleSubmit() {
        modalInnerRef.current.submit();
    }

    function handleValidAddress(address, isNewAddress) {
        setPendingSave({
            ...address,
            isNewAddress,
        });
    }

    if (!jobs) {
        return null;
    }
    
    return (

        <Modal show={true} centered size="xl" onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Fix Shipping Addresses ({jobs.count()} remaining)
                
                    {recentlyActiveUsers.any() &&
                        <React.Fragment>
                            {' '}
                            <div className="badge badge-warning">{recentlyActiveUsers.map(u => u.fullName).join(', ')} recently active</div>
                        </React.Fragment>
                    
                    }

                </Modal.Title>
            </Modal.Header>

            <JobAddressModalInner key={jobs.first().id} disabled={!!pendingSave} ref={modalInnerRef} address={jobs.first().shippingAddress} originalAddress={jobs.first().originalShippingAddress} allowNewAddress={true} onValidAddress={handleValidAddress} />

            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={handleSkip}>Skip</button>

                <button type="button" className="btn btn-primary" onClick={handleSubmit} disabled={!!pendingSave}>
                    {!!pendingSave &&
                        <span className="spinner-border spinner-border-sm"></span>
                    }
                    {' '}
                    Fix Shipping Address
                </button>

            </Modal.Footer>
        </Modal>

    );

}

JobBulkShippingAddressModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};