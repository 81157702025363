import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import { useForm } from 'react-hook-form';
import cx from 'classnames';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import { tryPut as put, tryPost as post, ApiValidationError } from 'lib/api';
import withFormHelpers from 'lib/formHelpers';
import { CancellationToken } from 'lib/cancellationTokens';

export default function BrandsCreateOrUpdateModal(props) {

    let { addToast } = useToasts();

    let [ formData, setFormData ] = useState(null);

    let form = withFormHelpers(useForm({ defaultValues: props.brand, mode: 'onBlur' }));


    useEffect(() => {

        if (!formData) return;

        let cancellationToken = new CancellationToken();

        (!!props.brand
            ? post(`/api/inventory/brands/${props.brand.id}`, formData)
            : put('/api/inventory/brands', formData))
    
            .then(([ response, ex ]) => {

                if (cancellationToken.isCancelled) {
                    return;
                }

                if (ex) {
    
                    setFormData(null);
                    
                    if (ex instanceof ApiValidationError) {
                        form.setApiValidationErrors(ex.validationErrors);
                        return;
                    }
    
                    addToast(ex.message, { appearance: 'error' });
                    return;
        
                }

                setFormData(null);

                props.onSuccess(response);
    
                let toastMsg = !!props.brand ? 'Brand updated' : 'Brand added';

                addToast(toastMsg, { appearance: 'success' });

            });
            
        return cancellationToken;

    }, [ formData ]);



    function handleSubmit(formData) {
        setFormData(formData);
    }


    return (

        <Modal show={true} centered onHide={props.onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {!props.brand &&
                        <React.Fragment>Add Brand</React.Fragment>
                    }
                    {!!props.brand &&
                        <React.Fragment>Edit {props.brand.name}</React.Fragment>
                    }
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <form onSubmit={form.handleSubmit(handleSubmit)}>

                    <fieldset disabled={!!formData}>

                        <div className="form-group">

                            <label>Name</label>

                            <input name="name" type="text" ref={form.register({ required: true })} className={cx('form-control', form.validationClassName('name'))} />

                            {form.hasErrors('name') &&
                                <div className="invalid-feedback">{form.errorMessage('name', 'Please enter a valid name.')}</div>
                            }

                        </div>

                    </fieldset>

                </form>

            </Modal.Body>

            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={props.onCancel}>Cancel</button>

                <button type="button" className="btn btn-primary" onClick={form.handleSubmit(handleSubmit)} disabled={!!formData}>
                    {formData &&
                        <span className="spinner-border spinner-border-sm"></span>
                    }
                    {' '}
                    <React.Fragment>
                        {!props.brand &&
                            <React.Fragment>Add Brand</React.Fragment>
                        }
                        {!!props.brand &&
                            <React.Fragment>Update Brand</React.Fragment>
                        }
                    </React.Fragment>
                </button>

            </Modal.Footer>
        </Modal>

    );

}

BrandsCreateOrUpdateModal.propTypes = {
    brand: PropTypes.object,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};