import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import Modal from 'react-bootstrap/Modal';

import { tryPut as put, tryDel as del } from 'lib/api';
import { CancellationToken } from 'lib/cancellationTokens';

export default function JobDeleteModal({ job, onSuccess, onCancel }) {

    let { addToast } = useToasts();

    let [ isSubmitting, setIsSubmitting ] = useState(false);

    useEffect(() => {

        if (!isSubmitting) {
            return;
        }

        let cancellationToken = new CancellationToken();

        if (job.isChild) {
            separateFromParent();
        }
        else if (job.isParent && job.children.any()) {
            makeChildrenOrphens();
        }
        else {
            deleteJob();
        }

        function separateFromParent() {

            let request = [ job.id ];

            put(`/api/fulfillment/jobs/${job.parentId}/unconsolidate`, request)
            
                .then(([ _, ex ]) => {
    
                    if (cancellationToken.isCancelled) {
                        return;
                    }
    
                    if (ex) {

                        setIsSubmitting(false);

                        addToast(ex.message, { appearance: 'error' });
                        return;
                    }
    
                    deleteJob();
    
                });

        }

        function makeChildrenOrphens() {

            let request = job.children.map(j => j.id);

            put(`/api/fulfillment/jobs/${job.id}/unconsolidate`, request)
            
                .then(([ _, ex ]) => {

                    if (cancellationToken.isCancelled) {
                        return;
                    }

                    if (ex) {

                        setIsSubmitting(false);

                        addToast(ex.message, { appearance: 'error' });
                        return;
                    }

                    deleteJob();

                });

        }

        function deleteJob() {

            del(`/api/fulfillment/jobs/${job.id}`)
        
                .then(([ _, ex ]) => {

                    if (cancellationToken.isCancelled) {
                        return;
                    }

                    setIsSubmitting(false);

                    if (ex) {
                        addToast(ex.message, { appearance: 'error' });
                        return;
                    }

                    addToast('Job has been deleted.', { appearance: 'success' });
                    
                    onSuccess();

                });

        }

        return cancellationToken;

    }, [ isSubmitting ]);


    function handleSubmit() {
        setIsSubmitting(true);
    }
    
    return (

        <Modal show={true} centered onHide={onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Job #{job.orderNumber}</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <p>Are you sure you want to delete this job?</p>

                {job.isChild &&
                    <p>This job will first be separated from its parent.</p>
                }

                {job.isParent && job.children.any() &&
                
                    <React.Fragment>

                        <p>Child jobs will become orphans:</p>

                        <ul>
                            {job.children.map((child) => (
                                <li key={child.id}>
                                    #{child.orderNumber} — {child.shippingName}
                                </li>
                            ))}
                        </ul>

                    </React.Fragment>
                
                }

            </Modal.Body>

            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={onCancel}>Cancel</button>

                <button type="button" className="btn btn-danger" onClick={handleSubmit} disabled={isSubmitting}>
                    {isSubmitting &&
                        <span className="spinner-border spinner-border-sm"></span>
                    }
                    {' '}
                    Delete
                </button>

            </Modal.Footer>
        </Modal>

    );

}

JobDeleteModal.propTypes = {
    job: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};