import React, { useState, useEffect } from 'react'
import { useToasts } from 'react-toast-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import moment from 'moment';

import { tryPut as put } from 'lib/api';
import { CancellationToken } from 'lib/cancellationTokens';

export default function HelperVraReport(props) {

    let { addToast } = useToasts();

    let [ file, setFile ] = useState(null);
    let [ isSubmitting, setIsSubmitting ] = useState(false);

    useEffect(() => {

        if (!isSubmitting) {
            return;
        }

        let cancellationToken = new CancellationToken();

        let request = {
            fileBase64: file.asBase64,
        };
        
        put("/api/books/vrareport", request)
        
            .then(([ response, ex ]) => {

                if (cancellationToken.isCancelled) {
                    return;
                }

                setIsSubmitting(false);

                if (ex) {
                    addToast(ex.message, { appearance: 'error' });
                    return;
                }

                addToast('Downloading CSV', { appearance: 'success' });

                let printFrame = document.createElement('iframe');
                printFrame.src = response.fileUrl;
                printFrame.style = 'display: none;'
                document.body.appendChild(printFrame);

            });

        return cancellationToken;

    }, [ isSubmitting ]);

    function handleFileSelected(event) {

        let file = event.target.files[0];

        setFile(file);

        let reader = new FileReader();

        reader.onload = (ev) => {
            file.asBase64 = ev.target.result.match('(?:data:text\/csv;base64,)(.+)')[1];
        };

        reader.readAsDataURL(file);

    }

    function handleSubmit() {
        setIsSubmitting(true);
    }

    return (

        <div className="mt-3 container-fluid">
            
            <h3>VRA Annual Sales Report Generator</h3>

            <p>Generates a CSV in the correct format required for VRA annual sales reporting.</p>

            <div className="row">

                <div className="col-3">

                    <div className="form-group">
                        <label>File</label>
                        <Form.File onChange={handleFileSelected} label={file?.name ?? 'Upload CSV'} custom />
                    </div>

                    <div className="form-group">
                        <button type="button" className="btn btn-primary" onClick={handleSubmit} disabled={isSubmitting}>
                            {isSubmitting &&
                                <span className="spinner-border spinner-border-sm"></span>
                            }
                            {' '}
                            Generate Report
                        </button>
                    </div>

                </div>


                <div className="col-3">

                    <div className="alert alert-primary small">

                        <p><FontAwesomeIcon icon={[ 'fas', 'info-circle' ]} fixedWidth /> Please upload a CSV with the following:</p>

                        <ul>
                            <li>Expected columns: Sku, Name, Quantity, UnitPrice (or TotalPrice).</li>
                            <li>Column headers are case sensitive.</li>
                            <li>The CSV you upload should already be filtered to only include the sales for the relevant reporting year.</li>
                            <li>Disclaimer: This tool makes no guarantees about which products have been notified to the VRA.</li>
                        </ul>

                    </div>

                </div>

            </div>

        </div>

    );

}