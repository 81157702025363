import ct from 'content-type';
import qs from 'query-string';

import { CancellationToken, OperationCancelledError } from 'lib/cancellationTokens';

let headerMiddleware = (headers) => {};
let errorMiddleware = (ex) => ex;

export function setHeaderMiddleware(middleware) {
    headerMiddleware = middleware;
}

export function setErrorMiddleware(middleware) {
    errorMiddleware = middleware;
}

export async function get(url, query, cancellationToken) {
    if (query instanceof CancellationToken) {
        cancellationToken = query;
        query = null;
    }

    return await request('GET', url, query, null, cancellationToken);
}

export async function tryGet(url, query) {

    let response;
    let error;

    try {
        response = await request('GET', url, query);
    }
    catch (ex) {
        error = ex;
    }

    return [ response, error ];
    
}

export async function post(url, data, cancellationToken) {
    if (data instanceof CancellationToken) {
        cancellationToken = data;
        data = null;
    }

    return await request('POST', url, null, data, cancellationToken);
}

export async function tryPost(url, data, query) {

    let response;
    let error;

    try {
        response = await request('POST', url, query, data);
    }
    catch (ex) {
        error = ex;
    }

    return [ response, error ];
    
}

export async function put(url, data, cancellationToken) {
    if (data instanceof CancellationToken) {
        cancellationToken = data;
        data = null;
    }

    return await request('PUT', url, null, data, cancellationToken);
}

export async function tryPut(url, data) {

    let response;
    let error;

    try {
        response = await request('PUT', url, null, data);
    }
    catch (ex) {
        error = ex;
    }

    return [ response, error ];
    
}

export async function del(url, query, cancellationToken) {
    if (query instanceof CancellationToken) {
        cancellationToken = query;
        query = null;
    }

    return await request('DELETE', url, query, null, cancellationToken);
}

export async function tryDel(url, query) {

    let response;
    let error;

    try {
        response = await request('DELETE', url, query);
    }
    catch (ex) {
        error = ex;
    }

    return [ response, error ];
    
}

export async function request(method, url, query, data, cancellationToken) {

    if (!cancellationToken) {
        cancellationToken = new CancellationToken();
    }

    try {
            
        if (query) {
            url += '?' + qs.stringify(query, { skipNull: true });
        }

        let headers = new Headers({
            'Content-Type': 'application/json'
        });

        headers = headerMiddleware(headers);
        
        let body;

        if (data) {
            body = JSON.stringify(data);
        }

        let response = await fetch(url, { method, headers, body });

        cancellationToken.throwIfCancelled();

        if (!response.ok) {

            if (response.status == 401 || response.status == 403) {
                throw new ApiAuthorizationError('Authorisation failed');
            }
            else if (response.status == 404) {
                throw new ApiNotFoundError('Not found');
            }
            else if (response.headers.has('content-type') && ct.parse(response.headers.get('content-type')).type == 'application/json') {
    
                let errorResponse = await response.json();
    
                if (errorResponse.errorType == 'GeneralError') {
                    throw new ApiError(errorResponse.errorMessage);
                }
                else if (errorResponse.errorType == 'ValidationError') {
                    throw new ApiValidationError(errorResponse.errorMessage, errorResponse.validationErrors);
                }
                
            }
    
            throw new Error(`${response.status}: ${response.statusText}`)
        }
    
        if (!response.headers.has('content-type') || ct.parse(response.headers.get('content-type')).type != 'application/json') {
            return;
        }

        let json = await response.json();

        cancellationToken.throwIfCancelled();
    
        return json;

    }
    catch (ex) {

        if (ex instanceof OperationCancelledError) {
            throw ex;
        }

        throw errorMiddleware(ex);

    }

}


export class ApiError extends Error {
    constructor(message) {
        super(message);
    }
}

export class ApiNotFoundError extends ApiError {
    constructor(message) {
        super(message);
    }
}

export class ApiAuthorizationError extends ApiError {
    constructor(message) {
        super(message);
    }
}

export class ApiValidationError extends ApiError {
    constructor(message, validationErrors) {
        super(message);
        this.validationErrors = validationErrors;
    }
}
 