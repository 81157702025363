import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import Modal from 'react-bootstrap/Modal';

import { tryPut as put } from 'lib/api';
import { CancellationToken } from 'lib/cancellationTokens';

export default function JobCompleteModal({ job, onSuccess, onCancel }) {

    let { addToast } = useToasts();

    let [ isSaving, setIsSaving ] = useState(false);

    useEffect(() => {

        if (!isSaving) {
            return;
        }

        let cancellationToken = new CancellationToken();

        put(`/api/fulfillment/jobs/${job.id}/complete`)
            
        .then(([ _, ex ]) => {

            if (cancellationToken.isCancelled) {
                return;
            }

            setIsSaving(false);

            if (ex) {
                addToast(ex.message, { appearance: 'error' });
                return;
            }
            
            onSuccess();

            addToast('Job completed.', { appearance: 'success' });

        });

        return cancellationToken;

    }, [ isSaving ]);

    function handleSubmit() {

        setIsSaving(true);

    }

    return (

        <Modal show={true} centered onHide={onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Complete Job</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>Are you sure you want to complete this job?</p>
            </Modal.Body>

            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={onCancel}>Cancel</button>

                <button type="button" className="btn btn-primary" onClick={handleSubmit} disabled={isSaving}>
                    {isSaving &&
                        <span className="spinner-border spinner-border-sm"></span>
                    }
                    {' '}
                    Confirm
                </button>

            </Modal.Footer>

        </Modal>

    );

}

JobCompleteModal.propTypes = {
    job: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};