import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'react-bootstrap/Dropdown';
import cx from 'classnames';

export default function AnalyseProjectButton(props) {

    let [ isExpanded, setIsExpanded ] = useState(false);

    function handleDropdownToggle(show) {
        setIsExpanded(show);
    }

    function handleAnalyseClick(analyse) {
        setIsExpanded(false);
        props.onAnalyseOptionClicked(analyse);
    }

    function handleProjectClick(project) {
        setIsExpanded(false);
        props.onProjectOptionClicked(project);
    }

    function getDaysFromStr(str) {
        return str.match(/(?:Last|Next) (\d*) Days/)[1] + 'd';
    }

    return (

        <Dropdown as="span" show={isExpanded} onToggle={handleDropdownToggle}>
            <Dropdown.Toggle variant="primary">
                <FontAwesomeIcon icon={['fas', 'chart-line']} /> Forecast: {getDaysFromStr(props.analyse)} — {getDaysFromStr(props.project)}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <div className="row no-gutters" style={{ width: '300px', padding: '0px 10px' }}>

                    <div className="col">
                        <div className="small" style={{ padding: '0.5rem 1rem' }}>
                            Analyse
                        </div>
                        <ul className="nav nav-pills flex-column">
                            {props.analyseOptions.map((analyseOption) => (
                                <li key={analyseOption} className="nav-item small">
                                    <a className={cx('nav-link', { 'active': analyseOption == props.analyse, 'text-secondary': analyseOption != props.analyse })} href="javascript:void(0);" onClick={handleAnalyseClick.bind(this, analyseOption)}>{analyseOption}</a>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="col ml-1">
                        <div className="small" style={{ padding: '0.5rem 1rem' }}>
                            Project
                        </div>
                        <ul className="nav nav-pills flex-column">
                            {props.projectOptions.map((projectOption) => (
                                <li key={projectOption} className="nav-item small">
                                    <a className={cx('nav-link', { 'active': projectOption == props.project, 'text-secondary': projectOption != props.project })} href="javascript:void(0);" onClick={handleProjectClick.bind(this, projectOption)}>{projectOption}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>


            </Dropdown.Menu>
        </Dropdown>

    )

}

AnalyseProjectButton.propTypes = {
    analyse: PropTypes.string.isRequired,
    project: PropTypes.string.isRequired,
    analyseOptions: PropTypes.array.isRequired,
    projectOptions: PropTypes.array.isRequired,
    onAnalyseOptionClicked: PropTypes.func.isRequired,
    onProjectOptionClicked: PropTypes.func.isRequired,
};