import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import cx from 'classnames';
import qs from 'query-string';

import NumberInput from 'components/NumberInput';

import { useApi } from 'lib/effects';
import { tryPut as put } from 'lib/api';
import { CancellationToken } from 'lib/cancellationTokens';

export default function InventoryProductsPrintShelfLabelsModal(props) {

    let { addToast } = useToasts();

    let [ shelfLabelSettings, setShelfLabelSettings ] = useState(null);

    let [ isOverridingSettings, setIsOverridingSettings ] = useState(false);
    let [ isSubmitting, setIsSubmitting ] = useState(false);
    let [ isSavingSettings, setIsSavingSettings ] = useState(false);

    useApi('/api/inventory', (response, ex) => {

        if (ex) {

            addToast(ex.message, { appearance: 'error' });
            return;

        }

        setShelfLabelSettings(response.shelfLabelSettings);

    }, []);

    useEffect(() => {

        if (!isSubmitting) {
            return;
        }

        let cancellationToken = new CancellationToken();

        let request = {
            productIds: props.products.map(p => p.id),
            settings: isOverridingSettings ? { ...shelfLabelSettings, drawBorder: true } : null,
        };

        put('/api/inventory/printshelflabels', request)
        
            .then(([ response, ex ]) => {

                if (cancellationToken.isCancelled) {
                    return;
                }

                setIsSubmitting(false);

                if (ex) {
                    addToast(ex.message, { appearance: 'error' });
                    return;
                }

                
                addToast('Shelf labels are being printed.', { appearance: 'success' });
            
                let printerAppUrl = qs.stringifyUrl({ 
                    url: 'fulfillv2://print', 
                    query: { type: 'Shelf Labels', url: response.fileUrl },
                });

                //let printerAppUrl = response.fileUrl;

                let printFrame = document.createElement('iframe');
                printFrame.src = printerAppUrl;
                printFrame.style = 'display: none;'
                document.body.appendChild(printFrame);

                if (!isOverridingSettings) {
                    props.onSuccess();
                }

            });

        return cancellationToken;

    }, [ isSubmitting ]);

    useEffect(() => {

        if (!isSavingSettings) {
            return;
        }

        let cancellationToken = new CancellationToken();

        put('/api/inventory/saveshelflabelsettings', shelfLabelSettings)
        
            .then(([ _, ex ]) => {

                if (cancellationToken.isCancelled) {
                    return;
                }

                setIsSavingSettings(false);

                if (ex) {
                    addToast(ex.message, { appearance: 'error' });
                    return;
                }

                setIsOverridingSettings(false);

                addToast(`Shelf label settings saved`, { appearance: 'success' });

            });

        return cancellationToken;

    }, [ isSavingSettings ]);

    function handleSubmit() {
        setIsSubmitting(true);
    }

    function handleCancel() {
        props.onCancel();
    }

    function handleShelfLabelSettingChanged(field, value) {
        shelfLabelSettings[field] = value;
        setShelfLabelSettings({ ...shelfLabelSettings });
    }

    if (!shelfLabelSettings) {
        return null;
    }

    return (

        <Modal show={true} centered size="lg" onHide={handleCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Print Shelf Labels</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <div className="row">

                    <div className="col-6">

                        <p>Print {props.products.length} shelf labels?</p>

                        <ul>
                            {props.products.map((product) => (
                                <li key={product.id}>{product.name}</li>
                            ))}
                        </ul>

                    </div>

                    <div className="col-6">

                        <div className="form-group mb-2">
                            <Form.Check type="switch" size="sm" custom id="isOverridingSettings" label="Tweak Settings" checked={isOverridingSettings} onChange={e => setIsOverridingSettings(e.target.checked)} />
                        </div>
                        
                        {isOverridingSettings &&
                        
                            <React.Fragment>

                                <div className="alert alert-warning small p-2">To aid with alignment, labels will be given a border. When you're done, save settings and reprint without borders.</div>
                                
                                <div className="form-group row mb-2">

                                    <label className="col-6 col-form-label col-form-label-sm">Labels Per Row</label>

                                    <div className="col-6">
                                        <NumberInput className="form-control form-control-sm" dp={0} value={shelfLabelSettings.labelsPerRow} onChange={handleShelfLabelSettingChanged.bind(null, 'labelsPerRow')} />
                                    </div>

                                </div>

                                <div className="form-group row mb-2">

                                    <label className="col-6 col-form-label col-form-label-sm">Top Margin (mm)</label>

                                    <div className="col-6">
                                        <NumberInput className="form-control form-control-sm" dp={2} value={shelfLabelSettings.topMargin} onChange={handleShelfLabelSettingChanged.bind(null, 'topMargin')} />
                                    </div>

                                </div>

                                <div className="form-group row mb-2">

                                    <label className="col-6 col-form-label col-form-label-sm">Left Margin (mm)</label>

                                    <div className="col-6">
                                        <NumberInput className="form-control form-control-sm" dp={2} value={shelfLabelSettings.leftMargin} onChange={handleShelfLabelSettingChanged.bind(null, 'leftMargin')} />
                                    </div>

                                </div>

                                <div className="form-group row mb-2">

                                    <label className="col-6 col-form-label col-form-label-sm">Label Width (mm)</label>

                                    <div className="col-6">
                                        <NumberInput className="form-control form-control-sm" dp={2} value={shelfLabelSettings.labelWidth} onChange={handleShelfLabelSettingChanged.bind(null, 'labelWidth')} />
                                    </div>

                                </div>

                                
                                <div className="form-group row mb-2">

                                    <label className="col-6 col-form-label col-form-label-sm">Label Height (mm)</label>

                                    <div className="col-6">
                                        <NumberInput className="form-control form-control-sm" dp={2} value={shelfLabelSettings.labelHeight} onChange={handleShelfLabelSettingChanged.bind(null, 'labelHeight')} />
                                    </div>

                                </div>

                                <div className="form-group row mb-2">

                                    <label className="col-6 col-form-label col-form-label-sm">Label Padding (mm)</label>

                                    <div className="col-6">
                                        <NumberInput className="form-control form-control-sm" dp={2} value={shelfLabelSettings.labelPadding} onChange={handleShelfLabelSettingChanged.bind(null, 'labelPadding')} />
                                    </div>

                                </div>

                                <button type="button" className="btn btn-sm btn-danger" onClick={() => setIsSavingSettings(true)} disabled={isSavingSettings}>
                                    {isSavingSettings &&
                                        <span className="spinner-border spinner-border-sm"></span>
                                    }
                                    {' '}
                                    Save Settings
                                </button>

                            </React.Fragment>

                        }

                    </div>

                </div>

            </Modal.Body>

            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={handleCancel}>Cancel</button>

                <button type="button" className={cx("btn", { 'btn-primary': !isOverridingSettings, 'btn-info': isOverridingSettings })} onClick={handleSubmit} disabled={isSubmitting}>
                    {isSubmitting &&
                        <span className="spinner-border spinner-border-sm"></span>
                    }
                    {' '}
                    {isOverridingSettings ? 'Test Print' : 'Print Shelf Labels'}
                </button>

            </Modal.Footer>
        </Modal>

    );

}

InventoryProductsPrintShelfLabelsModal.propTypes = {
    products: PropTypes.array.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};