import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToasts } from 'react-toast-notifications';
import { useForm } from 'react-hook-form';
import cx from 'classnames';
import Modal from 'react-bootstrap/Modal';

import { put, ApiValidationError } from 'lib/api';
import withFormHelpers from 'lib/formHelpers';
import { CancellationToken } from 'lib/cancellationTokens';


export default function OrganisationUsersChangePasswordModal(props) {

    let { addToast } = useToasts();

    let [ formData, setFormData ] = useState(null);
    let form = withFormHelpers(useForm({ mode: 'onBlur' }));

    useEffect(() => {

        if (!formData) {
            return;
        }

        let cancellationToken = new CancellationToken();

        async function asyncRequest() {

            try {
                await put(`/api/organisation/users/${props.user.id}/password`, formData, cancellationToken);
            }
            catch (ex) {
    
                if (cancellationToken.isCancelled) {
                    return;
                }

                setFormData(null);
                
                if (ex instanceof ApiValidationError) {
                    form.setApiValidationErrors(ex.validationErrors);
                    return;
                }

                addToast(ex.message, { appearance: 'error' });
                return;
    
            }

            setFormData(null);
            
            addToast('User password has been changed.', { appearance: 'success' });

            props.onSuccess();
            
        }

        asyncRequest();

        return cancellationToken;

    }, [ formData ]);

    function handleSubmit(formData) {
        setFormData(formData);
    }

    function handleCancel() {
        props.onCancel();
    }

    return (

        <Modal show={true} centered size="lg" onHide={handleCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Change Password for {props.user.fullName}</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <form onSubmit={form.handleSubmit(handleSubmit)}>

                    <fieldset disabled={!!formData}>

                        <div className="form-group">

                            <label>New Password</label>

                            <div className="input-group">

                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <FontAwesomeIcon icon={[ 'fas', 'key' ]} />
                                    </span>
                                </div>

                                <input name="newPassword" type="password" autoFocus={true} ref={form.register({ required: true })} onBlur={() => { if (form.getValues('newPasswordConfirm')) form.trigger('newPasswordConfirm'); }} className={cx('form-control', form.validationClassName('newPassword'))} />

                                {form.hasErrors('newPassword') &&
                                    <div className="invalid-feedback">{form.errorMessage('newPassword', 'Please enter a valid password.')}</div>
                                }

                            </div>

                        </div>

                        <div className="form-group">

                            <label>Confirm Password</label>

                            <div className="input-group">

                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <FontAwesomeIcon icon={[ 'fas', 'check' ]} />
                                    </span>
                                </div>

                                <input name="newPasswordConfirm" type="password" ref={form.register({ required: true, validate: (value) => !form.getValues('newPassword') || value == form.getValues('newPassword') })} className={cx('form-control', form.validationClassName('newPasswordConfirm'))} />

                                {form.hasErrors('newPasswordConfirm') &&
                                    <div className="invalid-feedback">{form.errorMessage('newPasswordConfirm', 'Type the same password twice to confirm.')}</div>
                                }

                            </div>

                        </div>

                    </fieldset>

                </form>

            </Modal.Body>

            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={handleCancel}>Cancel</button>

                <button type="button" className="btn btn-primary" onClick={form.handleSubmit(handleSubmit)} disabled={!!formData}>
                    {formData &&
                        <span className="spinner-border spinner-border-sm"></span>
                    }
                    {' '}
                    Change Password
                </button>

            </Modal.Footer>
        </Modal>

    );

}

OrganisationUsersChangePasswordModal.propTypes = {
    user: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};