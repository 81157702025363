import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export default function TextArea({ value, onChange, ...otherProps }) {

    let [ internalValue, setInternalValue ] = useState(value);

    useEffect(() => {
        setInternalValue(value);
    }, [ value ]);

    function handleChange(event) {
        setInternalValue(event.target.value);
    }
    
    function handleBlur(event) {

        internalValue = event.target.value;

        setInternalValue(internalValue);

        if (internalValue != value) {
            onChange(internalValue);
        }
    }

    return (
        <textarea {...otherProps} value={internalValue} onChange={handleChange} onBlur={handleBlur} />
    );

 }

 TextArea.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};