import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import jwt_decode from 'jwt-decode';
import qs from 'query-string';

let urlFragment = qs.parse(window.location.hash);

if (urlFragment.access_token) {

    let originalNonce = localStorage.getItem('nonce');

    let suppliedNonce = jwt_decode(urlFragment.id_token).nonce;

    if (originalNonce && suppliedNonce && suppliedNonce == originalNonce) {

        localStorage.removeItem('nonce');
        localStorage.setItem('access_token', urlFragment.access_token);
        localStorage.setItem('id_token', urlFragment.id_token);

        window.history.pushState(null, document.title, urlFragment.state);
        
    }

}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
