import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cx from 'classnames';

export default function JobStatus(props) {

    let { job, className, ...otherProps } = props;

    return (

        <div className={cx('inline-badges', className)} {...otherProps}>

            {job.isDeleted &&
                <span className="badge badge-dark" title="This job has been deleted">Deleted</span>
            }

            {!job.isDeleted &&
                <React.Fragment>

                    {job.children.map((childJob) => (
                        <span key={childJob.id} className="badge badge-secondary" title={`Includes consolidated job #${childJob.orderNumber}`}>#{childJob.orderNumber}</span>
                    ))}

                    {job.isNonShippable &&
                        <span className="badge badge-warning" title="Job does not contain any shippable items.">Non-Shippable</span>
                    }

                    {job.isReadyForDispatch &&
                        <span className="badge badge-success" title="Ready to be dispatched">Ready</span>
                    }

                    {job.isInStanddown &&
                        <span className="badge badge-tvk-orange" title="This job is in standdown">Standdown</span>
                    }
                    
                    {job.isOnHold &&
                        <span className="badge badge-tvk-orange" title={'This job is on hold' + (job.holdReason ? '\r\n' + job.holdReason : '')}>On Hold</span>
                    }

                    {job.isSnoozed &&
                        <span className="badge badge-tvk-orange" title={`This job is snoozed until ${moment(job.snoozedUntil).toDate().toLocaleString()}` + (job.snoozeReason ? '\r\n' + job.snoozeReason : '')}>Snoozed</span>
                    }

                    {job.hasPendingPayments &&
                        <span className="badge badge-tvk-orange" title="One or more pending payments">$ Pending</span>
                    }

                    {job.isBlockedByInvalidShippingAddress &&
                        <span className="badge badge-tvk-pink" title="The customer entered an invalid shipping address">Invalid Address</span>
                    }
                    
                    {job.isBlockedByUnpaidShipping &&
                        <span className="badge badge-danger" title="This job has unpaid shipping">Unpaid Shipping</span>
                    }

                    {job.isBlockedByNotApplicableShipping &&
                        <span className="badge badge-danger" title="Shipping method not selected">N/A Shipping</span>
                    }

                    {job.combinedIssues.filter(i => !i.isResolved).map((jobIssue) => (
                        <span key={jobIssue.id} className={cx('badge', { 'badge-danger': jobIssue.isProblem, 'badge-tvk-orange': jobIssue.isInfo  })} title={jobIssue.description}>{jobIssue.name}</span>
                    ))}

                    {job.isCompleted &&
                        <span className="badge badge-primary" title="This job is completed">Completed</span>
                    }

                    {!job.isCompleted && job.isDelivered &&
                        <span className="badge badge-tvk-blue" title="This job has been delivered">Delivered</span>
                    }

                    {!job.isCompleted && !job.isDelivered && job.isPickedUp &&
                        <span className="badge badge-tvk-blue" title="This job has been picked up">Picked Up</span>
                    }

                    {!job.isCompleted && !job.isDelivered && !job.isPickedUp && job.isTracked &&
                        <span className="badge badge-tvk-blue" title="This job has a tracking number">Packed</span>
                    }

                    {!job.isCompleted && !job.isDelivered && !job.isPickedUp && !job.isTracked && job.isDispatched &&
                        <span className="badge badge-tvk-cyan" title="This job has been dispatched">Dispatched</span>
                    }

                </React.Fragment>
            }

        </div>

    );

}

JobStatus.propTypes = {
    job: PropTypes.object.isRequired,
};