import React, { useState, useEffect } from 'react'
import { useToasts } from 'react-toast-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import moment from 'moment';

import { useApi } from 'lib/effects';
import { tryPut as put } from 'lib/api';
import { CancellationToken } from 'lib/cancellationTokens';

export default function HelperDummyShipments(props) {

    let { addToast } = useToasts();

    let [ data, setData ] = useState(null);
    let [ orderNumber, setOrderNumber ] = useState(null);
    let [ companyName, setCompanyName ] = useState(null);
    let [ trackingNumber, setTrackingNumber ] = useState(null);
    let [ isSubmitting, setIsSubmitting ] = useState(false);


    useApi('/api/vapehq/dummyshipments', (response, ex) => {

        if (ex) {

            addToast(ex.message, { appearance: 'error' });
            return;

        }

        setData(response);

    }, []);

    useEffect(() => {

        if (!isSubmitting) {
            return;
        }

        let cancellationToken = new CancellationToken();

        let request = {
            orderNumber,
            companyName,
            trackingNumber,
        };
        
        put("/api/vapehq/dummyshipments", request)
        
            .then(([ response, ex ]) => {

                if (cancellationToken.isCancelled) {
                    return;
                }

                setIsSubmitting(false);

                if (ex) {
                    addToast(ex.message, { appearance: 'error' });
                    return;
                }

                addToast('Downloading documents', { appearance: 'success' });

                {
                    let printFrame = document.createElement('iframe');
                    printFrame.src = response.invoiceFileUrl;
                    printFrame.style = 'display: none;'
                    document.body.appendChild(printFrame);
                }

                {
                    let printFrame = document.createElement('iframe');
                    printFrame.src = response.batteryDeclarationFileUrl;
                    printFrame.style = 'display: none;'
                    document.body.appendChild(printFrame);
                }

            });

        return cancellationToken;

    }, [ isSubmitting ]);


    function handleSubmit() {
        setIsSubmitting(true);
    }

    function handleCompanyNameChanged(event) {
        setCompanyName(event.target.value);
    }

    function handleOrderNumberChanged(event) {
        setOrderNumber(event.target.value);
    }

    function handleTrackingNumberChanged(event) {
        setTrackingNumber(event.target.value);
    }

    if (!data) {
        return null;
    }

    return (

        <div className="mt-3 container-fluid">
            
            <h3>Dummy Shipments</h3>

            <p>Generates dummy invoices and battery declarations for testing purposes.</p>

            <div className="row">

                <div className="col-3">

                    <div className="form-group">
                        <label>Order Number</label>
                        <input className="form-control" type="text" value={orderNumber ?? ""} onChange={handleOrderNumberChanged} />
                        <small className="form-text text-muted">Enter the DEAR sale number, eg.. SO-10502.</small>
                    </div>

                    <div className="form-group">
                        <label>Company</label>
                        <select name="company" className="form-control custom-select" onChange={handleCompanyNameChanged} value={companyName ?? ""}>
                            <option></option>
                            {data.companies.map((company) => (
                                <option key={company.name} value={company.name}>{company.name}</option>
                            ))}
                        </select>
                        <small className="form-text text-muted">Dummy company to send from.</small>
                    </div>

                    <div className="form-group">
                        <label>Tracking Number</label>
                        <input className="form-control" type="text" value={trackingNumber ?? ""} onChange={handleTrackingNumberChanged} />
                        <small className="form-text text-muted">Printed on the battery declaration (not required).</small>
                    </div>

                    <div className="form-group">
                        <button type="button" className="btn btn-primary" onClick={handleSubmit} disabled={isSubmitting}>
                            {isSubmitting &&
                                <span className="spinner-border spinner-border-sm"></span>
                            }
                            {' '}
                            Generate Documents
                        </button>
                    </div>

                </div>


                <div className="col-3">

                    <div className="alert alert-primary small">

                        <p><FontAwesomeIcon icon={[ 'fas', 'info-circle' ]} fixedWidth /> How to use:</p>

                        <ul>

                            <li>The following fields from the DEAR sale are used to populate the invoice and battery declaration:
                                <ul>
                                    <li>Phone (used on battery declaration)</li>
                                    <li>Ship to company (can leave blank)</li>
                                    <li>Ship to contact</li>
                                    <li>Shipping address line 1 & 2, etc..</li>
                                </ul>
                            </li>
                            <li>Dummy invoice will be populated with items from selected dummy company, with a total value between ${data.minOrderValue} and ${data.maxOrderValue}.</li>
                            <li>Disclaimer: This tool is for testing purposes only.</li>
                        </ul>

                    </div>

                </div>

            </div>

        </div>

    );

}