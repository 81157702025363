import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';

import { tryPut as put } from 'lib/api';
import { CancellationToken } from 'lib/cancellationTokens';

export default function JobsSnoozeModal(props) {

    let { addToast } = useToasts();

    let [ isSubmitting, setIsSubmitting ] = useState(false);

    let snoozeOptions = [
        '1 hour',
        '2 hours',
        '3 hours',
        '4 hours',
        '12 hours',
        '1 day',
        '2 days',
        'Enter Date',
    ];

    let timeOptions = [
        '9:00 am',
        '12:00 pm',
        '2:00 pm',
    ];

    let [ snooze, setSnooze ] = useState('2 hours');
    let [ date, setDate ] = useState(moment().add(18, 'hours').format('YYYY-MM-DD')); //if it's after 6am, we use tomorrow's date by default
    let [ time, setTime ] = useState('9:00 am');
    let [ snoozeReason, setSnoozeReason ] = useState(null);

    useEffect(() => {

        if (!isSubmitting) {
            return;
        }

        let cancellationToken = new CancellationToken();

        let snoozedUntil = getSnoozedUntilDateTime();

        let promises = props.jobs.map(j => put(`/api/fulfillment/jobs/${j.id}/snooze`, { snoozedUntil, snoozeReason }));

        Promise.all(promises).then((outcomes) => {

            if (cancellationToken.isCancelled) {
                return;
            }

            let successful = outcomes.filter(([ _, ex ]) => !ex);

            if (successful.any()) {
                addToast(`Snoozed ${successful.count()} jobs`, { appearance: 'success' });
            }

            let groupedErrors = outcomes.map(([ _, ex ]) => ex).filter(ex => !!ex).groupBy(e => e.message);

            for (let errorGroup of groupedErrors) {
                addToast(`${errorGroup.key} (${errorGroup.count()})`, { appearance: 'error' });
            }

            setIsSubmitting(false);

            props.onSuccess(snoozedUntil, snoozeReason);

        });

        return cancellationToken;

    }, [ isSubmitting ]);


    function handleSnoozeChanged(event) {
        setSnooze(event.target.value);
    }

    function handleDateChange(event) {
        setDate(event.target.value);
    }

    function handleTimeChanged(event) {
        setTime(event.target.value);
    }

    function handleSnoozeReasonChange(event) {
        setSnoozeReason(event.target.value);
    }

    function getSnoozedUntilDateTime() {

        let dateTime;

        if (snooze == 'Enter Date') {
            dateTime = moment(date + ' ' + time, "YYYY-MM-DD h:mm A");
        }
        else {
            let match = snooze.replace(/s$/, '').concat([ 's' ]).match(/(\d*)\s(hours|days)/);
            dateTime = moment().add(match[1], match[2]);
        }

        return dateTime.format('YYYY-MM-DDTHH:mm:ss');
    }

    function handleSubmit() {
        setIsSubmitting(true);
    }

    function handleCancel() {
        props.onCancel();
    }
    
    return (

        <Modal show={true} centered onHide={handleCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Snooze Jobs</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <p>The following jobs will be snoozed</p>

                <ul>
                    {props.jobs.orderBy(j => j.id).map((job) => (
                        <li key={job.id}>#{job.orderNumber} — {job.shippingName}</li>
                    ))}
                </ul>

                <hr />

                <fieldset disabled={isSubmitting}>

                    {snooze != 'Enter Date' &&

                        <div className="form-group">
                            <label>Snooze Duration</label>

                            <select className="custom-select" onChange={handleSnoozeChanged} value={snooze}>
                                {snoozeOptions.map((snoozeOption) => (
                                    <option key={snoozeOption} value={snoozeOption}>{snoozeOption}</option>
                                ))}
                            </select>
                        </div>

                    }

                    {snooze == 'Enter Date' &&
                    
                        <React.Fragment>

                            <div className="form-group">
                                <label>Snooze Until</label>
                                <input className="form-control" type="date" value={date} onChange={handleDateChange} />
                            </div>

                            <div className="form-group">
                                <label>Time Of Day</label>

                                <select className="custom-select" onChange={handleTimeChanged} value={time}>
                                    {timeOptions.map((timeOption) => (
                                        <option key={timeOption} value={timeOption}>{timeOption}</option>
                                    ))}
                                </select>
                            </div>

                        </React.Fragment>

                    }

                    <div className="form-group">
                        <label>Snooze Reason</label>
                        <textarea className="form-control" value={snoozeReason} onChange={handleSnoozeReasonChange} />
                    </div>

                </fieldset>

            </Modal.Body>

            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={handleCancel}>Cancel</button>

                <button type="button" className="btn btn-primary" onClick={handleSubmit} disabled={isSubmitting}>
                    {isSubmitting &&
                        <span className="spinner-border spinner-border-sm"></span>
                    }
                    {' '}
                    Confirm
                </button>

            </Modal.Footer>
        </Modal>

    );

}

JobsSnoozeModal.propTypes = {
    jobs: PropTypes.array.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};