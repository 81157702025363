import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

import JobPaymentModalInner from 'components/JobPaymentModalInner';

export default function JobPaymentModal({ job, onSuccess, onClose }) {

    return (

        <Modal show={true} centered size="lg" onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Verify Payment</Modal.Title>
            </Modal.Header>

            <JobPaymentModalInner job={job} onSuccess={onSuccess} onCancel={onClose} />

        </Modal>

    );

}

JobPaymentModal.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};