import CallableClass from 'lib/CallableClass';

export class CancellationToken extends CallableClass {

    isCancelled = false;

    __call__() {
        this.isCancelled = true;
    }

    cancel() {
        this.isCancelled = true;
    }

    throwIfCancelled() {
        if (this.isCancelled) {
            throw new OperationCancelledError();
        }
    }

};

export class OperationCancelledError extends Error {
}



