import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { useForm, Controller } from 'react-hook-form';
import { Typeahead, AsyncTypeahead } from 'react-bootstrap-typeahead';
import qs from 'query-string';
import scrollIntoView from 'scroll-into-view-if-needed'
import moment from 'moment';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import { useApi } from 'lib/effects';
import { tryPost as post, tryPut as put } from 'lib/api';
import withFormHelpers from 'lib/formHelpers';
import { CancellationToken } from 'lib/cancellationTokens';

import AppContext from 'AppContext';
import LoadingBar from 'components/LoadingBar';
import Pagination from 'components/Pagination';
import JobStatus from 'components/JobStatus';
import JobsConsolidateModal from 'components/JobsConsolidateModal';
import JobsUnconsolidateModal from 'components/JobsUnconsolidateModal';
import JobsSnoozeModal from 'components/JobsSnoozeModal';
import JobsUnsnoozeModal from 'components/JobsUnsnoozeModal';
import JobsHoldModal from 'components/JobsHoldModal';
import JobsUnholdModal from 'components/JobsUnholdModal';
import JobsResolveModal from 'components/JobsResolveModal';
import JobsTakeOutOfStanddownModal from 'components/JobsTakeOutOfStanddownModal';
import JobBulkShippingAddressModal from 'components/JobBulkShippingAddressModal';
import JobBulkPaymentModal from 'components/JobBulkPaymentModal';
import JobPrintShippingLabelsModal from 'components/JobPrintShippingLabelsModal';
import JobAddTrackingInfoModal from 'components/JobAddTrackingInfoModal';
import JobsPickupModal from 'components/JobsPickupModal';
import JobsDeliverModal from 'components/JobsDeliverModal';
import JobsCollectModal from 'components/JobsCollectModal';

export function Jobs(props) {

    let appContext = useContext(AppContext);
    
    let anchorRef = useRef();

    let { addToast } = useToasts();

    let params = qs.parse(props.location.search, { arrayFormat: 'bracket-separator' });

    let defaultCriteria = { 
        paymentMethods: [], 
        shippingMethods: [],
        countries: [],
        products: [],
        filters: [],
        searchString: null,
        page: 1,
    };

    let currentCriteria = {
        ...defaultCriteria,
        ...params,
        paymentMethods: params.paymentMethods ? params.paymentMethods.map(t => parseInt(t)) : defaultCriteria.paymentMethods,
        shippingMethods: params.shippingMethods ? params.shippingMethods.map(t => parseInt(t)) : defaultCriteria.shippingMethods,
        countries: params.countries ? params.countries.map(t => parseInt(t)) : defaultCriteria.countries,
        products: params.products ? params.products.map(t => parseInt(t)) : defaultCriteria.products,
        page: params.page ? parseInt(params.page) : defaultCriteria.page,
    };

    let form = withFormHelpers(useForm({ shouldUnregister: false }));

    let [ filterOptions, setFilterOptions ] = useState(null);

    let [ paymentMethodOptions, setPaymentMethodOptions ] = useState(null);
    let [ shippingMethodOptions, setShippingMethodOptions ] = useState(null);
    
    let [ isSearching, setIsSearching ] = useState(false);

    let [ jobs, setJobs ] = useState({
        items: [],
        page: 1,
        totalPages: 0,
        totalCount: 0,
    });

    let [ pendingConsolidateOperation, setPendingConsolidateOperation ] = useState(null);
    let [ pendingUnconsolidateOperation, setPendingUnconsolidateOperation ] = useState(null);
    let [ pendingSnoozeOperation, setPendingSnoozeOperation ] = useState(null);
    let [ pendingUnsnoozeOperation, setPendingUnsnoozeOperation ] = useState(null);
    let [ pendingResolveOperation, setPendingResolveOperation ] = useState(null);
    let [ pendingHoldOperation, setPendingHoldOperation ] = useState(null);
    let [ pendingUnholdOperation, setPendingUnholdOperation ] = useState(null);
    let [ pendingTakeOutOfStanddownOperation, setPendingTakeOutOfStanddownOperation ] = useState(null);
    let [ pendingDispatchOperation, setPendingDispatchOperation ] = useState(null);
    let [ pendingUndispatchOperation, setPendingUndispatchOperation ] = useState(null);
    let [ pendingReprintOperation, setPendingReprintOperation ] = useState(null);
    let [ isFixingAddresses, setIsFixingAddresses ] = useState(false);
    let [ isVerifyingPayments, setIsVerifyingPayments ] = useState(false);
    let [ isPrintingShippingLabels, setIsPrintingShippingLabels ] = useState(false);
    let [ pendingAddTrackingInfoOperation, setPendingAddTrackingInfoOperation ] = useState(null);
    let [ pendingPickupOperation, setPendingPickupOperation ] = useState(null);
    let [ pendingDeliverOperation, setPendingDeliverOperation ] = useState(null);
    let [ pendingCollectOperation, setPendingCollectOperation ] = useState(null);

    let [ searchedProducts, setSearchedProducts ] = useState([]);
    let [ pendingProductSearch, setPendingProductSearch ] = useState(null);

    useApi('/api/fulfillment', (response, ex) => {

        if (ex) {
            addToast(ex.message, { appearance: 'error' });
            return;
        }

        setPaymentMethodOptions(response.paymentMethodOptions);
        setShippingMethodOptions(response.shippingMethodOptions);
        setFilterOptions(response.filterOptions);

    }, []);

    useEffect(() => {

        if (currentCriteria.products.any()) {
            setPendingProductSearch({ productIds: currentCriteria.products });
        }

    }, []);

    useEffect(() => {

        if (!pendingProductSearch) {
            return;
        }

        let cancellationToken = new CancellationToken();

        post('/api/inventory/search', {
            ...pendingProductSearch,
            filters: [ 'Unit', 'Include Deprecated' ],
        })
        
            .then(([ response, ex ]) => {

                if (cancellationToken.isCancelled) {
                    return;
                }

                if (ex) {
                    setPendingProductSearch(null);

                    addToast(ex.message, { appearance: 'error' });
                    return;
                }

                let onlyNew = response.items.filter(n => !searchedProducts.any(p => p.id == n.id));

                setSearchedProducts(curr => curr.concat(onlyNew));

                setPendingProductSearch(null);
                
            });

        return cancellationToken;

    }, [ pendingProductSearch ]);

    useEffect(() => {

        form.reset(currentCriteria);

        setIsSearching(true);

        let cancellationToken = new CancellationToken();

        post('/api/fulfillment/search', currentCriteria)
        
            .then(([ response, ex ]) => {

                if (cancellationToken.isCancelled) {
                    return;
                }

                setIsSearching(false);

                if (ex) {
                    addToast(ex.message, { appearance: 'error' });
                    return;
                }

                setJobs(response);

                if (anchorRef.current) {
                    scrollIntoView(anchorRef.current, {
                        scrollMode: 'if-needed',
                        block: 'start',
                    });
                }

            });

        return cancellationToken;

    }, [ props.location ]);

    useEffect(() => {

        if (!pendingDispatchOperation) {
            return;
        }

        let cancellationToken = new CancellationToken();

        let request = pendingDispatchOperation != 'all' ? pendingDispatchOperation.map(j => j.id) : null;

        put('/api/fulfillment/dispatch', request)
        
            .then(([ response, ex ]) => {

                if (cancellationToken.isCancelled) {
                    return;
                }

                setPendingDispatchOperation(null);

                if (ex) {
                    addToast(ex.message, { appearance: 'error' });
                    return;
                }

                if (response.fileUrl) {

                    let printerAppUrl = qs.stringifyUrl({ 
                        url: 'fulfillv2://print', 
                        query: { type: 'Packing Slip', url: response.fileUrl },
                    })
        
                    let printFrame = document.createElement('iframe');
                    printFrame.src = printerAppUrl;
                    printFrame.style = 'display: none;'
                    document.body.appendChild(printFrame);

                }

                if (response.dispatchedCount > 0) {
                    addToast(`Dispatched ${response.dispatchedCount} jobs.`, { appearance: 'success' });
                }
                else if (response.failedCount > 0) {
                    addToast(`Failed to dispatch ${response.failedCount} jobs. Check logs for more information.`, { appearance: 'error' });
                }

                props.history.push(props.location);

            });

        return cancellationToken;

    }, [ pendingDispatchOperation ]);

    useEffect(() => {

        if (!pendingUndispatchOperation) {
            return;
        }

        let cancellationToken = new CancellationToken();

        let promises = pendingUndispatchOperation.map(j => put(`/api/fulfillment/jobs/${j.id}/undispatch`));

        Promise.all(promises).then((outcomes) => {

            if (cancellationToken.isCancelled) {
                return;
            }

            setPendingUndispatchOperation(null);

            let successful = outcomes.filter(([ _, ex ]) => !ex);

            if (successful.any()) {
                addToast(`Undispatched ${successful.count()} jobs`, { appearance: 'success' });
            }

            let groupedErrors = outcomes.map(([ _, ex ]) => ex).filter(ex => !!ex).groupBy(e => e.message);

            for (let errorGroup of groupedErrors) {
                addToast(`${errorGroup.key} (${errorGroup.count()})`, { appearance: 'error' });
            }

            props.history.push(props.location);

        });

        return cancellationToken;

    }, [ pendingUndispatchOperation ]);

    useEffect(() => {

        if (!pendingReprintOperation) {
            return;
        }

        let cancellationToken = new CancellationToken();

        let request = pendingReprintOperation.map(j => j.id);

        put('/api/fulfillment/reprint', request)
        
            .then(([ response, ex ]) => {

                if (cancellationToken.isCancelled) {
                    return;
                }

                setPendingReprintOperation(null);

                if (ex) {
                    addToast(ex.message, { appearance: 'error' });
                    return;
                }

                let printerAppUrl = qs.stringifyUrl({ 
                    url: 'fulfillv2://print', 
                    query: { type: 'Packing Slip', url: response.fileUrl },
                })
    
                let printFrame = document.createElement('iframe');
                printFrame.src = printerAppUrl;
                printFrame.style = 'display: none;'
                document.body.appendChild(printFrame);

                addToast(`Reprinted ${response.printedCount} jobs.`, { appearance: 'success' });

                props.history.push(props.location);

            });

        return cancellationToken;

    }, [ pendingReprintOperation ]);

    function generatePath({ paymentMethods, shippingMethods, countries, products, filters, searchString, page }) {

        let query = {
            filters: !filters.sequenceEqual(defaultCriteria.filters) ? filters : null,
            countries: !countries.sequenceEqual(defaultCriteria.countries) ? countries : null,
            products: !products.sequenceEqual(defaultCriteria.products) ? products : null,
            paymentMethods: !paymentMethods.sequenceEqual(defaultCriteria.paymentMethods) ? paymentMethods : null,
            shippingMethods: !shippingMethods.sequenceEqual(defaultCriteria.shippingMethods) ? shippingMethods : null,
            searchString: searchString || null,
            page: page != 1 ? page : null, 
        };

        return '/fulfillment?' + qs.stringify(query, { skipNull: true, arrayFormat: 'bracket-separator', sort: false });

    }

    function handleSubmit(formData) {
        props.history.push(generatePath({ ...formData, page: 1 }));
    }

    function handleReset() {
        props.history.push(generatePath(defaultCriteria));
    }

    function handleAllJobsChecked(event) {
        for (let job of jobs.items) {
            job.checked = event.target.checked;
        }
        setJobs({ ...jobs });
    }

    function handleJobChecked(job, event) {
        job.checked = event.target.checked;
        setJobs({ ...jobs });
    }

    function handleProductsToggle(job) {
        job.productsExpanded = !job.productsExpanded;
        setJobs({ ...jobs });
    }

    function handleShippingAddressToggle(job) {
        job.shippingAddressExpanded = !job.shippingAddressExpanded;
        setJobs({ ...jobs });
    }

    function handleConsolidateClicked() {
        let selectedJobs = jobs.items.filter(j => !!j.checked);

        if (selectedJobs.count() < 2) {
            return;
        }

        setPendingConsolidateOperation(selectedJobs);
    }

    function handleConsolidateSuccess() {
        setPendingConsolidateOperation(null);
        props.history.push(props.location);
    }

    function handleUnconsolidateSuccess() {
        setPendingUnconsolidateOperation(null);
        props.history.push(props.location);
    }

    function handleBulkSnoozeClicked() {
        let selectedJobs = jobs.items.filter(j => !!j.checked);

        if (!selectedJobs.any()) {
            return;
        }

        setPendingSnoozeOperation(selectedJobs);
    }

    function handleSnoozeSuccess() {
        setPendingSnoozeOperation(null);
        props.history.push(props.location);
    }

    function handleBulkUnsnoozeClicked() {
        let selectedJobs = jobs.items.filter(j => !!j.checked);

        if (!selectedJobs.any()) {
            return;
        }

        setPendingUnsnoozeOperation(selectedJobs);
    }

    function handleUnsnoozeSuccess() {
        setPendingUnsnoozeOperation(null);
        props.history.push(props.location);
    }

    function handleBulkHoldClicked() {
        let selectedJobs = jobs.items.filter(j => !!j.checked);

        if (!selectedJobs.any()) {
            return;
        }

        setPendingHoldOperation(selectedJobs);
    }

    function handleHoldSuccess() {
        setPendingHoldOperation(null);
        props.history.push(props.location);
    }

    function handleBulkResolveClicked() {
        let selectedJobs = jobs.items.filter(j => !!j.checked);

        if (!selectedJobs.any()) {
            return;
        }

        setPendingResolveOperation(selectedJobs);
    }

    function handleResolveSuccess() {
        setPendingResolveOperation(null);
        props.history.push(props.location);
    }

    
    function handleBulkPickupClicked() {
        let selectedJobs = jobs.items.filter(j => !!j.checked);

        if (!selectedJobs.any()) {
            return;
        }

        setPendingPickupOperation(selectedJobs);
    }

    function handlePickupSuccess() {
        setPendingPickupOperation(null);
        props.history.push(props.location);
    }
	

    function handleBulkDeliverClicked() {
        let selectedJobs = jobs.items.filter(j => !!j.checked);

        if (!selectedJobs.any()) {
            return;
        }

        setPendingDeliverOperation(selectedJobs);
    }

    function handleDeliverSuccess() {
        setPendingDeliverOperation(null);
        props.history.push(props.location);
    }
	

    function handleBulkCollectClicked() {
        let selectedJobs = jobs.items.filter(j => !!j.checked);

        if (!selectedJobs.any()) {
            return;
        }

        setPendingCollectOperation(selectedJobs);
    }

    function handleCollectSuccess() {
        setPendingCollectOperation(null);
        props.history.push(props.location);
    }

    function handleBulkUnholdClicked() {
        let selectedJobs = jobs.items.filter(j => !!j.checked);

        if (!selectedJobs.any()) {
            return;
        }

        setPendingUnholdOperation(selectedJobs);
    }

    function handleUnholdSuccess() {
        setPendingUnholdOperation(null);
        props.history.push(props.location);
    }

    function handleBulkTakeOutOfStanddownClicked() {
        let selectedJobs = jobs.items.filter(j => !!j.checked);

        if (!selectedJobs.any()) {
            return;
        }

        setPendingTakeOutOfStanddownOperation(selectedJobs);
    }

    function handleTakeOutOfStanddownSuccess() {
        setPendingTakeOutOfStanddownOperation(null);
        props.history.push(props.location);
    }

    function handleAddTrackingInfoSuccess() {
        setPendingAddTrackingInfoOperation(null);
        props.history.push(props.location);
    }

    function handleBulkDispatchClicked() {
        let selectedJobs = jobs.items.filter(j => !!j.checked);

        if (!selectedJobs.any()) {
            return;
        }

        setPendingDispatchOperation(selectedJobs);
    }

    function handleBulkUndispatchClicked() {
        let selectedJobs = jobs.items.filter(j => !!j.checked);

        if (!selectedJobs.any()) {
            return;
        }

        setPendingUndispatchOperation(selectedJobs);
    }

    function handleBulkReprintClicked() {
        let selectedJobs = jobs.items.filter(j => !!j.checked);

        if (!selectedJobs.any()) {
            return;
        }

        setPendingReprintOperation(selectedJobs);
    }

    function handleFixingAddressesClose() {
        setIsFixingAddresses(false);
        props.history.push(props.location);
    }

    function handleVerifyPaymentsClose() {
        setIsVerifyingPayments(false);
        props.history.push(props.location);
    }

    function handlePrintShippingLabelsClose() {
        setIsPrintingShippingLabels(false);
        props.history.push(props.location);
    }

    if (!filterOptions || !shippingMethodOptions || !paymentMethodOptions) {
        return (
            <LoadingBar />
        );
    }


    return (

        <React.Fragment>

            {(isSearching || pendingDispatchOperation || pendingUndispatchOperation || pendingReprintOperation) &&
                <LoadingBar />
            }

            <div className="mt-3 container-fluid">

                <div className="float-right">
                    <Dropdown as={ButtonGroup} variant="success" alignRight={true}>
                        <Button variant="success" onClick={() => setPendingDispatchOperation('all')}><FontAwesomeIcon icon={[ 'fas', 'arrow-alt-circle-right' ]} fixedWidth /> Dispatch</Button>
                        <Dropdown.Toggle split variant="success" id="dropdown-split-basic" />
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setPendingDispatchOperation('all')}><FontAwesomeIcon icon={[ 'fas', 'arrow-alt-circle-right' ]} fixedWidth /> Dispatch All Ready</Dropdown.Item>
                            <Dropdown.Item disabled={!jobs.items.filter(j => !!j.checked).any()} onClick={handleBulkDispatchClicked}><FontAwesomeIcon icon={[ 'fas', 'check-square' ]} fixedWidth /> Dispatch Selected</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    {' '}
                    <button className="btn btn-tvk-pink" onClick={() => setIsFixingAddresses(true)}><FontAwesomeIcon icon={[ 'fas', 'address-book' ]} fixedWidth /> Fix Addresses</button>
                    {' '}
                    <button className="btn btn-tvk-cyan" onClick={() => setIsPrintingShippingLabels(true)}><FontAwesomeIcon icon={[ 'fas', 'receipt' ]} fixedWidth /> Print Labels</button>
                    {' '}
                    <button className="btn btn-tvk-orange" onClick={() => setIsVerifyingPayments(true)}><FontAwesomeIcon icon={[ 'fas', 'dollar-sign' ]} fixedWidth /> Payments</button>
                    {' '}
                    <DropdownButton as="span" title="Bulk Actions">
                        <Dropdown.Item disabled={!jobs.items.filter(j => !!j.checked).any()} onClick={handleBulkResolveClicked}><FontAwesomeIcon icon={[ 'fas', 'check' ]} fixedWidth /> Resolve Issues</Dropdown.Item>
                        <Dropdown.Item disabled={!jobs.items.filter(j => !!j.checked).any()} onClick={handleBulkTakeOutOfStanddownClicked}><FontAwesomeIcon icon={[ 'fas', 'hourglass-end' ]} fixedWidth /> Take Out Of Standdown</Dropdown.Item>
                        <Dropdown.Item disabled={!jobs.items.filter(j => !!j.checked).any()} onClick={handleBulkSnoozeClicked}><FontAwesomeIcon icon={[ 'fas', 'moon' ]} fixedWidth /> Snooze Jobs</Dropdown.Item>
                        <Dropdown.Item disabled={!jobs.items.filter(j => !!j.checked).any()} onClick={handleBulkUnsnoozeClicked}><FontAwesomeIcon icon={[ 'fas', 'sun' ]} fixedWidth /> Unsnooze Jobs</Dropdown.Item>
                        <Dropdown.Item disabled={!jobs.items.filter(j => !!j.checked).any()} onClick={handleBulkHoldClicked}><FontAwesomeIcon icon={[ 'fas', 'hand-paper' ]} fixedWidth /> Put Jobs On Hold</Dropdown.Item>
                        <Dropdown.Item disabled={!jobs.items.filter(j => !!j.checked).any()} onClick={handleBulkUnholdClicked}><FontAwesomeIcon icon={[ 'far', 'hand-paper' ]} fixedWidth /> Take Jobs Off Hold</Dropdown.Item>
                        <Dropdown.Item disabled={!jobs.items.filter(j => !!j.checked).any()} onClick={handleBulkReprintClicked}><FontAwesomeIcon icon={[ 'fas', 'print' ]} fixedWidth /> Reprint Packing Slips</Dropdown.Item>
                        <Dropdown.Item disabled={jobs.items.filter(j => !!j.checked).count() < 2} onClick={handleConsolidateClicked}><FontAwesomeIcon icon={[ 'fas', 'plus' ]} fixedWidth /> Consolidate Jobs</Dropdown.Item>
                        <Dropdown.Item disabled={!jobs.items.filter(j => !!j.checked).any()} onClick={handleBulkUndispatchClicked}><FontAwesomeIcon icon={[ 'far', 'arrow-alt-circle-left' ]} fixedWidth /> Undispatch Selected</Dropdown.Item>
                    
                        <Dropdown.Item disabled={!jobs.items.filter(j => !!j.checked).any()} onClick={handleBulkPickupClicked}><FontAwesomeIcon icon={['fas', 'truck']} fixedWidth /> Mark Jobs As Picked Up</Dropdown.Item>
                        <Dropdown.Item disabled={!jobs.items.filter(j => !!j.checked).any()} onClick={handleBulkDeliverClicked}><FontAwesomeIcon icon={['fas', 'store']} fixedWidth /> Mark Jobs As Delivered</Dropdown.Item>
                        <Dropdown.Item disabled={!jobs.items.filter(j => !!j.checked).any()} onClick={handleBulkCollectClicked}><FontAwesomeIcon icon={['fas', 'handshake']} fixedWidth /> Mark Jobs As Collected</Dropdown.Item>

                    
                    </DropdownButton>
                    
                </div>

                <h3 className="mb-3"><FontAwesomeIcon icon={[ 'fas', 'hard-hat' ]} fixedWidth /> Jobs</h3>

                <div className="card mb-3">
                    <div className="card-body">

                        <form onSubmit={form.handleSubmit(handleSubmit)}>

                            <fieldset disabled={isSearching}>

                                <div className="form-group form-row mb-0">
                                    <label className="col-sm-1 col-form-label-sm">Payment Method</label>
                                    <div className="col-sm-11">

                                        <Controller
                                            name="paymentMethods"
                                            control={form.control}
                                            render={(renderProps) => (
                                            
                                                <Typeahead
                                                    id="paymentMethods"
                                                    ref={renderProps.ref}
                                                    size="sm"
                                                    multiple={true}
                                                    clearButton={true}
                                                    options={paymentMethodOptions}
                                                    selected={paymentMethodOptions.filter(t => renderProps.value.includes(t.id))}
                                                    inputProps={{
                                                        shouldSelectHint: (shouldSelect, e) => {
                                                            return e.code == 'Enter' || shouldSelect;
                                                        },
                                                    }}
                                                    labelKey={c => c.name}
                                                    onChange={(options) => { renderProps.onChange(options.map(o => o.id)); }}
                                                    onBlur={renderProps.onBlur}

                                                />

                                        )}/>

                                    </div>
                                </div>

                                <div className="form-group form-row mb-0">
                                    <label className="col-sm-1 col-form-label-sm">Shipping Method</label>
                                    <div className="col-sm-11">

                                        <Controller
                                            name="shippingMethods"
                                            control={form.control}
                                            render={(renderProps) => (
                                            
                                                <Typeahead
                                                    id="shippingMethods"
                                                    ref={renderProps.ref}
                                                    size="sm"
                                                    multiple={true}
                                                    clearButton={true}
                                                    options={shippingMethodOptions}
                                                    selected={shippingMethodOptions.filter(t => renderProps.value.includes(t.id))}
                                                    inputProps={{
                                                        shouldSelectHint: (shouldSelect, e) => {
                                                            return e.code == 'Enter' || shouldSelect;
                                                        },
                                                    }}
                                                    labelKey={c => c.name}
                                                    onChange={(options) => { renderProps.onChange(options.map(o => o.id)); }}
                                                    onBlur={renderProps.onBlur}

                                                />

                                        )}/>

                                    </div>
                                </div>

                                <div className="form-group form-row mb-0">
                                    <label className="col-sm-1 col-form-label-sm">Countries</label>
                                    <div className="col-sm-11">

                                        <Controller
                                            name="countries"
                                            control={form.control}
                                            render={(renderProps) => (
                                            
                                                <Typeahead
                                                    id="countries"
                                                    filterBy={() => true}
                                                    ref={renderProps.ref}
                                                    size="sm"
                                                    multiple={true}
                                                    clearButton={true}
                                                    options={appContext.config.countries}
                                                    selected={appContext.config.countries.filter(t => renderProps.value.includes(t.id))}
                                                    inputProps={{
                                                        shouldSelectHint: (shouldSelect, e) => {
                                                            return e.code == 'Enter' || shouldSelect;
                                                        },
                                                    }}
                                                    labelKey={c => c.name}
                                                    onChange={(options) => { renderProps.onChange(options.map(o => o.id)); }}
                                                    onBlur={renderProps.onBlur}

                                                />

                                        )}/>

                                    </div>
                                </div>

                                <div className="form-group form-row mb-0">
                                    <label className="col-sm-1 col-form-label-sm">Products</label>
                                    <div className="col-sm-11">

                                        <Controller
                                            name="products"
                                            control={form.control}
                                            render={(renderProps) => (
                                            
                                                <AsyncTypeahead
                                                    id="products"
                                                    ref={renderProps.ref}
                                                    size="sm"
                                                    multiple={true}
                                                    clearButton={true}
                                                    isLoading={!!pendingProductSearch}
                                                    options={searchedProducts}
                                                    selected={searchedProducts.filter(t => renderProps.value.includes(t.id))}
                                                    labelKey={option => option.name}
                                                    inputProps={{
                                                        shouldSelectHint: (shouldSelect, e) => {
                                                            return e.code == 'Enter' || shouldSelect;
                                                        },
                                                    }}
                                                    onSearch={(searchString) => setPendingProductSearch({ searchString })}
                                                    onChange={(options) => { renderProps.onChange(options.map(o => o.id)); }}
                                                />

                                        )}/>

                                    </div>
                                </div>

                                <div className="form-group form-row mb-0">
                                    <label className="col-sm-1 col-form-label-sm">Additional Filters</label>
                                    <div className="col-sm-11">
    
                                        <Controller
                                            name="filters"
                                            control={form.control}
                                            render={(renderProps) => (
                                            
                                                <Typeahead
                                                    id="filters"
                                                    ref={renderProps.ref}
                                                    size="sm"
                                                    multiple={true}
                                                    clearButton={true}
                                                    options={filterOptions}
                                                    selected={renderProps.value}
                                                    inputProps={{
                                                        shouldSelectHint: (shouldSelect, e) => {
                                                            return e.code == 'Enter' || shouldSelect;
                                                        },
                                                    }}
                                                    onChange={(options) => { renderProps.onChange(options); }}
                                                    onBlur={renderProps.onBlur}

                                                />

                                        )}/>

                                    </div>
                                </div>

                                <div className="form-group form-row mb-0">
                                    <label className="col-sm-1 col-form-label-sm">Search String</label>
                                    <div className="col-sm-11">
                                        <input name="searchString" type="text" ref={form.register} className="form-control form-control-sm mr-sm-2" id="searchString" placeholder="Search string" />
                                    </div>
                                </div>

                                <div className="form-group form-row mb-0">
                                    <div className="col-sm-12">

                                        <button type="submit" className="btn btn-sm btn-primary">
                                            {isSearching &&
                                                <span className="spinner-border spinner-border-sm"></span>
                                            }
                                            {' '}
                                            Search
                                        </button>
                                        {' '}
                                        <button type="button" className="btn btn-sm btn-secondary" onClick={handleReset}>Reset</button>


                                    </div>
                                </div>

                            </fieldset>
                        </form>

                    </div>
                </div>

                <div ref={anchorRef}></div>

                <table className="table">
                    <thead className="thead-dark" style={{ position: 'sticky', top: '0px', zIndex: 1 }}>
                        <tr>
                            <th style={{ width: '1%' }}></th>
                            <th className="text-center" style={{ width: '1%' }}><input type="checkbox" checked={jobs.items.any() && jobs.items.every(p => !!p.checked)} onChange={handleAllJobsChecked} /></th>
                            <th style={{ width: '5%' }}>Order #</th>
                            <th style={{ width: '15%' }}>Ship To</th>
                            <th>Status</th>
                            <th style={{ width: '12%' }}>Total</th>
                            <th style={{ width: '15%' }}>Order Date</th>
                            <th style={{ width: '5%' }}></th>
                        </tr>
                    </thead>

                    <tbody>

                        {jobs.items.map((job) => (
                            <React.Fragment key={job.id}>
                                <tr>
                                    <td className="text-center">
                                        <button className="btn btn-sm p-0" onClick={handleProductsToggle.bind(null, job)}><FontAwesomeIcon icon={[ 'fas', !job.productsExpanded ? 'chevron-down' : 'chevron-up' ]} /></button>
                                    </td>
                                    <td className="text-center" ><input type="checkbox" checked={!!job.checked} onChange={handleJobChecked.bind(null, job)} /></td>
                                    <td><Link className="text-reset" to={`/fulfillment/${job.id}`}>{job.orderNumber}</Link></td>
                                    <td>
                                        
                                        <div style={{ cursor: 'pointer' }} onClick={handleShippingAddressToggle.bind(null, job)}>

                                            {job.shippingMethod.isClickAndCollect &&
                                                <React.Fragment>
                                                    <FontAwesomeIcon icon={[ 'fas', 'store' ]} />
                                                    {' '}
                                                </React.Fragment>
                                            }

                                            {job.shippingMethod.isDhlExpress &&
                                                <React.Fragment>
                                                    <FontAwesomeIcon icon={[ 'fas', 'globe-asia' ]} />
                                                    {' '}
                                                </React.Fragment>
                                            }

                                            {job.shippingMethod.isAusPost &&
                                                <React.Fragment>
                                                    <FontAwesomeIcon icon={[ 'fas', 'plane' ]} />
                                                    {' '}
                                                </React.Fragment>
                                            }

                                            {job.isSaturdayDelivery &&
                                                <React.Fragment>
                                                    <FontAwesomeIcon icon={[ 'far', 'calendar-check' ]} />
                                                    {' '}
                                                </React.Fragment>
                                            }

                                            {job.shippingMethod.isAramex &&
                                                <React.Fragment>
                                                    <FontAwesomeIcon icon={[ 'fas', 'theater-masks' ]} />
                                                    {' '}
                                                </React.Fragment>
                                            }

                                            {!job.shippingAddressExpanded &&
                                                <span>
                                                    {job.shippingAddress.name}
                                                </span>
                                            }

                                            {!!job.shippingAddressExpanded &&
                                                <span style={{ whiteSpace: 'pre-line' }}>
                                                    {job.shippingAddress.addressFormatted}
                                                </span>
                                            }

                                        </div>

                                    </td>
                                    <td><JobStatus job={job} /></td>
                                    <td>{job.combinedShippableItemCount.toLocaleString()} items — ${job.combinedTotal}</td>
                                    <td>{moment(job.orderDate).toDate().toLocaleString()}</td>
                                    <td className="p-0 align-middle">
                                        <DropdownButton size="sm" title="Actions">
                                            <Dropdown.Item disabled={!job.isBlockedByUnresolvedIssues} onClick={() => setPendingResolveOperation([ job ])}><FontAwesomeIcon icon={[ 'fas', 'check' ]} fixedWidth /> Resolve Issues</Dropdown.Item>
                                            <Dropdown.Item disabled={!job.isInStanddown} onClick={() => setPendingTakeOutOfStanddownOperation([ job ])}><FontAwesomeIcon icon={['fas', 'hourglass-end']} fixedWidth /> Take Out Of Standdown</Dropdown.Item>
                                            <Dropdown.Item disabled={job.isDispatched || job.isSnoozed} onClick={() => setPendingSnoozeOperation([ job ])}><FontAwesomeIcon icon={[ 'fas', 'moon' ]} fixedWidth /> Snooze Job</Dropdown.Item>
                                            <Dropdown.Item disabled={!job.isSnoozed} onClick={() => setPendingUnsnoozeOperation([ job ])}><FontAwesomeIcon icon={[ 'fas', 'sun' ]} fixedWidth /> Unsnooze Job</Dropdown.Item>
                                            <Dropdown.Item disabled={job.isTracked || job.isOnHold} onClick={() => setPendingHoldOperation([ job ])}><FontAwesomeIcon icon={[ 'fas', 'hand-paper' ]} fixedWidth /> Put Job On Hold</Dropdown.Item>
                                            <Dropdown.Item disabled={!job.isOnHold} onClick={() => setPendingUnholdOperation([ job ])}><FontAwesomeIcon icon={[ 'far', 'hand-paper' ]} fixedWidth /> Take Job Off Hold</Dropdown.Item>
                                            <Dropdown.Item disabled={!job.isDispatched} onClick={() => setPendingReprintOperation([ job ])}><FontAwesomeIcon icon={[ 'fas', 'print' ]} fixedWidth /> Reprint Packing Slip</Dropdown.Item>
                                            <Dropdown.Item disabled={!job.children.any()} onClick={() => setPendingUnconsolidateOperation(job)}><FontAwesomeIcon icon={[ 'fas', 'minus' ]} fixedWidth /> Unconsolidate Job</Dropdown.Item>
                                            <Dropdown.Item disabled={!job.isDispatched} onClick={() => setPendingUndispatchOperation([ job ])}><FontAwesomeIcon icon={[ 'far', 'arrow-alt-circle-left' ]} fixedWidth /> Undispatch Job</Dropdown.Item>
                                            <Dropdown.Item disabled={!job.isDispatched || job.isTracked} onClick={() => setPendingAddTrackingInfoOperation(job)}><FontAwesomeIcon icon={[ 'fas', 'tape' ]} fixedWidth /> Add Tracking Info</Dropdown.Item>
                                                                    
                                            <Dropdown.Item disabled={job.isNonShippable || !job.isTracked || job.isPickedUp} onClick={() => setPendingPickupOperation([ job ])}><FontAwesomeIcon icon={['fas', 'truck']} fixedWidth /> Mark As Picked Up</Dropdown.Item>
                                            <Dropdown.Item disabled={job.isNonShippable || !job.isPickedUp || job.isDelivered} onClick={() => setPendingDeliverOperation([ job ])}><FontAwesomeIcon icon={['fas', 'store']} fixedWidth /> Mark As Delivered</Dropdown.Item>
                                            <Dropdown.Item disabled={job.isNonShippable || !job.shippingMethod.isClickAndCollect || !job.isDelivered || job.isCollected} onClick={() => setPendingCollectOperation([ job ])}><FontAwesomeIcon icon={['fas', 'handshake']} fixedWidth /> Mark As Collected</Dropdown.Item>

                                            <Dropdown.Divider />
                                            
                                            <Dropdown.Item target="_blank" href={job.orderUrl}><FontAwesomeIcon icon={['fas', 'link']} fixedWidth /> View Order At {job.storeName}</Dropdown.Item>

                                            {!!job.customerUrl &&
                                                <Dropdown.Item target="_blank" href={job.customerUrl}><FontAwesomeIcon icon={['fas', 'link']} fixedWidth /> View Customer At {job.storeName}</Dropdown.Item>
                                            }
                                            {!!job.dearSaleUrl &&
                                                <Dropdown.Item target="_blank" href={job.dearSaleUrl}><FontAwesomeIcon icon={['fas', 'boxes']} fixedWidth /> View Sale In DEAR Inventory</Dropdown.Item>
                                            }
                                        
                                        </DropdownButton>
                                    </td>
                                </tr>
                                
                                {!!job.productsExpanded &&

                                    <tr>
                                        <td className="p-0 border-top-0"></td>
                                        <td className="p-0 border-top-0" colSpan={100}>

                                            <table className="table table-sm">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th style={{ width: '3%' }} className="text-center">Qty</th>
                                                        <th>Product</th>
                                                        <th style={{ width: '10%' }} className="text-center">Unit Price</th>
                                                        <th style={{ width: '10%' }} className="text-center">Total Price</th>
                                                        <th style={{ width: '10%' }} className="text-center">Stock Locator</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {job.combinedItems.map((jobItem, i) => (
                                                        <tr key={i}>
                                                            <td className="text-center">{jobItem.quantity}</td>
                                                            <td>
                                                                <React.Fragment>
                                                                    {jobItem.isGiftCardItem &&
                                                                        <React.Fragment><FontAwesomeIcon icon={['fas', 'gift']} fixedWidth />{' '}</React.Fragment>
                                                                    }
                                                                    {jobItem.isShippingItem &&
                                                                        <React.Fragment><FontAwesomeIcon icon={['fas', 'truck']} fixedWidth />{' '}</React.Fragment>
                                                                    }
                                                                    {jobItem.name?.replaceAll('|', '•')}
                                                                    {jobItem.notes &&
                                                                        <div className="font-italic ml-3">{jobItem.notes}</div>
                                                                    }
                                                                </React.Fragment>
                                                            </td>
                                                            <td className="text-center">${jobItem.unitPrice}</td>
                                                            <td className="text-center">${jobItem.totalPrice}</td>
                                                            <td className="text-center">{jobItem.stockLocator}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>

                                        </td>
                                    </tr>

                                }

                            </React.Fragment>
                        ))}

                        {!jobs.totalCount &&
                            <tr>
                                <td colSpan={100}>No results</td>
                            </tr>
                        }
                    </tbody>

                    {jobs.totalCount > 0 &&
                        <tfoot>
                            <tr>
                                <td colSpan={100} className="text-right px-5">
                                    {jobs.totalCount.toLocaleString()} jobs

                                    {!!jobs.meta &&
                                        <span> — ${jobs.meta.totalValue.toLocaleString()}</span>
                                    }
                                </td>
                            </tr>
                        </tfoot>
                    }

                </table>

                <Pagination page={jobs.page} totalPages={jobs.totalPages} pathGenerator={(page) => generatePath({ ...currentCriteria, page })} />

            </div>

            {pendingConsolidateOperation &&
                <JobsConsolidateModal jobs={pendingConsolidateOperation} onSuccess={handleConsolidateSuccess} onCancel={() => setPendingConsolidateOperation(null)} />
            }

            {pendingUnconsolidateOperation &&
                <JobsUnconsolidateModal parentJob={pendingUnconsolidateOperation} childJobs={pendingUnconsolidateOperation.children} onSuccess={handleUnconsolidateSuccess} onCancel={() => setPendingUnconsolidateOperation(null)} />
            }

            {pendingSnoozeOperation &&
                <JobsSnoozeModal jobs={pendingSnoozeOperation} onSuccess={handleSnoozeSuccess} onCancel={() => setPendingSnoozeOperation(null)} />
            }

            {pendingUnsnoozeOperation &&
                <JobsUnsnoozeModal jobs={pendingUnsnoozeOperation} onSuccess={handleUnsnoozeSuccess} onCancel={() => setPendingUnsnoozeOperation(null)} />
            }

            {pendingHoldOperation &&
                <JobsHoldModal jobs={pendingHoldOperation} onSuccess={handleHoldSuccess} onCancel={() => setPendingHoldOperation(null)} />
            }

            {pendingUnholdOperation &&
                <JobsUnholdModal jobs={pendingUnholdOperation} onSuccess={handleUnholdSuccess} onCancel={() => setPendingUnholdOperation(null)} />
            }

            {pendingResolveOperation &&
                <JobsResolveModal jobs={pendingResolveOperation} onSuccess={handleResolveSuccess} onCancel={() => setPendingResolveOperation(null)} />
            }

            {pendingPickupOperation &&
                <JobsPickupModal jobs={pendingPickupOperation} onSuccess={handlePickupSuccess} onCancel={() => setPendingPickupOperation(null)} />
            }
			
            {pendingDeliverOperation &&
                <JobsDeliverModal jobs={pendingDeliverOperation} onSuccess={handleDeliverSuccess} onCancel={() => setPendingDeliverOperation(null)} />
            }
			
            {pendingCollectOperation &&
                <JobsCollectModal jobs={pendingCollectOperation} onSuccess={handleCollectSuccess} onCancel={() => setPendingCollectOperation(null)} />
            }

            {pendingTakeOutOfStanddownOperation &&
                <JobsTakeOutOfStanddownModal jobs={pendingTakeOutOfStanddownOperation} onSuccess={handleTakeOutOfStanddownSuccess} onCancel={() => setPendingTakeOutOfStanddownOperation(null)} />
            }

            {isFixingAddresses &&
                <JobBulkShippingAddressModal onClose={handleFixingAddressesClose} />
            }
            
            {isVerifyingPayments &&
                <JobBulkPaymentModal onClose={handleVerifyPaymentsClose} />
            }

            {isPrintingShippingLabels &&
                <JobPrintShippingLabelsModal onClose={handlePrintShippingLabelsClose} />
            }

            {!!pendingAddTrackingInfoOperation &&
                <JobAddTrackingInfoModal job={pendingAddTrackingInfoOperation} onSuccess={handleAddTrackingInfoSuccess} onCancel={() => setPendingAddTrackingInfoOperation(null)} />
            }

        </React.Fragment>

    );

}

Jobs.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

export default withRouter(Jobs);