import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import Modal from 'react-bootstrap/Modal';
import { Chart } from "react-google-charts";

import { useApi } from 'lib/effects';

export default function InventoryProductsDetailsModal(props) {

    let { addToast } = useToasts();

    let [ timeline, setTimeline ] = useState(null);

    useApi(`/api/inventory/product/${props.product.id}/timeline`, { analyse: props.analyse }, (response, ex) => {

        if (ex) {

            props.onClose();

            addToast(ex.message, { appearance: 'error' });
            return; 

        }

        setTimeline(response);

    }, []);

    function getSalesData() {
        return timeline.map(ti => {

            let eventsTitle = ti.events.map(e => e.title).join(', ') || null;
            let eventsDescription = ti.events.map(e => e.description).join(', ') || null;

            return [ new Date(ti.date), ti.externalSold, ti.sold, eventsTitle, eventsDescription ];
        });
    }

    function getOnHandData() {
        return timeline.map(ti => [ new Date(ti.date), ti.onHand ]);
    }

    if (!timeline) {
        return null;
    }

    return (

        <Modal show={true} centered size="xl" onHide={props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{props.product.name}</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <Chart
                    chartType="LineChart"
                    columns={[
                        {
                            type: 'date',
                            label: 'Date',
                        },
                        {
                            type: 'number',
                            label: 'Ext. Sold',
                        },
                        {
                            type: 'number',
                            label: 'Sold',
                        },
                        {
                            type: 'string',
                            role: 'annotation',
                        },
                        {
                            type: 'string',
                            role: 'annotationText',
                        },
                    ]}
                    options={
                        {
                            title: 'Sales',
                            colors: [ '#ff9900', '#3366cc' ],
                            legend: 'none',
                            height: 300,
                            hAxis: {
                                minValue: new Date(timeline.first().date),
                                maxValue: new Date(timeline.last().date),
                            },
                            vAxis: {
                                minValue: 0,
                            },
                            annotations: {
                                style: 'line',
                            },
                            trendlines: {
                                1: {
                                    tooltip: false,
                                    type: 'linear',
                                    labelInLegend: 'Sale Trend',
                                    visibleInLegend: true,
                                    lineWidth: 1
                                },
                            },
                        }
                    }
                    rows={getSalesData()}
                />

                <Chart
                    chartType="AreaChart"
                    columns={[
                        {
                            type: 'date',
                            label: 'Date',
                        },
                        {
                            type: 'number',
                            label: 'On Hand',
                        },
                    ]}
                    options={
                        {
                            title: 'On Hand',
                            legend: 'none',
                            height: 300,
                            hAxis: {
                                minValue: new Date(timeline.first().date),
                                maxValue: new Date(timeline.last().date),
                            },
                            vAxis: {
                                minValue: 0,
                            }
                        }
                    }
                    rows={getOnHandData()}
                />

            </Modal.Body>
        </Modal>

    );

}

InventoryProductsDetailsModal.propTypes = {
    product: PropTypes.object.isRequired,
    analyse: PropTypes.string.isRequired,
    project: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};